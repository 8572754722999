import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import QuickTokenCard from '../../../components/QuickTokenCard';
import * as consumerActions from '../../../redux/actions/Consumer';
import googlePlayBadge from '../../../assets/images/google-play-badge.svg';
import appStoreBadge from '../../../assets/images/apple-app-store-badge.svg';
import mainLogo from '../../../assets/images/logo.png';
import { Typography } from '@mui/material';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';

const ViewToken = ({ location }) => {
    const dispatch = useDispatch();
    let history = useHistory();

    const myQueues = useSelector((state) => state.consumerReducer.myQueues.filter((i) => i?.entry_type === 0));
    const [loading, setLoading] = useState(true);

    const getBusinessInfo = useCallback(async () => {
        return new Promise((resolve, reject) => {
            dispatch(consumerActions.getBusinessFromId(location?.state?.businessData?.businessId, resolve, reject));
        });
    }, [dispatch, location]);

    useEffect(() => {
        dispatch(consumerActions.enableMyQueueListener());
        if (location?.state?.businessData?.businessId) {
            getBusinessInfo().then((businessInfo) => {
                dispatch(consumerActions.joinQueue({
                    businessData: {
                        uid: location?.state?.businessData?.businessId,
                        services: location?.state?.selectedServices?.toString(),
                        title: businessInfo?.name,
                        note: location?.state?.note,
                        visitType: location?.state?.visitType,
                        capacity: location?.state?.capacity ? location?.state?.capacity : null,
                    }
                }));
                history.replace({ state: {} });
                setLoading(false);
            });
        } else {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [dispatch, location?.state, getBusinessInfo, history]);

    return (
        <div style={{ background: PRIMARY_COLOR, height: '100vh' }}>
            <div style={{ background: PRIMARY_COLOR, padding: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {myQueues[0] && <QuickTokenCard loading={loading} item={myQueues[0] ? myQueues[0] : null} />}
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <img src={mainLogo} style={{ height: '100px' }} alt={"Main Logo"} />
                <Typography variant='subtitle2' style={{ fontFamily: 'Poppins', color: 'white' }}>
                    <strong>iQueue 365</strong>
                </Typography>
            </div>
            
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px', marginTop: '20px' }}>
                <a href="https://bit.ly/3LSoGWB" target="_blank" rel="noreferrer">
                    <img style={{ height: '40px' }} src={googlePlayBadge} alt="Get it on google play" />
                </a>
                <a href="https://apple.co/38MAOu1" target="_blank" rel="noreferrer">
                    <img style={{ height: '40px', marginLeft: '10px' }} src={appStoreBadge} alt="Get it on app store" />
                </a>
            </div>
        </div>);
}

export default ViewToken;