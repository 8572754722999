import React, { useRef } from "react";
import { Divider, Typography/*, IconButton*/ } from "@mui/material";
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import QueueCard from "./queueCard";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';

const EmployeeRow = ({ employee, employeeQueueList, dropableId, highlightedEmployee, scrollerRef }) => {

    // let scrollRef = useRef(null);
    let rowRef = useRef(null);

    React.useEffect(() => {
        if(employee.uid !== highlightedEmployee) return;
        
        const containerPos = scrollerRef.current.getBoundingClientRect();
        const elPos = rowRef.current.getBoundingClientRect();

        if(containerPos.top <= elPos.top && elPos.bottom <= containerPos.bottom) return;

        scrollerRef.current.scrollTo({
            behaviour: "smooth",
            top: elPos.top - containerPos.top
        });
    }, [highlightedEmployee, employee, scrollerRef]);

    // const scroll = (scrollOff) => {
    //     const { scrollLeft } = scrollRef.current;
    //     scrollRef.current.scrollTo({ left: scrollOff + scrollLeft, behavior: 'smooth' });
    // };
    return (
        <div ref={rowRef}>
            <div style={{ display: 'flex', width: "98vw"}}>
                <div style={{ margin: '10px 0 10px 10px', textAlign: 'center', display: 'flex' }}>
                    <div style={{ background: 'green', marginLeft: '10px', width: '4px', height: '140px' }} />
                    <div style={employee.uid === highlightedEmployee ? { background: `linear-gradient(120deg, #ffffff 0%, ${PRIMARY_COLOR} 100%)`, height: '140px', borderRadius: '5px', width: "110px" } : { background: 'white', height: '140px', width: "110px" }}>
                        <Typography noWrap style={{
                            color: 'black', fontSize: "12px", fontFamily: 'Poppins', padding: '5px', 
                            marginLeft: '10px', borderRadius: '10px'
                        }}>
                            {employee?.name}
                        </Typography>
                        <PlaylistAddIcon fontSize="small" style={{ marginLeft: '10px' }} />
                        <Typography style={{
                            color: 'black', fontSize: '9px',
                            fontFamily: 'Poppins', padding: '5px', marginLeft: '10px', borderRadius: '10px',
                        }}>
                            Drag and drop a queue entry next to this employee to assign
                        </Typography>
                    </div>
                </div>
                {/*
                    employeeQueueList.length !== 0 && 
                    <div style={{ position: 'relative', display: "flex", alignItems: "center", zoom: "0.75" }}>
                        <IconButton style={{ position: 'absolute', zIndex: 99, background: "#333333dd" }} onClick={() => scroll(-600)}>
                            <ArrowBackIosIcon sx={{ color: "#fff", transform: "translate(5px)" }} />
                        </IconButton>
                    </div>
                    */}
                <div /*ref={scrollRef} */style={{ width: '100%', marginLeft: '20px', marginBottom: 0, display: "flex", alignItems: "center" }}>
                    <Droppable ignoreContainerClipping={true} droppableId={dropableId} direction="horizontal">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{ display: 'flex', height: '140px', alignItems: "center" }}>
                                {employeeQueueList.sort((a, b) => (b.created_timestamp - a.created_timestamp)).map((i, index) => (
                                    <Draggable draggableId={i.id} index={index} key={i.id}>
                                        {(provided, snapshot) => {
                                            return (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <QueueCard item={i} />
                                                {provided.placeholder}
                                            </div>
                                        )}}
                                    </Draggable>
                                ))}
                                <span
                                    style={{
                                        display: "none"
                                    }}
                                >
                                    {provided.placeholder}
                                </span>

                            </div>
                        )}
                    </Droppable>
                </div>

                {/*   
                    employeeQueueList.length !== 0 && 
                    <div style={{ position: 'relative', display: "flex", alignItems: "center", right: "40px", zoom: "0.75" }}>
                        <IconButton style={{ position: 'absolute', zIndex: 99, background: "#333333dd" }} onClick={() => scroll(600)}>
                            <ArrowForwardIosIcon sx={{ color: "#fff" }} />
                        </IconButton>
                    </div>
                                */}
            </div>
            <Divider />
        </div >)
}

export default EmployeeRow;