import * as types from './types';

export function loadNearByBusinesses(center, km) {
    return {
        type: types.LOAD_NEARBY_BUSINESSES,
        center,
        km
    };
};

export function updateNearbyBusinesses(businesses) {
    return {
        type: types.UPDATE_NEARBY_BUSINESSES,
        businesses
    };
};

export function enableBusinessesListener() {
    return {
        type: types.ENABLE_BUSINESSES_LISTENER
    };
};

export function enableMyQueueListener() {
    return {
        type: types.ENABLE_MY_QUEUE_LISTENER
    };
}

export function updateMyQueueAdd(iQueue) {
    return {
        type: types.UPDATE_MY_QUEUE_ADD,
        iQueue
    };
}

export function updateMyQueueChange(iQueue) {
    return {
        type: types.UPDATE_MY_QUEUE_CHANGE,
        iQueue
    };
}

export function updateMyQueueRemove(iQueue) {
    return {
        type: types.UPDATE_MY_QUEUE_REMOVE,
        iQueue
    };
}

export function joinQueue(joinData) {
    return {
        type: types.JOIN_QUEUE,
        joinData
    };
}

export function getBusinessFromId(businessId, resolve, reject) {
    return {
        type: types.GET_BUSINESS_FROM_ID,
        businessId,
        resolve,
        reject
    };
}

export function getEmployeeFromId(businessId, employeeId, resolve, reject) {
    return {
        type: types.GET_EMPLOYEE_FROM_ID,
        businessId,
        employeeId,
        resolve,
        reject
    };
}

export function rateIQueueEntry(iQueue, rating) {
    return {
        type: types.RATE_QUEUE_ENTRY,
        iQueue,
        rating
    };
}