export const SAVE_DEFAULT_TABLE = 'SAVE_DEFAULT_TABLE';
export const LOAD_DEFAULT_TABLES = 'LOAD_DEFAULT_TABLES';
export const DELETE_DEFAULT_TABLE = 'DELETE_DEFAULT_TABLE';

export const SAVE_FLOOR_SETUP = 'SAVE_FLOOR_SETUP';
export const LOAD_FLOOR_SETUP = 'LOAD_FLOOR_SETUP';

export const UPDATE_FLOOR_CLIENT_SIDE = 'UPDATE_FLOOR_CLIENT_SIDE';

export const ASSIGN_CUSTOMER_TO_TABLE = 'ASSIGN_CUSTOMER_TO_TABLE';
export const UNASSIGN_CUSTOMER_FROM_TABLE = 'UNASSIGN_CUSTOMER_FROM_TABLE';

export const SAVE_FLOOR_SIZE = 'SAVE_FLOOR_SIZE';
export const LOAD_FLOOR_SIZE = 'LOAD_FLOOR_SIZE';