import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
//import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
//import { Divider } from "@mui/material";
//import Avatar from "@mui/material/Avatar";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import * as BusinessActions from "../../redux/actions/Business";
//import { stringAvatar } from '../../../../utils/stringToInitial';
import { PRIMARY_COLOR } from '../../config/appStyleConfig';

export default function CalendarCard({ item, setEvent }) {
  const { businessData } = useSelector((state) => state.authReducer);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showNote, setShowNote] = React.useState(false)
  const sendReminderNotification = () => {
    dispatch(BusinessActions.sendReminderNotification(item));
  }
  const handleShowNoteClose = () => {
    setShowNote(false);
  };
  const handleAccept = (item) => {
    dispatch(BusinessActions.updateQueueStatus({ ...item, status: 1 }));
    setEvent(null);
  };
  const handleReject = (item) => {
    dispatch(BusinessActions.updateQueueStatus({ ...item, status: -2 }));
    setEvent(null);
  };
  const handleRemainingTime = () => {

    const minute = 1000 * 60;
    const hour = minute * 60;
    const dayi = hour * 24;

    let currTime = new Date().getTime();
    let appntTime = new Date(parseInt(item?.created_timestamp)).getTime();
    let diff = appntTime - currTime;
    let days = Math.floor(diff / dayi);
    let hrs = Math.floor((diff % dayi) / hour);
    let mins = Math.floor((diff % hour) / minute);

    if (days < 0 || hrs < 0 || mins < 0) {
      days = 0;
      hrs = 0;
      mins = 0;
    }

    return (days === 0) ? hrs + " Hour " + mins + " Minutes" : days + " Day " + hrs + " Hour " + mins + " Minutes";
  }
  return (
    <Card className={classes.cardRootExpanded}>
      <Grid
        style={{ position: "relative" }}
        container
        direction="column"
        spacing={0.9}
      >
        <Grid item style={{ height: "13px", background: "grey" }}></Grid>
        <Grid item>
          <Typography
            style={{
              fontFamily: "Poppins",
              marginTop: "8px",
              fontStyle: "normal",
              fontWeight: "800",
              fontSize: "14px",
            }}
            align="center"
          >
            {item?.title}
          </Typography>
        </Grid>
        <div
          style={{
            display: "flex",
            marginLeft: "15px",
            marginBottom: "5px",
            marginTop: "5px",
          }}
        >
          <HourglassEmptyIcon sx={{ fontSize: 15, marginTop: "2px" }} />
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "13px",
              marginLeft: "5px",
            }}
            align="left"
            variant="subtitle2"
            color="text.secondary"
          >
            {handleRemainingTime()}
          </Typography>
        </div>
        <div
          style={{ display: "flex", marginLeft: "15px", marginBottom: "5px" }}
        >
          <ConfirmationNumberIcon sx={{ fontSize: 15, marginTop: "2px" }} />
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "13px",
              marginLeft: "5px",
            }}
            align="left"
            variant="subtitle2"
            color="text.secondary"
          >
            {item?.token ? item?.token : "Generating Token"}
          </Typography>
        </div>
        {businessData.category !== "Restaurant" && <div
          style={{ display: "flex", marginLeft: "15px", marginBottom: "5px" }}
        >
          <PlaylistAddCheckIcon sx={{ fontSize: 15, marginTop: "2px" }} />
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "13px",
              marginLeft: "5px",
              maxWidth: "150px",
              minWidth: "150px",
            }}
            align="left"
            variant="subtitle2"
            color="text.secondary"
            noWrap
          >
            {item?.services ? `${item?.services?.split(',').map((i) => i.split(';')[0]).join(", ")}` : "No Service"}
          </Typography>
        </div>}
        {item?.capacity && <div
          style={{ display: "flex", marginLeft: "15px", marginBottom: "5px" }}
        >
          <PlaylistAddCheckIcon sx={{ fontSize: 15, marginTop: "2px" }} />
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "13px",
              marginLeft: "5px",
              maxWidth: "150px",
              minWidth: "150px",
            }}
            align="left"
            variant="subtitle2"
            color="text.secondary"
            noWrap
          >
            Capacity {item?.capacity}
          </Typography>
        </div>}
        <div
          style={{ display: "flex", marginLeft: "15px", marginBottom: "5px" }}
        >
          <AccessTimeIcon sx={{ fontSize: 15, marginTop: "2px" }} />
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "13px",
              marginLeft: "5px",
            }}
            align="left"
            variant="subtitle2"
            color="text.secondary"
          >
            {new Date(parseInt(item?.created_timestamp)).toLocaleTimeString(
              [],
              { hour: "2-digit", minute: "2-digit" }
            )}
          </Typography>
        </div>
        <div style={{ display: "flex", marginLeft: "15px", marginBottom: "5px" }}>
          <LocationOnIcon sx={{ fontSize: 15, marginTop: "2px" }} />
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "13px",
              marginLeft: "5px",
            }}
            align="left"
            variant="subtitle2"
            color="text.secondary"
          >
            {item?.video_visit === true ? item.status === 0 ? "Done" : 'Not yet joined' : item?.reached ? "Waiting in the lobby" : "Location not shared"}
          </Typography>
        </div>
        <div style={{ display: "flex", marginLeft: "15px" }}>
          <CallOutlinedIcon sx={{ fontSize: 15, marginTop: "2px" }} />
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "13px",
              marginLeft: "5px",
            }}
            align="left"
            variant="subtitle2"
            color="text.secondary"
          >
            {item?.customer_contact ? item?.customer_contact : 'Not available'}
          </Typography>
        </div>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          {item.status === 1 && <Tooltip title="Send Reminder Notification" placement="left">
            <IconButton onClick={() => sendReminderNotification()} style={{ color: PRIMARY_COLOR }} size="small">
              <NotificationsNoneOutlinedIcon style={{ width: "20px" }} />
            </IconButton>
          </Tooltip>}
          <Tooltip title="Show Note" placement="right">
            <IconButton onClick={() => { setShowNote(true) }} style={{ color: PRIMARY_COLOR }} size="small">
              <ChatBubbleOutlineOutlinedIcon style={{ width: "20px" }} />
            </IconButton>
          </Tooltip>
          <Dialog
            open={showNote}
            onClose={handleShowNoteClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='xs'
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">Note</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {item?.appointment_note ? item?.appointment_note : 'No Note Added'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleShowNoteClose}>Close</Button>
            </DialogActions>
          </Dialog>
          {/* <IconButton style={{ color: PRIMARY_COLOR }} size="small">
            <CallOutlinedIcon style={{ width: "20px" }} />
          </IconButton> */}
        </Grid>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {item.status === 2 && <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleAccept(item);
            }}
          >
            Accept
          </Button>}
          {item.status !== 0 && item.status !== 3 && item.status !== 5 && item.status !== 4 && < Button
            variant="contained"
            color="error"
            onClick={() => {
              handleReject(item);
            }}
          >
            Decline
          </Button>}
        </Stack>
      </Grid>
    </Card >
  );
}
