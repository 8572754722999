import React, { useRef, useEffect} from 'react';
import { Loader } from "@googlemaps/js-api-loader"
import {  mapApiKey } from '../../../../config/firebaseConfig';
import { Button, Grid } from '@mui/material';
import { useStyles } from '../../../Signup/Business/styles';

const loader = new Loader({
    // apiKey: firebaseConfig.apiKey,
    apiKey: mapApiKey.apiKey,
    version: "weekly",
    libraries: ["places"]
});


function getLongAndLat() {
    return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
    );
}

const MapComponent = ({data, setLatPos, setLngPos, isEdit}) => {
    let marker = useRef();
    let map = useRef();
    let mapContainer = useRef();
    const classes = useStyles();

    useEffect(() => {
        if (map.current) return;
        loader.load().then(() => {
            var myStyles = [{
                featureType: "poi",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            }
            ];
            map.current = new window.google.maps.Map(mapContainer.current, {
                center: { lat: data.lat, lng: data.lng },
                zoom: (data?.lat === 23.785345 && data?.lng === -28.580709) ? 1.5 : 15,
                mapTypeControl: false,
                streetViewControl: false,
                disableDefaultUI: true,
                clickableIcons: false,
                styles: myStyles
            });
            
            marker.current = new window.google.maps.Marker({
                position: { lat: data.lat, lng: data.lng },
                map: map.current,
                optimized: false,
                title: "Your Location",
            });

            map.current.addListener("center_changed", () => {
                marker.current.setPosition(new window.google.maps.LatLng(map.current.getCenter().lat(), map.current.getCenter().lng()));
                setLatPos(map.current.getCenter().lat());
                setLngPos(map.current.getCenter().lng());
            });
        });
    }, [data, setLatPos, setLngPos]);

    const getPosition = async () => {
        try{
            const position = await getLongAndLat();
            const [curLat, curLng] = [position.coords.latitude, position.coords.longitude];
            setLatPos(curLat);
            setLngPos(curLng);
            map.current.setCenter({ lat: curLat, lng: curLng });
            map.current.setZoom(15);
        }
        catch(e) {
            console.error(e.message);
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <div ref={mapContainer} style={{ height: '40vh', maxHeight: '400px', position: 'relative', borderRadius: '5px', pointerEvents: isEdit ? "" : "none" }} />
            </Grid>
            {
                navigator.geolocation &&
                (
                    <Grid item xs={12} sx={{ mt: 4 }}>
                        <Button
                            variant='outlined'
                            className={classes.outlinedButton}
                            fullWidth
                            onClick={getPosition}
                        >
                            Use Current Location
                        </Button>
                    </Grid>
                )
            }
        </Grid>
    );
}

export default MapComponent;