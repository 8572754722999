import React, { useEffect, useState } from 'react';
//import { Link as RLink } from "react-router-dom";
import {
    Button, CssBaseline, FormControlLabel,
    Checkbox, Grid, Box, Typography, Container, createTheme, ThemeProvider,
    CircularProgress, Grow, Snackbar
} from '@mui/material';
import { useStyles } from './styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import MuiAlert from "@mui/material/Alert";
//Firebase
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { getDatabase } from "firebase/database";
//Components
import { SideIntro, Header } from '../../../components/SideIntro';
import OTPVerification from '../../../components/OTPVerification';
import axios from 'axios';

const theme = createTheme();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function BusinessLogin() {
    const classes = useStyles();

    const [otpData, setOTPData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [contryCode, setContryCode] = useState('IN');
    const [snackbarData, setSnackBarData] = useState(null);

    useEffect(() => {
        axios.get('https://1.1.1.1/cdn-cgi/trace').then((response) => {
            setContryCode(response?.data?.split("\n")?.filter(el => el?.startsWith("loc"))?.pop()?.split('=')[1])
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    useEffect(() => {
        window.recaptchaVerifier = null
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const contact = new FormData(event.currentTarget).get('contact');
        if(contact.split(' ').slice(1).join('')) {
            const data = { contact: contact.replace(/[. ,:-]+/g, "").replace(/[{()}]/g, '') };
            if (getDatabase()) {
                signInWithNumber(data);
            }
        }
        else {
            setSnackBarData({
                open: true,
                message: "Please enter contact number",
                severity: 'error'
            });
            setLoading(false);
        }
    };

    const signInWithNumber = (data, renderCaptcha = true, resendOtp = false) => {
        //Use resendOtp to show a snack maybe
        if (renderCaptcha && !window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier('cap-contain', {
                size: 'invisible'
            }, getAuth());
        }

        signInWithPhoneNumber(getAuth(), data.contact, window.recaptchaVerifier)
            .then((confirmationResult) => {
                let newData = {
                    businessLogin: true,
                    userData: data,
                    confirmationResult: confirmationResult
                }
                setOTPData(newData);
                setLoading(false);
            }).catch((error) => {
                console.error(error);
                setSnackBarData({
                    open: true,
                    message: (error?.code === "auth/invalid-phone-number") ? "Invalid contact number" : error?.code,
                    severity: 'error'
                });
                setLoading(false);
            });
    }

    return (
        <ThemeProvider theme={theme}>
            <Header />
            <Grid container sx={{ pb: '25px', minHeight: "calc(100vh - 175px)", display: "flex", alignItems: "center" }}>
                <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Container component="main" maxWidth='sm'>
                        <SideIntro sideTitle={'Welcome to iQueue Business'} sideSubTitle={"Only contact number is needed to login"} />
                    </Container>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography component="h1" variant="h5" style={{ fontFamily: 'Poppins' }}>
                                Sign up or Sign in
                                {/* Sign into your account */}
                            </Typography>
                            {!otpData &&
                                <Grow in>
                                    <Box component="form"
                                        onKeyDown={(e) => {
                                            if (e.code === "Enter") { handleSubmit(e); }
                                        }} onSubmit={handleSubmit} noValidate sx={{ mt: 4, width: '100%' }}>
                                        <PhoneInput
                                            countryCodeEditable={false}
                                            country={contryCode.toLocaleLowerCase()}
                                            preferredCountries={['in', 'us', 'ca']}
                                            inputStyle={{ fontFamily: 'Poppins', width: '100%' }}
                                            inputProps={{
                                                id: "contact",
                                                label: "Mobile Number",
                                                name: "contact",
                                            }}
                                        />

                                        <FormControlLabel
                                            control={<Checkbox sx={{ marginTop: 1 }} value="remember" color="primary" />}
                                            label={<Typography sx={{ fontFamily: 'Poppins', marginTop: 1 }}>Remember me</Typography>}
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            disabled={loading}
                                            variant="contained"
                                            className={classes.styledButton}
                                            style={{ height: '50px', borderRadius: '5px',textTransform: 'none' }}
                                            sx={{ mt: 3, mb: 2, }}
                                            
                                        >
                                            {loading ? <><CircularProgress style={{ color: 'white', float: 'left', width: '20px', height: '20px' }} /> <span style={{ marginLeft: '10px' }}>Signing you in</span></> : 'Sign In/Sign Up'}
                                        </Button>
                                        <Grid container>
                                            <Grid item xs />
                                            <Grid item>
                                                {/*<Link component={RLink} to="/user/login" variant="body2" style={{ fontFamily: 'Poppins' }}>
                                                    {"Switch to iQueue Client"}
                                                </Link>
                                                 <RLink to="/user/login" style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                                                    {"Switch to iQueue Client"}
                                                </RLink> */}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grow>}
                            {otpData &&
                                <OTPVerification
                                    data={otpData}
                                    loading={loading}
                                    setLoading={setLoading}
                                    signInWithNumber={signInWithNumber}
                                />
                            }
                        </Box>
                        <div id="cap-contain" />
                    </Container>
                </Grid>
                {snackbarData && snackbarData.open && (
                    <Snackbar open={snackbarData.open} autoHideDuration={3000} onClose={() => setSnackBarData(null)}>
                        <Alert severity={snackbarData.severity}>{snackbarData.message}</Alert>
                    </Snackbar>
                )}
            </Grid>
        </ThemeProvider>
    );
}