import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    CssBaseline, Grid, Box, Typography, Container,
    ThemeProvider,
} from '@mui/material';
import { theme, useStyles } from './styles';
//Data Validators
import { validateData } from './dataValidators';
//Firebase
import { getDatabase, ref, set } from "firebase/database";
//Components
import { SideIntro, Header } from '../../../components/SideIntro';
//Pages
import { GeneralInfoStep } from './generalInfoStep';
import * as authActions from '../../../redux/actions/Auth';

export default function UserSignup() {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();

    const [loading, setLoading] = useState(false);
    const [activeStep] = useState(0);
    const [consolidateData, setConsolidateData] = useState({
        name: null,
        contact: '+91'
    });
    const [error, setError] = useState({
        name:
        {
            status: false,
            message: ''
        },
        contact: {
            status: false,
            message: ''
        },
    });

    const { auth } = useSelector((state) => state.authReducer);

    useEffect(() => {
        return window.recaptchaVerifier = null;
    }, []);

    const nextStep = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let error = validateData(data, setError);
        if (error) {
            return;
        }
        setConsolidateData({
            name: data.get('name'),
            contact: data.get('contact').replace(/[. ,:-]+/g, "").replace(/[{()}]/g, ''),
        });
        const newData = {
            name: data.get('name'),
            contact: data.get('contact').replace(/[. ,:-]+/g, "").replace(/[{()}]/g, ''),
        }
        setLoading(true);
        if (getDatabase()) {
            signInWithNumber(newData);
        }
    }

    const signInWithNumber = (data) => {
        let newData = {
            userData: data,
        }
        //setOTPData(newData);
        //setActiveStep(1);
        saveDataToDB(newData);
    }
    const saveDataToDB = (data) => {
        setLoading(false);
        const dbData = { ...data?.userData, uid: auth?.uid }
        if (getDatabase()) {
            set(ref(getDatabase(), `iQueue/Restricted/Consumers/${dbData?.uid}/profile/`), dbData)
                .then(() => {
                    dispatch(authActions.setUserLoginData(dbData));
                })
                .catch((error) => {
                    alert(error);
                    history.push({
                        pathname: '/user/home',
                    });
                });
        };
    }
    return (
        <ThemeProvider theme={theme}>
            <Header />
            <Grid container sx={{ pb: '25px', minHeight: "calc(100vh - 175px)", display: "flex", alignItems: "center" }}>
                <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}  >
                    <Container component="main" maxWidth='sm'>
                        <SideIntro sideTitle={'Welcome to iQueue'} sideSubTitle={"Enter your name"} />
                    </Container>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Container component="main" maxWidth='xs'>
                        <CssBaseline />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <Typography component="h1" variant="h5" style={{ zIndex: 9, fontFamily: 'Poppins' }}>
                                Setup your account
                            </Typography>
                            {activeStep === 0 &&
                                <GeneralInfoStep nextStep={nextStep}
                                    consolidateData={consolidateData}
                                    error={error}
                                    setError={setError}
                                    loading={loading}
                                    classes={classes}
                                    setConsolidateData={setConsolidateData} />}
                        </Box>
                        <div id="cap-contain" />
                    </Container>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}