import * as types from './types';

export function enableQueueListener(time) {
    return {
        type: types.ENABLE_QUEUE_LISTENER,
        time
    };
};

export function disableQueueListener(time) {
    return {
        type: types.DISABLE_QUEUE_LISTENER,
        time
    };
};

export function sendNotificationToken(token) {
    return {
        type: types.SEND_NOTIFICATION_TOKEN,
        token
    }
}
export function updateAddQueueState(iQueue) {
    return {
        type: types.UPDATE_ADD_QUEUE_STATE,
        iQueue
    };
};
export function updateDeleteQueueState(iQueue) {
    return {
        type: types.UPDATE_DELETE_QUEUE_STATE,
        iQueue
    };
};

export function updateChangeQueueState(iQueue) {
    return {
        type: types.UPDATE_CHANGE_QUEUE_STATE,
        iQueue
    };
};

export function updateQueueElement(iQueue, time) {
    return {
        type: types.UPDATE_QUEUE_ELEMENT,
        iQueue,
        time
    };
};

export function updateQueueStatus(iQueue) {
    return {
        type: types.UPDATE_QUEUE_STATUS,
        iQueue
    };
};

export function deleteIqueue(iQueue) {
    return {
        type: types.DELETE_IQUEUE,
        iQueue
    }
}
export function updateResetQueueState() {
    return {
        type: types.UPDATE_RESET_QUEUE_STATE
    };
};

export function enableEmployeeListener() {
    return {
        type: types.ENABLE_EMPLOYEE_LISTENER
    };
};


export function updateAddEmployeeState(employee) {
    return {
        type: types.UPDATE_ADD_EMPLOYEE_STATE,
        employee
    };
};
export function updateDeleteEmployeeState(employee) {
    return {
        type: types.UPDATE_DELETE_EMPLOYEE_STATE,
        employee
    };
};
export function updateEmployeeState(employee) {
    return {
        type: types.UPDATE_EMPLOYEE_STATE,
        employee
    };
};

export function sendReminderNotification(iQueue) {
    return {
        type: types.SEND_REMINDER_NOTIFICATION,
        iQueue
    };
};

export function disableSuccessMessage() {
    return {
        type: types.DISABLE_QUEUE_SUCCESS_MESSAGE_STATE,
    };
};
export function getCustomers(business_uid) {
    return {
        type: types.GET_CUSTOMERS,
        business_uid
    };
};
export function updateCustomers(customers) {
    return {
        type: types.UPDATE_CUSTOMERS,
        customers
    };
}
export function deleteCustomer(customerToBeRemoved, business_uid) {
    return {
        type: types.DELETE_CUSTOMER,
        customerToBeRemoved,
        business_uid
    };
};
export function updateCustomerRating(customer, business_uid) {
    return {
        type: types.UPDATE_CUSTOMER_RATING,
        customer,
        business_uid
    };
};
export function updateCustomerBlockStatus(customer, business_uid) {
    return {
        type: types.UPDATE_CUSTOMER_BLOCK_STATUS,
        customer,
        business_uid
    };
};

export function assignEmployeeWork(empId) {
    return {
        type: types.ASSIGN_EMPLOYEE_WORK,
        empId
    };
};

export function updateEmployeeNextFree(employeeId, nextFree) {
    return {
        type: types.UPDATE_EMPLOYEE_NEXT_FREE,
        employeeId,
        nextFree
    };
};

export function sendBugReport(bug) {
    return {
        type: types.SEND_BUG_REPORT,
        bug
    };
};

export function loadNotifications() {
    return {
        type: types.LOAD_NOTIFICATIONS
    };
};

export function updateBusinessNotifications(notifications) {
    return {
        type: types.UPDATE_BUSINESS_NOTIFICATIONS,
        notifications
    };
};

export function markNotificationsAsRead() {
    return {
        type: types.MARK_NOTIFICATIONS_READ,
    };
};

export function uploadBusinessMenu(menu, resolve, reject) {
    return {
        type: types.UPLOAD_BUSINESS_MENU,
        menu,
        resolve,
        reject
    };
};

export function deleteBusinessMenu(resolve, reject) {
    return {
        type: types.DELETE_BUSINESS_MENU,
        resolve,
        reject
    };
};