export const ENABLE_QUEUE_LISTENER = 'ENABLE_QUEUE_LISTENER';
export const DISABLE_QUEUE_LISTENER = 'DISABLE_QUEUE_LISTENER';

export const SEND_NOTIFICATION_TOKEN = 'SEND_NOTIFICATION_SAGA';

export const UPDATE_ADD_QUEUE_STATE = 'UPDATE_ADD_QUEUE_STATE';
export const UPDATE_DELETE_QUEUE_STATE = 'UPDATE_DELETE_QUEUE_STATE';
export const UPDATE_CHANGE_QUEUE_STATE = 'UPDATE_CHANGE_QUEUE_STATE';
export const UPDATE_RESET_QUEUE_STATE = 'UPDATE_RESET_QUEUE_STATE';

export const UPDATE_QUEUE_ELEMENT = 'UPDATE_QUEUE_ELEMENT';
export const UPDATE_QUEUE_STATUS = 'UPDATE_QUEUE_STATUS'
export const DELETE_IQUEUE = 'DELETE_IQUEUE'

export const DISABLE_QUEUE_SUCCESS_MESSAGE_STATE = 'DISABLE_QUEUE_SUCCESS_MESSAGE_STATE';

export const ENABLE_EMPLOYEE_LISTENER = 'ENABLE_EMPLOYEE_LISTENER';
export const DISABLE_EMPLOYEE_LISTENER = 'DISABLE_EMPLOYEE_LISTENER';

export const UPDATE_ADD_EMPLOYEE_STATE = 'UPDATE_ADD_EMPLOYEE_STATE';
export const UPDATE_DELETE_EMPLOYEE_STATE = 'UPDATE_DELETE_EMPLOYEE_STATE';
export const UPDATE_EMPLOYEE_STATE = 'UPDATE_EMPLOYEE_STATE';

export const SEND_REMINDER_NOTIFICATION = 'SEND_REMINDER_NOTIFICATION';

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const UPDATE_CUSTOMER_RATING = "UPDATE_CUSTOMER_RATING";
export const UPDATE_CUSTOMER_BLOCK_STATUS = "UPDATE_CUSTOMER_BLOCK_STATUS";

export const ASSIGN_EMPLOYEE_WORK = "ASSIGN_EMPLOYEE_WORK";
export const UPDATE_EMPLOYEE_NEXT_FREE = "UPDATE_EMPLOYEE_NEXT_FREE";

export const SEND_BUG_REPORT = "SEND_BUG_REPORT";

export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const UPDATE_BUSINESS_NOTIFICATIONS = "UPDATE_BUSINESS_NOTIFICATIONS";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";

export const UPLOAD_BUSINESS_MENU = "UPLOAD_BUSINESS_MENU";
export const DELETE_BUSINESS_MENU = "DELETE_BUSINESS_MENU";