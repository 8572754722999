import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText
} from "@mui/material";

function AppointmentNote({showNote, setShowNote,item}) {

    const handleShowNoteClose = () => {
        setShowNote(false);
    };

    return (<Dialog
        open={showNote}
        onClose={handleShowNoteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">Note</DialogTitle>
        <DialogContent sx={{ width: "450px" }}>
            <DialogContentText id="alert-dialog-description">
                {item?.appointment_note}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleShowNoteClose}>Close</Button>
        </DialogActions>
    </Dialog>);


}



export default AppointmentNote;