import React from "react";
import { Grid, Typography, Button, Divider, Rating, IconButton } from "@mui/material";
import { Favorite as FavoriteIcon, Circle as CircleIcon } from "@mui/icons-material";
import moment from "moment";
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';

const VisitingCard = ({ item }) => {
  const [value, setValue] = React.useState(2);
  return (
    <Grid container style={{ position: "relative", background: "#FFFFFF", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", borderRadius: "5px", width: "320px", gridGap: "1rem", padding: "2rem 1rem 1rem 1rem", marginBottom: '2rem' }}>
      <Grid container item>
        <Grid item>
          <Typography style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: 600, fontSize: "15px", lineHeight: "22px", color: "#2A2A2A" }}>{item?.business_name}</Typography>
          <Typography style={{ fontFamily: "Roboto", fontStyle: "normal", fontWeight: "normal", fontSize: "12px", lineHeight: "14px", color: "#808080" }} variant="caption">
            {moment(item?.created_timestamp).format('lll')}
          </Typography>
        </Grid>
        <IconButton style={{ position: "absolute", top: 5, right: 5, color: "red" }}>
          <FavoriteIcon></FavoriteIcon>
        </IconButton>
      </Grid>
      <Grid style={{ width: "fitContent" }} container item direction="row" alignItems="center">
        <Grid style={{ display: "flex", alignItems: "center" }} item direction="row" alignItems="center">
          <CircleIcon style={{ color: "#2A2A2A", fontSize: "5px" }}></CircleIcon>
          <Typography style={{ fontFamily: "Roboto", fontStyle: "normal", fontWeight: "normal", fontSize: "11px", lineHeight: "13px", color: "#808080", marginLeft: "5px" }}>Hair Cut</Typography>
        </Grid>
        <Divider style={{ border: "0.5px solid #808080", margin: "0 8px" }} orientation="vertical"></Divider>
        <Grid style={{ display: "flex", alignItems: "center" }} item direction="row" alignItems="center">
          <CircleIcon style={{ color: "#2A2A2A", fontSize: "5px" }}></CircleIcon>
          <Typography style={{ fontFamily: "Roboto", fontStyle: "normal", fontWeight: "normal", fontSize: "11px", lineHeight: "13px", color: "#808080", marginLeft: "5px" }}>Shampoo</Typography>
        </Grid>
        <Divider style={{ border: "0.5px solid #808080", margin: "0 8px" }} orientation="vertical"></Divider>
        <Grid style={{ display: "flex", alignItems: "center" }} item direction="row" alignItems="center">
          <CircleIcon style={{ color: "#2A2A2A", fontSize: "5px" }}></CircleIcon>
          <Typography style={{ fontFamily: "Roboto", fontStyle: "normal", fontWeight: "normal", fontSize: "11px", lineHeight: "13px", color: "#808080", marginLeft: "5px" }}>Others</Typography>
        </Grid>
      </Grid>
      <Grid container item>
        <Typography style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: 600, fontSize: "13px", lineHeight: "19px", color: "#2A2A2A" }}>Amount : {item?.amount}</Typography>
      </Grid>
      <Grid container item>
        <Rating
          name="simple-controlled"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        />
      </Grid>
      <Grid style={{ display: "flex" }} container item direction="row" justifyContent="space-between">
        <Button style={{ backgroundColor: PRIMARY_COLOR, flexGrow: "1", marginRight: "3px" }} variant="contained">
          Pay
        </Button>
        <Button style={{ color: PRIMARY_COLOR, borderColor: PRIMARY_COLOR, flexGrow: "1", marginLeft: "3px" }} variant="outlined">
          Call
        </Button>
      </Grid>
      <Grid container item direction="column">
        <Typography style={{ fontFamily: "Roboto", fontStyle: "normal", fontWeight: 500, fontSize: "13px", lineHeight: "15px", textDecorationLine: "underline", color: PRIMARY_COLOR, cursor: "pointer" }} textAlign="center">
          Receipt
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VisitingCard;
