import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Drawer, AppBar, Box, Toolbar, Typography, CssBaseline,
    List, ListItem, ListItemIcon, ListItemText, Divider,
    IconButton, Snackbar, Avatar, Button, Tooltip, Badge
} from "@mui/material";
import GroupsIcon from '@mui/icons-material/Groups';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MenuIcon from "@mui/icons-material/Menu";
import ShareIcon from '@mui/icons-material/Share';
import InfoIcon from '@mui/icons-material/Info';
import BugReportIcon from '@mui/icons-material/BugReport';
import Feedback from '../../Feedback/Business/index';
import MuiAlert from "@mui/material/Alert";
import { useStyles } from "./styles";
import { stringAvatar } from "../../../utils/stringToInitial";
import DashboardBusiness from "../../Dashboard/Business";
import DashboardRestaurant from "../../DashboardRestaurant/Business";
import CalendarView from "../../Calendar/Business";
import Settings from "../../Settings/Business";
import CRM from "../../CRM";
import Analytics from "../../Analytics";
import SpeedDialMenu from "./SpeedDialMenu";
import UserMenu from "./UserMenu";
import InviteCustomers from "../../InviteCustomers";
import About from '../../About';
import { SchedulerRowPlaceHolder } from '../../Dashboard/Business/PlaceHolders'
import { SchedulerRowRestaurantPlaceHolder } from "../../DashboardRestaurant/Business/PlaceHolders";
import SpeedDialMenuRestaurant from "./SpeedDialMenuRestaurant";
import NotificationsIcon from '@mui/icons-material/Notifications';
import * as businessActions from "../../../redux/actions/Business";
import NotificationPopOver from "./NotificationPopover";
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { NavLink } from "react-router-dom";
import DashboardFoodTruckBusiness from "../../DashboardFoodTruck/Business";
import SpeedDialMenuFoodTruck from "./SpeedDialMenuFoodTruck";
import { Coupons } from "../../Coupons";
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import SpeedDialMenuChatBot from "./SpeedDialChatBot";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BusinessHome = () => {
    const dispatch = useDispatch();
    const { businessData } = useSelector((state) => state.authReducer);
    const { tables } = useSelector((state) => state.businessRestaurantReducer);
    const { success, successMessage, employees, notifications } = useSelector((state) => state.businessReducer);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedPageIndex, setSelectedPageIndex] = useState(0);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [anchorElNotification, setAnchorElNotification] = useState(null);

    const classes = useStyles();

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    useEffect(() => {
        setAnchorElNotification(null);
    }, [selectedPageIndex]);

    useEffect(() => {
        dispatch(businessActions.loadNotifications());
    }, [dispatch]);

    const handleNotificationPopover = (event) => {
        if (notifications.length !== 0) {
            if (!anchorElNotification) {
                dispatch(businessActions.markNotificationsAsRead())
                setAnchorElNotification(event.currentTarget);
                return;
            }
            setAnchorElNotification(null);
        }
    }
    return (
        <div>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={() => setDrawerOpen(!drawerOpen)}>
                        <MenuIcon />
                    </IconButton>
                    <Typography noWrap variant="h6" component="div" sx={{ fontSize: "18px", flexGrow: 1, fontFamily: "Poppins", marginLeft: "12px" }}>
                        {businessData.name}
                        <br></br>
                        <Typography noWrap style={{ fontFamily: "Poppins", fontSize: "12px" }}>iQueue Business</Typography>
                    </Typography>
                    <Tooltip title="iQueue Dashboard">
                        <Button startIcon={<GroupsIcon />}
                            className={selectedPageIndex === 0 ? classes.headerButtonSelected : classes.headerButton}
                            onClick={() => setSelectedPageIndex(0)}>
                            <Typography noWrap style={{ fontSize: '13px', fontFamily: 'Poppins' }}>iQueue</Typography>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Open Calendar">
                        <Button startIcon={<CalendarTodayIcon />}
                            className={selectedPageIndex === 1 ? classes.headerButtonSelected : classes.headerButton}
                            onClick={() => setSelectedPageIndex(1)}>
                            <Typography noWrap style={{ fontSize: '13px', fontFamily: 'Poppins' }}>Calendar</Typography>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Share with Customers">
                        <Button startIcon={<ShareIcon />}
                            className={selectedPageIndex === 3 ? classes.headerButtonSelected : classes.headerButton}
                            onClick={() => setSelectedPageIndex(3)}>
                            <Typography noWrap style={{ fontSize: '13px', fontFamily: 'Poppins' }}>Invite Customers</Typography>
                        </Button>
                    </Tooltip>
                    {/* <NavLink style={{ textDecoration: 'none' }} to="/business/subscription">
                        <Tooltip title="Manage Subscriptions">
                            <Button startIcon={<LoyaltyIcon />}
                                className={selectedPageIndex === 6 ? classes.headerButtonSelected : classes.headerButton}
                                style={{ marginRight: "20px" }}
                            >Subscription</Button>
                        </Tooltip>
                    </NavLink> */}
                    {/* <Tooltip title={"Notifications"}> */}
                    <Tooltip title="Manage Coupons">
                        <Button startIcon={<LoyaltyIcon />}
                            className={selectedPageIndex === 8 ? classes.headerButtonSelected : classes.headerButton}
                            // style={{ marginRight: "20px" }}
                            onClick={() => setSelectedPageIndex(8)}>
                            <Typography noWrap style={{ fontSize: '13px', fontFamily: 'Poppins' }}>Coupons</Typography>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Open Settings">
                        <Button startIcon={<SettingsIcon />}
                            className={selectedPageIndex === 5 ? classes.headerButtonSelected : classes.headerButton}
                            style={{ marginRight: "20px" }}
                            onClick={() => setSelectedPageIndex(5)}>
                            <Typography noWrap style={{ fontSize: '13px', fontFamily: 'Poppins' }}>Settings</Typography>
                        </Button>
                    </Tooltip>
                    <IconButton style={{ cursor: "pointer", marginRight: '30px' }}
                        sx={{ width: 34, height: 34 }}
                        onClick={handleNotificationPopover}
                    >
                        <Badge badgeContent={notifications?.filter((i) => i?.read === false)?.length} color="error" max={10}>
                            {anchorElNotification ? <NotificationsOffIcon style={{ color: 'white' }} /> : <NotificationsIcon style={{ color: 'white' }} />}
                        </Badge>
                    </IconButton>
                    {/* </Tooltip> */}
                    <Tooltip title={businessData.name}>
                        <Avatar
                            sx={{ width: 34, height: 34 }}
                            {...stringAvatar(businessData.name)}
                            onClick={handleOpenUserMenu}
                        />
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Drawer
                open={drawerOpen}
                anchor={"left"}
                onClose={() => {
                    setDrawerOpen(false);
                }}
                sx={{
                    width: 260,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: 260,
                        boxSizing: "border-box",
                    },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: "auto" }}>
                    <List>
                        <ListItem
                            button
                            selected={selectedPageIndex === 0}
                            onClick={() => {
                                setSelectedPageIndex(0);
                                setDrawerOpen(false);
                            }}
                        >
                            <ListItemIcon>
                                <GroupsIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography style={{ fontFamily: 'Poppins' }}>
                                iQueue
                            </Typography>}
                                secondary={
                                    <Typography style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'grey' }}>
                                        Home
                                    </Typography>} />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            selected={selectedPageIndex === 1}
                            onClick={() => {
                                setSelectedPageIndex(1);
                                setDrawerOpen(false);
                            }}
                        >
                            <ListItemIcon>
                                <CalendarTodayIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography style={{ fontFamily: 'Poppins' }}>
                                Calendar
                            </Typography>}
                                secondary={
                                    <Typography style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'grey' }}>
                                        View Schedule
                                    </Typography>} />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            selected={selectedPageIndex === 2}
                            onClick={() => {
                                setSelectedPageIndex(2);
                                setDrawerOpen(false);
                            }}
                        >
                            <ListItemIcon>
                                <AnalyticsIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography style={{ fontFamily: 'Poppins' }}>
                                Analytics
                            </Typography>}
                                secondary={
                                    <Typography style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'grey' }}>
                                        Business Analytics
                                    </Typography>} />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            selected={selectedPageIndex === 3}
                            onClick={() => {
                                setSelectedPageIndex(3);
                                setDrawerOpen(false);
                            }}
                        >
                            <ListItemIcon>
                                <ShareIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography style={{ fontFamily: 'Poppins' }}>
                                Invite Customers
                            </Typography>}
                                secondary={
                                    <Typography style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'grey' }}>
                                        Share QR
                                    </Typography>} />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            selected={selectedPageIndex === 4}
                            onClick={() => {
                                setSelectedPageIndex(4);
                                setDrawerOpen(false);
                            }}
                        >
                            <ListItemIcon>
                                <SupportAgentIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography style={{ fontFamily: 'Poppins' }}>
                                CRM
                            </Typography>}
                                secondary={
                                    <Typography style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'grey' }}>
                                        Customer Relationship Management
                                    </Typography>} />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            selected={selectedPageIndex === 5}
                            onClick={() => {
                                setSelectedPageIndex(5);
                                setDrawerOpen(false);
                            }}
                        >
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography style={{ fontFamily: 'Poppins' }}>
                                Settings
                            </Typography>}
                                secondary={
                                    <Typography style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'grey' }}>
                                        Edit Business Details
                                    </Typography>} />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            selected={selectedPageIndex === 6}
                            onClick={() => {
                                setSelectedPageIndex(6);
                                setDrawerOpen(false);
                            }}
                        >
                            <ListItemIcon>
                                <BugReportIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography style={{ fontFamily: 'Poppins' }}>
                                Send Feedback
                            </Typography>}
                                secondary={
                                    <Typography style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'grey' }}>
                                        Report a Bug
                                    </Typography>} />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            selected={selectedPageIndex === 7}
                            onClick={() => {
                                setSelectedPageIndex(7);
                                setDrawerOpen(false);
                            }}
                        >
                            <ListItemIcon>
                                <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography style={{ fontFamily: 'Poppins' }}>
                                About
                            </Typography>}
                                secondary={
                                    <Typography style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'grey' }}>
                                        About us
                                    </Typography>} />
                        </ListItem>
                        <Divider />
                    </List>
                </Box>
            </Drawer>

            <main className={classes.content} onClick={() => setAnchorElNotification(null)}>
                <Toolbar />
                {
                    (() => {
                        switch (selectedPageIndex) {
                            case 0: return businessData.category === "Restaurant" ? <DashboardRestaurant /> : businessData.category === "Food Truck" ? <DashboardFoodTruckBusiness /> : <DashboardBusiness />;
                            case 1: return <CalendarView />;
                            case 2: return <Analytics />
                            case 3: return <InviteCustomers />;
                            case 4: return <CRM />;
                            case 5: return <Settings activeTab={activeTab} setActiveTab={setActiveTab} />;
                            case 6: return <Feedback />;
                            case 7: return <About />;
                            case 8: return <Coupons />;
                            default: return <div></div>;
                        }
                    })()
                }
            </main>

            {success && (
                <Snackbar open={true} autoHideDuration={3000}>
                    <Alert severity="info">{successMessage}</Alert>
                </Snackbar>
            )}
            {/*SpeedDials*/}
            {selectedPageIndex === 0 && employees.length !== 0 && businessData.category !== "Restaurant" && businessData.category !== "Food Truck" && <SpeedDialMenu />}
            {selectedPageIndex === 0 && employees.length === 0 && businessData.category !== "Restaurant" && businessData.category !== "Food Truck" && <SchedulerRowPlaceHolder setActiveTab={setActiveTab} setSelectedPageIndex={setSelectedPageIndex} />}
            {selectedPageIndex === 0 && Object.keys(tables).length !== 0 && businessData.category === "Restaurant" && < SpeedDialMenuRestaurant />}
            {selectedPageIndex === 0 && businessData.category === "Food Truck" && <SpeedDialMenuFoodTruck />}
            {selectedPageIndex === 0 && Object.keys(tables).length === 0 && businessData.category === "Restaurant" && <SchedulerRowRestaurantPlaceHolder setActiveTab={setActiveTab} setSelectedPageIndex={setSelectedPageIndex} />}
            {<SpeedDialMenuChatBot />}
            {<UserMenu setSelectedPageIndex={setSelectedPageIndex} setActiveTab={setActiveTab} anchorElUser={anchorElUser} setAnchorElUser={setAnchorElUser} />}
            {<NotificationPopOver anchorEl={anchorElNotification} />}
        </div>
    );
};

export default BusinessHome;