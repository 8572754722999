import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../config/appStyleConfig';

export const useStyles = makeStyles({
    styledButton: {
        borderRadius: '10px',
        fontFamily: 'Poppins',
        color: 'white',
        background: PRIMARY_COLOR,
        "&:hover": {
            backgroundColor: PRIMARY_COLOR
        }
    },
});