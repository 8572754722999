
import { Card, Grid, IconButton, Typography } from '@mui/material';
import { useDrag } from 'react-dnd'
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from "react-redux";
import * as businessActions from '../../../../redux/actions/Business';
import { useEffect, useState } from 'react';
import { Notifications } from '@mui/icons-material';
import NoteIcon from '@mui/icons-material/Note';
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';
import AppointmentNote from '../../../../components/AppointmentNote/AppointmentNote';
import ConfirmationDialog from '../../../../components/DeleteEntryConfirmationDialog/ConfirmationDialog';
import './stylesCSS.css'


export const DragTest = ({ tables, item, move, setSuggestedTable, setPreferredTable }) => {

    const [showNote, setShowNote] = useState(false);
    const [delEntryItem, setConfirmDeleteItem] = useState(null);

    const dispatch = useDispatch();
    const sendReminderNotification = () => {
        dispatch(businessActions.sendReminderNotification(item));
    }
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'BOX',
        item: item,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                move(item, dropResult.name);
                setSuggestedTable(null);
                setPreferredTable(null);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }));

    useEffect(() => {
        if (isDragging) {
            let preferredTable = Object.keys(tables)
                .find(key => tables[key]?.id === item?.preferred_table);
            if (preferredTable) {
                setPreferredTable(preferredTable);
            }
            let table = Object.keys(tables)
                .sort((a, b) => a.capacity - b.capacity)
                .find(key => tables[key].capacity === item.capacity && !tables[key].busy);
            if (table && !tables[table].busy) {
                setSuggestedTable(table);
                return;
            }
            let tableMore;
            const arr = Object.keys(tables).map(el => {
                return tables[el];
            });
            arr.sort((a, b) => {
                return b.capacity - a.capacity;
            });
            arr.forEach((ii) => {
                if (ii.capacity > item.capacity && !ii.busy) {
                    tableMore = ii.id;
                    return;
                }
            });
            if (tableMore) {
                setSuggestedTable(tableMore);
                return;
            }
        } else {
            setSuggestedTable(null);
            setPreferredTable(null);
        }
    }, [isDragging]);

    return (
        <div className="box" ref={drag} style={{ cursor: 'pointer', position: 'relative' }}>
            <div className="ribbon ribbon-top-left" style={{ marginLeft: "0px", zoom: "0.75" }}><span>Confirmed</span></div>
            <Card
                item={item}
                elevation={0}
                style={{
                    width: '90%', height: '20%',
                    margin: '15px 5px 5px 8px', fontFamily: 'Poppins', fontSize: '12px',
                    padding: '0px 10px 10px 10px',
                    border: '1px solid #A9A9A9',
                }}
            >
                <Grid container spacing={2} >
                    <Grid style={{ textAlign: 'center' }} item xs={5}>
                        <Typography style={{ fontSize: '2vw', color: 'blue', fontFamily: 'Poppins', fontWeight: '600', marginTop: '18px' }}>
                            {item?.capacity ? item.capacity : 'NA'}
                        </Typography>
                        <Typography style={{ fontSize: '0.7vw', color: 'black', fontFamily: 'Poppins', fontWeight: '600', marginBottom: '17px' }}>
                            {new Date(item?.created_timestamp).toLocaleTimeString('en-us', {
                                hour: '2-digit', minute: '2-digit'
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography style={{ fontFamily: 'Poppins', fontSize: '0.7vw', marginTop: '10px' }}>
                            {item?.entry_type === 1 ? 'RESERVE BY' : 'EAT-NOW BY'}
                        </Typography>
                        <Typography noWrap style={{ fontFamily: 'Poppins', fontSize: '0.9vw', fontWeight: '600' }}>
                            {item.customer_name}
                        </Typography>
                        <Typography style={{ fontFamily: 'Poppins', fontSize: '0.9vw', fontWeight: '600' }}>
                            {item.customer_contact}
                        </Typography>
                    </Grid>
                </Grid>
                <div style={{ marginTop: '-10px' }}>
                    <IconButton onClick={() => sendReminderNotification()} style={{ color: PRIMARY_COLOR, background: "#fff", border: `1px solid ${PRIMARY_COLOR}`, marginLeft: '15px', marginTop: '5px' }} size="small">
                        <Notifications style={{ width: '1vw', height: '1vw', color: PRIMARY_COLOR }} />
                    </IconButton>
                    {item?.appointment_note && <IconButton onClick={() => setShowNote(true)} style={{ color: PRIMARY_COLOR, background: "#fff", border: `1px solid ${PRIMARY_COLOR}`, marginLeft: '15px', marginTop: '5px' }} size="small">
                        <NoteIcon style={{ width: '15px', height: '15px', color: PRIMARY_COLOR }} />
                    </IconButton>}
                    <IconButton onClick={() => setConfirmDeleteItem(item)} style={{ color: PRIMARY_COLOR, background: "#fff", border: "1px solid #d32f2f", marginLeft: '15px', marginTop: '5px' }} size="small">
                        <DeleteIcon style={{ width: '1vw', height: '1vw', }} color="error" />
                    </IconButton>
                </div>
                <AppointmentNote item={item} showNote={showNote} setShowNote={setShowNote} />
            </Card>
            {delEntryItem ? <ConfirmationDialog
                headingText={`Delete Entry by ${delEntryItem?.customer_name} ?`}
                descriptionText="Are you sure you want to delete this entry?"
                cancelText="Cancel"
                acceptText="Delete"
                item={delEntryItem}
                setItem={setConfirmDeleteItem}
            /> : null}
        </div>
    )
}