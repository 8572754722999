
import { Card, Grid, IconButton, Typography } from '@mui/material';
import { useDrag } from 'react-dnd'
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from "react-redux";
import * as businessActions from '../../../../redux/actions/Business';
import { useEffect, useState } from 'react';
import { Check, Notifications } from '@mui/icons-material';
import NoteIcon from '@mui/icons-material/Note';
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';
import './stylesCSS.css';
import AppointmentNote from '../../../../components/AppointmentNote/AppointmentNote';

export const DragTest2 = ({ item, move }) => {
    const dispatch = useDispatch();
    const [showNote, setShowNote] = useState(false);
    const sendReminderNotification = () => {
        dispatch(businessActions.sendReminderNotification(item));
    }
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'BOX',
        item: item,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                if (dropResult?.name === "inProgress") {
                    move(item, 5);
                } else if (dropResult?.name === "mainQueue") {
                    move(item, 1)
                }
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }));

    const deleteIqueue = () => {
        dispatch(businessActions.deleteIqueue(item))
    }

    return (
        <div className="box" ref={drag} style={{ cursor: 'pointer', position: 'relative' }}>
            <Card
                item={item}
                elevation={0}
                style={{
                    width: '90%',
                    margin: '15px 5px 5px 8px', fontFamily: 'Poppins', fontSize: '12px',
                    padding: '0px 10px 10px 10px',
                    border: '1px solid #A9A9A9',
                }}
            >
                <Grid container spacing={2} >
                    <Grid style={{ textAlign: 'center', marginLeft: '10px' }} item xs={5}>
                        <Typography style={{ fontSize: '13px', color: 'blue', fontFamily: 'Poppins', fontWeight: '600', marginTop: '25px' }}>
                            {item?.token ? item.token : 'NA'}
                        </Typography>
                        <Typography style={{ fontSize: '11px', color: 'black', fontFamily: 'Poppins', fontWeight: '600', marginBottom: '17px' }}>
                            {new Date(item?.created_timestamp).toLocaleTimeString('en-us', {
                                hour: '2-digit', minute: '2-digit'
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontFamily: 'Poppins', fontSize: '10px', marginTop: '10px', fontWeight: '600' }}>
                            {item?.entry_type === 1 ? 'CATERING BY' : 'TAKE-OUT BY'}
                        </Typography>
                        <Typography noWrap style={{ fontFamily: 'Poppins', fontSize: '13px', fontWeight: '600' }}>
                            {item.customer_name}
                        </Typography>
                        <Typography style={{ fontFamily: 'Poppins', fontSize: '11px', fontWeight: '600' }}>
                            {item.customer_contact}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container >
                    <Typography style={{
                        // overflow: "hidden",
                        // textOverflow: "ellipsis", display: "-webkit-box",
                        // WebkitLineClamp: "2",
                        // WebkitBoxOrient: "vertical",
                        fontSize: '11px', color: 'black', fontFamily: 'Poppins', fontWeight: '600', marginBottom: '15px'
                    }}>
                        {item?.services ? `${item?.services?.split(',').map((i) => i.split(';')[0]).join(", ")}` : 'No menu items selected'}
                    </Typography>
                </Grid>
                <div style={{ marginTop: '-10px' }}>
                    {item?.appointment_note && <IconButton onClick={() => { setShowNote(true) }} style={{ color: PRIMARY_COLOR, background: "#fff", border: `1px solid ${PRIMARY_COLOR}`, marginLeft: '15px', marginTop: '5px' }} size="small">
                        <NoteIcon style={{ width: '15px', height: '15px', color: PRIMARY_COLOR }} />
                    </IconButton>}
                    <IconButton onClick={() => deleteIqueue()} style={{ color: PRIMARY_COLOR, background: "#fff", border: "1px solid #d32f2f", marginLeft: '15px', marginTop: '5px' }} size="small">
                        <DeleteIcon style={{ width: '15px', height: '15px' }} color="error" />
                    </IconButton>
                    <IconButton onClick={() => move(item, 0)} style={{ color: 'green', background: "#fff", border: "1px solid green", marginLeft: '15px', marginTop: '5px' }} size="small">
                        <Check style={{ width: '15px', height: '15px', color: 'green' }} />
                    </IconButton>
                </div>
                <AppointmentNote item={item} showNote={showNote} setShowNote={setShowNote} />
            </Card>
        </div>
    )
}