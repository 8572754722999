import { Box, Button, Grid, Grow, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { useStyles } from "../Timing/styles";
import LeaveDaysCalender from "./LeaveDaysCalender";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useDispatch, useSelector } from "react-redux";
import { updateBusinessDetails } from "../../../../redux/actions/Auth";
import moment from "moment";
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';

const LeaveDays = () => {
  const { businessData } = useSelector((state) => state.authReducer);
  const classes = useStyles();
  const [showLeaveDays, setShowLeaveDays] = useState(false);
  const dispatch = useDispatch();
  const handleCancelLeave = () => {
    dispatch(
      updateBusinessDetails({
        ...businessData,
        leave_days: "",
      })
    );
  };

  const getDates = (leaveDays) => {
    let start = 0;
    let end = 0
    if (leaveDays) {
      let dates = leaveDays.split(",");
      start = moment(parseInt(dates[0])).format('LL');
      end = moment(parseInt(dates[1])).format('LL');
    }
    return { start, end };
  };
  let { start, end } = getDates(businessData?.leave_days)

  return (
    <Grow in>
      <Box sx={{ mt: 3 }}>
        <Grid
          container
          spacing={2}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={6}>
            <CalendarTodayIcon fontSize="large" sx={{ color: PRIMARY_COLOR }} />
          </Grid>

          {!businessData?.leave_days ? (
            <Grid item xs={6}>
              <Typography variant="subtitle">No Leave Scheduled</Typography>
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  Leave Scheduled from:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {start} to {end}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            {!businessData?.leave_days ? (
              <Button
                variant="contained"
                className={classes.styledButton}
                size="large"
                sx={{ height: "50px", borderRadius: "5px", marginTop: "20px" }}
                onClick={() => {
                  setShowLeaveDays(true);
                }}
              >
                Taking a leave ?
              </Button>
            ) : (
              <Button
                variant="contained"
                className={classes.styledButton}
                size="large"
                sx={{
                  height: "50px",
                  borderRadius: "5px",
                  marginTop: "20px"
                }}
                onClick={handleCancelLeave}
              >
                Cancel Leave
              </Button>
            )}
          </Grid>
          <Modal
            open={showLeaveDays}
            onClose={() => setShowLeaveDays(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <LeaveDaysCalender setShowLeaveDays={setShowLeaveDays} />
          </Modal>
        </Grid>
      </Box>
    </Grow>
  );
};

export default LeaveDays;
