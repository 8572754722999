import { all } from "redux-saga/effects";

import { authSaga, businessDetailsSaga, DeleteBusinessSaga, logOutSaga, updateGlobalAnalyticsSaga } from "./AuthSaga";
import {
    businessQueueSaga, businessStopQueueSaga,
    businessEmployeeSaga, businessQueueElementSaga, businessAddEmployeeSaga,
    businessDeleteEmployeeSaga, businessQueueStatusSaga, businessSendReminderNotificationSaga,
    deleteCustomersSaga, getCustomersSaga, updateCustomerRatingSaga, updateCustomerBlockStatusSaga,
    businessDeleteQueueSaga, businessSendBugReportSaga, sendTokenToServerSaga,
    loadBusinessNotificationsSaga, markNotificationsAsReadSaga, uploadBusinessMenuSaga,
    deleteBusinessMenuSaga
} from "./BusinessSaga";

import {
    consumerLoadNearbyBusinessSaga, consumerEnableMyQueueListenerSaga,
    consumerJoinQueueSaga, getBusinessFromIdSaga, getEmployeeFromIdSaga,
    rateIQueueEntrySaga,
} from './ConsumerSaga';

import {
    saveDefaultTableSaga, loadDefaultTableSaga, deleteDefaultTableSaga,
    saveFloorSetupSaga, loadFloorSetupSaga,
    assignCustomerToTableSaga, unAssignCustomerFromTableSaga,
    saveFloorSizeSaga, loadFloorSizeSaga
} from './BusinessRestaurantsSaga';

import {
    createCouponSaga, loadCouponsSaga,
    deleteCouponSaga, uploadGenerateURLSaga,
    shareCouponWithSaga
} from './BusinessCouponsSaga';

export default function* watch() {
    yield all([
        authSaga(),
        DeleteBusinessSaga(),
        logOutSaga(),
        updateGlobalAnalyticsSaga(),

        sendTokenToServerSaga(),
        businessDetailsSaga(),
        businessQueueSaga(),
        businessStopQueueSaga(),
        businessEmployeeSaga(),
        businessQueueElementSaga(),
        businessDeleteQueueSaga(),
        businessAddEmployeeSaga(),
        businessDeleteEmployeeSaga(),
        businessSendReminderNotificationSaga(),
        businessSendBugReportSaga(),
        loadBusinessNotificationsSaga(),
        markNotificationsAsReadSaga(),
        uploadBusinessMenuSaga(),
        deleteBusinessMenuSaga(),

        consumerLoadNearbyBusinessSaga(),
        consumerEnableMyQueueListenerSaga(),
        consumerJoinQueueSaga(),
        getBusinessFromIdSaga(),
        businessQueueStatusSaga(),
        getEmployeeFromIdSaga(),
        rateIQueueEntrySaga(),
        deleteCustomersSaga(),
        getCustomersSaga(),
        updateCustomerRatingSaga(),
        updateCustomerBlockStatusSaga(),


        saveDefaultTableSaga(),
        loadDefaultTableSaga(),
        deleteDefaultTableSaga(),
        saveFloorSetupSaga(),
        loadFloorSetupSaga(),
        assignCustomerToTableSaga(),
        unAssignCustomerFromTableSaga(),
        saveFloorSizeSaga(),
        loadFloorSizeSaga(),

        createCouponSaga(),
        loadCouponsSaga(),
        deleteCouponSaga(),
        uploadGenerateURLSaga(),
        shareCouponWithSaga()
    ]);
}