import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../../../../config/appStyleConfig';

export const useStyles = makeStyles(() => ({
    chatBoxContainer: {
        position: 'absolute',
        bottom: 100,
        right: 20,
    },
    chatBox: {
        width: 320,
        height: 400,

    },
    chatBoxHeader: {
        backgroundColor: PRIMARY_COLOR,
        color: 'blue',
        padding: 10,
        fontWeight: 'bold',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    chatBoxBody: {
        padding: 5,
        overflowY: 'scroll',
        height: 'calc(100% - 100px)',
    },
    inputContainer: {
        display: 'flex',
        padding: 5,
    },
    input: {
        fontFamily: 'Poppins',
        flex: 0.97,
        padding: 2,
        border: `1px solid grey`,
        borderRadius: 3,
    },
    sendButton: {
        padding: 2,
    },
    messageContainer: {
        marginBottom: 2,
    },
    incomingMessage: {
        backgroundColor: PRIMARY_COLOR,
        opacity: 0.9,
        color: 'white',
        padding: '8px 8px 8px 5px',
        borderRadius: 2,
    },
    outgoingMessage: {
        backgroundColor: 'white',
        padding: '8px 8px 8px 5px',
        color: 'black',
        borderRadius: 3,
        textAlign:'right',
        alignSelf: 'flex-start',
    },
}));