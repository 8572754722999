import { Grid, Typography } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import React, { useState } from "react";
import Logo from "../../assets/images/logo.png";
import AboutDialog from "../../components/AboutDialog";
import { PRIMARY_COLOR } from '../../config/appStyleConfig';

const About = () => {
  const [open, setOpen] = useState(false);
  const [typeOfDialogBox, setTypeOfDialogBox] = useState("");
  //typeOfDialogBox=1 : Terms Of use
  //typeOfDialogBox=2 : Third Party Notices

  const toggleDialog = () => {
    setOpen(open => !open);
  }
  
  const handleClickOpenTerms = () => {
    setTypeOfDialogBox(1);
    toggleDialog();
  };
  const handleClickOpenTps = () => {
    setTypeOfDialogBox(2);
    toggleDialog();
  };


  

  return (
    <>
      <Grid container direction={"column"} sx={{ marginTop: "4rem" }} justifyItems={"center"} alignItems={"center"} rowGap={"3rem"}>
        <Grid container item direction={"column"} justifyItems={"center"} alignItems={"center"} rowGap={".8rem"}>
          <Grid>
            <img style={{ width: "65px" }} src={Logo} alt="iQueue logo"></img>
          </Grid>
          <Grid>
            <Typography sx={{ color: PRIMARY_COLOR, fontWeight: "900", fontSize: "22px" }}>iQueue 365 for Business</Typography>
          </Grid>
        </Grid>
        <Grid container item direction={"column"} justifyItems={"center"} alignItems={"center"} rowGap={".6rem"}>
          <Grid item>
            <Typography onClick={handleClickOpenTerms} sx={{ textDecoration: "none", color: "#36454f", fontSize: "18px", fontWeight: "bold", cursor: "pointer" }}>
              Terms of Use
            </Typography>
          </Grid>
          <Grid item>
            <a href="http://iqueue365.com/policies/privacy.html" rel="noreferrer" target="_blank" style={{ textDecoration: "none", color: "#36454f", fontSize: "18px", fontWeight: "bold" }}>
              Privacy Policy
            </a>
          </Grid>

          <Grid item>
            <Typography onClick={handleClickOpenTps} sx={{ textDecoration: "none", color: "#36454f", fontSize: "18px", fontWeight: "bold", cursor: "pointer" }}>
              Third Party Notices
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ display: "flex", alignItems: "center", width: "fit-content", position: "absolute", bottom: "2rem", left: 0, right: 0, margin: "auto" }}>
        <CopyrightIcon fontSize={"4px"} />
        <Typography>Next Generation Mobile Apps LLC. All rights reserved</Typography>
      </div>
      <AboutDialog open={open} toggleDialog={toggleDialog} typeOfDialogBox={typeOfDialogBox} />
    </>
  );
};

export default About;
