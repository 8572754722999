import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    cardRoot: {
        marginLeft: '30px',
        background: "#FFFFFF",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        height: '140px',
        width: "140px",
        marginBottom: '10px'
    },
    cardRootExpanded: {
        margin: '0 12.5px 10px',
        background: "#FFFFFF",
        boxShadow: "1.25px 2.5px 10px rgba(0, 0, 0, 0.3)",
        borderRadius: "5px",
        width: "135px"
    },
    span: {
        fontSize: '11px',
    }
});