import * as React from 'react';
import {
    Card,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteIcon from '@mui/icons-material/Note';
import { useSelector } from "react-redux";
import animationData from '../../../../assets/lottie/empty_notification.json';
import Lottie from 'react-lottie';
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';
import ConfirmationDialog from '../../../../components/DeleteEntryConfirmationDialog/ConfirmationDialog';


export default function ServerEntriesDrawer({ open, setOpen, queueList }) {

    const { iQueues } = useSelector((state) => state.businessReducer);
    const [delEntryItem, setConfirmDeleteItem] = React.useState(null);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const list = () => (
        <Box
            sx={{ width: 300, marginTop: 10 }}
            role="presentation"
        >
            <Typography style={{ textAlign: 'center', fontFamily: 'Poppins', fontSize: '12px', fontWeight: '600' }}>
                Served entries will be shown here
            </Typography>
            {iQueues.filter((queue) => queue.status === 0).length === 0 &&
                <div>
                    <Lottie
                        options={defaultOptions}
                        height={200}
                        width={200}
                    />
                    <Typography style={{ textAlign: 'center', fontFamily: 'Poppins', fontSize: '12px' }}>
                        No entries yet
                    </Typography>
                </div>
            }
            {iQueues.filter((queue) => queue.status === 0).sort((a, b) => (a.created_timestamp - b.created_timestamp)).map((item, index) => (
                <Card
                    item={item}
                    elevation={0}
                    style={{
                        width: '90%',
                        margin: '15px 5px 5px 8px', fontFamily: 'Poppins', fontSize: '12px',
                        padding: '0px 10px 10px 10px',
                        border: '1px solid #A9A9A9',
                    }}
                >
                    <Grid container spacing={2} >
                        <Grid style={{ textAlign: 'center', marginLeft: '10px' }} item xs={5}>
                            <Typography style={{ fontSize: '13px', color: 'blue', fontFamily: 'Poppins', fontWeight: '600', marginTop: '25px' }}>
                                {item?.token ? item.token : 'NA'}
                            </Typography>
                            <Typography style={{ fontSize: '11px', color: 'black', fontFamily: 'Poppins', fontWeight: '600', marginBottom: '17px' }}>
                                {new Date(item?.created_timestamp).toLocaleTimeString('en-us', {
                                    hour: '2-digit', minute: '2-digit'
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{ fontFamily: 'Poppins', fontSize: '10px', marginTop: '10px', fontWeight: '600' }}>
                                {item?.entry_type === 1 ? 'CATERING BY' : 'TAKE-OUT BY'}
                            </Typography>
                            <Typography noWrap style={{ fontFamily: 'Poppins', fontSize: '13px', fontWeight: '600' }}>
                                {item.customer_name}
                            </Typography>
                            <Typography style={{ fontFamily: 'Poppins', fontSize: '11px', fontWeight: '600' }}>
                                {item.customer_contact}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Typography style={{
                            // overflow: "hidden",
                            // textOverflow: "ellipsis", display: "-webkit-box",
                            // WebkitLineClamp: "2",
                            // WebkitBoxOrient: "vertical",
                            fontSize: '11px', color: 'black', fontFamily: 'Poppins', fontWeight: '600', marginBottom: '15px'
                        }}>
                            {item?.services ? `${item?.services?.split(',').map((i) => i.split(';')[0]).join(", ")}` : 'No menu items selected'}
                        </Typography>
                    </Grid>
                    <div style={{ marginTop: '-10px' }}>
                        {item?.appointment_note && <IconButton onClick={() => { }} style={{ color: PRIMARY_COLOR, background: "#fff", border: `1px solid ${PRIMARY_COLOR}`, marginLeft: '15px', marginTop: '5px' }} size="small">
                            <NoteIcon style={{ width: '15px', height: '15px', color: PRIMARY_COLOR }} />
                        </IconButton>}
                        <IconButton onClick={() => setConfirmDeleteItem(item)} style={{ color: PRIMARY_COLOR, background: "#fff", border: "1px solid #d32f2f", marginLeft: '15px', marginTop: '5px' }} size="small">
                            <DeleteIcon style={{ width: '15px', height: '15px' }} color="error" />
                        </IconButton>
                    </div>
                </Card>
            ))}
            {delEntryItem ? <ConfirmationDialog
                headingText="Delete Served Entry?"
                descriptionText="Are you sure you want to delete this served entry?"
                cancelText="Cancel"
                acceptText="Delete"
                item={delEntryItem}
                setItem={setConfirmDeleteItem}
            /> : null}
        </Box>
    );

    return (

        <Drawer
            BackdropProps={{ invisible: true }}
            anchor={'right'}
            open={open}
            onClose={() => setOpen(false)
            }
        >
            {list()}
        </ Drawer>
    );
}