import React from 'react';
import {
    Box, Typography
} from '@mui/material';
import mainLogo from '../../assets/images/logo.png';
import animationData from '../../assets/lottie/waveHeader';
import Lottie from 'react-lottie';

export const SideIntro = ({ sideTitle, sideSubTitle }) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img src={mainLogo} style={{ height: '140px' }} alt={"Main Logo"} />
                <Typography component="h1" variant="h5" style={{ marginTop: '20px', fontFamily: 'Poppins', fontWeight: 800, fontSize: '28px' }}>
                    {sideTitle}
                </Typography>
                <Typography component="h1" variant="h6" style={{ color: '#808080', textAlign: 'center', marginTop: '20px', fontFamily: 'Poppins', fontSize: '22px' }}>
                    {sideSubTitle}
                    {/* Enter your Name, Email address<br/>and select your profile picture. */}
                </Typography>
            </Box>
        </>
    )
}
export const Header = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <Lottie
            options={defaultOptions}
            height={160}
        />)
}