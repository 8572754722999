import { Avatar, Card, Tooltip, Typography } from '@mui/material'
import { useDrop } from 'react-dnd'
import { getImageForCapacity } from '../../../utils/getImageForTable';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useStyles } from './styles';
import useCheckMobileScreen from '../../../components/Hooks/useCheckMobileScreen';

const cardStyles = {
    width: '12vw',
    height: '23vh',
    // background: 'transparent',
    display: 'flex', justifyContent: 'center',
    direction: 'column',
    alignItems: "center"
}

function getStyles(left, top, isDragging) {
    const transform = `translate3d(${left}px, ${top}px, 0)`
    return {
        position: 'absolute',
        transform,
        WebkitTransform: transform,
        // IE fallback: hide the real node using CSS when dragging
        // because IE will ignore our custom "empty image" drag preview.
        opacity: isDragging ? 0 : 1,
        height: isDragging ? 0 : '',
    }
}

export const DropTest = ({ table, myKey, handleUnassign, handleFreeTable, suggestedTable, preferredTable }) => {
    const classes = useStyles();

    const device = useCheckMobileScreen();

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: 'BOX',
        drop: (item, monitor) => {
            // console.log(item)
            return { name: myKey };
        },
    }));

    return (
        <div
            style={getStyles(table.left, table.top, false)} ref={drop}
        >
            <Card
                elevation={0}
                style={{
                    ...cardStyles,
                    height: table.size?.height ? table.size?.height + 20 : '160px',
                    width: table.size?.width ? table.size?.width + 20 : '160px',
                    background: preferredTable === table.id ? 'rgba(147, 250, 165, 0.5)' : suggestedTable === table.id ? 'rgba(138, 117, 206, 0.3)' : 'transparent',
                    transform: `rotate(${table.rotate}deg)`,
                }}
            >
                <div>
                    <div>
                        {device === "pc" && <img  width={table.size.width - 60} src={getImageForCapacity(table.type, table.capacity)} alt={table.capacity} />}
                        {device === "mobile" && <img width={table.size.width - 10} src={getImageForCapacity(table.type, table.capacity)} alt={table.capacity} />}
                        {device === "tablet" && <img width={table.size.width - 20} src={getImageForCapacity(table.type, table.capacity)} alt={table.capacity} />}
                        {!table?.busy && <Avatar
                            className={classes.sizeButton}
                            sx={{
                                width: '1.7vw', height: '1.7vw',
                                top: table?.capacity === 1 ?
                                    '47%' :
                                    table?.capacity === 2 ?
                                        '41%'
                                        :
                                        '41%',
                                left: table?.capacity === 1 ?
                                    '41%' :
                                    table?.capacity === 2 ?
                                        '42%'
                                        :
                                        '43%',
                            }}
                        >
                            <Typography style={{ fontFamily: 'Poppins', fontSize: '1vw' }}>
                                {table?.capacity}
                            </Typography>
                        </Avatar>}
                        {preferredTable === table.id && <Typography
                            style={{
                                backgroundColor: 'rgba(147, 250, 165, 1)',
                                fontSize: '12px',
                                color: 'black',
                                padding: '10px',
                                position: 'absolute',
                                borderRadius: '5px',
                                fontFamily: 'Poppins',
                                justifyContent: 'center',
                                top: '80%',
                                left: '50%',
                                transform: table?.capacity === 1 ?
                                    'translate(-50%, -20%)' :
                                    table?.capacity === 2 ?
                                        'translate(-50%, -60%)'
                                        :
                                        'translate(-50%, -50%)',
                            }}>
                            Preferred Table
                        </Typography>}
                        {table?.customer_name &&
                            <Tooltip
                                title={`${table?.customer_name}`}>
                                <Avatar
                                    className={classes.sizeBusyButton}
                                    sx={{
                                        width: '1.7vw', height: '1.7vw',
                                        top: table?.capacity === 1 ?
                                            '47%' :
                                            table?.capacity === 2 ?
                                                '41%'
                                                :
                                                '41%',
                                        left: table?.capacity === 1 ?
                                            '41%' :
                                            table?.capacity === 2 ?
                                                '42%'
                                                :
                                                '43%',
                                    }}
                                >
                                    <Typography style={{ fontFamily: 'Poppins', fontSize: '1vw' }}>
                                        {table?.capacity}
                                    </Typography>
                                </Avatar>
                            </Tooltip>}
                        {table?.busy &&
                            <Tooltip
                                title={`Unassign`}>
                                <IconButton
                                    onClick={() => handleUnassign(table)}
                                    variant="contained"
                                    size="small"
                                    style={{
                                        zIndex: 999999999,
                                        fontSize: '10px', color: 'white', position: 'absolute',
                                        top: '50%',
                                        left: '15%',
                                        width: '1.8vw',
                                        height: '1.8vw',
                                        backgroundColor: 'red',
                                        opacity: 0.8,
                                        transform: table.capacity === 1 ?
                                            'translate(-50%, -20%)' :
                                            table.capacity === 2 ?
                                                'translate(-50%, -60%)'
                                                :
                                                'translate(-50%, -50%)',
                                    }}><CancelIcon style={{
                                        width: '1.2vw',
                                        height: '1.2vw',
                                    }} /></IconButton>
                            </Tooltip>}
                        {table?.busy &&
                            <Tooltip
                                title={`Completed`}>
                                <IconButton
                                    onClick={() => handleFreeTable(table)}
                                    size="small"
                                    variant="contained"
                                    style={{
                                        zIndex: 9999999999,
                                        fontSize: '10px', color: 'white', position: 'absolute',
                                        top: '50%',
                                        left: '85%',
                                        width: '1.8vw',
                                        height: '1.8vw',
                                        opacity: 0.8,
                                        backgroundColor: 'green',
                                        transform: table.capacity === 1 ?
                                            'translate(-50%, -20%)' :
                                            table.capacity === 2 ?
                                                'translate(-50%, -60%)'
                                                :
                                                'translate(-50%, -50%)',
                                    }}><CheckCircleIcon style={{
                                        width: '1.2vw',
                                        height: '1.2vw',
                                    }} /></IconButton>
                            </Tooltip>}
                    </div>
                </div>
            </Card>
        </div>
    )
}