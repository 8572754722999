import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import CircularProgressBar from "../../../../components/CircularProgressBar/CircularProgressBar";
import QRCode from "react-qr-code";
import WaitLobby from "../../../../components/VideoCall/waitLobby";
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';

const TokenCard = ({ item }) => {
  const [videoCallLobby, setVideoCallLobby] = useState(false);

  return (
    <>
      <Grid zIndex={10} container style={{ position: "relative", background: "#FFFFFF", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", borderRadius: "5px", width: "320px", gridGap: "1rem", padding: "1rem 1rem 1rem 1rem" }}>
        <Grid container item alignItems="center" justifyContent="center" direction="column">
          <Typography style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: 700, fontSize: "13px", lineHeight: "18px", textAlign: "center", color: "#2A2A2A" }}>You are checked into iQueue of</Typography>
          <Typography style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: 900, fontSize: "16px", lineHeight: "18px", textAlign: "center", color: "#2A2A2A" }}>{item?.business_name}</Typography>
        </Grid>
        <Grid container item direction="column">
          <Typography gutterBottom style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "normal", fontSize: "11px", lineHeight: "18px", textAlign: "center", color: "#A3A3A3" }} textAlign="center">
            Token No.
          </Typography>
          <div style={{ textAlign: "center" }}>
            <QRCode size={70} value={item?.token ? item?.token : "Waiting for token"} />
          </div>
          <Typography style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: 800, fontSize: "15px", lineHeight: "18px", textAlign: "center" }} textAlign="center">
            {item?.token ? item?.token : "Waiting for token"}
          </Typography>
        </Grid>

        <Grid container item direction="column">
          <Typography gutterBottom style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: 600, fontSize: "13px", lineHeight: "10px", textAlign: "center" }} align="center">
            You are in Queue at
          </Typography>
          <CircularProgressBar gutterBottom value={item?.current_queue_index} />
          <Typography style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: "normal", fontSize: "11px", lineHeight: "18px", textAlign: "center", color: "#A3A3A3" }} textAlign="center">
            Estimate Time remaining
          </Typography>
        </Grid>
        <Grid container item direction="column">
          <Typography style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: 800, fontSize: "20px", lineHeight: "18px", textAlign: "center" }} textAlign="center">
            0:32:00
          </Typography>
        </Grid>
        <Grid container item justifyContent="space-evenly">
          <Button
            onClick={() => { setVideoCallLobby(true) }}
            style={{ backgroundColor: PRIMARY_COLOR, flexGrow: "1", marginRight: "5px", marginLeft: "10px" }} variant="contained">
            Video Call
          </Button>

          <Button style={{ flexGrow: "1", marginRight: "10px", marginLeft: "5px" }} variant="contained" color="error">
            Cancel
          </Button>
          {item?.is_in_lobby === true && <Typography>Business is waiting in the lobby</Typography>}
        </Grid>

        <div style={{ position: "absolute", width: "30px", height: "30px", right: "-15px", top: " 84px", background: PRIMARY_COLOR, borderRadius: "50%" }}></div>
        <div style={{ position: "absolute", width: "30px", height: "30px", left: "-15px", top: "84px", background: PRIMARY_COLOR, borderRadius: "50%" }}></div>

        {videoCallLobby && <WaitLobby
          videoCallLobby={videoCallLobby}
          setVideoCallLobby={setVideoCallLobby}
          consumerQueueId={item.uid}
          consumerId={item?.customer_uid}
          businessId={item?.business_uid}
          consumerTimeStamp={item?.created_timestamp}
          consumer={true}
          request={{
            callId: item.customer_uid,
            callName: item.customer_name,
            recId: item.business_uid,
            recName: item.business_name,
            initiator: true
          }} />}
      </Grid>
    </>
  );
};

export default TokenCard;
