export const LOAD_NEARBY_BUSINESSES = 'LOAD_NEARBY_BUSINESSES';
export const ENABLE_BUSINESSES_LISTENER = 'ENABLE_BUSINESSES_LISTENER';
export const ENABLE_MY_QUEUE_LISTENER = 'ENABLE_MY_QUEUE_LISTENER';

export const UPDATE_MY_QUEUE_ADD = 'UPDATE_MY_QUEUE_ADD';
export const UPDATE_MY_QUEUE_CHANGE = 'UPDATE_MY_QUEUE_CHANGE';
export const UPDATE_MY_QUEUE_REMOVE = 'UPDATE_MY_QUEUE_REMOVE';

export const UPDATE_NEARBY_BUSINESSES = 'UPDATE_NEARBY_BUSINESSES';

export const GET_BUSINESS_FROM_ID = 'GET_BUSINESS_FROM_ID';
export const GET_EMPLOYEE_FROM_ID = 'GET_EMPLOYEE_FROM_ID';

export const RATE_QUEUE_ENTRY = 'RATE_QUEUE_ENTRY';

export const JOIN_QUEUE = 'JOIN_QUEUE';