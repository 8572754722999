import React from 'react';
import {
    Box, Tabs, Tab, styled, Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { Menu } from "../Menu";
import { PrintedMenu } from "../PrintedMenu";
import DeleteBusiness from "../DeleteBusiness";
import RestaurantFlow from "../../../RestaurantFlow";
import LeaveDays from "../LeaveDays";
import { Timing } from "../Timing";
import { Services } from "../Services";
import Employees from "../Employees";
import Details from "../Details";

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#635ee7',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontFamily: 'Poppins',
        fontWeight: '600',
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.6)',
        '&.Mui-selected': {
            color: '#000',
            fontWeight: '600'
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
export const DefaultTabs = ({ value, handleChange, businessData }) => {
    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Tab-Panel"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                >
                    <StyledTab label="Basic Details" {...a11yProps(0)} />
                    <StyledTab label="Employees" {...a11yProps(1)} />
                    <StyledTab label="Timings" {...a11yProps(2)} />
                    <StyledTab label="Services" {...a11yProps(3)} />
                    <StyledTab label="Leave Days" {...a11yProps(4)} />
                    <StyledTab label="Delete Business" {...a11yProps(5)} />
                </StyledTabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Details />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Employees />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Timing />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Services />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <LeaveDays />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <DeleteBusiness />
            </TabPanel>
        </div>
    )
}

export const RestaurantTabs = ({ value, handleChange, businessData }) => {
    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Tab-Panel"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                >
                    <StyledTab label="Basic Details" {...a11yProps(0)} />
                    <StyledTab label="Restaurant Setup" {...a11yProps(1)} />
                    <StyledTab label="Timings" {...a11yProps(2)} />
                    <StyledTab label="Menu" {...a11yProps(3)} />
                    <StyledTab label="Printed Menu" {...a11yProps(4)} />
                    <StyledTab label="Leave Days" {...a11yProps(5)} />
                    <StyledTab label="Delete Business" {...a11yProps(6)} />
                </StyledTabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Details />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <RestaurantFlow />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Timing />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Menu />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <PrintedMenu />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <LeaveDays />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <DeleteBusiness />
            </TabPanel>
        </div>
    )
}

export const FoodTruckTabs = ({ value, handleChange, businessData }) => {
    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Tab-Panel"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                >
                    <StyledTab label="Basic Details" {...a11yProps(0)} />
                    <StyledTab label="Timings" {...a11yProps(1)} />
                    <StyledTab label="Menu" {...a11yProps(2)} />
                    <StyledTab label="Printed Menu" {...a11yProps(3)} />
                    <StyledTab label="Leave Days" {...a11yProps(4)} />
                    <StyledTab label="Delete Business" {...a11yProps(5)} />
                </StyledTabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Details />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Timing />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Menu />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <PrintedMenu />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <LeaveDays />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <DeleteBusiness />
            </TabPanel>
        </div>
    )
}