import React from 'react';
import { TableSetup } from './TableSetup';
import { DndProvider } from 'react-dnd'
// import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

export default function RestaurantFlow() {
    return (
        <div>
            <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
                <TableSetup />
            </DndProvider>
        </div>
    );
}