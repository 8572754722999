import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';

export const useStyles = makeStyles({
    appBar: {
        background: PRIMARY_COLOR,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    content: {
        flexGrow: 1,
        padding: 3,
    },
    StaticTooltipLabel: {
        whiteSpace: "nowrap",
        maxWidth: "none",
        width: '160px'
    },
    popupButton: {
        color: PRIMARY_COLOR,
        border: `solid 1px ${PRIMARY_COLOR}`,
        minWidth: '170px'
    },
    numberField: {
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        }
    },
    headerButton: {
        paddingLeft: '15px',
        paddingRight: '15px',
        color: 'white',
        textTransform: 'none',
        fontFamily: 'Poppins',
        fontSize: '13px',
        marginLeft: '14px'
    },
    headerButtonSelected: {
        paddingLeft: '15px',
        paddingRight: '15px',
        color: 'white',
        background: 'rgba(0,0,0,0.5)',
        textTransform: 'none',
        fontFamily: 'Poppins',
        fontSize: '13px',
        marginLeft: '14px'
    }
});