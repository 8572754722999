import * as types from './types';

export function createCoupon(couponData, resolve, reject) {
    return {
        type: types.CREATE_COUPON,
        couponData,
        resolve,
        reject
    };
};

export function deleteCoupon(couponData) {
    return {
        type: types.DELETE_COUPON,
        couponData
    };
};

export function loadCoupons() {
    return {
        type: types.LOAD_COUPONS,
    };
};

export function updateCouponsList(couponsData) {
    return {
        type: types.UPDATE_COUPONS_LIST,
        couponsData
    };
};

export function uploadAndGenerateUrl(couponItem, resolve, reject) {
    return {
        type: types.UPLOAD_GENERATE_URL,
        couponItem,
        resolve,
        reject
    };
}

export function shareCouponWith(customerList, couponData) {
    return {
        type: types.SHARE_COUPON_WITH,
        customerList,
        couponData,
    };
}