import React from 'react';
import { Button, FormGroup, FormControlLabel, Switch, Typography } from '@mui/material'
import update from 'immutability-helper'
import { useCallback, useEffect, useState } from 'react'
import { useDrop } from 'react-dnd'
import { DraggableBox } from './DraggableBox.js'
import { ItemTypes } from './ItemTypes.js'
import { snapToGrid as doSnapToGrid } from './snapToGrid.js'
import { v4 as uuidv4 } from 'uuid';
import * as businessRestaurantActions from "../../../redux/actions/BusinessRestaurant";
import { useDispatch, useSelector } from 'react-redux'
import { PRIMARY_COLOR } from '../../../config/appStyleConfig.js';
import useOrientation from '../../../components/Hooks/useOrientation.js';
import { forwardRef } from 'react';

const DEFAULT_SCALE_HEIGHT = 526;
const DEFAULT_SCALE_WIDTH = 1205;

const styles = {
  width: '100%',
  height: '70vh',
  border: '1px solid black',
  position: 'relative',
}
export const Container = forwardRef(({ snapToGrid }, containerRef) => {
  const dispatch = useDispatch();
  const { tables } = useSelector((state) => state.businessRestaurantReducer);
  const { businessData } = useSelector((state) => state.authReducer);

  const [boxes, setBoxes] = useState(null);
  const orientation = useOrientation();

  useEffect(() => {
    dispatch(businessRestaurantActions.loadFloorSetup());
  }, [dispatch]);

  const moveBox = useCallback(
    (newItem, left, top, x, y, curr, size) => {
      const containerPos = containerRef?.current?.getBoundingClientRect();
      let item = boxes[newItem.id];
      let it = {};
      if (!item) {
        let uid = uuidv4();
        let leftScale = containerRef?.current?.clientWidth / DEFAULT_SCALE_WIDTH;
        let topScale = containerRef?.current?.clientHeight / DEFAULT_SCALE_HEIGHT;
        const [maxX, maxY] = [containerPos.width - (leftScale * newItem.size.width) - 2, containerPos.height - (topScale * newItem.size.height) - 2];

        let posX = curr.x - containerPos?.left;
        let posY = curr.y - containerPos?.top;
        if (posX < 0) posX = 0;
        else if (posX > maxX) posX = maxX;
        if (posY < 0) posY = 0;
        else if (posY > maxY) posY = maxY;
        it[uid] = {
          top: posY, left: posX, id: uid, capacity: newItem.capacity, type: newItem.type, accessible: newItem.accessible, busy: newItem.busy,
          size: {
            height: topScale * newItem.size.height,
            width: leftScale * newItem.size.width
          }, minCapacity: newItem.minCapacity, rotate: newItem.rotate !== undefined ? newItem.rotate : 0,
          // x: 0, y: 0
        };
        setBoxes(update(boxes, { $merge: it }));
        handleSaveFlow(update(boxes, { $merge: it }));
        return;
      }

      const [maxX, maxY] = [containerPos.width - size.width - 2, containerPos.height - size.height - 2];
      if (left < 0)
        left = 0;
      else if (left > maxX)
        left = maxX;
      if (top < 0)
        top = 0;
      else if (top > maxY)
        top = maxY;

      setBoxes(
        update(boxes, {
          [newItem.id]: {
            $merge: { left, top, /*x, y*/ },
          },
        }),
      )
      handleSaveFlow(update(boxes, {
        [newItem.id]: {
          $merge: { left, top, /*x, y*/ },
        },
      }));
      return;
    },
    [boxes],
  )
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset()
        const curr = monitor.getSourceClientOffset()
        let left = Math.round(item.left + delta.x)
        let top = Math.round(item.top + delta.y);
        if (snapToGrid) {
          ;[left, top] = doSnapToGrid(left, top)
        }
        moveBox(item, left, top, delta.x, delta.y, curr, item.size);
        return undefined
      },
    }),
    [moveBox],
  )

  const loadFloorSizeFromDB = useCallback(() => {
    return new Promise((resolve, reject) => {
      dispatch(businessRestaurantActions.loadFloorSize(resolve, reject));
    });
  }, [dispatch]);

  const handleResize = useCallback(async () => {
    let floorSize = await loadFloorSizeFromDB();
    let leftScale = containerRef?.current?.clientWidth / floorSize.floorWidth;
    let topScale = containerRef?.current?.clientHeight / floorSize.floorHeight;
    let newTables = {};
    Object.keys(tables).forEach((key) => {
      let tb = {
        ...tables[key],
        size: {
          height: topScale * tables[key].size.height,
          width: leftScale * tables[key].size.width
        },
        left: leftScale * tables[key].left,
        top: topScale * tables[key].top
      }
      newTables = { ...newTables, [key]: tb }
    });
    setBoxes(newTables);
  }, [tables, loadFloorSizeFromDB]);

  useEffect(() => {
    //setBoxes(tables);
    handleResize();
  }, [tables]);

  const handleSaveFlow = (boxes) => {
    dispatch(businessRestaurantActions.saveFloorSize({
      floorHeight: containerRef?.current?.clientHeight,
      floorWidth: containerRef?.current?.clientWidth,
    }));
    dispatch(businessRestaurantActions.saveFloorSetup(boxes));
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <div>
      <div ref={drop}>
        <div ref={containerRef} style={{ ...styles, height: orientation === "PORTRAIT" ? '35vh' : '70vh' }}>
          {boxes && Object.keys(boxes).map((key) => (
            <DraggableBox handleSaveFlow={handleSaveFlow} setBoxes={setBoxes} key={key} id={key} {...boxes[key]} isOnScreen={true} />
          ))}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {/* <FormGroup >
          <FormControlLabel
            control={
              <Switch
                checked={true}
                onChange={() => { }}
              />
            }
            label={<Typography style={{ fontFamily: 'Poppins', fontWeight: '600' }}>Allow auto save</Typography>}
          />
        </FormGroup> */}
        <Typography style={{ fontFamily: 'Poppins', fontWeight: '600' }}>Auto Save is on (Changes will be saved automatically)</Typography>
        {/*<Button variant="contained" style={{ marginTop: '10px', background: PRIMARY_COLOR }} onClick={() => handleSaveFlow()}>Save Floor Setup</Button>*/}
      </div>
    </div>
  )
});
