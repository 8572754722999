import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';

export const useStyles = makeStyles({
    styledButton: {
        fontFamily: 'Poppins',
        color: '#FFF',
        background: PRIMARY_COLOR,
        "&:hover": {
            backgroundColor: PRIMARY_COLOR
        }
    },
    styledButtonRed: {
        fontFamily: 'Poppins',
        color: '#FFF',
        background: 'red',
        "&:hover": {
            backgroundColor: 'red'
        }
    },
    disabledStyledButton: {
        fontFamily: 'Poppins',
        color: '#FFF',
        background: 'grey',
        "&:hover": {
            backgroundColor: 'grey'
        }
    },
});