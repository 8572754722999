import React from "react";
import { Box, Card, Grid, IconButton, Rating, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import * as businessActions from "../../redux/actions/Business";
import BlockIcon from "@mui/icons-material/Block";
import '../../components/CustomizedCard/stylesCSS.css';

const CustomerCard = (props) => {
  const { businessData } = useSelector((state) => state.authReducer);
  const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);
  const [isBlocked, setIsBlocked] = React.useState(props.customer?.is_blocked || false);
  const [value, setValue] = React.useState(props.customer?.rating);
  const dispatch = useDispatch();
  const handleChangeRating = (newValue) => {
    setValue(newValue);
    const newCustomer = {
      ...props.customer,
      rating: newValue,
    };
    dispatch(businessActions.updateCustomerRating(newCustomer, businessData?.uid));
  };
  const handleBlockCustomer = () => {
    const newCustomer = {
      ...props.customer,
      is_blocked: isBlocked,
    };
  
    dispatch(businessActions.updateCustomerBlockStatus(newCustomer, businessData?.uid));
    setIsConfirmationOpen(false);
  };


  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      { 
        props.customer?.is_blocked ?
        <div className="ribbon ribbon-top-left ribbon-crm-card ribbon-blocked"><span>Blocked</span></div> :
        props.customer?.is_new && <div className="ribbon ribbon-top-left ribbon-crm-card"><span>New</span></div>
      }
      <Card variant="outlined" sx={{ borderLeft: "none" }}>
        <Box sx={{ display: "flex", overflow: "hidden" }}>
          <div style={{ width: "4px", background: "green" }}></div>
          <Grid container sx={{ px: "1.75rem", py: "1.5rem" }} justifyContent="space-between">
            <Grid item xs={8}>
              <Typography style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: 600, fontSize: "15px", lineHeight: "22px", color: "#2A2A2A" }}>{props.customer.name}</Typography>
              <Typography style={{ fontFamily: "Roboto", fontStyle: "normal", fontWeight: "normal", fontSize: "12px", lineHeight: "14px", color: "#808080" }} variant="caption">
                {props.customer.contact}
              </Typography>
              <div>
                <Rating
                  sx={{ mt: 1 }}
                  name="rating"
                  value={value}
                  onChange={(event, newValue) => {
                    handleChangeRating(newValue);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2} textAlign="right">
              <Tooltip title={!isBlocked?"Block Customer":"Unblock Customer"}>
                <IconButton
                  onClick={() => {
                    setIsConfirmationOpen(true);
                    setIsBlocked(!isBlocked);
                  }}
                  sx={{ color: !isBlocked? "red":"grey" }}
                  aria-label="block"
                  >
                  <BlockIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={2} textAlign="right">
              <Tooltip title="Delete Customer">
                <IconButton aria-label="delete" onClick={props?.removeHandler}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Dialog
        open={isConfirmationOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{isBlocked? "Block Customer":"Unblock Customer"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{isBlocked? "Do you want to block":"Do you want to unblock"} {props.customer.name}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsConfirmationOpen(false);
              setIsBlocked(!isBlocked);
            }}
          >
            No
          </Button>
          <Button onClick={handleBlockCustomer} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CustomerCard;
