import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CalendarCard from "../../../components/CalendarCard/CalendarCard";
import * as businessActions from "../../../redux/actions/Business";
//mui
import Modal from "@mui/material/Modal";
import { Button, Grid, Paper, Typography } from "@mui/material";

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { Timeline, TimelineEvent } from 'react-event-timeline'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import VideocamIcon from '@mui/icons-material/Videocam';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
const CalendarView = () => {
  const dispatch = useDispatch();

  const { businessData } = useSelector((state) => state.authReducer);
  const { iQueues } = useSelector((state) => state.businessReducer);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    let date = new Date();
    dispatch(businessActions.updateResetQueueState());
    dispatch(businessActions.enableQueueListener({ year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }));
  }, [dispatch]);

  useEffect(() => {
    let eventArray = iQueues.filter((iq) => iq.entry_type === 1 && iq.status !== -2).map((item) => {
      return {
        ...item,
        title: item.customer_name,
        start: moment(item.created_timestamp).toDate(),
        end: moment(item.created_timestamp).add("40", "minutes").toDate(),
        status: item.status,
      };
    });
    setEvents(eventArray);
  }, [businessData, iQueues]);

  // const findEventStyle = (event) => {
  //     switch (event.status) {
  //         case 0://served
  //             return { style: { backgroundColor: "#6495ed" } };
  //         case 1://accepted
  //             return { style: { backgroundColor: "green" } };
  //         case 2://pending
  //             return { style: { backgroundColor: "#ffae42" } };
  //         default:
  //             return { style: { display: "none" } };

  //     }
  // };

  const findEventStyle = (event) => {
    switch (event.status) {
      case 0://served
        return "#6495ed"
      case 1://accepted
        return "green"
      case 2://pending
        return "#ffae42"
      default:
        return PRIMARY_COLOR

    }
  };

  const dateChange = (event) => {
    let date = new Date(event);
    dispatch(businessActions.updateResetQueueState());
    dispatch(businessActions.enableQueueListener({ year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }));
    setValue(date);
  }
  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12} md={4}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Paper style={{ margin: '10px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 6))}
                  minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 6))}
                  value={value}
                  onChange={(newValue) => {
                    dateChange(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper style={{
            overflow: 'scroll',
            marginRight: '20px', marginTop: '10px', padding: '10px', height: '75vh', maxHeight: '75vh'
          }}>
            <Typography variant="h5" component="h3" style={{ fontFamily: 'Poppins', fontWeight: '600' }}>
              {value.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' })}
            </Typography>
            <div style={{ marginTop: '20px' }}>
              {events.length === 0 && <Typography variant="h6" component="h3" style={{ fontFamily: 'Poppins' }}>
                {businessData.category === "Restaurant" ? 'No Reservations Found' :
                  businessData.category === "Food Truck" ? 'No Orders Found' :
                    'No Appointments Found'}
              </Typography>}

              {events.length !== 0 && <Timeline

              >
                {events.sort((a, b) => (a.created_timestamp - b.created_timestamp)).map((item) => {
                  return (
                    <TimelineEvent key={item.uid}
                      iconColor={findEventStyle(item)}
                      titleStyle={{ fontSize: '15px', fontFamily: 'Poppins', fontWeight: '600' }}
                      bubbleStyle={{ color: findEventStyle(item), backgroundColor: 'white' }}
                      title={businessData.category === "Restaurant" ? "Reservation by " + item.title : "Appointment with " + item.title}
                      createdAt={new Date(item.created_timestamp).toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' })}
                      icon={item?.video_visit === true ? <VideocamIcon fontSize="small" /> : <InsertInvitationIcon fontSize="small" />}>

                      {/* <Typography variant="body2" component="p" style={{ fontFamily: 'Poppins', fontWeight: '400' }}>
                                                {new Date(item.created_timestamp).toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' })}
                                                {' '}-{' '}
                                                {addMinutes(new Date(item.created_timestamp), 30).toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' })}
                                            </Typography> */}
                      <br></br>
                      {item?.video_visit && item?.status === 0 ? null : (
                        <Button
                          onClick={() => {
                            setEvent(item);
                          }}
                          style={{ textTransform: 'none', color: 'white', backgroundColor: PRIMARY_COLOR }}
                          size="small" variant="contained">Verify</Button>)}
                    </TimelineEvent>
                  )
                })}
              </Timeline>}
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Modal
        open={event != null}
        onClose={() => {
          setEvent(null);
        }}
      >
        <div style={style}>
          <CalendarCard item={event} setEvent={setEvent} />
        </div>
      </Modal>
    </div>
  );
};

export default CalendarView;
