export const validateData = (data, setError) => {
    let isTrue = false;
    if (!data.get('name')?.trim()) {
        setError((value) => ({ ...value, name: { status: true, message: 'Full Name is required' } }));
        isTrue = true;
    }
    if (!data.get('contact')?.trim()) {
        setError((value) => ({ ...value, contact: { status: true, message: 'Mobile Number is required' } }));
        isTrue = true;
    }
    if (data.get('contact')?.length > 0 && data.get('contact')?.length < 10) {
        setError((value) => ({ ...value, contact: { status: true, message: 'Mobile Number is invalid' } }));
        isTrue = true;
    }
    return isTrue;
}