import createReducer from "../../../utils/createReducer";
import * as authTypes from "../../actions/BusinessCoupons/types";
import moment from "moment";

const initialState = {
  coupons: {}
};

export const businessCouponsReducer = createReducer(initialState, {
  [authTypes.UPDATE_COUPONS_LIST](state, action) {
    if (action?.couponsData) {
      const updatedCoupons = Object.keys(action.couponsData).reduce((couponsAcc, key) => {
        const coupon = action.couponsData[key];
        const endDate = coupon.endDate;
        // Converting 12hr to 24hr format
        const endTime = moment(coupon.endTime, ["h:mm A"]).format("HH:mm");
        const currentDate = Date.now();

        const expiryDate = moment(endDate + " " + endTime);
        const todayDate = moment(currentDate);

        couponsAcc[key] = {
          ...coupon,
          isExpired: expiryDate.isBefore(todayDate),
        };
        return couponsAcc;
      }, {});
      return {
        ...state,
        coupons: updatedCoupons
      }
    }
    return {
      ...state,
      coupons: { ...action?.couponsData }
    }
  },
});
