import React, { useState } from 'react';
import {
    Grid, Box, Button, Typography, Grow, Backdrop, CircularProgress
} from "@mui/material";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from 'react-redux';
import * as businessActions from '../../../../redux/actions/Business';
import { useCallback } from 'react';

export const PrintedMenu = () => {
    const dispatch = useDispatch();
    const { businessData } = useSelector((state) => state.authReducer);
    const [isEdit, setIsEdit] = useState(false);
    const [file, setFile] = useState(null);
    const [deleteFile, setDeleteFile] = useState(false);
    const [fileType, setFileType] = useState(null);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();


    const handleUpload = useCallback(async () => {
        return new Promise((resolve, reject) => {
            dispatch(businessActions.uploadBusinessMenu(file, resolve, reject));
        });
    }, [dispatch, file]);

    const handleDelete = useCallback(async () => {
        return new Promise((resolve, reject) => {
            dispatch(businessActions.deleteBusinessMenu(resolve, reject));
        });
    }, [dispatch]);

    const handleFileUpload = async () => {
        if (file) {
            setLoading(true);
            await handleUpload();
            setLoading(false);
        }
        if (deleteFile) {
            setLoading(true);
            await handleDelete();
            setLoading(false);
        }
        setIsEdit(false);
    }

    const handleSelectFile = (event) => {
        if (event?.target?.files[0]) {
            var file = event.target.files[0];
            const allowedFileTypes = ["jpg", "png", "jpeg", "pdf"];
            const imageFileTypes = ["jpg", "png", "jpeg"];
            if (!allowedFileTypes.some(el => file.type.toLowerCase().includes(el))) {
                return alert("Invalid file type selected");
            }
            const fileSize = file.size / 1024 / 1024;
            if (fileSize > 10) {
                return alert("File size too large");
            }
            if (imageFileTypes.some(el => file.type.toLowerCase().includes(el))) {
                setFileType('image');
            } else {
                setFileType('pdf');
            }
            setFile(file);
            setDeleteFile(false);
        }
    }

    const getFileType = (fileName) => {
        if (fileName) {
            const imageFileTypes = ["jpg", "png", "jpeg"];
            if (imageFileTypes.some(el => fileName.toLowerCase().includes(el))) {
                return 'image';
            }
            return 'pdf';
        }
    }

    const handleDeleteFile = () => {
        setDeleteFile(true);
        setFile(null);
        setFileType(null);
    }

    return (
        <Grow in>
            <Box component="form" noValidate sx={{ width: "100%" }}>
                <Grid container spacing={2}>
                    <Grid item container direction={"row"} justifyContent={"center"} alignItems="center">
                        {businessData.printed_menu && !fileType && !file && !deleteFile && getFileType(businessData.printed_menu) === "image" &&
                            <img src={businessData.printed_menu} style={{ height: '58vh', maxHeight: '58vh', maxWidth: '90vw' }} alt={"Main Logo"} />}
                        {businessData.printed_menu && !fileType && !file && !deleteFile && getFileType(businessData.printed_menu) === "pdf" &&
                            <iframe
                                style={{ height: '58vh', maxHeight: '58vh', maxWidth: '90vw' }}
                                src={`${businessData.printed_menu}#toolbar=0`}
                                type='application/pdf'
                                title='title'
                            />}
                        {((!businessData.printed_menu && !fileType) || deleteFile) && < Typography variant="h6" component="h6" style={{ fontFamily: 'Poppins', fontWeight: '600', textAlign: 'center' }}>
                            No menu uploaded yet (Please upload pdf/jpg files less than 10 MB)</Typography>}
                        {fileType && fileType === "image" && file &&
                            <img src={URL.createObjectURL(file)} style={{ height: '58vh', maxHeight: '58vh', maxWidth: '90vw' }} alt={"Main Logo"} />}
                        {fileType && fileType === "pdf" && <iframe
                            style={{ height: '58vh', maxHeight: '58vh', maxWidth: '90vw' }}
                            src={`${URL.createObjectURL(file)}#toolbar=0`}
                            type='application/pdf'
                            title='title'
                        />}
                    </Grid>
                    <Grid item container direction={"row"} justifyContent={"center"} alignItems="center">

                        <Button
                            sx={{ marginLeft: "1rem" }}
                            variant="contained"
                            disabled={!isEdit}
                            component="label"
                            className={
                                isEdit ? classes.styledButton : classes.disabledStyledButton}
                        >
                            Select Menu File
                            <input type="file" accept=".pdf , .jpeg , .jpg , .png" hidden onChange={handleSelectFile} onClick={(e) => e.target.value = null} />
                        </Button>

                        {!deleteFile && (businessData.printed_menu || file) && <Button
                            sx={{ marginLeft: "1rem" }}
                            variant="contained"
                            disabled={!isEdit}
                            onClick={handleDeleteFile}
                            className={
                                isEdit ? classes.styledButtonRed : classes.disabledStyledButton}
                        >
                            Delete File
                        </Button>}
                    </Grid>
                    <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems="center"
                    >
                    </Grid>
                </Grid>
                <Grid container justifyContent={"center"} alignItems={"center"} sx={{ marginTop: "1rem" }}>
                    <Grid item>
                        {isEdit ?
                            <Button variant="contained" sx={{ marginLeft: "1rem" }} className={classes.styledButton} onClick={handleFileUpload}>
                                Update
                            </Button> :
                            <Button variant="contained" sx={{ marginLeft: "1rem" }} className={classes.styledButton} onClick={() => setIsEdit(true)}>
                                Edit
                            </Button>}
                    </Grid>
                </Grid>
                {loading && <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>}
            </Box>
        </Grow >)
}