import React, { useState } from 'react';
import {
    SpeedDial, SpeedDialIcon,
    SpeedDialAction,
} from "@mui/material";
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import { useStyles } from "../styles";
import { ServicesConfirmDialog } from './ServiceConfirmDialog';
import { Restaurant } from '@mui/icons-material';
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';

const actions = [
    { icon: <TableRestaurantIcon />, name: 'Add Reservation' },
    { icon: <Restaurant />, name: 'Add Eat Now' },
];


const SpeedDialMenuRestaurant = () => {
    const classes = useStyles();

    const [addWalkInDialog, setAddWalkInDialog] = useState(false);
    const [addAppointmentDialog, setAppointmentDialog] = useState(false);

    const handleSpeedDialAction = (action) => {
        if (action.name === "Add Eat Now") {
            setAddWalkInDialog(true);
            return;
        }
        if (action.name === "Add Reservation") {
            setAppointmentDialog(true);
            return;
        }
        return alert("Something went wrong!");
    }
    return (
        <div>
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: 'absolute', bottom: 36, right: 36 }}
                FabProps={{
                    sx: {
                        bgcolor: PRIMARY_COLOR,
                        '&:hover': {
                            bgcolor: PRIMARY_COLOR,
                        }
                    }
                }}
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        classes={{ staticTooltipLabel: classes.StaticTooltipLabel }}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => handleSpeedDialAction(action)}
                    //tooltipOpen
                    />
                ))}
            </SpeedDial>
            {addAppointmentDialog && <ServicesConfirmDialog
                popupData={{ entryType: 1 }}
                serviceConfirmDialog={addAppointmentDialog}
                setServiceConfirmDialog={setAppointmentDialog} />}
            {addWalkInDialog && <ServicesConfirmDialog
                popupData={{ entryType: 0 }}
                serviceConfirmDialog={addWalkInDialog}
                setServiceConfirmDialog={setAddWalkInDialog} />}
        </div>
    );
}

export default SpeedDialMenuRestaurant;