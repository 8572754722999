import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../config/appStyleConfig';

export const useStyles = makeStyles({
    appBar: {
        background: PRIMARY_COLOR,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    content: {
        flexGrow: 1,
        padding: 3,
        textAlign: 'center',
        marginTop: '10%'
    },
});