import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { firebaseConfig } from '../../../config/firebaseConfig';
import DashboardRestaurantBusiness from '../../DashboardRestaurant/Business'
import { LoadingBackdrop } from "../../../components/LoadingBackdrop";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ExternalRestaurnt = () => {
    let query = useQuery();

    const [videoCallLobby, setVideoCallLobby] = useState(false);
    const [auth, setAuth] = useState(null);

    useEffect(() => {
        if (auth) {
            setVideoCallLobby(true);
        }
    }, [auth, query]);

    useEffect(() => {
        if (!("indexedDB" in window)) {
            alert("This browser doesn't support IndexedDB")
        } else {
            let user = JSON.parse(query.get("userAuthObj"));
            user = { ...user, apiKey: firebaseConfig.apiKey }
            let indexdb = window.indexedDB.open('firebaseLocalStorageDb', 1);
            indexdb.onupgradeneeded = function (event) {
                var db = event.target.result;
                db.createObjectStore("firebaseLocalStorage", { keyPath: "fbase_key" });

            }
            indexdb.onsuccess = function (event) {
                let db = event.target.result;
                let transaction = db.transaction(['firebaseLocalStorage'], 'readwrite')
                let storage = transaction.objectStore('firebaseLocalStorage')
                storage.put({
                    fbase_key: `firebase:authUser:${user.apiKey}:[DEFAULT]`,
                    value: user
                });
                setTimeout(()=>{
                    setAuth(true)
                },5000);
            }
        }
    }, [query]);
    return (<div>
        {
            videoCallLobby ? (
                <DashboardRestaurantBusiness isMobile={true} />
            ) : <LoadingBackdrop />
        }
    </div>)
}

export default ExternalRestaurnt;