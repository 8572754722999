import React, { useRef } from 'react';
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { Button, Grid, Typography, Tooltip } from '@mui/material';
import { useStyles } from './styles';
import ReactToPrint from 'react-to-print';
import googlePlayBadge from '../../assets/images/google-play-badge.svg';
import appStoreBadge from '../../assets/images/apple-app-store-badge.svg';
import mainLogo from '../../assets/images/logo.png';
import phoneLogo from '../../assets/images/ipad_dark.png';

const InviteCustomers = () => {
    const classes = useStyles();
    const componentRef = useRef();
    const { businessData } = useSelector((state) => state.authReducer);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={5} style={{ textAlign: 'center', marginTop: '12%' }}>
                <h2 style={{ fontSize: '40px', fontFamily: 'Poppins' }}>Share with everyone</h2>
                <Typography variant="subtitle2" style={{ fontFamily: 'Poppins', fontWeight: 800 }}>
                    Share this QR Code with your friends and<br></br> family to invite them to join you.
                </Typography>
                <br></br>
                <ReactToPrint
                    trigger={() => <Tooltip title="Get the PDF File for Printing"><Button className={classes.styledButton} style={{ width: '120px' }}>Print</Button></Tooltip>}
                    content={() => componentRef.current}
                />
            </Grid>
            <Grid item xs={12} md={7} style={{ marginTop: '3%' }}>
                <div ref={componentRef} style={{ margin: '5px' }}>
                    <Grid container style={{ background: '#2f314b', padding: '15px', borderRadius: '5px' }}>
                        <Grid item xs={6}>
                            <div style={{ display: 'flex' }}>
                                <img src={mainLogo} style={{ height: '50px' }} alt={"Main Logo"} />
                                <Typography variant="subtitle2" style={{ marginTop: '8px', marginLeft: '10px', fontFamily: 'Poppins', color: 'white', fontSize: '20px', fontWeight: 800 }}>iQueue 365</Typography>
                            </div>
                            <br></br>
                            <Typography variant="subtitle2" style={{ fontFamily: 'Poppins', color: 'white', fontSize: '32px', fontWeight: 800 }}>Use our mobile app<br></br>to avoid waiting in<br></br>the Queue.</Typography>
                            <br></br>
                            <Typography variant="subtitle2" style={{ fontFamily: 'Poppins', color: '#88c9f0', fontSize: '20px' }}>No more waiting because now<br></br>you can take appointments and<br></br>walk-in using iQueue.</Typography>
                            <br></br>
                            <Typography style={{ fontFamily: 'Poppins', color: 'white', fontSize: '15px' }}>With iQueue customers can view providers and<br></br>take appointments or walk in based on<br></br>their availability</Typography>
                            <br></br>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ float: 'right' }}>
                                <div style={{ display: 'flex' }}>
                                    <img style={{ height: '450px' }} src={phoneLogo} alt="Get it on google play" />
                                    <div style={{ borderRadius: '5px', position: 'absolute', marginLeft: '69px', marginTop: '75px', background: 'white', padding: '8px' }} >
                                        <QRCode size={205} value={`http://app.iqueue365.com/mobile/user/quick-check-in?bid=${businessData.uid}&name=${encodeURIComponent(businessData.name)}&services=${encodeURIComponent(businessData.services)}&atleastOneServiceEnabled=${businessData.atleastOneServiceEnabled}&category=${businessData.category}`} />
                                        <Typography style={{ fontFamily: 'Poppins', width: '200px', fontSize: '13px', textAlign: 'center' }}><div style={{ whiteSpace: 'nowrap', wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}>{businessData?.name}</div> Quick walk in by scanning the QR Code<br></br><span style={{ fontWeight: 800 }}>iQueue 365</span></Typography>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <img style={{ height: '50px', margin: '10px', marginTop: '25px' }} src={googlePlayBadge} alt="Get it on google play" />
                            <div style={{ borderRadius: '5px', background: 'white', width: '120px', textAlign: 'center', padding: '10px', marginLeft: '38px' }} >
                                <QRCode size={100} value={`https://bit.ly/3LSoGWB`} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '40px' }}>
                            <img style={{ height: '50px', margin: '10px', marginTop: '25px' }} src={appStoreBadge} alt="Get it on google play" />
                            <div style={{ borderRadius: '5px', background: 'white', width: '120px', textAlign: 'center', padding: '10px', marginLeft: '27px' }} >
                                <QRCode size={100} value={`https://apple.co/38MAOu1`} />
                            </div>
                        </div>
                    </Grid>
                </div>
            </Grid >
        </Grid >)
}

export default InviteCustomers;