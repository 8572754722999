import * as authReducer from './AuthReducer';
import * as businessReducer from './BusinessReducer';
import * as consumerReducer from './ConsumerReducer';
import * as businessRestaurantReducer from './BusinessRestaurantReducer';
import * as businessCouponsReducer from './BusinessCouponReducer';

export default Object.assign(
    authReducer,
    businessReducer,
    consumerReducer,
    businessRestaurantReducer,
    businessCouponsReducer
);