import React, { useState, useCallback } from 'react';
import { Button, TextField, Snackbar, CircularProgress } from '@mui/material';
import MuiAlert from "@mui/material/Alert";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import * as businessCouponActions from "../../redux/actions/BusinessCoupons";
import AdapterMoment from '@mui/lab/AdapterMoment';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/lab';
import { PRIMARY_COLOR } from '../../config/appStyleConfig';
import { Check, Upload } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AddCoupons = () => {
    const dispatch = useDispatch();

    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [termsCondition, setTermsCondition] = useState('');
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment().add(2, 'days'));
    const [startTime, setStartTime] = useState(moment());
    const [endTime, setEndTime] = useState(moment().add(2, 'hours'));
    const [snackbarData, setSnackBarData] = useState(null);
    const [attachedFile, setAttachedFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const createCoupon = useCallback(async (data) => {
        return new Promise((resolve, reject) => {
            dispatch(businessCouponActions.createCoupon(data, resolve, reject));
        });
    }, [dispatch]);

    const handleCreateCoupon = async () => {
        if (!name.trim() || !description.trim() || !termsCondition.trim() || !code.trim()) {
            setSnackBarData({
                open: true,
                message: "Please fill all the required fields",
                severity: 'error'
            });
            return;
        }
        setLoading(true);
        let data = {
            code,
            name,
            description,
            termsCondition,
            startDate: moment(startDate).format('DD-MMM-YYYY'),
            endDate: moment(endDate).format('DD-MMM-YYYY'),
            startTime: moment(startTime).format('hh:mm a'),
            endTime: moment(endTime).format('hh:mm a'),
            file: attachedFile ? attachedFile : null,
        }
        try {
            await createCoupon(data);
            setName('');
            setCode('');
            setDescription('');
            setTermsCondition('');
            setAttachedFile(null);
            setSnackBarData({
                open: true,
                message: "Coupon created successfully",
                severity: 'success'
            });
            setLoading(false);
        } catch (error) {
            setName('');
            setCode('');
            setDescription('');
            setTermsCondition('');
            setAttachedFile(null);
            setSnackBarData({
                open: true,
                message: "Something went wrong, please try again",
                severity: 'error'
            });
            setLoading(false);
        }
        return;
    }

    const handleImageAttach = (event) => {
        var file = event.target.files[0];
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 10) {
            return alert("File size too large");
        }
        setAttachedFile(file);
    }

    return (
        <div>
            <TextField
                style={{ width: "49%" }}
                margin="normal"
                required
                inputProps={{ maxLength: 50 }}
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                label={`Coupon Name`}
                name="name"
                autoFocus
            />
            <TextField
                style={{ width: "48%", marginLeft: '10px' }}
                margin="normal"
                required
                inputProps={{ maxLength: 30 }}
                id="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                label={`Coupon Code`}
                name="code"
            />
            <TextField
                style={{ width: "100%", }}
                multiline
                rows={2}
                margin="normal"
                required
                inputProps={{ maxLength: 120 }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="description"
                label={`Description`}
                name="description"
            />
            <TextField
                style={{ width: "100%", }}
                multiline
                rows={3}
                margin="normal"
                required
                inputProps={{ maxLength: 450 }}
                value={termsCondition}
                onChange={(e) => setTermsCondition(e.target.value)}
                id="termsCondition"
                label={`Terms and Conditions`}
                name="termsCondition"
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label="Date desktop"
                    inputFormat="DD-MMM-YYYY"
                    value={startDate}
                    onChange={(e) => {
                        if (e.format('YYYY-MM-DD') > endDate.format('YYYY-MM-DD')) {
                            setSnackBarData({
                                open: true,
                                message: "Start date cannot be greater than end date",
                                severity: 'error'
                            });
                            return;
                        }
                        setStartDate(e)
                    }}
                    renderInput={(params) => <TextField {...params}
                        style={{ width: "50%", }}
                        margin="normal"
                        required
                        onKeyDown={(e) => e.preventDefault()}
                        id="startDate"
                        label={`Start Date`}
                        name="startDate"

                    />}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label="Date desktop"
                    inputFormat="DD-MMM-YYYY"
                    value={endDate}
                    onChange={(e) => {
                        if (e.format('YYYY-MM-DD') < startDate.format('YYYY-MM-DD')) {
                            setSnackBarData({
                                open: true,
                                message: "End date cannot be less than start date",
                                severity: 'error'
                            });
                            return;
                        }
                        setEndDate(e)
                    }}
                    renderInput={(params) => <TextField {...params}
                        style={{ width: "48%", marginLeft: '5px' }}
                        margin="normal"
                        required
                        onKeyDown={(e) => e.preventDefault()}
                        id="endDate"
                        label={`End Date`}
                        name="endDate"
                    />}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                    label="Time desktop"
                    value={startTime}
                    onChange={(e) => setStartTime(e)}
                    renderInput={(params) => <TextField {...params}
                        style={{ width: "50%", }}
                        margin="normal"
                        onKeyDown={(e) => e.preventDefault()}
                        required
                        id="startTime"
                        label={`Start Time`}
                        name="startTime"

                    />}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                    label="Time desktop"
                    value={endTime}
                    onChange={(e) => setEndTime(e)}
                    renderInput={(params) => <TextField {...params}
                        style={{ width: "48%", marginLeft: '5px' }}
                        margin="normal"
                        required
                        onKeyDown={(e) => e.preventDefault()}
                        id="endTime"
                        label={`End Time`}
                        name="endTime"
                    />}
                />
            </LocalizationProvider>
            <input
                accept="image/*"
                id="coupon-image"
                onClick={(e) => { e.target.value = null }}
                type="file"
                hidden
                onChange={handleImageAttach}
            />
            {attachedFile === null ? <label htmlFor="coupon-image">
                <Button variant="outlined"
                    component="span"
                    style={{
                        width: '100%',
                        color: PRIMARY_COLOR,
                        border: `1px solid ${PRIMARY_COLOR}`,
                        marginBottom: '5px',
                    }}
                    startIcon={<Upload />}
                >Attach image (Optional)</Button>
            </label> :
                <Button variant="outlined"
                    onClick={() => setAttachedFile(null)}
                    style={{
                        width: '100%',
                        color: PRIMARY_COLOR,
                        border: `1px solid ${PRIMARY_COLOR}`,
                        marginBottom: '5px',
                    }}
                    startIcon={<Check />}
                >Attached (Remove Image)</Button>}
            <Button variant="contained" size="large"
                disabled={loading}
                style={{
                    color: 'white',
                    background: PRIMARY_COLOR,
                    width: '100%',
                    justifySelf: 'center',
                    border: `1px solid ${PRIMARY_COLOR}`
                }} onClick={() => handleCreateCoupon()}>
                {loading ? <><CircularProgress style={{ color: 'white', float: 'left', width: '20px', height: '20px' }} /> <span style={{ marginLeft: '10px' }}>Creating Coupon</span></> : 'Create Coupon'}
            </Button>
            {snackbarData && snackbarData.open && (
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackbarData.open} autoHideDuration={3000} onClose={() => setSnackBarData(null)}>
                    <Alert severity={snackbarData.severity}>{snackbarData.message}</Alert>
                </Snackbar>
            )}
        </div>
    )
}