import {
  Grid,
  Typography,
  // Rating,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as businessActions from "../../../../redux/actions/Business";
import { useStyles } from "../../../Home/Business/styles";
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';

const EmployeeCard = ({ data }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setopenEdit] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [showContactError, setShowContactError] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    name: data?.name,
    contact: data?.contact,
    uid: data?.uid
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const handleEdit = () => {
    const validName = Boolean(employeeData?.name?.trim());
    const tempContact = employeeData?.contact?.trim();
    const validContact = Boolean(tempContact) && (tempContact.length >= (tempContact[0] === '+' ? 11 : 10));

    if (validName && validContact) {
      dispatch(businessActions.updateAddEmployeeState(employeeData));
      setopenEdit(false)
    }
    else {
      if (!validName) setShowNameError(true);
      if (!validContact) setShowContactError(true);
    }
  }
  const onClickHandelDelete = () => {
    dispatch(businessActions.updateDeleteEmployeeState(data));
  };

  return (
    <Grid
      container
      style={{
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
        width: "320px",
        gridGap: "1rem",
        padding: "2rem 1rem 1rem 1rem",
      }}
    >
      <Grid item container>
        <Grid item>
          <div style={{ maxWidth: '300px' }}>
            <Typography
              noWrap
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "15px",
                lineHeight: "22px",
                color: "#2A2A2A",
                textOverflow: 'ellipsis',
              }}
            >
              {data?.name}
            </Typography>
          </div>
          <div>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "14px",
                color: "#808080",
              }}
              variant="caption"
            >
              {data?.contact}
            </Typography>
          </div>
        </Grid>
      </Grid>
      {/* <Grid container item direction="column">
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#808080",
            }}
            variant="caption"
          >
            Avg Rating :
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "13px",
              lineHeight: "19px",
              color: "#2A2A2A",
              marginLeft: "6px",
            }}
          >
            {" "}
            {value}
          </Typography>
        </Grid>
        <Grid item>
          <Rating
            name="simple-controlled"
            value={value}
            readOnly 
          />
        </Grid>
      </Grid> */}
      <Grid item container>
        <Button
          style={{
            backgroundColor: PRIMARY_COLOR,
            flexGrow: "1",
            marginRight: "3px",
          }}
          variant="contained"
          onClick={() => {
            setopenEdit(true);
          }}
        >
          Edit
        </Button>
        <Button
          style={{ flexGrow: "1", marginLeft: "3px" }}
          variant="contained"
          color="error"
          onClick={handleClickOpen}
        >
          Delete
        </Button>
      </Grid>
      {/*  Dialog box for editing employee */}
      <Dialog
        open={openEdit}
        onClose={() => {
          setopenEdit(false);
        }}
      >
        <DialogTitle>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontSize: "18px", flexGrow: 1, fontFamily: "Poppins" }}
          >
            Edit Employee
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={employeeData.name}
                error={showNameError}

                onChange={(e) => {
                  setShowNameError(false);
                  setEmployeeData({ ...employeeData, name: e.target.value });
                }}
              />
              <TextField
                required
                autoFocus
                margin="dense"
                id="contact"
                name="contact"
                label="Contact"
                type="text"
                fullWidth
                variant="standard"
                value={employeeData?.contact || ""}
                error={showContactError}
                onChange={(e) => {
                  setShowContactError(false);
                  const { value } = e.target;
                  if (!(/^\d{0,15}$/).test(value[0] === '+' ? value.slice(1) : value))
                    return;
                  setEmployeeData({ ...employeeData, contact: value });
                }}
              />
            </Grid>
          </Grid>
          <Divider />
          <Button
            variant="outlined"
            className={classes.popupButton}
            style={{ marginTop: "15px" }}
            onClick={handleEdit}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            className={classes.popupButton}
            style={{ marginTop: "15px", marginLeft: "10px" }}
            onClick={() => {
              setopenEdit(false);
            }}
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
      {/*  Dialog box for deleting employee */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this employee?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickHandelDelete} autoFocus>
            Yes
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default EmployeeCard;
