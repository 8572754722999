import * as types from './types';

export function saveDefaultTable(tableData, resolve, reject) {
    return {
        type: types.SAVE_DEFAULT_TABLE,
        tableData,
        resolve,
        reject
    };
};

export function saveFloorSize(tableSize) {
    return {
        type: types.SAVE_FLOOR_SIZE,
        tableSize
    };
};

export function loadFloorSize(resolve, reject) {
    return {
        type: types.LOAD_FLOOR_SIZE,
        resolve,
        reject
    };
};

export function loadDefaultTables(resolve, reject) {
    return {
        type: types.LOAD_DEFAULT_TABLES,
        resolve,
        reject
    };
};

export function deleteDefaultTable(tableId) {
    return {
        type: types.DELETE_DEFAULT_TABLE,
        tableId
    };
};

export function saveFloorSetup(tableData) {
    return {
        type: types.SAVE_FLOOR_SETUP,
        tableData
    };
};

export function loadFloorSetup() {
    return {
        type: types.LOAD_FLOOR_SETUP,
    };
};

export function updateFloorClientSide(tableData) {
    return {
        type: types.UPDATE_FLOOR_CLIENT_SIDE,
        tableData
    };
};

export function assignCustomerToTable(queueItem, tableId, resolve, reject) {
    return {
        type: types.ASSIGN_CUSTOMER_TO_TABLE,
        queueItem,
        tableId,
        resolve,
        reject
    };
};

export function unAssignCustomerFromTable(tableData) {
    return {
        type: types.UNASSIGN_CUSTOMER_FROM_TABLE,
        tableData,
    };
};