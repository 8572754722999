import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    Button, CssBaseline, Box, Typography, CircularProgress,
    Container, ThemeProvider, Snackbar
} from '@mui/material';
import MuiAlert from "@mui/material/Alert";
import { theme, useStyles } from './styles';
import { Redirect } from "react-router-dom";
import { getDatabase, ref, set } from "firebase/database";
import * as authActions from '../../redux/actions/Auth';
import OTPInput from "otp-input-react";
import { PRIMARY_COLOR } from '../../config/appStyleConfig';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function OTPVerification({ data, loading, setLoading, signInWithNumber }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    let history = useHistory();
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(30);
    const [snackbarData, setSnackBarData] = useState(null);

    useEffect(() => {
        if (timer > 0) {
            setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        }
    }, [timer]);

    //TODO : Move this in saga for user signup and this
    const saveDataToDB = (data) => {
        setLoading(false);
        const dbData = { ...data.userData };
        if (getDatabase()) {
            set(ref(getDatabase(), `iQueue/Restricted/Consumers/${dbData?.uid}/profile/`), dbData)
                .then(() => {
                    dispatch(authActions.setUserLoginData(dbData));
                    localStorage.setItem('loginType', 'USER');
                    localStorage.setItem('contact', dbData.contact);
                    history.push({
                        pathname: '/mobile/user/view-token',
                        state: {
                            businessData: data.businessData,
                            selectedServices: data.selectedServices,
                            note: dbData.note,
                            visitType: data.visitType,
                            capacity: data?.userData?.capacity
                        }
                    });
                })
                .catch((error) => {
                    setSnackBarData({
                        open: true,
                        message: error?.message,
                        severity: 'error'
                    });
                    history.push({
                        pathname: '/user/home',
                    });
                });
        };
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        //const newData = new FormData(event.currentTarget);
        setLoading(true);
        data.confirmationResult.confirm(otp).then((result) => {
            // User signed in successfully.
            if (data.userQuickLogin) {
                saveDataToDB({
                    userData: { ...data.userData, uid: result.user.uid },
                    businessData: data.businessData,
                    selectedServices: data.selectedServices,
                    visitType: data.visitType
                });
            }
            else if (data.userLogin) {
                localStorage.setItem('loginType', 'USER');
                localStorage.setItem('contact', data.userData.contact);
            } else if (data.businessLogin) {
                localStorage.setItem('loginType', 'BUSINESS');
                localStorage.setItem('contact', data.userData.contact);
            }
        }).catch((error) => {
            setSnackBarData({
                open: true,
                message: error?.message,
                severity: 'error'
            });
            // User couldn't sign in (bad verification code?)
            // ...
            setLoading(false);
            console.log(error);
        });
    };
    if (data) {
        return (
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs" sx={{ padding: '0!important' }}>
                    <CssBaseline />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ fontFamily: 'Poppins', fontWeight: '800' }}>Enter OTP Sent to {data.userData.contact}</Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                                <OTPInput
                                    name="otpNumber"
                                    id="otpNumber"
                                    value={otp}
                                    onChange={setOtp}
                                    autoFocus
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={false} />
                            </div>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.styledButton}
                                style={{ height: '50px', borderRadius: '5px' }}
                                sx={{
                                    mt: 2, background: PRIMARY_COLOR, "&:hover": {
                                        backgroundColor: PRIMARY_COLOR
                                    }, "&:disabled": {
                                        color: 'white',
                                        backgroundColor: PRIMARY_COLOR
                                    }
                                }}
                                disabled={loading}
                            >
                                {loading ? <><CircularProgress style={{ color: 'white', float: 'left', width: '20px', height: '20px' }} /> <span style={{ marginLeft: '10px' }}>Verifying OTP</span></> : 'Verify'}
                            </Button>
                            <Button
                                fullWidth
                                disabled={timer !== 0}
                                variant='outlined'
                                sx={{
                                    my: 2, color: PRIMARY_COLOR, borderColor: PRIMARY_COLOR,
                                    "&:hover": { borderColor: PRIMARY_COLOR },
                                    "&:disabled": { color: PRIMARY_COLOR, borderColor: PRIMARY_COLOR }
                                }}
                                onClick={() => {
                                    setTimer(timer + 30);
                                    signInWithNumber({ contact: data.userData.contact }, false, true)
                                }}
                            >
                                {timer > 0 ? `Resend OTP in 00:${timer}` : 'Resend OTP'}
                            </Button>
                            <Typography variant="subtitle2" gutterBottom component="div" sx={{ color: '#666' }}>
                                <b>Note :</b> Standard message or data rates may apply, and this text message may
                                count towards any messaging limit set in your mobile plan. Check with your mobile
                                provider for further information.
                            </Typography>
                        </Box>
                    </Box>
                    {snackbarData && snackbarData.open && (
                        <Snackbar open={snackbarData.open} autoHideDuration={3000} onClose={() => setSnackBarData(null)}>
                            <Alert severity={snackbarData.severity}>{snackbarData.message}</Alert>
                        </Snackbar>
                    )}
                </Container>
            </ThemeProvider>
        );
    } else {
        return (
            <Redirect to={'/user/login'} />);
    }
}