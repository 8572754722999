import React, { useRef, useEffect } from 'react';
import { Loader } from "@googlemaps/js-api-loader"
import {  mapApiKey } from '../../../config/firebaseConfig';
import { Button, CircularProgress, Box, Grid } from '@mui/material';

const loader = new Loader({
    // apiKey: firebaseConfig.apiKey,
    apiKey: mapApiKey.apiKey,
    version: "weekly",
    libraries: ["places"]
});


function getLongAndLat() {
    return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
    );
}

export const ConfirmLocationStep = ({ nextStep0, classes, loading, consolidateData, setConsolidateData, setActiveStep }) => {

    let map = useRef();
    let mapContainer = useRef();
    useEffect(() => {
        if (map.current) return;
        loader.load().then(() => {
            var myStyles = [{
                featureType: "poi",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            }
            ];
            map.current = new window.google.maps.Map(mapContainer.current, {
                center: { lat: consolidateData.lat, lng: consolidateData.lng },
                zoom: (consolidateData?.lat === 23.785345 && consolidateData?.lng === -28.580709) ? 1.5 : 15,
                mapTypeControl: false,
                streetViewControl: false,
                disableDefaultUI: true,
                clickableIcons: false,
                styles: myStyles
            });
            let marker = new window.google.maps.Marker({
                position: { lat: consolidateData.lat, lng: consolidateData.lng },
                map: map.current,
                optimized: false,
                title: "Your Location",
            });
            map.current.addListener("center_changed", () => {
                marker.setPosition(new window.google.maps.LatLng(map.current.getCenter().lat(), map.current.getCenter().lng()));
                setConsolidateData({ ...consolidateData, lat: map.current.getCenter().lat(), lng: map.current.getCenter().lng() });
            });
        });
    }, [consolidateData, setConsolidateData]);
    return (
        <Box component="form" noValidate onSubmit={nextStep0}>
            <p>
                Please confirm your location by selecting on the map.
            </p>
            <div ref={mapContainer} style={{ height: '40vh', maxHeight: '400px', position: 'relative', borderRadius: '5px' }} />

            <Grid container rowSpacing={2} sx={{ mt: 1 }}>
                {
                    navigator.geolocation &&
                    (
                        <Grid item xs={12}>
                            <Button
                                variant='outlined'
                                className={classes.outlinedButton}
                                fullWidth
                                onClick={async () => {
                                    try{
                                        const position = await getLongAndLat();
                                        const [curLat, curLng] = [position.coords.latitude, position.coords.longitude];

                                        if(consolidateData?.lat !== curLat || consolidateData?.lng !== curLng) {
                                            map.current.setCenter({ lat: curLat, lng: curLng });
                                            map.current.setZoom(15);
                                            setConsolidateData({ ...consolidateData, lat: curLat, lng: curLng });
                                        }
                                    }
                                    catch(e) {
                                        console.error(e.message);
                                    }
                                }}
                            >
                                Use Current Location
                            </Button>
                        </Grid>
                    )
                }

                <Grid item xs={12}>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <Button
                                id="sign-in-button"
                                fullWidth
                                variant="contained"
                                className={classes.styledButton}
                                sx={{ height: '50px', borderRadius: '5px' }}
                                disabled={loading}
                                onClick={() => setActiveStep(0)}
                            >
                                Previous
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                id="sign-in-button"
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.styledButton}
                                sx={{ height: '50px', borderRadius: '5px' }}
                                disabled={loading}
                            >
                                {loading ? <><CircularProgress style={{ color: 'white', float: 'left', width: '20px', height: '20px' }} /> <span style={{ marginLeft: '10px' }}>Working on it</span></> : 'Next'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}