import React, { useEffect, useState, useCallback } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import NotesIcon from '@mui/icons-material/Notes';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import BadgeIcon from '@mui/icons-material/Badge';
import { useStyles } from './styles';
import WaitLobby from "../VideoCall/waitLobby";
import './stylesCSS.css';
import { useDispatch } from "react-redux";
import * as businessActions from '../../redux/actions/Business';
import * as consumerActions from '../../redux/actions/Consumer';
import { PRIMARY_COLOR } from '../../config/appStyleConfig';

export default function CustomizedCard({ item }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [videoCallLobby, setVideoCallLobby] = useState(false);
  const [changingTime, setChangingTime] = useState('Loading...');
  const [suggestedEmployee, setSuggestedEmployee] = useState('Loading...');
  const [showNote, setShowNote] = useState(false);

  const handleShowNoteClose = () => {
    setShowNote(false);
  };
  const getEmployeeInfo = useCallback(async () => {
    return new Promise((resolve, reject) => {
      dispatch(consumerActions.getEmployeeFromId(item?.business_uid, item?.suggested_emp, resolve, reject));
    });
  }, [dispatch, item]);

  useEffect(() => {
    getEmployeeInfo().then((empInfo) => {
      setSuggestedEmployee(empInfo);
    });
  }, [dispatch, getEmployeeInfo]);

  useEffect(() => {
    if (item.entry_type === 0) {
      const myInterval = setInterval(() => {
        setChangingTime(Math.round(((new Date() - new Date(item.created_timestamp)) / 1000) / 60))
      }, 1000);

      return () => { clearInterval(myInterval) };
    }
  }, [item.created_timestamp, item.entry_type]);

  const sendReminderNotification = () => {
    dispatch(businessActions.sendReminderNotification(item));
  }

  const deleteIqueue = () => {
    dispatch(businessActions.deleteIqueue(item))
  }

  return (
    <div style={{ position: "relative", marginTop: "-5px" }}>
      <div className="ribbon ribbon-top-left" style={{ marginLeft: "5px", zoom: "0.85" }}><span>New</span></div>
      <Card className={classes.cardRootExpanded}>
        <Grid style={{ position: "relative" }} container direction="column" rowSpacing={0.9} columnSpacing={0.5}>
          <Grid item style={{ height: "12px", background: changingTime === "Loading..." && item.entry_type === 0 ? "grey" : changingTime > 60 ? "orange" : "green" }}></Grid>
          <Grid item sx={{ width: "100%" }}>
            <Typography noWrap style={{ color: "#222", fontFamily: 'Poppins', marginTop: "4px", fontStyle: "normal", fontWeight: "800", fontSize: "12px" }} align="center">
              {item?.customer_name}
            </Typography>
          </Grid>
          <div style={{ display: 'flex', margin: '4px 0 2px 15px' }}>
            <HourglassEmptyIcon sx={{ fontSize: 12, marginTop: '2px' }} />
            <Typography style={{ color: "#222", fontFamily: 'Poppins', fontSize: '10px', marginLeft: '5px' }} align="left" variant="subtitle2">
              {item.entry_type === 0 ? changingTime !== 'Loading...' ? `${changingTime} Minutes ago` : changingTime : `Booked for ${new Date(parseInt(item?.created_timestamp)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
            </Typography>
          </div>
          <div style={{ display: 'flex', marginLeft: '15px', marginBottom: '2px' }}>
            <ConfirmationNumberIcon sx={{ fontSize: 12, marginTop: '2px' }} />
            <Typography style={{ color: "#222", fontFamily: 'Poppins', fontSize: '10px', marginLeft: '5px' }} align="left" variant="subtitle2">
              {item?.token ? item?.token : 'Generating Token'}
            </Typography>
          </div>
          <div style={{ display: 'flex', marginLeft: '15px', marginBottom: '2px' }}>
            <PlaylistAddCheckIcon sx={{ fontSize: 12, marginTop: '2px' }} />
            <Typography noWrap style={{ color: "#222", minWidth: '135px', maxWidth: '135px', fontFamily: 'Poppins', fontSize: '10px', marginLeft: '5px' }} align="left" variant="subtitle2">
              {item?.services ? `${item?.services?.split(',').map((i) => i.split(';')[0]).join(", ")}` : 'No Services'}
            </Typography>
          </div>
          <div style={{ display: 'flex', marginLeft: '15px', marginBottom: '2px' }}>
            <BadgeIcon sx={{ fontSize: 12, marginTop: '2px' }} />
            <Typography style={{ color: "#222", fontFamily: 'Poppins', fontSize: '10px', marginLeft: '5px' }} align="left" variant="subtitle2">
              {suggestedEmployee?.name}
            </Typography>
          </div>
          <div style={{ display: 'flex', marginLeft: '15px' }}>
            <LocationOnIcon sx={{ fontSize: 12, marginTop: '2px' }} />
            <Typography style={{ color: "#222", fontFamily: 'Poppins', fontSize: '10px', marginLeft: '5px' }} align="left" variant="subtitle2">
              {item?.video_visit === true ? 'Not yet joined' : item?.reached ? "Waiting in the lobby" : "Location not shared"}
            </Typography>
          </div>
          <Grid container item direction="row" justifyContent="space-evenly" alignItems="center" style={{ background: PRIMARY_COLOR, marginTop: "7px", padding: "7px 0" }}>
            <Tooltip title="Send Reminder Notification">
              <IconButton onClick={() => sendReminderNotification()} style={{ color: PRIMARY_COLOR, background: "#fff", border: `1px solid ${PRIMARY_COLOR}` }} size="small">
                <NotificationsIcon style={{ width: '15px', height: '15px' }} />
              </IconButton>
            </Tooltip>
            {item?.appointment_note && (<Tooltip title="Show Note"><IconButton onClick={() => setShowNote(true)} style={{ color: PRIMARY_COLOR, background: "#fff", border: `1px solid ${PRIMARY_COLOR}` }} size="small">
              <NotesIcon style={{ width: '15px', height: '15px' }} />
            </IconButton></Tooltip>)}
            {item?.video_visit && <Tooltip title="Video call"><IconButton style={{ color: PRIMARY_COLOR, background: "#fff", border: `1px solid ${PRIMARY_COLOR}` }} size="small"
              onClick={() => setVideoCallLobby(true)}>
              <VideoCallIcon style={{ width: '15px', height: '15px' }} />
              {item?.is_in_lobby === true && <Typography style={{ fontSize: '12px' }}>Waiting</Typography>}
            </IconButton></Tooltip>}
            <Tooltip title="Cancel Appointment">
              <IconButton onClick={() => deleteIqueue()} style={{ color: PRIMARY_COLOR, background: "#fff", border: "1px solid #d32f2f" }} size="small">
                <DeleteIcon style={{ width: '15px', height: '15px' }} color="error" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {
          videoCallLobby && (
            <WaitLobby
              videoCallLobby={videoCallLobby}
              setVideoCallLobby={setVideoCallLobby}
              consumer={false}
              consumerId={item?.customer_uid}
              businessId={item?.business_uid}
              consumerTimeStamp={item?.created_timestamp}
              consumerQueueId={item?.uid}
              request={null}
            />
          )
        }
        <Dialog
          open={showNote}
          onClose={handleShowNoteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Note</DialogTitle>
          <DialogContent sx={{ width: "450px" }}>
            <DialogContentText id="alert-dialog-description">
              {item?.appointment_note}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleShowNoteClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Card >
    </div>
  );
}
