import React, { useEffect, useState } from 'react';
import {
    Grid, Box, Button, CircularProgress,
    FormGroup, FormControlLabel, Checkbox, Typography, TextField, Switch, 
    Snackbar,
} from '@mui/material';
import MuiAlert from "@mui/material/Alert";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { ServiceTimeSelect } from './serviceTimeSelect';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
  
export const ServiceInfoStep = ({ classes, loading, setActiveStep, otpStep, consolidateData, setConsolidateData }) => {

    const [availableServices, setAvailableServices] = useState([]);
    const [newService, setNewService] = useState('');
    const [changeServiceTime, setChangeServiceTime] = useState(null);
    const [atleastOneServiceEnabled, setAtleastOneServiceEnabled] = useState(true)
    const [snackbarData, setSnackBarData] = useState(null);

    useEffect(() => {
        if (availableServices.length === 0) {
            const remoteConfig = getRemoteConfig();
            remoteConfig.settings.minimumFetchIntervalMillis = 1000;
            let defObj = consolidateData.category;
            remoteConfig.defaultConfig = {
                defObj: "Doc,Sal"
            };
            fetchAndActivate(remoteConfig)
                .then(() => {
                    const val = getValue(remoteConfig, defObj);
                    if (val._value) {
                        setAvailableServices(val._value.split(",").map((v) => `${v.trim()};30`));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [consolidateData, availableServices]);

    const handleChange = (event) => {
        if (event.target.checked) {
            setConsolidateData({ ...consolidateData, services: [...consolidateData.services, event.target.name] });
        } else {
            const itemName = event.target.name.split(';')[0];
            setConsolidateData({ ...consolidateData, services: consolidateData.services.filter((i) => i.split(';')[0] !== itemName) });
        }
    };

    const handleSwitch = () =>{
        setAtleastOneServiceEnabled(!atleastOneServiceEnabled);
    }

    const handleAddService = (event) => {
        let serviceName = newService.trim();
        if (serviceName && (!availableServices.map(s => s.split(';')[0]).some(s => s === serviceName))) {
            serviceName = `${serviceName};30`;
            setAvailableServices([...availableServices, serviceName]);
            setConsolidateData({ ...consolidateData, services: [...consolidateData.services, serviceName] });
        }
        setNewService('');
    };
    
    return (
        <Box component="form" onSubmit={otpStep} noValidate sx={{ width: '100%' }}>
            <p style={{ textAlign: "center", fontFamily: 'Poppins' }}>
                Please select and confirm your services
            </p>
            {availableServices.length === 0 && <Typography variant='subtitle1' style={{ textAlign: "center", fontFamily: 'Poppins' }}>
                No service available under this category
            </Typography>}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormGroup>
                        {availableServices.map((item, index) => {
                            const [itemName, itemDur] = item.split(';');
                            return (
                                <FormControlLabel key={index} 
                                    control={ <Checkbox checked={consolidateData?.services?.map(s => s.split(';')[0]).some((i) => i === itemName)} name={item} onChange={handleChange} />}
                                    label={ <div className={classes.serviceItem}>
                                                <Typography style={{ fontFamily: "Poppins", fontWeight: 600 }}>{itemName}</Typography>
                                                <Button onClick={() => { setChangeServiceTime(item) }}>{itemDur} Min</Button>
                                            </div>} /> 
                            )}
                        )}
                    </FormGroup>
                </Grid>
                <Grid item xs={12} style={{ dispaly: 'flex' }}>
                    <TextField
                        value={newService}
                        inputProps={{ style: { fontFamily: "Poppins" }, maxLength: 100 }}
                        InputLabelProps={{ style: { fontFamily: "Poppins" } }}
                        label="Enter new service"
                        size='small'
                        onChange={(e) => {
                            if(!e.target.value.includes(';'))
                                setNewService(e.target.value)
                        }}
                    />
                    <Button variant="contained" style={{ width: '35%', marginLeft: '1rem' }} className={classes.styledButton} onClick={handleAddService}>
                        Add Service
                    </Button>
                </Grid>
                <Grid item xs={12} style={{ dispaly: 'flex' }} >
                    <FormGroup>
                    <FormControlLabel
                        control={<Switch
                        checked={atleastOneServiceEnabled}
                        onChange={handleSwitch}
                        />}
                        label={<p style={{ textAlign: "center" }}>Customer must select at-least one service to take appointment/walk-in</p>}
                    />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                    <Button
                        id="sign-in-button"
                        fullWidth
                        variant="contained"
                        className={classes.styledButton}
                        // style={{ height: '50px', borderRadius: '5px' }}
                        sx={{ mt: 3, height: '50px', borderRadius: '5px' }}
                        disabled={loading}
                        onClick={() => { 
                            setConsolidateData({ ...consolidateData, services: [] })
                            setActiveStep(3)
                        }}
                    >
                        Previous
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        onClick={(ev) => {    
                            if(consolidateData.services.length === 0) {
                                ev.preventDefault();
                                setSnackBarData({
                                    open: true,
                                    message: availableServices.length === 0 ? "Please add at-least one service" : "Please select at-least one service",
                                    severity: "error",
                                });
                                return;
                            }
                            setConsolidateData({
                                ...consolidateData,
                                atleastOneServiceEnabled: atleastOneServiceEnabled,
                            });
                        }}
                        id="sign-in-button"
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.styledButton}
                        sx={{ mt: 3, height: '50px', borderRadius: '5px' }}
                        // sx={{ height: '50px', marginLeft: { xs: '0px', md: '10px', lg: '10px' }, borderRadius: '5px', mt: { xs: 0, md: 3, lg: 3 }, mb: 2 }}
                        disabled={loading}
                    >
                        {loading ? <><CircularProgress style={{ color: 'white', float: 'left', width: '20px', height: '20px' }} /> <span style={{ marginLeft: '10px' }}>Working on it</span></> : 'Next'}
                    </Button>
                </Grid>
            </Grid>
            {changeServiceTime && <ServiceTimeSelect
                classes={classes}
                item={changeServiceTime}
                setItem={setChangeServiceTime}
                consolidateData={consolidateData}
                setConsolidateData={setConsolidateData}
                availableServices={availableServices}
                setAvailableServices={setAvailableServices} />}
                {snackbarData && snackbarData.open && (
            <Snackbar
            open={snackbarData.open}
            autoHideDuration={3000}
            onClose={() => setSnackBarData(null)}
            >
            <Alert severity={snackbarData.severity}>{snackbarData.message}</Alert>
            </Snackbar>
        )}
        </Box>)
}