import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    cardRoot: {
        marginLeft: '30px',
        background: "#FFFFFF",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        height: '140px',
        width: "140px",
        marginBottom: '10px'
    },
    cardRootExpanded: {
        marginLeft: '30px',
        background: "#FFFFFF",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        width: "220px",
        height: "290px",
        marginBottom: '10px'
    }
});