import React, { useState } from "react";
import { Button, TextField, Grid, Box, Grow, Typography, Switch, FormGroup, FormControlLabel, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import { updateBusinessDetails } from "../../../../redux/actions/Auth";

function getTime(timeStr) {
  if (!timeStr) return null;
  let valArr = timeStr.split(":");
  let hrs = +valArr[0];
  valArr = valArr[1].split(" ");
  let mins = +valArr[0];
  const isPM = valArr[1] === "pm" || valArr[1] === "PM";

  if (isPM) hrs += 12;
  if (hrs % 12 === 0) hrs -= 12;

  return new Date().setHours(hrs, mins, 0);
}


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const Timing = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { businessData } = useSelector((state) => state.authReducer);
  const [openTime, setOpenTime] = useState(getTime(businessData?.openTime));
  const [closeTime, setCloseTime] = useState(getTime(businessData?.closeTime));
  const [appointments, setAppointments] = useState(Boolean(businessData?.accept_appointments));
  const [videoVisits, setVideoVisits] = useState(Boolean(businessData?.accept_video_visits));
  const [autoAccept, setAutoAccept] = useState(Boolean(businessData?.auto_accept));
  const [acceptTakeAway, setAcceptTakeAway] = useState(Boolean(businessData?.accept_take_away));
  const [acceptCatering, setAcceptCatering] = useState(Boolean(businessData?.accept_catering));
  const [allowSelectPreferredTables, setAllowSelectPreferredTables] = useState(Boolean(businessData?.displayPreferredTable));
  const [isEdit, setIsEdit] = useState(false);
  const [showErr, setShowErr] = useState(false);

  const allDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const allFalse = [false, false, false, false, false, false, false];
  const [openDays, setOpenDays] = useState(
    businessData?.openDays
      ? businessData?.openDays.split(",").map((item) => {
        return parseInt(item) ? true : false;
      })
      : allFalse
  );

  const handleUpdate = () => {
    const isTimeValid = moment(openTime).isValid() && moment(closeTime).isValid();
    const areDiff = !moment(openTime).isSame(closeTime);
    if (isTimeValid && areDiff) {
      dispatch(
        updateBusinessDetails({
          ...businessData,
          accept_appointments: appointments,
          accept_video_visits: videoVisits,
          auto_accept: autoAccept,
          displayPreferredTable: businessData.category === "Restaurant" ? allowSelectPreferredTables : null,
          accept_take_away: businessData.category === "Food Truck" ? acceptTakeAway : null,
          accept_catering: businessData.category === "Food Truck" ? acceptCatering : null,
          openTime: moment(openTime).format('hh:mm a'),
          closeTime: moment(closeTime).format('hh:mm a'),
          openDays: openDays
            .map((item) => {
              return item ? "1" : "0";
            })
            .toString()
        })
      );

      setIsEdit(!isEdit);
    }
    else {
      if (!areDiff)
        setShowErr(true);
    }
  };

  return (
    <Grow in>
      <Box component="form" noValidate sx={{ mt: 3 }}>
        <Grid container rowGap={5} direction="column" xs={12} sm={10} md={8} lg={6} sx={{ marginX: "auto" }}>
          <Grid item sx={{ paddingLeft: "0!important" }}>
            <Typography variant="h6" style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "16px", marginBottom: "8px" }}>
              Operating hours:
            </Typography>

            <Grid container spacing={2} justifyContent="space-between" sx={{ position: "relative" }}>
              <Grid item alignSelf="center" sx={{ position: "absolute", width: "100%" }}>
                <hr />
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    readOnly={!isEdit}
                    value={openTime}
                    onChange={(newValue) => {
                      setOpenTime(newValue);
                    }}
                    renderInput={(params) => <TextField
                      {...params}
                      style={{ maxWidth: "135px", background: "#fff" }}
                      onKeyDown={e => e.preventDefault()}
                    />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item alignSelf="center">
                <div className={classes.divider}> to </div>
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    readOnly={!isEdit}
                    name="closeTime"
                    value={closeTime}
                    onChange={(newValue) => {
                      setCloseTime(newValue);
                    }}
                    renderInput={(params) => <TextField
                      {...params}
                      style={{ maxWidth: "135px", background: "#fff" }}
                      onKeyDown={e => e.preventDefault()}
                    />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={{ paddingLeft: "0!important" }}>
            <Typography variant="h6" style={{ fontFamily: "Poppins", fontWeight: 600, fontSize: "15px", marginBottom: "8px" }}>
              Operating days:
            </Typography>

            <Grid container columnSpacing={1} rowSpacing={1}>
              {allDays.map((day, index) => {
                return (
                  <Grid item xs={6} sm={4} key={index}>
                    <Button
                      fullWidth
                      name={day}
                      className={
                        openDays[index]
                          ? classes.styledButton
                          : classes.disabledButton
                      }
                      style={{
                        height: "35px",
                        borderRadius: "5px",
                        textTransform: "none",
                        cursor: isEdit ? "pointer" : "auto"
                      }}
                      onClick={() => {
                        if (isEdit) {
                          let newOpenDays = [...openDays];
                          newOpenDays[index] = !openDays[index];
                          if (newOpenDays.some((el) => el))
                            setOpenDays(newOpenDays);
                        }
                      }}
                    >
                      {day}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          {businessData?.category !== "Restaurant" && businessData?.category !== "Food Truck" && <Grid item >
            <FormGroup >
              <FormControlLabel
                control={
                  <Switch
                    checked={appointments}
                    onChange={() => { if (isEdit) setAppointments(!appointments); }}
                  />
                }
                label="Accept Appointments"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={videoVisits}
                    onChange={() => { if (isEdit) setVideoVisits(!videoVisits); }}
                  />
                }
                label="Accept Video Visits"
              />
              <FormControlLabel
                control={
                  <Switch
                    readOnly={!isEdit}
                    checked={autoAccept}
                    onChange={() => { if (isEdit) setAutoAccept(!autoAccept); }}
                  />
                }
                label="Auto-Accept"
              />
            </FormGroup>
          </Grid>}

          {businessData?.category === "Food Truck" && <Grid item >
            <FormGroup >
              <FormControlLabel
                control={
                  <Switch
                    checked={acceptTakeAway}
                    onChange={() => { if (isEdit) setAcceptTakeAway(!acceptTakeAway); }}
                  />
                }
                label="Accept Take aways"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={acceptCatering}
                    onChange={() => { if (isEdit) setAcceptCatering(!acceptCatering); }}
                  />
                }
                label="Accept catering"
              />
            </FormGroup>
          </Grid>}

          {businessData?.category === "Restaurant" && <Grid item >
            <FormGroup >
              <FormControlLabel
                control={
                  <Switch
                    checked={allowSelectPreferredTables}
                    onChange={() => { if (isEdit) setAllowSelectPreferredTables(!allowSelectPreferredTables); }}
                  />
                }
                label="Allow customers to select preferred table"
              />
            </FormGroup>
          </Grid>}

          <Grid item alignSelf={"center"} sx={{ mt: 2 }}>
            {isEdit ? <Button
              id="sign-in-button"
              variant="contained"
              size="large"
              className={classes.styledButton}
              sx={{ height: "50px", borderRadius: "5px" }}
              onClick={handleUpdate}
            >
              UPDATE
            </Button> :
              <Button
                id="sign-in-button"
                variant="contained"
                size="large"
                className={classes.styledButton}
                sx={{ height: "50px", borderRadius: "5px" }}
                onClick={() => setIsEdit(!isEdit)}
              >
                Edit
              </Button>}
          </Grid>
        </Grid>
        <Snackbar
          open={showErr}
          onClose={() => { setShowErr(false) }}
          autoHideDuration={3000}
        >
          <Alert severity="error">Open and close time must be different</Alert>
        </Snackbar>
      </Box>
    </Grow>
  );
};
