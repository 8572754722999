import * as types from "../../actions/BusinessCoupons/types";
import { select, takeEvery } from "redux-saga/effects";
import { getDatabase, ref, push, set, onValue, off, remove, update } from "firebase/database";
import { store } from "../../store/configureStore";
import * as businessCouponActions from "../../actions/BusinessCoupons";
import {
    getStorage, ref as storageRef, uploadString, getDownloadURL,
    deleteObject, uploadBytes
} from "firebase/storage";

function* createCouponAsync(action) {
    try {
        const { auth } = yield select((state) => state.authReducer);
        const db = getDatabase();
        const tokenRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/Coupons/`);
        const newRef = push(tokenRef);
        if (action?.couponData?.file !== null) {
            const storage = getStorage();
            const couponImageRef = storageRef(
                storage,
                `${auth?.uid}/coupon_images/${action?.couponData?.name}-${action?.couponData?.code}`
            );
            uploadBytes(couponImageRef, action?.couponData?.file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    set(newRef, {
                        ...action?.couponData,
                        file: downloadURL,
                        id: newRef.key
                    }).then(() => {
                        action?.resolve(downloadURL);
                        //Update reducer (if you make loading not real time)
                    });
                });
            });
            return;
        }
        yield set(newRef, {
            ...action?.couponData,
            id: newRef.key
        }).then(() => {
            action?.resolve('Done');
            //Update reducer (if you make loading not real time)
        });
    } catch (error) {
        console.log(error);
    }
}

function* loadCouponsAsync(action) {
    try {
        const { auth } = yield select((state) => state.authReducer);
        const db = getDatabase();
        off(ref(db, `iQueue/Public/Businesses/${auth?.uid}/Coupons/`));
        const tokenRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/Coupons/`);
        onValue(tokenRef, (snapshot) => {
            store.dispatch(
                businessCouponActions.updateCouponsList(snapshot.val())
            );
        },
        );
    } catch (error) {
        console.log(error);
    }
}

function* deleteCouponAsync(action) {
    try {
        const { auth } = yield select((state) => state.authReducer);
        const db = getDatabase();
        const tokenRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/Coupons/${action?.couponData?.id}`);
        yield remove(tokenRef).then(() => {
            //Update reducer (if you make loading not real time)
            //Clean up any generated images
            const storage = getStorage();
            const imagesRef = storageRef(
                storage,
                action?.couponData?.downloadURL
            );
            deleteObject(imagesRef).then(() => {

            }).catch((er) => {
                console.log(er);
            });
            //Clean up any coupon images
            const couponImageRef = storageRef(
                storage,
                action?.couponData?.file
            );
            deleteObject(couponImageRef).then(() => {

            }).catch((er) => {
                console.log(er);
            });
        });
    } catch (error) {
        console.log(error);
    }
}

function* uploadGenerateURLAsync(action) {
    try {
        const { auth } = yield select((state) => state.authReducer);
        const storage = getStorage();
        const couponsImageRef = storageRef(
            storage,
            `${auth?.uid}/share_coupons/${action?.couponItem?.name}-${action?.couponItem?.code}`
        );
        uploadString(couponsImageRef, action?.couponItem?.base64, 'data_url').then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                const db = getDatabase();
                const tokenRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/Coupons/${action?.couponItem?.id}`);
                update(tokenRef, {
                    downloadURL: downloadURL
                }).then(() => {
                    action.resolve(downloadURL);
                });
            });
        });
    } catch (error) {
        console.log(error);
    }
}

function* shareCouponWithAsync(action) {
    try {
        const { auth, businessData } = yield select((state) => state.authReducer);
        const db = getDatabase();
        action?.customerList?.forEach((customer) => {
            const tokenRef = ref(db, `iQueue/Restricted/Consumers/${customer}/Coupons/${action?.couponData?.id}/`);
            update(tokenRef, {
                // ...action?.couponData,
                'couponId': action?.couponData?.id,
                'businessId': auth?.uid,
                'businessName': businessData?.name,
            }).then(() => {

            });
        });
    } catch (error) {
        console.log(error);
    }
}

export function* createCouponSaga() {
    yield takeEvery(types.CREATE_COUPON, createCouponAsync);
}
export function* deleteCouponSaga() {
    yield takeEvery(types.DELETE_COUPON, deleteCouponAsync);
}
export function* loadCouponsSaga() {
    yield takeEvery(types.LOAD_COUPONS, loadCouponsAsync);
}

export function* uploadGenerateURLSaga() {
    yield takeEvery(types.UPLOAD_GENERATE_URL, uploadGenerateURLAsync);
}

export function* shareCouponWithSaga() {
    yield takeEvery(types.SHARE_COUPON_WITH, shareCouponWithAsync);
}