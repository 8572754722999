import { takeEvery, select } from 'redux-saga/effects';
import { getAuth } from "firebase/auth";
import * as types from '../../actions/Consumer/types';
import * as consumerActions from '../../actions/Consumer';
import {
    getDatabase, ref, query,
    orderByChild, startAt, endAt, onChildAdded,
    onChildChanged, set, get, onValue, update, onChildRemoved
} from "firebase/database";
import * as geofire from 'geofire-common';
import { store } from '../../store/configureStore';
import { v4 as uuidv4 } from 'uuid';
import { updateGlobalAnalytics } from '../../actions/Auth'


function* getNearbyBusinessesAsync(action) {
    try {
        if (getAuth()) {
            const radiusInM = action.km * 1000;
            const bounds = geofire.geohashQueryBounds(action.center, radiusInM);
            const db = getDatabase();
            const businessRef = ref(db, `iQueue/Public/Businesses`);
            const promises = [];
            let performBoundQueries = async () => {
                for (const b of bounds) {
                    const topRes = query(businessRef, orderByChild('profile/geoHash'), startAt(b[0]), endAt(b[1]));
                    let snap = await get(topRes);
                    promises.push(snap.val());
                    //There are some bugs with onValue
                    /*return onValue(topRes, (snapshot) => {
                        promises.push(snapshot.val());
                    }, {
                        onlyOnce: true
                    });*/
                }
            }
            yield performBoundQueries();
            let matchData = []
            for (const promise of promises) {
                for (var key in promise) {
                    let lat = promise[key].profile.lat;
                    const lng = promise[key].profile.lng;
                    const distanceInKm = geofire.distanceBetween([lat, lng], action.center);
                    const distanceInM = distanceInKm * 1000;
                    if (distanceInM <= radiusInM) {
                        matchData.push(promise[key]);
                    }
                }
            }
            store.dispatch(consumerActions.updateNearbyBusinesses(matchData.map((i) => i.profile)));
        }
    } catch (error) {
        console.log(error);
    }
}

function* getMyQueueAsync() {
    try {
        const { auth } = yield select((state) => state.authReducer);
        const db = getDatabase();
        const queueRef = query(ref(db, `iQueue/Restricted/Consumers/${auth?.uid}/iQueues/`), orderByChild('created_timestamp'));
        onChildAdded(queueRef, (data) => {
            store.dispatch(consumerActions.updateMyQueueAdd(data.val()));
        });
        onChildChanged(queueRef, (data) => {
            store.dispatch(consumerActions.updateMyQueueChange(data.val()));
        });
        onChildRemoved(queueRef, (data) => {
            store.dispatch(consumerActions.updateMyQueueRemove(data.val()));
        });
    } catch (error) {
        console.log(error);
    }
}

function* getBusinessFromIdAsync(action) {
    try {
        const db = getDatabase();
        const businessRef = ref(db, `iQueue/Public/Businesses/${action.businessId}`);
        yield onValue(businessRef, (snapshot) => {
            action.resolve(snapshot.val().profile);
        });
    } catch (error) {
        console.log(error);
    }
}

function* getEmployeeFromIdAsync(action) {
    try {
        const db = getDatabase();
        const businessRef = ref(db, `iQueue/Restricted/Businesses/${action.businessId}/employees/${action.employeeId}`);
        yield onValue(businessRef, (snapshot) => {
            action.resolve(snapshot.val());
        });
    } catch (error) {
        console.log(error);
    }
}

function* rateIQueueEntryAsync(action) {
    try {
        let newDate = new Date(action?.iQueue?.created_timestamp);
        yield set(ref(getDatabase(), `iQueue/Restricted/Businesses/${action?.iQueue?.business_uid}/iQueues/${newDate.getFullYear()}/${newDate.getMonth()}/${newDate.getDate()}/${action?.iQueue?.uid}/rating`), action?.rating)
            .then(() => {
            }).catch((error) => {
                alert(error);
            });
        yield set(ref(getDatabase(), `iQueue/Restricted/Consumers/${action?.iQueue?.customer_uid}/iQueues/${action?.iQueue?.uid}/rating`), action?.rating)
            .then(() => {
            }).catch((error) => {
                alert(error);
            });
    } catch (error) {
        console.log(error);
    }
}

function getDuration(services) {
    let duration = 0;
    if (services) {
        let servicesList = services.split(',').map((i) => {
            return { name: i.split(';')[0], time: i.split(';')[1] };
        });
        servicesList.forEach((serv) => {
            duration += parseInt(serv.time);
        });
    }
    return duration ? duration : 15;
}

function* joinQueueAsync({ joinData }) {
    try {
        const { userData } = yield select((state) => state.authReducer);
        console.log(userData);
        let timeStamp = joinData?.businessData?.created_timestamp ? joinData?.businessData?.created_timestamp : new Date().getTime();
        let id = uuidv4();
        let dataToSave = {
            amount: 100,
            business_uid: joinData?.businessData?.uid,
            business_name: joinData?.businessData?.title,
            created_timestamp: timeStamp,
            duration: getDuration(joinData?.businessData?.services ? joinData?.businessData?.services : null),
            customer_name: userData?.name ? userData?.name : joinData?.businessData?.customerData?.name,
            customer_uid: userData?.uid ? userData?.uid : joinData?.businessData?.customerData?.contact,
            capacity: joinData?.businessData?.capacity ? parseInt(joinData?.businessData?.capacity) : null,
            customer_contact: userData?.contact ? userData?.contact : joinData?.businessData?.customerData?.contact,
            entry_type: joinData?.businessData?.entryType ? joinData?.businessData?.entryType : 0,
            services: joinData?.businessData?.services ? joinData?.businessData?.services : null,
            video_visit: joinData?.businessData?.visitType === "video" ? true : false,
            //appointmentTime: For now its in created_timestamp.
            status: joinData?.businessData?.entryType === 1 && joinData?.businessData?.type !== "MANUAL" ? 2 : 1,
            note: joinData?.businessData?.note ? joinData?.businessData?.note : null,
            uid: id
        }
        set(ref(getDatabase(), `iQueue/Restricted/Businesses/${joinData.businessData?.uid}/iQueues/${new Date(timeStamp).getFullYear()}/${new Date(timeStamp).getMonth()}/${new Date(timeStamp).getDate()}/${id}/`), dataToSave)
            .then(() => {
            }).catch((error) => {
                alert(error);
            });
        if (userData?.uid) {
            set(ref(getDatabase(), `iQueue/Restricted/Consumers/${userData?.uid}/iQueues/${id}/`), dataToSave)
                .then(() => {
                }).catch((error) => {
                    alert(error);
                });
        } else {
            //Create new profile
            set(ref(getDatabase(), `iQueue/Restricted/Consumers/${dataToSave?.customer_contact}/profile`), {
                'contact': dataToSave?.customer_contact,
                'name': dataToSave?.customer_name,
                'uid': dataToSave?.customer_contact,
            }).then(() => {
            }).catch((error) => {
                alert(error);
            });
            set(ref(getDatabase(), `iQueue/Restricted/Consumers/${dataToSave?.customer_contact}/iQueues/${id}/`), dataToSave)
                .then(() => {
                }).catch((error) => {
                    alert(error);
                });
        }
        addCustomerInCrm(dataToSave.customer_contact, dataToSave.customer_name, dataToSave.business_uid);
        //update global analytics
        store.dispatch(updateGlobalAnalytics("Appointments"));
        store.dispatch(updateGlobalAnalytics("Customers"));
    } catch (error) {
        console.log(error);
    }
}

function addCustomerInCrm(customer_contact, customer_name, business_uid) {
    try {
        const customerObj = {
            name: customer_name,
            contact: customer_contact,
            rating: 0,
            is_new: true,
            is_blocked: false,
            uid: customer_contact,
        };
        const db = getDatabase();

        //check if customer is already present in cutomer array or not

        onValue(
            ref(db, `iQueue/Restricted/Businesses/${business_uid}/Customers`),
            (snapshot) => {
                if (snapshot.exists()) {
                    if (!Object.keys(snapshot.val()).includes(customer_contact)) {
                        customerObj.is_new = true;
                        const userRef = ref(db, `iQueue/Restricted/Businesses/${business_uid}/Customers/${customer_contact}`);
                        update(userRef, customerObj);
                    } else {
                        const userRef = ref(db, `iQueue/Restricted/Businesses/${business_uid}/Customers/${customer_contact}`);
                        update(userRef, { is_new: false });
                    }
                } else {
                    customerObj.is_new = true;
                    const userRef = ref(db, `iQueue/Restricted/Businesses/${business_uid}/Customers/${customer_contact}`);
                    update(userRef, customerObj);
                }
            },
            {
                onlyOnce: true,
            }
        );
    } catch (err) {
        console.log(err);
    }
}

export function* consumerLoadNearbyBusinessSaga() {
    yield takeEvery(types.LOAD_NEARBY_BUSINESSES, getNearbyBusinessesAsync);
}

export function* consumerEnableMyQueueListenerSaga() {
    yield takeEvery(types.ENABLE_MY_QUEUE_LISTENER, getMyQueueAsync);
}

export function* consumerJoinQueueSaga() {
    yield takeEvery(types.JOIN_QUEUE, joinQueueAsync);
}

export function* getBusinessFromIdSaga() {
    yield takeEvery(types.GET_BUSINESS_FROM_ID, getBusinessFromIdAsync);
}

export function* getEmployeeFromIdSaga() {
    yield takeEvery(types.GET_EMPLOYEE_FROM_ID, getEmployeeFromIdAsync);
}

export function* rateIQueueEntrySaga() {
    yield takeEvery(types.RATE_QUEUE_ENTRY, rateIQueueEntryAsync);
}