import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from "@googlemaps/js-api-loader";
import {
    Typography, Button,
    IconButton, Grid, Rating,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'chart.js'
import { Bar } from 'react-chartjs-2';
import { useStyles } from './styles';
import * as consumerActions from '../../../redux/actions/Consumer';
import { mapApiKey } from '../../../config/firebaseConfig';
import {
    getDatabase, onChildChanged, onChildRemoved,
    onValue, off, get, ref
} from "firebase/database";
import { initPopup } from './mapCustomPopup';
import { ServicesConfirmDialog } from './ServicesConfirmDialog';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';
Chart.register(annotationPlugin);

let placeHolderdata = [
    { hour: 0, value: 0 }, { hour: 1, value: 0 }, { hour: 2, value: 0 },
    { hour: 3, value: 0 }, { hour: 4, value: 0 }, { hour: 5, value: 0 },
    { hour: 6, value: 0 }, { hour: 7, value: 0 }, { hour: 8, value: 0 },
    { hour: 9, value: 0 }, { hour: 10, value: 0 }, { hour: 11, value: 0 },
    { hour: 12, value: 0 }, { hour: 13, value: 0 }, { hour: 14, value: 0 },
    { hour: 15, value: 0 }, { hour: 16, value: 0 }, { hour: 17, value: 0 },
    { hour: 18, value: 0 }, { hour: 19, value: 0 }, { hour: 20, value: 0 },
    { hour: 21, value: 0 }, { hour: 22, value: 0 }, { hour: 23, value: 0 },
]

const loader = new Loader({
    // apiKey: firebaseConfig.apiKey,
    apiKey: mapApiKey.apiKey,
    version: "weekly",
    libraries: ["places"]
});

const DashboardUser = forwardRef(({ searchQuery, setSearchResultList }, forwardRef) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    let mapContainer = useRef();
    let map = useRef();
    let popup = useRef();
    let popupContainer = useRef();

    const { businesses } = useSelector((state) => state.consumerReducer);

    //const [userLocation, setUserLocation] = useState(null);
    const [analyticDay, setAnalyticDay] = useState(new Date().getDay());
    const [analyticDays] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
    const [serviceConfirmDialog, setServiceConfirmDialog] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [businessesList, setBusinessList] = useState([]);
    const [markerList, setMarkerList] = useState([]);
    const [popupData, setPopupData] = useState({
        title: '',
        openTime: '',
        closeTime: '',
        contact: '',
        address: '',
        queueSize: 0,
        images: []
    });

    useImperativeHandle(forwardRef, () => ({

        handleParentPopupCall(business) {
            handlePopupOpen(business)
        }

    }));

    useEffect(() => {
        if (businesses) {
            setBusinessList(businesses);
        }
    }, [businesses]);

    useEffect(() => {
        if (map.current) return;
        loader.load().then(() => {
            var myStyles = [{
                featureType: "poi",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            }
            ];
            map.current = new window.google.maps.Map(mapContainer.current, {
                center: { lat: 31.9668382, lng: 77.118168 },
                zoom: 15,
                mapTypeControl: false,
                streetViewControl: false,
                clickableIcons: false,
                styles: myStyles
            });
            let myTimeout;
            map.current.addListener("center_changed", () => {
                if (myTimeout) {
                    clearTimeout(myTimeout);
                }
                myTimeout = setTimeout(() => {
                    const center = [map.current.getCenter().lat(), map.current.getCenter().lng()];
                    dispatch(consumerActions.loadNearByBusinesses(center, 120));
                }, 500);
            });
            setMapLoaded(true);
        });
    }, [dispatch]);

    useEffect(() => {
        const db = getDatabase();
        const businessRef = ref(db, 'iQueue/Public/Businesses');
        /*onChildAdded(businessRef, (data) => {
            //setBusinessList((val) => [...val, data.val().profile])
        });*/

        onChildChanged(businessRef, (data) => {
            setBusinessList((val) => val.map((item) => {
                if (item?.uid === data?.val()?.profile?.uid) {
                    return data?.val()?.profile;
                }
                return item;
            }));
            setMarkerList((val) => val?.map((mp, index) => {
                if (mp?.uid === data?.val()?.profile?.uid) {
                    mp?.marker?.setMap(null);
                    val?.splice(index, 1);
                    if (popup.current) {
                        setPopupData({
                            title: data?.val()?.profile?.name,
                            openTime: data?.val()?.profile?.openTime,
                            closeTime: data?.val()?.profile?.closeTime,
                            contact: data?.val()?.profile?.contact,
                            images: data?.val()?.profile?.businessImages,
                        });
                    }
                }
                return mp;
            }));

        });

        onChildRemoved(businessRef, (data) => {
            setBusinessList((val) => val?.filter((item) => {
                return item?.uid !== data?.val()?.profile?.uid
            }));
            setMarkerList((val) => val?.map((mp, index) => {
                if (mp?.uid === data?.val()?.profile?.uid) {
                    mp?.marker?.setMap(null);
                    val?.splice(index, 1);
                    if (popup?.current) {
                        popup?.current?.setMap(null);
                    }
                }
                return mp;
            }));
        });
    }, []);

    function dayDiff(startdate, enddate) {
        var dayCount = 0;

        while (enddate >= startdate) {
            dayCount++;
            startdate.setDate(startdate.getDate() + 1);
        }

        return dayCount;
    }

    const handlePopupOpen = useCallback((businessData) => {
        if (popup.current) popup?.current?.onRemove();
        const Popup = initPopup();
        off(ref(getDatabase(), `iQueue/Restricted/Businesses/${businessData.uid}/analytics/`));
        onValue(ref(getDatabase(), `iQueue/Restricted/Businesses/${businessData.uid}/analytics/`), (snapshot) => {
            setPopupData((val) => ({ ...val, queueSize: snapshot?.val()?.queue_size }))
        });
        const diffInDays = dayDiff(new Date(businessData.create_time), new Date());
        off(ref(getDatabase(), `iQueue/Restricted/Businesses/${businessData.uid}/analytics/hourly_queue/${new Date().getDay()}/`));
        onValue(ref(getDatabase(), `iQueue/Restricted/Businesses/${businessData.uid}/analytics/hourly_queue/${new Date().getDay()}/`), (snapshot) => {
            let chartData = {
                labels: [],
                datasets: [{
                    label: 'Average Queue',
                    data: [],
                    backgroundColor: PRIMARY_COLOR,
                }]
            };
            let tempArray = [];
            snapshot?.forEach((snap) => {
                let val;
                if (diffInDays <= 7) {
                    val = (snap.val());
                } else {
                    val = (snap.val() / (diffInDays / 7));
                }
                let initValue = val >= 0.5 ? Math.ceil(val) : Math.floor(val);
                let tmp = {
                    hour: parseInt(snap.key.split('_')[1]),
                    value: initValue
                }
                tempArray.push(tmp);
            });
            placeHolderdata = [{ hour: 0, value: 0 }, { hour: 1, value: 0 }, { hour: 2, value: 0 },
            { hour: 3, value: 0 }, { hour: 4, value: 0 }, { hour: 5, value: 0 },
            { hour: 6, value: 0 }, { hour: 7, value: 0 }, { hour: 8, value: 0 },
            { hour: 9, value: 0 }, { hour: 10, value: 0 }, { hour: 11, value: 0 },
            { hour: 12, value: 0 }, { hour: 13, value: 0 }, { hour: 14, value: 0 },
            { hour: 15, value: 0 }, { hour: 16, value: 0 }, { hour: 17, value: 0 },
            { hour: 18, value: 0 }, { hour: 19, value: 0 }, { hour: 20, value: 0 },
            { hour: 21, value: 0 }, { hour: 22, value: 0 }, { hour: 23, value: 0 }];
            placeHolderdata = placeHolderdata.map(item1 => {
                return Object.assign(item1, tempArray.find(item2 => {
                    return item1.hour === item2.hour
                }))
            })
            placeHolderdata.forEach((i) => {
                chartData.labels.push(i.hour + ':00');
                chartData.datasets[0].data.push(i.value);
            });
            setPopupData((val) => ({ ...val, chartData: chartData }))
        });
        setPopupData({
            uid: businessData.uid,
            title: businessData.name,
            openTime: businessData.openTime,
            closeTime: businessData.closeTime,
            contact: businessData.contact,
            address: businessData.address,
            images: businessData.businessImages,
            createdAt: businessData.create_time
        });
        let lat = businessData.lat;
        switch (map.current.getZoom()) {
            case 22:
            case 21:
                lat = businessData.lat + 0.000030;
                break;
            case 20:
                lat = businessData.lat + 0.000070;
                break;
            case 19:
                lat = businessData.lat + 0.000090;
                break;
            case 18:
                lat = businessData.lat + 0.000200;
                break;
            case 17:
                lat = businessData.lat + 0.000400;
                break;
            case 16:
                lat = businessData.lat + 0.0001200;
                break;
            case 15:
                lat = businessData.lat + 0.002000;
                break;
            case 14:
            case 13:
            case 12:
                lat = businessData.lat + 0.008000;
                break;
            case 11:
            case 10:
            case 9:
                lat = businessData.lat + 0.080000;
                break;
            default:
                lat = businessData.lat;
                break;
        }
        map.current.panTo({ lat: lat, lng: businessData.lng });
        popup.current = new Popup(
            new window.google.maps.LatLng(businessData.lat, businessData.lng),
            popupContainer.current
        );
        popup.current.setMap(map.current);
    }, []);

    useEffect(() => {
        if (mapLoaded) {
            businessesList.forEach((business) => {
                let markerAlreadyExist = markerList.find((item) => item.uid === business.uid);
                if (!markerAlreadyExist) {
                    let position = { lat: business.lat, lng: business.lng };
                    const marker = new window.google.maps.Marker({
                        position,
                        map: map.current,
                        title: business.name,
                        id: business.uid,
                        label: { className: 'marker-position', color: '#000000', fontSize: '14px', text: business.name },
                        optimized: false
                    });
                    marker.addListener("click", () => {
                        handlePopupOpen(business);
                    });
                    setMarkerList((val) => [...val, { marker: marker, uid: business.uid }]);
                }
            });
        }
    }, [mapLoaded, businessesList, markerList, handlePopupOpen]);

    useEffect(() => {
        if (mapLoaded) {
            const contentString =
                '<div id="content">' +
                '<div id="siteNotice">' +
                "</div>" +
                '<h1 id="firstHeading" class="firstHeading">Your Location</h1>' +
                "</div>";
            const infoWindow = new window.google.maps.InfoWindow({
                content: contentString,
            });
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        //setUserLocation(pos);
                        const lineSymbol = {
                            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            scale: 5,
                            fillColor: "#000000",
                            fillOpacity: 1.6,
                            strokeWeight: 0,
                            rotation: 0,
                            strokeColor: "#000000",
                        };
                        let marker = new window.google.maps.Marker({
                            position: pos,
                            map: map.current,
                            optimized: false,
                            title: "Your Location",
                            icon: lineSymbol
                        });
                        map.current.setCenter(pos);
                        marker.addListener("click", () => {
                            infoWindow.open({
                                anchor: marker,
                                map: map.current,
                                shouldFocus: false,
                            });
                        });
                    },
                    () => {
                        handleLocationError(true, infoWindow, map.current.getCenter());
                    }
                );
            } else {
                // Browser doesn't support Geolocation
                handleLocationError(false, infoWindow, map.current.getCenter());
            }
        }
    }, [mapLoaded]);

    useEffect(() => {
        if (!searchQuery) {
            return setSearchResultList([]);
        }
        setSearchResultList(businessesList.filter((biz) => biz.name.toLowerCase().includes(searchQuery.toLowerCase())
            || biz.address.toLowerCase().includes(searchQuery.toLowerCase())));
    }, [searchQuery, businessesList, setSearchResultList]);

    const handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
        infoWindow.setPosition(pos);
        infoWindow.setContent(
            browserHasGeolocation
                ? "Error: The Geolocation service failed."
                : "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(map.current);
    }

    const changeAnalyticDay = async (newDay) => {
        const diffInDays = dayDiff(new Date(popupData.createdAt), new Date());
        let snapshot = await get(ref(getDatabase(), `iQueue/Restricted/Businesses/${popupData.uid}/analytics/hourly_queue/${newDay}/`));
        if (snapshot.val()) {
            let chartData = {
                labels: [],
                datasets: [{
                    label: 'Average Queue',
                    data: [],
                    backgroundColor: PRIMARY_COLOR,
                }]
            };
            let tempArray = [];
            snapshot?.forEach((snap) => {
                let val;
                if (diffInDays <= 7) {
                    val = (snap.val());
                } else {
                    val = (snap.val() / (diffInDays / 7));
                }
                let initValue = val >= 0.5 ? Math.ceil(val) : Math.floor(val);
                let tmp = {
                    hour: parseInt(snap.key.split('_')[1]),
                    value: initValue
                }
                tempArray.push(tmp);
            });
            placeHolderdata = [{ hour: 0, value: 0 }, { hour: 1, value: 0 }, { hour: 2, value: 0 },
            { hour: 3, value: 0 }, { hour: 4, value: 0 }, { hour: 5, value: 0 },
            { hour: 6, value: 0 }, { hour: 7, value: 0 }, { hour: 8, value: 0 },
            { hour: 9, value: 0 }, { hour: 10, value: 0 }, { hour: 11, value: 0 },
            { hour: 12, value: 0 }, { hour: 13, value: 0 }, { hour: 14, value: 0 },
            { hour: 15, value: 0 }, { hour: 16, value: 0 }, { hour: 17, value: 0 },
            { hour: 18, value: 0 }, { hour: 19, value: 0 }, { hour: 20, value: 0 },
            { hour: 21, value: 0 }, { hour: 22, value: 0 }, { hour: 23, value: 0 }];
            placeHolderdata = placeHolderdata.map(item1 => {
                return Object.assign(item1, tempArray.find(item2 => {
                    return item1.hour === item2.hour
                }))
            })
            placeHolderdata.forEach((i) => {
                chartData.labels.push(i.hour + ':00');
                chartData.datasets[0].data.push(i.value);
            });
            setPopupData((val) => ({ ...val, chartData: chartData }))
        } else {
            let chartData = {
                labels: [],
                datasets: [{
                    label: 'Average Queue',
                    data: [],
                    backgroundColor: PRIMARY_COLOR,
                }]
            };
            placeHolderdata = [{ hour: 0, value: 0 }, { hour: 1, value: 0 }, { hour: 2, value: 0 },
            { hour: 3, value: 0 }, { hour: 4, value: 0 }, { hour: 5, value: 0 },
            { hour: 6, value: 0 }, { hour: 7, value: 0 }, { hour: 8, value: 0 },
            { hour: 9, value: 0 }, { hour: 10, value: 0 }, { hour: 11, value: 0 },
            { hour: 12, value: 0 }, { hour: 13, value: 0 }, { hour: 14, value: 0 },
            { hour: 15, value: 0 }, { hour: 16, value: 0 }, { hour: 17, value: 0 },
            { hour: 18, value: 0 }, { hour: 19, value: 0 }, { hour: 20, value: 0 },
            { hour: 21, value: 0 }, { hour: 22, value: 0 }, { hour: 23, value: 0 }];
            placeHolderdata.forEach((i) => {
                chartData.labels.push(i.hour + ':00');
                chartData.datasets[0].data.push(i.value);
            });
            setPopupData((val) => ({ ...val, chartData: chartData }))
        }
    }
    return (
        <div>
            <div ref={mapContainer} style={{ height: '100vh', position: 'relative' }} />
            <div ref={popupContainer} style={{ width: '450px', height: 'auto', display: popupData.title ? 'block' : 'none' }}>
                <IconButton style={{ float: 'right' }} onClick={() => { popup.current.onRemove() }}>
                    <CancelIcon style={{ color: PRIMARY_COLOR }} />
                </IconButton>
                <div style={{ display: 'flex' }}>
                    <div style={{ padding: '10px', paddingTop: '0px' }}>
                        <Grid container>
                            <Grid item md={7} xs={7}>
                                <div>
                                    <Typography noWrap style={{ fontFamily: 'Poppins', fontSize: '25px', fontWeight: 600, marginTop: '5px' }}>{popupData?.title}</Typography>
                                    <p style={{ fontFamily: 'Poppins', fontWeight: 600 }}>{popupData?.address}</p>
                                    <p style={{ fontFamily: 'Poppins' }}>Open : {popupData?.openTime} to {popupData?.closeTime}</p>
                                    <p style={{ fontFamily: 'Poppins' }}>Contact : {popupData?.contact}</p>
                                    <p style={{ fontFamily: 'Poppins' }}>Current Queue Size : {popupData?.queueSize}</p>
                                    <Rating style={{ marginTop: '-5px', marginBottom: '10px' }}
                                        name="simple-controlled"
                                        defaultValue={3.5} precision={0.5}
                                    />
                                </div>
                            </Grid>
                            <Grid item md={5} xs={5}>
                                <div style={{ marginLeft: '5px', marginTop: '70px' }}>
                                    <Button variant="outlined" size="small" className={classes.popupButton} onClick={() => {
                                        /*if (myQueues.filter((i) => i.status === 1).find((i) => i.business_uid === popupData.uid)) {
                                            return alert("You are already in queue for this business");
                                        }*/
                                        setPopupData({ ...popupData, entryType: 0 });
                                        setServiceConfirmDialog(true);
                                        //dispatch(consumerActions.joinQueue({ businessData: popupData }));
                                    }}>Walk In
                                    </Button>
                                    <Button variant="outlined" size="small" className={classes.popupButton} style={{ marginTop: '10px' }}
                                        onClick={() => {
                                            setPopupData({ ...popupData, entryType: 1 });
                                            setServiceConfirmDialog(true);
                                        }}>
                                        Appointment
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{ marginLeft: '40px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <IconButton onClick={() => {
                                    changeAnalyticDay(analyticDay > 0 ? analyticDay - 1 : 6);
                                    setAnalyticDay(analyticDay > 0 ? analyticDay - 1 : 6)
                                }} style={{ marginTop: '-8px', color: 'black', marginRight: '15px' }} component="span">
                                    <ArrowBackIosIcon />
                                </IconButton>
                                <Typography style={{ fontFamily: 'Poppins', fontWeight: 700, width: '100px' }}>
                                    {analyticDays[analyticDay]}
                                </Typography>
                                <IconButton onClick={() => {
                                    changeAnalyticDay(analyticDay < 6 ? analyticDay + 1 : 0);
                                    setAnalyticDay(analyticDay < 6 ? analyticDay + 1 : 0)
                                }} style={{ marginTop: '-8px', color: 'black' }} component="span">
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </div>
                            {popupData?.chartData && <Bar
                                data={popupData?.chartData}
                                plugins={[ChartDataLabels]} options={{
                                    aspectRatio: 3,
                                    maintainAspectRatio: true,
                                    layout: {
                                        padding: {
                                            top: 20,
                                        },
                                    },
                                    plugins: {
                                        annotation: analyticDay === new Date().getDay() ? {
                                            annotations: {
                                                line1: {
                                                    type: 'line',
                                                    yMin: popupData?.queueSize ? popupData?.queueSize : 0,
                                                    yMax: popupData?.queueSize ? popupData?.queueSize : 0,
                                                    borderColor: 'rgb(255, 99, 132)',
                                                    borderDash: [4, 4],
                                                    borderWidth: 2,
                                                },
                                                label1: {
                                                    type: 'label',
                                                    xValue: 0.8,
                                                    yValue: popupData?.queueSize ? popupData?.queueSize + 0.6 : 0.9,
                                                    color: 'red',
                                                    content: [`Live ${popupData?.queueSize ? popupData?.queueSize : 0}`],
                                                    font: { size: 12 }
                                                }
                                            }
                                        } : {},
                                        datalabels: {
                                            display: true,
                                            color: "black",
                                            align: "end",
                                            anchor: "end",
                                            font: { size: "12" }
                                        },
                                        legend: {
                                            display: false
                                        }
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                display: false
                                            },
                                            ticks: {
                                                maxRotation: 0,
                                                minRotation: 0,
                                            }
                                        },
                                        y: {
                                            display: false
                                        }

                                    }
                                }} />}
                        </div>
                    </div>
                    <div style={{ padding: '10px', marginTop: '50px' }}>
                        <div style={{ display: 'flex' }}>
                            {popupData?.images?.length > 0 && popupData?.images?.map((imageUrl) => (
                                <img src={imageUrl} alt="bIMG" style={{ width: '50px', height: '50px' }} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {serviceConfirmDialog && <ServicesConfirmDialog
                popupData={popupData}
                serviceConfirmDialog={serviceConfirmDialog}
                setServiceConfirmDialog={setServiceConfirmDialog} />}
        </div>)
});

export default DashboardUser;