/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, forwardRef, useEffect, useCallback } from 'react';

import {
    Dialog, IconButton, Slide, Paper, Grid, Box,
    DialogTitle, DialogContent, DialogActions, Button,
    DialogContentText
} from '@mui/material';

import { CallEnd, Mic, MicOff, Videocam, VideocamOff } from '@mui/icons-material';

import { makeStyles } from '@mui/styles';
import { LocalVideo, RemoteVideo } from './VideoElement';

import {
    getDatabase, ref, set, push, onValue, remove
} from "firebase/database";

const useStyles = makeStyles((theme) => ({
    disabledMedia: {
        background: '#c5221f',
        "&:hover": {
            background: '#b12624'
        }
    }
}));

//------Configuring TURN and STUN servers------
var pcConfig = {
    iceServers: [{
        urls: ["stun:bn-turn1.xirsys.com"]
    }, {
        username: "bi8Y2dLUTu0Uz0-aKz9wQXloP5HC7x-DV0S_zzNVn2IeRpbVPGhnVPGodCRUR3BsAAAAAGLHxsthbmV3bmFtZTc3Ng==",
        credential: "8e85c156-fe82-11ec-83f7-0242ac140004",
        urls: [
            "turn:bn-turn1.xirsys.com:80?transport=udp",
            "turn:bn-turn1.xirsys.com:3478?transport=udp",
            "turn:bn-turn1.xirsys.com:80?transport=tcp",
            "turn:bn-turn1.xirsys.com:3478?transport=tcp",
            "turns:bn-turn1.xirsys.com:443?transport=tcp",
            "turns:bn-turn1.xirsys.com:5349?transport=tcp"
        ]
    }]
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
//var dataChannel;
const VideoCallScreen = (props) => {
    const { videoCallDialog, setVideoCallDialog, request, consumer, handleQuitLobby } = props;
    const classes = useStyles();

    const [localStream, setLocalStream] = useState(null);
    const [remoteStream, setRemoteStream] = useState(null);
    const [offerSent, setOfferSent] = useState(false);
    const [answerSent, setAnswerSent] = useState(false);
    const [pc, setPc] = useState(null);
    const [videoOn, setVideoOn] = useState(false);
    const [micOn, setMicOn] = useState(false);
    const [availableDevices, setAvailableDevices] = useState([]);
    const [showReconnectDialog, setShowReconnectDialog] = useState(false);

    // sets available media devices
    const updateAvailableDevices = useCallback(() => {
        const requiredInputs = { audioinput: "Microphone", videoinput: "Camera" };

        navigator.mediaDevices.enumerateDevices().then((devices) => {
            const tempDevices = [...new Set(devices.map(device => device.kind))].filter(device => (device === "audioinput" || device === "videoinput"));

            setAvailableDevices(tempDevices);
            setVideoOn(tempDevices.includes("videoinput") && videoOn);
            setMicOn(tempDevices.includes("audioinput") && micOn);

            if (tempDevices.length < Object.keys(requiredInputs).length)
                alert(Object.keys(requiredInputs).filter(device => !tempDevices.includes(device)).map(key => requiredInputs[key]).join(", ") + " not accessible");
        }).catch((e) => {
            alert(Object.values(requiredInputs).join(", ") + " not accessible");
            setAvailableDevices([]);
            console.error(e);
        });
    }, []);


    const setLocalStreamInputs = useCallback(async (audioinput = false, videoinput = false) => {
        audioinput = audioinput && availableDevices.includes("audioinput");
        videoinput = videoinput && availableDevices.includes("videoinput");

        if (audioinput || videoinput) {
            try {
                let stream = await navigator.mediaDevices.getUserMedia({
                    audio: audioinput,
                    video: videoinput
                });
                setLocalStream(stream);
                setMicOn(audioinput);
                setVideoOn(videoinput);
            } catch (e) {
                console.log(e);
            }
        }
        else {
            // Check handleStreamState()
            setMicOn(audioinput);
            setVideoOn(videoinput);
        }
    }, [availableDevices]);

    useEffect(() => {
        setPc(new RTCPeerConnection(pcConfig));
        navigator.mediaDevices.ondevicechange = () => {
            updateAvailableDevices();
        };
        updateAvailableDevices();
    }, [updateAvailableDevices]);

    useEffect(() => {
        setLocalStreamInputs(true, true);
    }, [availableDevices, setLocalStreamInputs]);

    //Receiving Answer
    useEffect(() => {
        if (pc && consumer) {
            onValue(ref(getDatabase(), `iQueue/Restricted/Consumers/${request.callId}/videocall/`), (snapshot) => {
                if (snapshot.val() && pc && snapshot.val().answer) {
                    if (pc.signalingState !== 'stable' && pc.signalingState !== 'closed') {
                        pc.setRemoteDescription({ type: 'answer', sdp: snapshot.val().answer }).catch((err) => {
                            console.log(err);
                        });
                    }
                    if (snapshot.val()) {
                        for (var key in snapshot.val().icecandidate) {
                            var candidate = new RTCIceCandidate({
                                sdpMLineIndex: snapshot.val().icecandidate[key].label,
                                candidate: snapshot.val().icecandidate[key].candidate
                            });
                            if (pc.signalingState !== 'closed') {
                                pc.addIceCandidate(candidate);
                            }
                        }
                    }
                }
            });
        }
    }, [consumer, pc, request.callId]);

    //Call receiver (business) here
    useEffect(() => {
        if (localStream && !answerSent && !consumer) {
            setAnswerSent(true);
            localStream.getTracks().forEach(track => pc.addTrack(track, localStream));
            pc.ontrack = (event) => {
                setRemoteStream(event.streams[0]);
            }
            pc.oniceconnectionstatechange = function () {
                if (pc.iceConnectionState === 'disconnected') {
                    setShowReconnectDialog(true);
                }
            }
            pc.onicecandidate = (event) => {
                if (event.candidate) {
                    var cand = {
                        type: 'candidate',
                        to: request.recId,
                        label: event.candidate.sdpMLineIndex,
                        id: event.candidate.sdpMid,
                        candidate: event.candidate.candidate
                    }
                    push(ref(getDatabase(), `iQueue/Restricted/Consumers/${request.callId}/videocall/icecandidate`), cand)
                        .then(() => {
                        }).catch((error) => {
                            alert(error);
                        });
                }
            }
            pc.setRemoteDescription(request.offer);
            pc.createAnswer().then((answer) => {
                pc.setLocalDescription(answer);
                const answerToSend = {
                    to: request.callId,
                    to_name: request.callName,
                    from: request.recId,
                    from_name: request.recName,
                    answer: answer.sdp,
                }
                onValue(ref(getDatabase(), `iQueue/Restricted/Businesses/${request.recId}/videocall/icecandidate`), (snapshot) => {
                    if (snapshot.val()) {
                        for (var key in snapshot.val()) {
                            var candidate = new RTCIceCandidate({
                                sdpMLineIndex: snapshot.val()[key].label,
                                candidate: snapshot.val()[key].candidate
                            });
                            if (pc.signalingState !== 'closed') {
                                pc.addIceCandidate(candidate);
                            }
                        }
                    }
                });
                set(ref(getDatabase(), `iQueue/Restricted/Consumers/${request.callId}/videocall/`), answerToSend)
                    .then(() => {
                        if (window.iQueueApp) {
                            window.iQueueApp.onCallConnected();
                        }
                    }).catch((error) => {
                        alert(error);
                    });
            });
        }
    }, [pc, localStream, request, answerSent, consumer]);

    //Call initiator (consumer) here
    useEffect(() => {
        if (localStream && !offerSent && consumer) {
            setOfferSent(true);
            localStream.getTracks().forEach(track => pc.addTrack(track, localStream));
            pc.ontrack = (event) => {
                setRemoteStream(event.streams[0]);
            }
            pc.oniceconnectionstatechange = function () {
                if (pc.iceConnectionState === 'disconnected') {
                    setShowReconnectDialog(true);
                }
            }
            pc.onicecandidate = (event) => {
                if (event.candidate) {
                    var cand = {
                        type: 'candidate',
                        to: request.recId,
                        label: event.candidate.sdpMLineIndex,
                        id: event.candidate.sdpMid,
                        candidate: event.candidate.candidate
                    }
                    push(ref(getDatabase(), `iQueue/Restricted/Businesses/${request.recId}/videocall/icecandidate`), cand)
                        .then(() => {
                        }).catch((error) => {
                            alert(error);
                        });
                }
            }
            //dataChannel = pc.createDataChannel("chat");
            pc.createOffer().then((offer) => {
                pc.setLocalDescription(offer).then(() => {
                    const offerToSend = {
                        to: request.recId,
                        to_name: request.recName,
                        from: request.callId,
                        from_name: request.callName,
                        offer: offer.sdp,
                        state: 'ringing'
                    }
                    //socket.emit("sendOffer", offerToSend);
                    set(ref(getDatabase(), `iQueue/Restricted/Businesses/${request.recId}/videocall/`), offerToSend)
                        .then(() => {
                            if (window.iQueueApp) {
                                window.iQueueApp.onCallConnected();
                            }
                        }).catch((error) => {
                            alert(error);
                        });
                });
            });
        }
    }, [localStream, pc, request, offerSent, consumer]);

    const handleClose = () => {
        if (localStream !== null) {
            localStream.getTracks().forEach(function (track) { track.stop() });
        }
        if (pc !== null) {
            pc.close();
        }
        setLocalStream(null);
        setRemoteStream(null);
        setOfferSent(false);
        setPc(null);
        setMicOn(false);
        setVideoOn(false);
        setVideoCallDialog(false);
        remove(ref(getDatabase(), `iQueue/Restricted/Businesses/${request.recId}/videocall/`))
            .then(() => {
            }).catch((error) => {
                alert(error);
            });
        remove(ref(getDatabase(), `iQueue/Restricted/Consumers/${request.callId}/videocall/`))
            .then(() => {
            }).catch((error) => {
                alert(error);
            });
        handleQuitLobby();
        //Use this with android activity
        if (window.iQueueApp) {
            window.iQueueApp.onCallEnd(0);
            window.iQueueApp.closeWindow();
            window.iQueueApp.displayMessage('Call disconnected');
        }
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('closeWindow').then(function (result) {
                // get result from Flutter side. It will be the number 64.
                console.log(result);
            });
        }
    };

    const handleCloseButWaitLobby = () => {
        if (localStream !== null) {
            localStream.getTracks().forEach(function (track) { track.stop() });
        }
        if (pc !== null) {
            pc.close();
        }
        setLocalStream(null);
        setRemoteStream(null);
        setOfferSent(false);
        setPc(null);
        setMicOn(false);
        setVideoOn(false);
        setVideoCallDialog(false);
        remove(ref(getDatabase(), `iQueue/Restricted/Businesses/${request.recId}/videocall/`))
            .then(() => {
            }).catch((error) => {
                alert(error);
            });
        remove(ref(getDatabase(), `iQueue/Restricted/Consumers/${request.callId}/videocall/`))
            .then(() => {
            }).catch((error) => {
                alert(error);
            });
        //Use this with android activity
        // if (window.iQueueApp) {
        //     window.iQueueApp.onCallEnd(0);
        //     window.iQueueApp.closeWindow();
        //     window.iQueueApp.displayMessage('Call disconnected');
        // }
    };

    const handleStreamState = (type) => {
        if (type === "audio") {
            localStream.getTracks().filter((t) => t.kind === 'audio').forEach(track => track.enabled = !track.enabled);
            setMicOn(!micOn);
            return;
        }
        if (type === "video") {
            localStream.getTracks().filter((t) => t.kind === 'video').forEach(track => track.enabled = !track.enabled);
            setVideoOn(!videoOn);
            return;
        }
    }

    return (
        <div>
            <Dialog fullScreen open={videoCallDialog} onClose={handleClose} TransitionComponent={Transition}>
                <Box sx={{ height: "100%", padding: { md: "1rem" }, position: "relative", background: "#252525" }}>
                    {remoteStream && <RemoteVideo remoteStream={remoteStream} />}
                </Box>

                <Paper elevation={4}
                    sx={{
                        position: "fixed",
                        bottom: { xs: "auto", md: "50px" },
                        top: { xs: "4rem", md: "auto" },
                        right: { xs: "1rem", md: "2rem", lg: "4rem" },
                        height: { xs: "150px", sm: "180px", md: "140px", lg: "180px" },
                        width: { xs: "100px", sm: "120px", md: "210px", lg: "270px" },
                        overflow: "hidden",
                        background: "#151515"
                    }}
                >
                    {localStream && <LocalVideo localStream={localStream} />}
                </Paper>

                <Grid container sx={{ position: "fixed", bottom: "50px" }} alignItems={"center"} justifyContent={"center"}>
                    <Paper elevation={8} sx={{ background: "#252525", paddingX: { xs: 4, sm: 10 }, paddingY: "0.75rem" }}>
                        <Grid container spacing={4}>
                            <Grid item>
                                <IconButton size='large' aria-label="mic" sx={{ color: '#dadce0' }} className={`${micOn ? "" : classes.disabledMedia}`} onClick={() => { handleStreamState('audio'); }}>
                                    {
                                        micOn ?
                                            <Mic /> :
                                            <MicOff />
                                    }
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton size='large' aria-label="end call" sx={{ color: '#dadce0' }} className={`${classes.disabledMedia}`} onClick={handleClose}>
                                    <CallEnd />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton size='large' aria-label="video" sx={{ color: '#dadce0' }} className={`${videoOn ? "" : classes.disabledMedia}`} onClick={() => { handleStreamState('video'); }}>
                                    {
                                        videoOn ?
                                            <Videocam /> :
                                            <VideocamOff />
                                    }
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Dialog>
            {showReconnectDialog && <Dialog
                open={showReconnectDialog}
                // onClose={handleShowNoteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='xs'
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">Call Disconnected</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Remote user has disconnected.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={() => handleCloseButWaitLobby()}>Reconnect</Button> */}
                    <Button onClick={() => handleClose()}>End Call</Button>
                </DialogActions>
            </Dialog>}
        </div>
    );
}

export default VideoCallScreen;