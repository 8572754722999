import { createTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../config/appStyleConfig';

export const theme = createTheme({
    //palette: {
    //     action: {
    //         disabled: 'white',
    //         background: PRIMARY_COLOR,
    //     }
    // }
});

export const useStyles = makeStyles({
    styledButton: {
        background: PRIMARY_COLOR,
        "&:hover": {
            background: PRIMARY_COLOR,
        },
        "&:disabled": {
            color: 'white',
            backgroundColor: PRIMARY_COLOR
        }
    },
});