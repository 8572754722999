export function timeConvert(n) {
    /*var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);*/
    let startDate = new Date();
    let endDate = new Date(n.est_serving_time);
    let remTime = endDate.getTime() - startDate.getTime();
    remTime = remTime < 0 ? 0 : remTime;
    var sec = (remTime) / 1000;
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = Math.floor(sec - (hours * 3600) - (minutes * 60)); //  get seconds
    return `${hours ? hours : '00'}h ${minutes ? minutes : '00'}m ${seconds ? seconds : '00'}s`;
}