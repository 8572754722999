import { Box, Button, CircularProgress, FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import { useState } from "react";

export const OtherSetupSettingsFoodTruck = ({ classes, nextStep2, setActiveStep, loading, consolidateData }) => {
    const [settings, setSettings] = useState({
        accept_take_away: consolidateData.accept_take_away || false,
        accept_catering: consolidateData.accept_catering || false,
    });

    const handleChange = (key) => {
        setSettings((settings) => ({ ...settings, [key]: !settings[key] }));
    }

    return (
        <Box component="form" noValidate sx={{ mt: 3, width: '100%' }} onSubmit={(e) => nextStep2(e, settings)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormGroup >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={settings.accept_take_away}
                                    onChange={() => handleChange('accept_take_away')}
                                />
                            }
                            label="Accept Take away"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={settings.accept_catering}
                                    onChange={() => handleChange('accept_catering')}
                                />
                            }
                            label="Accept Catering"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container columnSpacing={2} marginTop={4}>
                <Grid item xs={6}>
                    <Button
                        id="sign-in-button"
                        fullWidth
                        variant="contained"
                        className={classes.styledButton}
                        sx={{ height: '50px', borderRadius: '5px' }}
                        disabled={loading}
                        onClick={() => setActiveStep(2)}
                    >
                        Previous
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        id="sign-in-button"
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.styledButton}
                        sx={{ height: '50px', borderRadius: '5px' }}
                        disabled={loading}
                    >
                        {loading ? <><CircularProgress style={{ color: 'white', float: 'left', width: '20px', height: '20px' }} /> <span style={{ marginLeft: '10px' }}>Working on it</span></> : 'Next'}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}