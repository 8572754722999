import React from "react";
import { Backdrop, CircularProgress } from '@mui/material';
import {
    Route, Redirect
} from "react-router-dom";

import { useSelector } from 'react-redux';
import { LoadingBackdrop } from "../components/LoadingBackdrop";

export const AuthRouteUser = ({ path, component }) => {
    const { auth, userData, businessData } = useSelector((state) => state.authReducer);

    if ((auth && auth === "loading") || (auth && userData && userData === "loading")) {
        return (<Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
        </Backdrop>);

    } else if (auth && auth !== "loading" && userData && userData !== "loading") {
        return (<Route exact path={path} component={component} />);

    } else if (businessData && businessData !== 'loading') {
        return (<Redirect to="/business/home" />);
    } else if ((auth && auth !== "loading") && !businessData && !userData) {
        if (localStorage.getItem('loginType') === 'BUSINESS') {
            return (<Redirect to="/business/signup" />)
        } else if (localStorage.getItem('loginType') === 'USER') {
            return (<Redirect to="/user/signup" />)
        } else {
            return (<Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>);
        }
    } else {
        return (<Redirect to="/user/login" />);
    }
}

export const AuthRouteBusiness = ({ path, component }) => {
    const { auth, userData, businessData } = useSelector((state) => state.authReducer);

    if ((auth && auth === "loading") || (auth && businessData && businessData === "loading")) {
        return (<LoadingBackdrop />);

    } else if (auth && auth !== "loading" && businessData && businessData !== 'loading') {
        return (<Route exact path={path} component={component} />);

    } else if (userData && userData !== 'loading') {
        return (<Redirect to="/user/home" />);
    } else if ((auth && auth !== "loading") && !businessData && !userData) {
        if (localStorage.getItem('loginType') === 'BUSINESS') {
            return (<Redirect to="/business/signup" />)
        } else if (localStorage.getItem('loginType') === 'USER') {
            return (<Redirect to="/user/signup" />)
        } else {
            return (<LoadingBackdrop />);
        }
    }
    else {
        return (<Redirect to="/business/login" />);
    }
}

export const AuthRouteSetup = ({ path, component }) => {
    const { auth, userData, businessData } = useSelector((state) => state.authReducer);

    if ((auth && auth !== "loading") && !businessData && !userData) {
        return (<Route exact path={path} component={component} />);
    }
    else {
        return (<Redirect to="/business/login" />);
    }
}