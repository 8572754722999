import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { memo, useState } from 'react'
// import * as businessRestaurantActions from "../../../redux/actions/BusinessRestaurant";
import { useDispatch } from 'react-redux'
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';
import useCheckMobileScreen from "../../../components/Hooks/useCheckMobileScreen";

const styles = {
  cursor: 'move',
  width: '12vw',
  height: '23vh',
  background: 'rgba(255, 255, 255, 0.1)',
  display: 'flex', justifyContent: 'center',
  alignItems: "center"
}
export const Box = memo(function Box({ id, image, title, yellow, capacity,
  preview, isOnScreen, setBoxes, size, isStatic, rotate = 0, handleSaveFlow }) {

  const [onCard, setOnCard] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const device = useCheckMobileScreen();

  const dispatch = useDispatch();


  const handleRemoveFromList = () => {
    setBoxes(current => {
      const copy = { ...current };
      delete copy[id];
      handleSaveFlow(copy);
      return copy;
    });
  }

  const handleRotateRight = () => {
    setBoxes(current => {
      const copy = { ...current };
      copy[id].rotate = copy[id].rotate + 10;
      handleSaveFlow(copy);
      return copy;
    });
  }


  const handleRotateLeft = () => {
    setBoxes(current => {
      const copy = { ...current };
      copy[id].rotate = copy[id].rotate - 10;
      handleSaveFlow(copy);
      return copy;
    });
  }

  // const handleRemoveFromSideList = () => {
  //   setBoxes(current => {
  //     const copy = { ...current };
  //     delete copy[id];
  //     return copy;
  //   });
  //   dispatch(businessRestaurantActions.deleteDefaultTable(id));
  // }

  return (
    <Card
      elevation={0}
      // onMouseOver={() => {
      //   setOnCard(true);
      // }}
      // onMouseOut={() => {
      //   setOnCard(false);
      // }}
      style={{
        ...styles,
        height: size?.height && !isStatic ? size.height : '160px',
        width: size?.width && !isStatic ? size.width : '160px',
        // border: isStatic ? '1px solid #ccc' : 'none',
        // borderRadius: isStatic ? '0px' : 'none',
        transform: isOnScreen || preview ? `rotate(${rotate}deg)` : 'none',
      }}
      role={preview ? 'BoxPreview' : 'Box'}
    >
      <div>
        {/* {!isOnScreen && onCard && <button onClick={() => handleRemoveFromSideList()}>Remove</button>} */}
        {!isOnScreen && !preview &&
          <div><img width={'90vw'} src={image} alt={title} /></div>}
        {!isOnScreen && preview &&
          <div>
            {device === "pc" && <img width={size.width - 60} src={image} alt={title} />}
            {device === "mobile" && <img width={size.width - 10} src={image} alt={title} />}
            {device === "tablet" && <img width={size.width - 20} src={image} alt={title} />}
          </div>}
        {isOnScreen && <div>
          {device === "pc" && <img width={size.width - 60} src={image} alt={title} />}
          {device === "mobile" && <img width={size.width - 10} src={image} alt={title} />}
          {device === "tablet" && <img width={size.width - 20} src={image} alt={title} />}
          {isOnScreen && onCard &&
            <IconButton
              onClick={() => setShowDeleteConfirmation(true)}
              size="small"
              style={{
                fontSize: '10px', color: 'red', position: 'absolute',
                top: '50%',
                left: '50%',
                transform: capacity === 1 ?
                  'translate(-50%, -20%)' :
                  capacity === 2 ?
                    'translate(-50%, -60%)'
                    :
                    'translate(-50%, -50%)',
              }}><DeleteIcon style={{ width: '1.3vw' }} /></IconButton>}

          {isOnScreen && onCard &&
            <IconButton
              onClick={() => handleRotateLeft()}
              size="small"
              style={{
                fontSize: '10px', color: 'white', position: 'absolute',
                top: '50%',
                width: '1.6vw',
                height: '1.6vw',
                left: '15%',
                backgroundColor: PRIMARY_COLOR,
                opacity: 0.8,
                transform: capacity === 1 ?
                  'translate(-50%, -20%)' :
                  capacity === 2 ?
                    'translate(-50%, -60%)'
                    :
                    'translate(-50%, -50%)',
              }}><RotateLeftIcon style={{
                width: '1.1vw',
                height: '1.1vw',
              }} /></IconButton>}
          {isOnScreen && onCard &&
            <IconButton
              onClick={() => handleRotateRight()}
              size="small"
              style={{
                fontSize: '10px', color: 'white', position: 'absolute',
                top: '50%',
                width: '1.6vw',
                height: '1.6vw',
                left: '85%',
                opacity: 0.8,
                backgroundColor: PRIMARY_COLOR,
                transform: capacity === 1 ?
                  'translate(-50%, -20%)' :
                  capacity === 2 ?
                    'translate(-50%, -60%)'
                    :
                    'translate(-50%, -50%)',
              }}><RotateRightIcon style={{
                width: '1.1vw',
                height: '1.1vw',
              }} /></IconButton>}
        </div>}
        {isStatic &&
          <IconButton
            disabled
            size="small"
            style={{
              fontSize: '10px', color: 'white', position: 'absolute',
              top: '50%',
              width: '1.6vw',
              height: '1.6vw',
              left: '50%',
              backgroundColor: PRIMARY_COLOR,
              opacity: 0.8,
              transform: capacity === 1 ?
                'translate(-50%, -20%)' :
                capacity === 2 ?
                  'translate(-50%, -60%)'
                  :
                  'translate(-50%, -50%)',
            }}>{capacity}</IconButton>}
      </div>
      <Dialog
        open={showDeleteConfirmation}
        onClose={() => {
          setShowDeleteConfirmation(false);
        }}
        aria-labelledby="Delete Dialog1"
      >
        <DialogTitle id="responsive-dialog-title">
          Remove Table?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this table?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setShowDeleteConfirmation(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setShowDeleteConfirmation(false);
              handleRemoveFromList();
            }}
            autoFocus
            color="error"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Card >
  )
})
