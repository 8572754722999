import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';

export const useStyles = makeStyles({
    appBar: {
        background: PRIMARY_COLOR,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    customizeToolbar: {
        minHeight: 60
    },
    popupButton: {
        color: PRIMARY_COLOR,
        border: `solid 1px ${PRIMARY_COLOR}`,
        minWidth: '170px'
    },
    search: {
        position: 'relative',
        borderRadius: 2,
        marginRight: 2,
        marginLeft: 0,
        width: '70%',
    },
    searchIcon: {
        paddingLeft: 100,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: 20,
        // vertical padding + font size from searchIcon
        paddingLeft: 130,
        width: '100%',
    },
});