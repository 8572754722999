/* eslint-disable react-hooks/exhaustive-deps */
//TODO: Improve this page (divide into smaller parts)
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './styleCSS.css';
import { useStyles } from './styles';
import {
    AppBar, Box, Toolbar, Typography, Button, CssBaseline,
    IconButton, Avatar, Card, Grow, InputBase,
    Drawer, List, ListItem, ListItemIcon, ListItemText, Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
//Firebase
import { getAuth } from "firebase/auth";

import TokenCard from './TokenNumberCard/TokenCard';
import * as consumerActions from '../../../redux/actions/Consumer';
import { Chart } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DashboardUser from '../../../pages/Dashboard/User';
import RecentHistory from '../../RecentHistory/User';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';
Chart.register(annotationPlugin);


const UserHome = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { userData } = useSelector((state) => state.authReducer);
    const { myQueues } = useSelector((state) => state.consumerReducer);

    const childRef = useRef();

    //const [userLocation, setUserLocation] = useState(null);
    const [selectedPageIndex, setSelectedPageIndex] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResultList, setSearchResultList] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showQueue, setShowQueue] = useState(true);

    useEffect(() => {
        dispatch(consumerActions.enableMyQueueListener());
    }, [dispatch]);

    return (
        <Box style={{ position: 'relative' }}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setDrawerOpen(!drawerOpen)}
                            edge="start">
                            <MenuIcon />
                        </IconButton>
                        <Typography noWrap variant="h6" component="div" sx={{ fontSize: '18px', flexGrow: 1, fontFamily: 'Poppins', marginLeft: '12px' }}>
                            {userData?.name}<br></br>
                            <Typography style={{ fontFamily: 'Poppins', fontSize: '12px' }}>iQueue</Typography>
                        </Typography>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search nearby"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                inputProps={{ 'aria-label': 'search' }} />
                        </div>
                        <Avatar sx={{ width: 34, height: 34 }} alt="Remy Sharp" src="https://i.pravatar.cc/400?img=12" />
                        <Button color="inherit" onClick={() => { getAuth().signOut() }}>Logout</Button>
                        <Button color="inherit" onClick={() => { setShowQueue(!showQueue) }}>Show Queue</Button>
                    </Toolbar>
                </AppBar>
                {searchResultList.length !== 0 && <Card style={{
                    position: 'absolute', zIndex: 99, top: '70px', left: '320px', background: 'white',
                    width: '650px', borderRadius: '5px',
                    overflowY: 'scroll'
                }}>
                    <List>
                        {searchResultList.map((item, index) => (
                            <ListItem button key={item.uid} onClick={() => {
                                setSearchQuery('');
                                setSearchResultList([]);
                                if (childRef && childRef?.current) {
                                    childRef?.current?.handleParentPopupCall(item);
                                }
                            }}>
                                <ListItemText primary={item.name} secondary={item.address} />
                            </ListItem>
                        ))}
                    </List>
                </Card>}
                <Drawer
                    open={drawerOpen}
                    anchor={'left'}
                    onClose={() => { setDrawerOpen(false) }}
                    sx={{
                        width: 260,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 260,
                            boxSizing: 'border-box',
                        },
                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto' }}>
                        <List>
                            <ListItem button selected={selectedPageIndex === 0}
                                onClick={() => {
                                    setSelectedPageIndex(0);
                                    setDrawerOpen(false);
                                }}>
                                <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Dashboard'} secondary={"Explore iQueue"} />
                            </ListItem>
                            <Divider />
                            <ListItem button selected={selectedPageIndex === 1}
                                onClick={() => {
                                    setSelectedPageIndex(1);
                                    setDrawerOpen(false);
                                }}>
                                <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon>
                                <ListItemText primary={'History'} secondary={"History and fav"} />
                            </ListItem>
                            <Divider />
                            <ListItem button selected={selectedPageIndex === 2}
                                onClick={() => {
                                    setSelectedPageIndex(2);
                                    setDrawerOpen(false);
                                }}>
                                <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Settings'} secondary={"Account Settings"} />
                            </ListItem>
                            <Divider />
                        </List>
                    </Box>
                </Drawer>
            </Box>
            {showQueue && myQueues.filter((i) => i.status === 1).length !== 0 &&
                <Grow in>
                    <Card style={{
                        position: 'absolute', zIndex: 99, top: '80px', right: '5px', background: PRIMARY_COLOR,
                        height: '450px', width: '350px', borderRadius: '5px', padding: '10px',
                        overflowY: 'scroll'
                    }}>
                        {myQueues.filter((i) => i.status === 1).map((i, index) => (
                            <div key={index} style={{ marginLeft: '5px' }} >
                                <TokenCard item={i} />
                                <br></br>
                            </div>
                        ))}
                    </Card>
                </Grow>}
            {selectedPageIndex === 0 && <main>
                <DashboardUser ref={childRef} setSearchResultList={setSearchResultList} searchQuery={searchQuery} />
            </main>}
            {selectedPageIndex === 1 && <main className={classes.content}>
                <Toolbar />
                <RecentHistory />
            </main>}
        </Box >
    )
}
export default UserHome;