import React, { useState, useCallback, useEffect } from 'react';
import {
    Button, Typography, Dialog, DialogContent,
    DialogTitle, CardContent, DialogActions, Divider, CircularProgress
} from "@mui/material";
import html2canvas from "html2canvas";
import { PRIMARY_COLOR } from "../../config/appStyleConfig";
import { ShareVia } from './ShareVia';
import { ShareWith } from './ShareWith';
import { useDispatch, useSelector } from "react-redux";
import * as businessCouponActions from "../../redux/actions/BusinessCoupons";

export const ShowSharePreview = ({ item, setItem }) => {
    const dispatch = useDispatch();
    const { businessData } = useSelector((state) => state.authReducer);

    const [shareVia, setShareVia] = useState(null);
    const [shareWith, setShareWith] = useState(null);
    const [dataUrl, setDataUrl] = useState(null);
    const [loading, setLoading] = useState(true);

    const elemRef = useCallback((node) => {
        if (node !== null) {
            let t = async () => {
                const element = document.getElementById('print');
                let canvas = await html2canvas(element, {
                    allowTaint: true,
                    useCORS: true,
                    scale: 2.5
                });
                setDataUrl(canvas.toDataURL('image/png'));
            }
            t();
        }
    }, [])

    const handleUploadDownloadImage = async () => {
        let link = document.createElement('a');
        link.href = dataUrl;
        link.download = `coupon-${businessData?.name}-${item?.name}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // const handleShareVia = async () => {
    //     if (navigator.share) {
    //         const blob = await (await fetch(dataUrl)).blob();
    //         const file = new File([blob], 'coupon.png', { type: blob.type });
    //         navigator.share({
    //             title: "Awesome Deals",
    //             text: `Check out this great deal at ${businessData?.name} `,
    //             files: [file],
    //         })
    //     }
    // }

    const getURL = useCallback(async (it) => {
        return new Promise((resolve, reject) => {
            dispatch(businessCouponActions.uploadAndGenerateUrl(it, resolve, reject));
        });
    }, [dispatch]);

    useEffect(() => {
        let d = async () => {
            if (item?.downloadURL) {
                setLoading(false);
                return;
            }
            if (dataUrl && !item?.downloadURL) {
                await getURL({
                    ...item,
                    base64: dataUrl
                });
                setLoading(false);
            }
        }
        d();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataUrl, getURL]);

    return (<Dialog
        open={item !== null}
        // onClose={() => {
        //     setItem(null);
        // }}
        aria-labelledby="Delete Dialog1"
    >
        <DialogTitle id="responsive-dialog-title">
            Preview coupon
        </DialogTitle>
        <DialogContent style={{ overFlow: 'auto' }}>
            <div id="print" style={{ minWidth: '410px', padding: '6px', minHeight: '200px' }} ref={elemRef}>
                <div style={{ border: `2px solid ${PRIMARY_COLOR}`, borderRadius: '5px' }}>
                    <CardContent>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <Typography style={{ fontFamily: 'Poppins', fontSize: '18px', color: PRIMARY_COLOR }}>
                                    <strong>{businessData?.name}</strong>
                                </Typography>
                                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                                    <strong>{item.name}</strong>
                                </Typography>
                                <Typography style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                                    {item.code}
                                </Typography>
                                <Typography style={{ fontFamily: 'Poppins', color: 'grey', fontSize: '12px', wordWrap: 'break-word', }}>
                                    {item.description}
                                </Typography>
                                <Typography style={{ fontFamily: 'Poppins', color: 'grey', fontSize: '12px' }}>
                                    <strong>{item.startDate}</strong> To <strong>{item.endDate}</strong>
                                </Typography>
                                <Typography style={{ fontFamily: 'Poppins', color: 'grey', fontSize: '12px' }}>
                                    <strong>{item.startTime}</strong> To <strong>{item.endTime}</strong>
                                </Typography>
                                <Typography style={{ fontFamily: 'Poppins', color: 'grey', fontSize: '10px' }}>
                                    Terms and conditions apply
                                </Typography>
                            </div>
                            <div>
                                {item?.file ?
                                    <img style={{ borderRadius: '5px' }} width={120} src={item?.file} alt="Coupon"></img> : null}
                            </div>
                        </div>
                    </CardContent>
                </div>
            </div>
            <Divider />
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" size="small"
                onClick={() => handleUploadDownloadImage()}
                disabled={dataUrl === null}
                style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                    color: dataUrl === null ? 'grey' : PRIMARY_COLOR,
                    border: dataUrl === null ? `1px solid grey` : `1px solid ${PRIMARY_COLOR}`
                }}>
                {dataUrl === null ? <><CircularProgress style={{ color: 'grey', float: 'left', width: '10px', height: '10px' }} /> <span style={{ marginLeft: '10px' }}>Download</span></> : 'Download'}
            </Button>
            <Button variant="outlined" size="small"
                onClick={() => setShareWith(item)}
                disabled={loading}
                style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                    color: loading ? 'grey' : PRIMARY_COLOR,
                    border: loading ? `1px solid grey` : `1px solid ${PRIMARY_COLOR}`
                }}>
                {loading ? <><CircularProgress style={{ color: 'grey', float: 'left', width: '10px', height: '10px' }} /> <span style={{ marginLeft: '10px' }}>Share</span></> : 'Share With'}
            </Button>
            <Button variant="outlined" size="small"
                onClick={() => setShareVia({
                    ...item,
                    base64: dataUrl
                })}
                style={{
                    marginLeft: '10px',
                    marginTop: '10px', color: PRIMARY_COLOR,
                    border: `1px solid ${PRIMARY_COLOR}`
                }}>
                Share via
            </Button>
            <Button variant="outlined" size="small"
                onClick={() => setItem(null)}
                style={{
                    marginTop: '10px', color: 'red',
                    border: `1px solid red`
                }}>
                Close
            </Button>
        </DialogActions>
        {shareVia !== null && <ShareVia item={shareVia} setItem={setShareVia} />}
        {shareWith !== null && <ShareWith item={shareWith} setItem={setShareWith} />}
    </Dialog>);
}