import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
// import { DragDropContext } from "react-beautiful-dnd";
import * as businessActions from "../../../redux/actions/Business";
import HomePanel from "./HomePanel";
import { getNotificationToken } from '../../../utils/pushNotifications';
import * as businessRestaurantActions from "../../../redux/actions/BusinessRestaurant";
import { DndProvider } from 'react-dnd'
// import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { usePreview } from 'react-dnd-preview';
import { Card, Grid, Snackbar, Typography } from '@mui/material';
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const MyPreview = () => {
    const { display, itemType, item, style } = usePreview();
    if (!display) {
        return null;
    }
    return (
        <div className="item-list__item" style={style}>
            <Card
                item={item}
                elevation={0}
                style={{
                    width: '90%', height: '100px', margin: '20px', fontFamily: 'Poppins', fontSize: '12px',
                    padding: '0px 10px 10px 10px',
                    border: '1px solid #A9A9A9',
                    opacity: '0.8',
                }}
            >
                <Grid container spacing={2} >
                    <Grid style={{ textAlign: 'center' }} item xs={4}>
                        <Typography style={{ fontSize: '25px', color: 'blue', fontFamily: 'Poppins', fontWeight: '600', marginTop: '18px' }}>
                            {item?.capacity ? item.capacity : 'NA'}
                        </Typography>
                        <Typography style={{ fontSize: '11px', color: 'black', fontFamily: 'Poppins', fontWeight: '600', marginBottom: '17px' }}>
                            {new Date(item?.created_timestamp).toLocaleTimeString('en-us', {
                                hour: '2-digit', minute: '2-digit'
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography style={{ fontFamily: 'Poppins', fontSize: '12px', marginTop: '10px' }}>
                            {item?.entry_type === 1 ? 'RESERVE BY' : 'EAT-NOW BY'}
                        </Typography>
                        <Typography noWrap style={{ fontFamily: 'Poppins', fontSize: '13px', fontWeight: '600' }}>
                            {item.customer_name}
                        </Typography>
                        <Typography style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: '600' }}>
                            {item.customer_contact}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

const DashboardRestaurantBusiness = ({ isMobile }) => {
    const dispatch = useDispatch();

    const { iQueues } = useSelector((state) => state.businessReducer);
    const { tables } = useSelector((state) => state.businessRestaurantReducer);

    const [queueList, setQueueList] = useState(iQueues);
    const [showErrorSnack, setShowErrorSnack] = useState(null);
    const [date, setDate] = useState(new Date());

    const assignCustomerToTable = useCallback(async (removed, dropId) => {
        return new Promise((resolve, reject) => {
            dispatch(businessRestaurantActions.assignCustomerToTable(removed, dropId, resolve, reject));
        });
    }, [dispatch]);

    useEffect(() => {
        dispatch(businessRestaurantActions.loadFloorSetup());
    }, [dispatch, iQueues]);

    // useEffect(() => {
    //     let assignedQueues = iQueues.filter((queue) => queue.status === 0);
    //     if (assignedQueues.length > 0) {
    //         console.log('iQueues');
    //         assignedQueues.forEach((queue) => {
    //             if (queue.serving_table) {
    //                 let newItem = myTables[queue.serving_table];
    //                 if (newItem && newItem?.busy && newItem?.assignedQueueId === queue.uid) {
    //                     newItem.assinedTo = queue?.customer_name;
    //                     newItem.assignedQueueId = queue?.uid;
    //                     newItem.queueItemTimeStamp = queue?.created_timestamp;
    //                     setMyTables({ newItem });
    //                 }
    //             }
    //         });
    //     }
    // }, [iQueues]);

    // removes contact from local-storage once user lands on home page
    useEffect(() => {
        localStorage.removeItem('contact');
    }, []);

    useEffect(() => {
        if (navigator?.serviceWorker) {
            getNotificationToken();
        }
    }, [])

    useEffect(() => {
        let availableQueues = iQueues.filter((queue) => queue.status === 1 || queue.status === 2);
        setQueueList(availableQueues);
    }, [iQueues]);

    useEffect(() => {
        let date = new Date();
        dispatch(businessActions.updateResetQueueState());
        dispatch(businessActions.enableQueueListener({ year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }));
    }, [dispatch]);

    const move = async (removed, dropId) => {
        let res = await assignCustomerToTable(removed, dropId);
        if (res?.error === true) {
            console.log(res);
            setShowErrorSnack(res?.msg);
        }
    };

    return (
        <div>
            <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
                <HomePanel
                    isMobile={isMobile}
                    queueList={queueList}
                    move={move}
                    date={date}
                    setDate={setDate}
                    tables={tables} />
                <MyPreview />
            </DndProvider>
            {showErrorSnack && <Snackbar
                open={(showErrorSnack !== null)}
                autoHideDuration={3000}
                onClose={() => setShowErrorSnack(null)}
            >
                <Alert severity="error">{showErrorSnack}</Alert>
            </Snackbar>}
        </div>
    );
};

export default DashboardRestaurantBusiness;