import React from 'react';
import { useDrop } from 'react-dnd'
import { useSelector, useDispatch } from "react-redux";
import { Grid } from '@mui/material';
import InProgressCard from '../../../components/InProgressCard/InProgressCard';

const styles = {
    width: '100%',
    height: '70vh',
    marginTop: '9px',
    border: '1px solid black',
    position: 'relative',
    display: 'flex',
    overflow: 'scroll'
}
export const ConTest = ({ move }) => {

    const { iQueues } = useSelector((state) => state.businessReducer);

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: 'BOX',
        drop: (item, monitor) => {
            // console.log(item)
            return { name: 'inProgress' };
        },
    }));

    return (
        <div>
            <div ref={drop} style={styles}>
                <Grid container>
                    {iQueues.filter((ite) => ite.status === 5).sort((a, b) => (a.created_timestamp - b.created_timestamp)).map((q) => (
                        <InProgressCard item={q} move={move} key={q.uid} />
                    ))}
                </Grid>
            </div>
        </div>
    )
}
