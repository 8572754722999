import createReducer from '../../../utils/createReducer';
import * as consumerTypes from '../../actions/Consumer/types';

const initialState = {
    businesses: [],
    myQueues: [],
    error: false,
    success: false,
};

export const consumerReducer = createReducer(initialState, {
    [consumerTypes.UPDATE_NEARBY_BUSINESSES](state, action) {
        return {
            ...state,
            businesses: action.businesses,
            error: false,
        };
    },
    [consumerTypes.UPDATE_MY_QUEUE_ADD](state, action) {
        return {
            ...state,
            myQueues: [action?.iQueue].concat(state.myQueues),
            error: false,
        };
    },
    [consumerTypes.UPDATE_MY_QUEUE_CHANGE](state, action) {
        return {
            ...state,
            myQueues: state.myQueues.map((queue) => {
                if (queue.uid === action?.iQueue?.uid) {
                    return action?.iQueue;
                }
                return queue;
            }),
            error: false,
        };
    },
    [consumerTypes.UPDATE_MY_QUEUE_REMOVE](state, action) {
        return {
            ...state,
            myQueues: state.myQueues.filter((queue) => queue.uid !== action?.iQueue?.uid),
            error: false,
        };
    }
});