import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Typography, Grid } from '@mui/material';
import React, { useState } from 'react';

export const ServiceTimeSelect = ({ classes, item, setItem, availableServices, setAvailableServices, consolidateData, setConsolidateData }) => {
    const [hrs, setHrs] = useState('0');
    const [mins, setMins] = useState('30');

    const handleUpdateServiceTime = (item) => {
        if(hrs || mins) {
            const time = 60 * +hrs + +mins;
            setAvailableServices(availableServices.map((i) => i === item ? `${item?.split(';')[0]};${time ? time : '15'}` : i));
            if(consolidateData !== undefined)
                setConsolidateData({...consolidateData, services: consolidateData.services.map((i) => i === item ? `${item?.split(';')[0]};${time ? time : '15'}` : i)});
            setItem(null);
        }
    }

    return (<Dialog open={item}>
        <DialogTitle>
            <Typography variant="h6" style={{ fontFamily: 'Poppins' }}> {item?.split(';')[0]}</Typography>
            <Typography variant="subtitle2" style={{ fontFamily: 'Poppins' }}>Time to serve:</Typography>
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item>
                    <TextField
                        value={hrs}
                        inputProps={{ maxLength: 2 }}
                        sx={{ width: "100px" }}
                        helperText="Hrs."
                        onChange={(e) => {
                            const re = /^([0-1]?[0-9]|2[0-3])$/g;
                            if (e.target.value === '')
                                setHrs('');
                            else if(re.test(e.target.value))
                                setHrs(e.target.value);
                        }}
                        size="small"
                    />
                </Grid>
                <Grid item>
                    <TextField
                        value={mins}
                        inputProps={{ maxLength: 2 }}
                        sx={{ width: "100px", textAlign: "right" }}
                        helperText="Mins."
                        onChange={(e) => {
                            const re = /^[0-5]?[0-9]$/g;
                            if (e.target.value === '')
                                setMins('');
                            else if(re.test(e.target.value))
                                setMins(e.target.value);
                        }}
                        size="small"
                    />
                </Grid>
            </Grid>
            
            <DialogActions style={{ marginTop: '15px' }}>
                <Button
                    size="small"
                    className={classes.styledButton}
                    onClick={() => handleUpdateServiceTime(item)}>
                    Save
                </Button>
                <Button
                    size="small"
                    className={classes.outlinedButton}
                    onClick={() => setItem(null)}>
                    Cancel
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>)
}