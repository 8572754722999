import { createTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';

export const theme = createTheme({
    textField: {
        [`& fieldset`]: {
            borderRadius: 100,
        },
    },
    palette: {
        action: {
            disabled: 'white'
        }
    }
});

export const useStyles = makeStyles({
    styledButton: {
        fontFamily: 'Poppins',
        color: '#FFF',
        background: PRIMARY_COLOR,
        "&:hover": {
            backgroundColor: PRIMARY_COLOR
        }
    },
    disabledButton: {
        fontFamily: 'Poppins',
        color: '#B1B1B1',
        background: '#EAEAEA',
        "&:hover": {
            backgroundColor: '#EAEAEA'
        }
    },
    outlinedButton: {
        fontFamily: 'Poppins',
        color: PRIMARY_COLOR,
        borderColor: PRIMARY_COLOR,
        "&:hover": {
            borderColor: PRIMARY_COLOR,
        }
    },
    serviceItem: {
        display: 'flex',
        width: '330px',
        justifyContent: 'space-between',
        marginTop: '10px'
    },
    divider: {
        position: "relative",
        background: "#dd9aff",
        borderRadius: "100%",
        width: "2.5rem",
        height: "2.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#666"
    }
});