import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {
  Grid,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import './stylesCSS.css';
import { useDispatch } from "react-redux";
import * as businessActions from '../../redux/actions/Business';
import { PRIMARY_COLOR } from '../../config/appStyleConfig';
import { Notifications } from '@mui/icons-material';
import NoteIcon from '@mui/icons-material/Note';
import { useDrag } from 'react-dnd'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export default function InProgressCard({ item, move }) {
  const dispatch = useDispatch();

  const [changingTime, setChangingTime] = useState('Loading...');
  const [showNote, setShowNote] = useState(false);

  const handleShowNoteClose = () => {
    setShowNote(false);
  };

  useEffect(() => {
    if (item.entry_type === 0) {
      const myInterval = setInterval(() => {
        setChangingTime(Math.round(((new Date() - new Date(item.created_timestamp)) / 1000) / 60))
      }, 1000);

      return () => { clearInterval(myInterval) };
    }
  }, [item.created_timestamp, item.entry_type]);

  const sendReminderNotification = () => {
    dispatch(businessActions.sendReminderNotification(item));
  }

  const deleteIqueue = () => {
    dispatch(businessActions.deleteIqueue(item))
  }

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'BOX',
    item: item,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        console.log(dropResult);
        if (dropResult?.name === "mainQueue") {
          move(item, 1);
        } else if (dropResult?.name === "readyQueue") {
          move(item, 4);
        }
        // setSuggestedTable(null);
        // setPreferredTable(null);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div style={{ marginTop: '1vw', width: '17vw', margin: '10px', cursor: 'pointer', }} ref={drag}>
      <div style={{ position: "relative", marginTop: "-5px" }}>
        <div className="ribbon ribbon-top-left" style={{ marginLeft: "0px", zoom: "0.85" }}><span>Confirmed</span></div>
        <Card
          item={item}
          elevation={0}
          style={{
            width: '90%',
            margin: '15px 5px 5px 8px', fontFamily: 'Poppins', fontSize: '12px',
            padding: '0px 10px 10px 10px',
            border: '1px solid #A9A9A9',
          }}
        >
          <Grid container spacing={2} >
            <Grid style={{ textAlign: 'center', marginLeft: '10px' }} item xs={5}>
              <Typography style={{ fontSize: '13px', color: 'blue', fontFamily: 'Poppins', fontWeight: '600', marginTop: '25px' }}>
                {item?.token ? item.token : 'NA'}
              </Typography>
              <Typography style={{ fontSize: '11px', color: 'black', fontFamily: 'Poppins', fontWeight: '600', marginBottom: '17px' }}>
                {new Date(item?.created_timestamp).toLocaleTimeString('en-us', {
                  hour: '2-digit', minute: '2-digit'
                })}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontFamily: 'Poppins', fontSize: '10px', marginTop: '10px', fontWeight: '600' }}>
                {item?.entry_type === 1 ? 'CATERING BY' : 'TAKE-OUT BY'}
              </Typography>
              <Typography noWrap style={{ fontFamily: 'Poppins', fontSize: '13px', fontWeight: '600' }}>
                {item.customer_name}
              </Typography>
              <Typography style={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: '600' }}>
                {item.customer_contact}
              </Typography>
            </Grid>
          </Grid>
          <Grid container >
            <Typography style={{
              // overflow: "hidden",
              // textOverflow: "ellipsis", display: "-webkit-box",
              // WebkitLineClamp: "2",
              // WebkitBoxOrient: "vertical",
              fontSize: '11px', color: 'black', fontFamily: 'Poppins', fontWeight: '600', marginBottom: '5px'
            }}>
              {item?.services ? `${item?.services?.split(',').map((i) => i.split(';')[0]).join(", ")}` : 'No menu items selected'}
            </Typography>
          </Grid>
          {item?.appointment_note && <Grid container >
            <Typography style={{
              // overflow: "hidden",
              // textOverflow: "ellipsis", display: "-webkit-box",
              // WebkitLineClamp: "2",
              // WebkitBoxOrient: "vertical",
              fontSize: '11px', color: 'red', fontFamily: 'Poppins', fontWeight: '600', marginBottom: '15px'
            }}>
              Note  : {item?.appointment_note}
            </Typography>
          </Grid>}
          <div style={{ marginTop: '-10px' }}>
            <IconButton onClick={() => sendReminderNotification()} style={{ color: PRIMARY_COLOR, background: "#fff", border: `1px solid ${PRIMARY_COLOR}`, marginLeft: '15px', marginTop: '5px' }} size="small">
              <Notifications style={{ width: '15px', height: '15px', color: PRIMARY_COLOR }} />
            </IconButton>
            <IconButton onClick={() => deleteIqueue()} style={{ color: PRIMARY_COLOR, background: "#fff", border: "1px solid #d32f2f", marginLeft: '15px', marginTop: '5px' }} size="small">
              <DeleteIcon style={{ width: '15px', height: '15px' }} color="error" />
            </IconButton>
            <IconButton onClick={() => move(item, 4)} style={{ color: 'green', background: "#fff", border: "1px solid green", marginLeft: '15px', marginTop: '5px' }} size="small">
              <ArrowCircleRightIcon style={{ width: '15px', height: '15px', color: 'green' }} />
            </IconButton>
          </div>
        </Card>
      </div>
    </div>
  );
}
