import React, { useState } from 'react';
import {
    SpeedDial, SpeedDialIcon,
    SpeedDialAction,
} from "@mui/material";
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useStyles } from "../styles";
import { ServicesConfirmDialog } from './ServiceConfirmDialog';
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';

const actions = [
    { icon: <DateRangeIcon />, name: 'Add Appointment' },
    { icon: <DirectionsWalkIcon />, name: 'Add Walk In' },
];


const SpeedDialMenu = () => {
    const classes = useStyles();

    const [addWalkInDialog, setAddWalkInDialog] = useState(false);
    const [addAppointmentDialog, setAppointmentDialog] = useState(false);

    const handleSpeedDialAction = (action) => {
        if (action.name === "Add Walk In") {
            setAddWalkInDialog(true);
            return;
        }
        if (action.name === "Add Appointment") {
            setAppointmentDialog(true);
            return;
        }
        return alert("Something went wrong!");
    }
    return (
        <div>
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: 'absolute', bottom: 36, right: 36 }}
                FabProps={{
                    sx: {
                        bgcolor: PRIMARY_COLOR,
                        '&:hover': {
                            bgcolor: PRIMARY_COLOR,
                        }
                    }
                }}
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        classes={{ staticTooltipLabel: classes.StaticTooltipLabel }}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => handleSpeedDialAction(action)}
                    //tooltipOpen
                    />
                ))}
            </SpeedDial>
            {addAppointmentDialog && <ServicesConfirmDialog
                popupData={{ entryType: 1 }}
                serviceConfirmDialog={addAppointmentDialog}
                setServiceConfirmDialog={setAppointmentDialog} />}
            {addWalkInDialog && <ServicesConfirmDialog
                popupData={{ entryType: 0 }}
                serviceConfirmDialog={addWalkInDialog}
                setServiceConfirmDialog={setAddWalkInDialog} />}
        </div>
    );
}

export default SpeedDialMenu;