import { takeEvery, select } from "redux-saga/effects";
import * as types from "../../actions/Business/types";
import * as businessActions from "../../actions/Business";
import { getAuth } from "firebase/auth";
import { off, get, update, getDatabase, ref, onChildAdded, onChildChanged, onChildRemoved, increment, orderByChild, query, push, set, remove, onValue } from "firebase/database";
import { store } from "../../store/configureStore";
import { clearAlert, setAlert, updateGlobalAnalytics, updateBusinessDetails } from "../../actions/Auth";
// eslint-disable-next-line
import Worker from 'worker-loader!./updateQueueTime.worker.js';
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";

const azureDevOpsUrl = `https://dev.azure.com/${process.env.REACT_APP_AZURE_DEVOPS_ORGANISATION}/${process.env.REACT_APP_AZURE_DEVOPS_PROJECT}`;

function* stopIqueueAsync({ time }) {
  try {
    if (getAuth()) {
      const { auth } = yield select((state) => state.authReducer);
      const db = getDatabase();
      const queueRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/iQueues/${time.year}/${time.month}/${time.date}/`);
      off(queueRef);
    }
  } catch (error) {
    console.log(error);
  }
}

function* sendTokenToServerAsync({ token }) {
  try {
    if (getAuth()) {
      const { auth } = yield select((state) => state.authReducer);
      const db = getDatabase();
      const tokenRef = ref(db, `iQueue/Restricted/NotificationTokens`);
      update(tokenRef, { [`WB_${auth?.uid}`]: token })
    }
  } catch (error) {
    console.log(error);
  }
}

function* getIqueueAsync({ time }) {
  try {
    if (getAuth()) {
      let queuelength = 0;
      const { auth } = yield select((state) => state.authReducer);
      const db = getDatabase();
      //TODO : What if business user jumps to date using a date selector ? (not yet implemented)
      const currentDate = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/iQueues/${time.year}/${time.month}/${time.date}/`);
      const nextDate = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/iQueues/${time.year}/${time.month}/${time.date + 1}/`);
      const previousDate = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/iQueues/${time.year}/${time.month}/${time.date - 1}/`);
      off(currentDate);
      off(nextDate);
      off(previousDate);
      if (time.date !== new Date().getDate()) {
        let currentDate = new Date();
        const currenDateRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/iQueues/${currentDate.getFullYear()}/${currentDate.getMonth()}/${currentDate.getDate()}/`);
        const byCurrentDateDateRef = query(currenDateRef, orderByChild("created_timestamp"));
        onChildAdded(byCurrentDateDateRef, (data) => {
          if (data.val().uid) {
            if (data.val().status === 1) {
              queuelength++;
            }
            let dummy = {
              ...data.val(),
              id: data.val().uid.toString(),
              image: `https://picsum.photos/id/270/200/300`,
              isQueueInBack: true,
            };
            store.dispatch(
              businessActions.updateQueueElement(
                {
                  consumerUid: data.val().customer_uid,
                  updateToken: true,
                  businessUid: data.val().business_uid,
                  id: data.val().uid,
                  queueIndex: queuelength,
                },
                time
              )
            );
            store.dispatch(businessActions.updateAddQueueState(dummy));
            setTimeout(() => {
              store.dispatch(businessActions.disableSuccessMessage());
            }, 3000);
          }
        });
        const queueRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/iQueues/${time.year}/${time.month}/${time.date}`);
        const byDateRef = query(queueRef, orderByChild("created_timestamp"));
        onChildAdded(byDateRef, (data) => {
          if (data.val().uid) {
            if (data.val().status === 1) {
              queuelength++;
            }
            let dummy = {
              ...data.val(),
              id: data.val().uid.toString(),
              image: `https://picsum.photos/id/270/200/300`,
            };
            store.dispatch(
              businessActions.updateQueueElement(
                {
                  consumerUid: data.val().customer_uid,
                  updateToken: true,
                  businessUid: data.val().business_uid,
                  id: data.val().uid,
                  queueIndex: queuelength,
                },
                time
              )
            );
            store.dispatch(businessActions.updateAddQueueState(dummy));
            setTimeout(() => {
              store.dispatch(businessActions.disableSuccessMessage());
            }, 3000);
          }
        });

        onChildChanged(byDateRef, (data) => {
          if (data.val().uid) {
            let dummy = {
              ...data.val(),
              id: data.val().uid.toString(),
              image: `https://picsum.photos/id/270/200/300`,
            };
            store.dispatch(businessActions.updateChangeQueueState(dummy));
          }
        });

        onChildRemoved(byDateRef, (data) => {
          if (data.val().uid) {
            if (data.val().status === 1) {
              queuelength--;
            }
            let dummy = {
              ...data.val(),
              id: data.val().uid.toString(),
              image: `https://picsum.photos/id/270/200/300`,
            };
            store.dispatch(businessActions.updateDeleteQueueState(dummy));
          }
        });
      } else if (time.date === new Date().getDate()) {
        const queueRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/iQueues/${time.year}/${time.month}/${time.date}`);
        const byDateRef = query(queueRef, orderByChild("created_timestamp"));
        onChildAdded(byDateRef, (data) => {
          if (data.val().uid) {
            if (data.val().status === 1) {
              queuelength++;
            }
            let dummy = {
              ...data.val(),
              id: data.val().uid.toString(),
              image: `https://picsum.photos/id/270/200/300`,
            };
            store.dispatch(
              businessActions.updateQueueElement(
                {
                  consumerUid: data.val().customer_uid,
                  updateToken: true,
                  businessUid: data.val().business_uid,
                  id: data.val().uid,
                  queueIndex: queuelength,
                  updateQueueSize: true,
                },
                time
              )
            );
            store.dispatch(businessActions.updateAddQueueState(dummy));
            setTimeout(() => {
              store.dispatch(businessActions.disableSuccessMessage());
            }, 3000);
          }
        });

        onChildChanged(byDateRef, (data) => {
          if (data.val().uid) {
            let dummy = {
              ...data.val(),
              id: data.val().uid.toString(),
              image: `https://picsum.photos/id/270/200/300`,
            };
            store.dispatch(businessActions.updateChangeQueueState(dummy));
          }
        });

        onChildRemoved(byDateRef, (data) => {
          if (data.val().uid) {
            if (data.val().status === 1) {
              queuelength--;
            }
            let dummy = {
              ...data.val(),
              id: data.val().uid.toString(),
              image: `https://picsum.photos/id/270/200/300`,
            };
            store.dispatch(businessActions.updateDeleteQueueState(dummy));
          }
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* getEmployeesAsync() {
  try {
    if (getAuth()) {
      const { auth } = yield select((state) => state.authReducer);
      const db = getDatabase();
      const employeeRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/employees/`);
      onChildAdded(employeeRef, (data) => {
        let dummy = {
          ...data.val(),
          id: data.val().uid.toString(),
        };
        store.dispatch(businessActions.updateEmployeeState(dummy));
      });
    }
  } catch (error) {
    console.log(error);
  }
}
function* addEmployeesAsync({ employee }) {
  try {
    if (getAuth()) {
      const { auth } = yield select((state) => state.authReducer);

      const db = getDatabase();

      if (employee?.uid) {
        const employeeRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/employees/${employee.uid}`);
        yield update(employeeRef, employee).then(() => { store.dispatch(setAlert()) })
      } else {
        const employeeRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/employees/`);
        const newRef = push(employeeRef);
        //   console.log("show ir",uniqueueKey);
        yield set(newRef, {
          name: employee.name,
          uid: newRef.key,
          contact: employee.contact
        });
      }
      setTimeout(() => {
        store.dispatch(clearAlert())
      }, 3000);

    }
  } catch (error) {
    console.log(error);
  }
}
function* deleteEmployeesAsync({ employee }) {
  try {
    if (getAuth()) {
      const { auth } = yield select((state) => state.authReducer);

      const db = getDatabase();

      const employeeRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/employees/${employee.uid}`);
      remove(employeeRef);

    }
  } catch (error) {
    console.log(error);
  }
}

function* updateRemainingTimeAndPositionInTheQueue(time, force) {
  const { iQueues } = yield select((state) => state.businessReducer);
  const { employees } = yield select((state) => state.businessReducer);
  let instance = new Worker();
  instance.postMessage({ employees, iQueues, time, force });
  instance.onmessage = function (e) {
    sendUpdateToServer(e, time);
    store.dispatch(businessActions.updateEmployeeNextFree(e.data.servingEmployee, e.data.servingEmployeeNextFree));
  }
}

function sendUpdateToServer(e, time) {
  const { newIndex, est_serving_time, servingEmployee, queueEntry } = e.data;
  update(ref(getDatabase(), `iQueue/Restricted/Businesses/${queueEntry?.business_uid}/iQueues/${time.year}/${time.month}/${time.date}/${queueEntry?.uid}`), {
    current_queue_index: newIndex,
    initial_queue_index: newIndex,
    est_serving_time: est_serving_time,
    suggested_emp: servingEmployee,
  });
  update(ref(getDatabase(), `iQueue/Restricted/Consumers/${queueEntry?.customer_uid}/iQueues/${queueEntry?.uid}`), {
    current_queue_index: newIndex,
    initial_queue_index: newIndex,
    est_serving_time: est_serving_time,
    suggested_emp: servingEmployee,
  });
}
function* updateQueueElementAsync({ iQueue, time }) {
  try {
    if (getDatabase()) {
      //Fetch remote config
      if (iQueue.updateToken) {
        const db = getDatabase();
        let exist = yield get(ref(db, `iQueue/Restricted/Businesses/${iQueue?.businessUid}/iQueues/${time.year}/${time.month}/${time.date}/${iQueue?.id}`));
        if (!exist?.val()?.token) {
          const remoteConfig = getRemoteConfig();
          remoteConfig.settings.minimumFetchIntervalMillis = 1000;
          yield fetchAndActivate(remoteConfig);
          const val = getValue(remoteConfig, "use_cloud_token_generation");
          let use_cloud_token_generation = val._value;
          if (use_cloud_token_generation === 'false') {
            let token = localStorage.getItem("tokenCounter");
            localStorage.setItem("tokenCounter", parseInt(token) + 1);
            let tokenVal = parseInt(token) + 1;
            yield update(ref(getDatabase(), `iQueue/Restricted/Businesses/${iQueue?.businessUid}/analytics`), {
              token_counter: increment(1),
            });
            yield update(ref(getDatabase(), `iQueue/Restricted/Businesses/${iQueue?.businessUid}/iQueues/${time.year}/${time.month}/${time.date}/${iQueue?.id}`), {
              token: "IQ000".concat(tokenVal),
            });
            yield update(ref(getDatabase(), `iQueue/Restricted/Consumers/${iQueue?.consumerUid}/iQueues/${iQueue?.id}`), {
              token: "IQ000".concat(tokenVal),
            });
          }
          //Update in analytics
          yield update(ref(getDatabase(), `iQueue/Restricted/Businesses/${iQueue?.businessUid}/analytics/hourly_queue/${new Date().getDay()}`), {
            [`h_${new Date().getHours()}`]: increment(1),
          });
          //TODO : Use cloud_token check here 
          // if (iQueue.updateQueueSize && (iQueue?.queueIndex || iQueue?.queueIndex === 0)) {
          //   yield update(ref(getDatabase(), `iQueue/Restricted/Businesses/${iQueue?.businessUid}/analytics`), {
          //     queue_size: increment(1),
          //   });
          // }
          if (use_cloud_token_generation === 'false') {
            yield updateRemainingTimeAndPositionInTheQueue(time, false);
          }
        } else if (exist?.val()?.token && exist?.val()?.status === 1) {
          const remoteConfig = getRemoteConfig();
          remoteConfig.settings.minimumFetchIntervalMillis = 1000;
          yield fetchAndActivate(remoteConfig);
          const val = getValue(remoteConfig, "use_cloud_token_generation");
          let use_cloud_token_generation = val._value;
          if (use_cloud_token_generation === 'false') {
            yield updateRemainingTimeAndPositionInTheQueue(time, false);
          }
        }
      } else if (iQueue.moveBetweenEmployees) {
        yield update(ref(getDatabase(), `iQueue/Restricted/Businesses/${iQueue.businessData?.uid}/iQueues/${time.year}/${time.month}/${time.date}/${iQueue.removed.id}/`), {
          service_employee: iQueue.destClone.uid,
        });
        yield update(ref(getDatabase(), `iQueue/Restricted/Consumers/${iQueue.removed?.customer_uid}/iQueues/${iQueue.removed.id}`), {
          service_employee: iQueue.destClone.uid,
        });
      } else if (iQueue.moveBackToMainQueue) {
        yield update(ref(getDatabase(), `iQueue/Restricted/Businesses/${iQueue.businessData?.uid}/iQueues/${time.year}/${time.month}/${time.date}/${iQueue.removed.id}/`), {
          status: iQueue.status,
          service_employee: null
        });
        yield update(ref(getDatabase(), `iQueue/Restricted/Consumers/${iQueue.removed?.customer_uid}/iQueues/${iQueue.removed.id}`), {
          status: iQueue.status,
          service_employee: null
        });
        // yield update(ref(getDatabase(), `iQueue/Restricted/Businesses/${iQueue.businessData?.uid}/employees/${iQueue.removed.service_employee}`), {
        //   busyTill: 0,
        // });
        const remoteConfig = getRemoteConfig();
        remoteConfig.settings.minimumFetchIntervalMillis = 1000;
        yield fetchAndActivate(remoteConfig);
        const val = getValue(remoteConfig, "use_cloud_token_generation");
        let use_cloud_token_generation = val._value;
        if (use_cloud_token_generation === 'false') {
          yield updateRemainingTimeAndPositionInTheQueue(time, false);
        }
      } else {
        //Update Global analytics
        let minutes = Math.round(((new Date() - new Date(iQueue?.removed?.created_timestamp)) / 1000) / 60);
        store.dispatch(updateGlobalAnalytics("minutes_saved", minutes));
        yield update(ref(getDatabase(), `iQueue/Restricted/Businesses/${iQueue.businessData?.uid}/iQueues/${time.year}/${time.month}/${time.date}/${iQueue.removed.id}/`), {
          current_queue_index: 0,
          status: iQueue.status,
          service_employee: iQueue.destClone.uid,
        });
        yield update(ref(getDatabase(), `iQueue/Restricted/Consumers/${iQueue.removed?.customer_uid}/iQueues/${iQueue.removed.id}`), {
          current_queue_index: 0,
          status: iQueue.status,
          service_employee: iQueue.destClone.uid,
        });
        //yield store.dispatch(businessActions.assignEmployeeWork({ empId: iQueue.destClone.uid, duration: iQueue?.removed?.duration, uid: iQueue?.removed?.uid }));
        // yield update(ref(getDatabase(), `iQueue/Restricted/Businesses/${iQueue.businessData?.uid}/employees/${iQueue.destClone.uid}`), {
        //   busyTill: new Date().getTime() + (iQueue?.removed?.duration * 1000 * 60),
        //   nextFree: 0
        // });
        const remoteConfig = getRemoteConfig();
        remoteConfig.settings.minimumFetchIntervalMillis = 1000;
        yield fetchAndActivate(remoteConfig);
        const val = getValue(remoteConfig, "use_cloud_token_generation");
        let use_cloud_token_generation = val._value;
        if (use_cloud_token_generation === 'false') {
          yield updateRemainingTimeAndPositionInTheQueue(time, false);
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* updateQueueStatusAsync({ iQueue }) {
  try {
    if (getDatabase()) {
      const time = new Date(iQueue?.created_timestamp)
      yield update(ref(getDatabase(), `iQueue/Restricted/Businesses/${iQueue?.business_uid}/iQueues/${time.getFullYear()}/${time.getMonth()}/${time.getDate()}/${iQueue?.id}`), { status: iQueue?.status })
      yield update(ref(getDatabase(), `iQueue/Restricted/Consumers/${iQueue?.customer_uid}/iQueues/${iQueue?.id}`), { status: iQueue?.status })
      //do here

    }
  } catch (error) {
    console.log(error)
  }
}

function* deleteQueueAsync({ iQueue }) {
  try {
    if (getDatabase()) {
      const time = new Date(iQueue?.created_timestamp)
      yield remove(ref(getDatabase(), `iQueue/Restricted/Businesses/${iQueue?.business_uid}/iQueues/${time.getFullYear()}/${time.getMonth()}/${time.getDate()}/${iQueue?.uid}`))
      yield remove(ref(getDatabase(), `iQueue/Restricted/Consumers/${iQueue?.customer_uid}/iQueues/${iQueue?.uid}`))
    }
  } catch (error) {
    console.log(error)
  }
}
function* sendReminderNotificationAsync({ iQueue }) {
  try {
    const time = new Date(iQueue?.created_timestamp)
    yield update(ref(getDatabase(), `iQueue/Restricted/Businesses/${iQueue?.business_uid}/iQueues/${time.getFullYear()}/${time.getMonth()}/${time.getDate()}/${iQueue?.id}`), { reminder_counter: iQueue?.reminder_counter ? increment(1) : 1 })
    yield update(ref(getDatabase(), `iQueue/Restricted/Consumers/${iQueue?.customer_uid}/iQueues/${iQueue?.id}`), { reminder_counter: iQueue?.reminder_counter ? increment(1) : 1 })
  } catch (error) {
    console.log(error);
  }
}
function* getCustomers({ business_uid }) {
  try {
    const dbRef = ref(getDatabase(), `iQueue/Restricted/Businesses/${business_uid}/Customers`);

    yield onValue(dbRef, (snapshot) => {
      if (snapshot.val()) {
        let arr = [];
        Object.values(snapshot.val()).map(item => {
          arr.push(item);
          return item;
        })

        store.dispatch(businessActions.updateCustomers(arr))
      }
    });
  } catch (error) {
    console.log(error);
  }
}
function* deleteCustomer({ customerToBeRemoved, business_uid }) {
  try {

    const db = getDatabase();
    const userRef = ref(db, `iQueue/Restricted/Businesses/${business_uid}/Customers/${customerToBeRemoved.uid}`);
    yield remove(userRef);
    store.dispatch(businessActions.getCustomers(business_uid));
  } catch (err) {
    console.log(err);
  }
}
function* updateCustomerRating({ customer, business_uid }) {
  try {
    const db = getDatabase();
    const userRef = ref(db, `iQueue/Restricted/Businesses/${business_uid}/Customers/${customer.uid}`);
    yield update(userRef, { rating: customer.rating })

  } catch (err) {
    console.log(err);
  }
}
function* updateCustomerBlockStatus({ customer, business_uid }) {
  try {
    const db = getDatabase();
    const userRef = ref(db, `iQueue/Restricted/Businesses/${business_uid}/Customers/${customer.uid}`);
    yield update(userRef, { is_blocked: customer.is_blocked })

  } catch (err) {
    console.log(err);
  }
}

async function createAttachments(files) {
  return await Promise.all(files.map((file, index) => {
    return new Promise(async (resolve, reject) => {
      let attachmentUrl = null;
      if (file) {
        const attachmentFileName = "bug_attachment_" + (index + 1);
        const createAttachmentUrl = `${azureDevOpsUrl}/_apis/wit/attachments?fileName=${attachmentFileName}.png&api-version=${process.env.REACT_APP_AZURE_DEVOPS_API_VERSION}`;
        let attachmentResponse = await fetch(createAttachmentUrl, {
          method: "POST",
          headers: {
            'Content-Type': "application/octet-stream",
            'Authorization': `Basic ${btoa(":" + process.env.REACT_APP_AZURE_DEVOPS_PAT)}`
          },
          body: file
        });
        attachmentResponse = await attachmentResponse.json();
        attachmentUrl = attachmentResponse.url;
      }

      if (attachmentUrl) {
        resolve({
          "op": "add",
          "path": "/relations/-",
          "value": {
            "rel": "AttachedFile",
            "url": attachmentUrl
          }
        })
      } else {
        resolve();
      }
    })
  }))
}

function* sendBugReportAsync({ bug }) {
  try {
    const createItemUrl = `${azureDevOpsUrl}/_apis/wit/workitems/$Bug?api-version=${process.env.REACT_APP_AZURE_DEVOPS_API_VERSION}`;
    const { auth, businessData } = yield select((state) => state.authReducer);

    const createItemData = [
      {
        "op": "add",
        "path": "/fields/System.Title",
        "from": null,
        "value": bug.title
      },
      {
        "op": "add",
        "path": "/fields/System.State",
        "from": null,
        "value": "New"
      },
      {
        "op": "add",
        "path": "/fields/Repro Steps",
        "from": null,
        "value": `
          <b>Business Name:</b> ${businessData?.name},
          <br>
          <br>
          <b>Firebase Id:</b> ${auth?.uid},
          <br>
          <br>
          <b>Device:</b> ${window.navigator.userAgent},
          <br>
          <br>
          <b>Steps to reproduce:</b>
          <br>
          <br>
          ${bug.description}
        `
      }
    ]

    const attachments = yield createAttachments(bug?.files);
    yield fetch(createItemUrl, {
      method: "POST",
      headers: {
        'Content-Type': "application/json-patch+json",
        'Authorization': `Basic ${btoa(":" + process.env.REACT_APP_AZURE_DEVOPS_PAT)}`
      },
      body: JSON.stringify([...createItemData, ...attachments])
    })
    store.dispatch(setAlert());

    setTimeout(() => {
      store.dispatch(clearAlert())
    }, 3000);
  } catch (error) {
    console.log(error);
  }
}

function* loadNotificationsAsync() {
  try {
    const db = getDatabase();
    const { auth } = yield select((state) => state.authReducer);
    const businessRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/notifications`);
    yield onValue(businessRef, (snapshot) => {
      if (snapshot.val()) {
        store.dispatch(businessActions.updateBusinessNotifications(snapshot.val()))
      }
    });
  } catch (err) {
    console.log(err);
  }
}

function* markNotificationsAsReadAsync() {
  try {
    const db = getDatabase();
    const { auth } = yield select((state) => state.authReducer);
    const { notifications } = yield select((state) => state.businessReducer);
    notifications.forEach((notification) => {
      const businessRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/notifications/${notification.uid}`);
      update(businessRef, {
        'read': true
      });
    });
  } catch (err) {
    console.log(err);
  }
}

function* uploadBusinessMenuAsync(action) {
  try {
    const { auth, businessData } = yield select((state) => state.authReducer);
    const storage = getStorage();
    const imagesRef = storageRef(
      storage,
      `${auth?.uid}/menu.${action?.menu?.name?.split('.')[1]}`
    );
    return uploadBytes(imagesRef, action.menu).then((snapshot) => {
      return getDownloadURL(snapshot.ref).then((downloadURL) => {
        store.dispatch((updateBusinessDetails({
          ...businessData,
          printed_menu: downloadURL,
        })));
        action.resolve(downloadURL);
        return downloadURL;
      });
    });
  } catch (err) {
    console.log(err);
  }
}

function* deleteBusinessMenuAsync(action) {
  try {
    const { businessData } = yield select((state) => state.authReducer);
    const storage = getStorage();
    const imagesRef = storageRef(
      storage,
      businessData.printed_menu
    );
    deleteObject(imagesRef).then(() => {
      store.dispatch((updateBusinessDetails({
        ...businessData,
        printed_menu: null,
      })));
      action.resolve('done');
    }).catch((error) => {
      console.log(error);
    });
  } catch (err) {
    console.log(err);
  }
}

export function* businessQueueSaga() {
  yield takeEvery(types.ENABLE_QUEUE_LISTENER, getIqueueAsync);
}

export function* businessStopQueueSaga() {
  yield takeEvery(types.DISABLE_QUEUE_LISTENER, stopIqueueAsync);
}

export function* sendTokenToServerSaga() {
  yield takeEvery(types.SEND_NOTIFICATION_TOKEN, sendTokenToServerAsync)
}
export function* businessEmployeeSaga() {
  yield takeEvery(types.ENABLE_EMPLOYEE_LISTENER, getEmployeesAsync);
}
export function* businessAddEmployeeSaga() {
  yield takeEvery(types.UPDATE_ADD_EMPLOYEE_STATE, addEmployeesAsync);
}
export function* businessDeleteEmployeeSaga() {
  yield takeEvery(types.UPDATE_DELETE_EMPLOYEE_STATE, deleteEmployeesAsync);
}

export function* businessQueueElementSaga() {
  yield takeEvery(types.UPDATE_QUEUE_ELEMENT, updateQueueElementAsync);
}

export function* businessQueueStatusSaga() {
  yield takeEvery(types.UPDATE_QUEUE_STATUS, updateQueueStatusAsync)
}

export function* businessDeleteQueueSaga() {
  yield takeEvery(types.DELETE_IQUEUE, deleteQueueAsync)
}
export function* businessSendReminderNotificationSaga() {
  yield takeEvery(types.SEND_REMINDER_NOTIFICATION, sendReminderNotificationAsync)
}
export function* deleteCustomersSaga() {
  yield takeEvery(types.DELETE_CUSTOMER, deleteCustomer)
}
export function* getCustomersSaga() {
  yield takeEvery(types.GET_CUSTOMERS, getCustomers)
}
export function* updateCustomerRatingSaga() {
  yield takeEvery(types.UPDATE_CUSTOMER_RATING, updateCustomerRating)
}
export function* updateCustomerBlockStatusSaga() {
  yield takeEvery(types.UPDATE_CUSTOMER_BLOCK_STATUS, updateCustomerBlockStatus)
}
export function* businessSendBugReportSaga() {
  yield takeEvery(types.SEND_BUG_REPORT, sendBugReportAsync);
}

export function* loadBusinessNotificationsSaga() {
  yield takeEvery(types.LOAD_NOTIFICATIONS, loadNotificationsAsync);
}

export function* markNotificationsAsReadSaga() {
  yield takeEvery(types.MARK_NOTIFICATIONS_READ, markNotificationsAsReadAsync);
}

export function* uploadBusinessMenuSaga() {
  yield takeEvery(types.UPLOAD_BUSINESS_MENU, uploadBusinessMenuAsync);
}

export function* deleteBusinessMenuSaga() {
  yield takeEvery(types.DELETE_BUSINESS_MENU, deleteBusinessMenuAsync);
}