import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Alert,
  Grow,
  Tooltip,
} from "@mui/material";
import { useStyles } from "../../Settings/Business/Timing/styles";
import { useDispatch } from "react-redux";
import * as businessActions from "../../../redux/actions/Business/";

const availableCategories = [
  "Sign-Up Issue",
  "Appointment Related Issue",
  "Walk-in Related Issue",
  "App Crashed",
  "Unable to Sign-In",
  "Other Issue",
];

const Feedback = () => {
  const [category, setCategory] = useState("");
  const [bugDesc, setBugDesc] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [filesSelected, setFilesSelected] = useState([]);
  const [binaryFiles, setBinaryFiles] = useState([]);
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (bugDesc.trim().length === 0 && category.length === 0) {
      setDescError(true);
      setCategoryError(true);
    } else if (bugDesc.trim().length === 0) {
      setDescError(true);
    } else if (category.length === 0) {
      setCategoryError(true);
    } else {
      dispatch(
        businessActions.sendBugReport({ title: category, description: bugDesc, files: binaryFiles })
      );
      // Custom Alert Call
      setShowAlert(true);
      setCategory("");
      setBugDesc("");
      setFilesSelected([]);
      setBinaryFiles([]);
    }
  };

  const readFileAsArrayBuffer = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        setBinaryFiles((prevBinaryFiles) => [...prevBinaryFiles, reader.result]);
        setFilesSelected((prevFilesSelected) => [...prevFilesSelected, file.name]);
        resolve();
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  }
  const handleFileUpload = (event) => {
    let files = event.target.files;
    if(!files || files.length === 0) return
    files = Object.values(files);
    files.forEach(async (file) => {
      await readFileAsArrayBuffer(file);
    })
  }

  // Custom Alert Clear
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowAlert(false);
    }, 3000);
    return () => {
      clearInterval(timeout);
    };
  }, [showAlert]);

  return (
    <Grid container sx={{ padding: "3rem 3rem" }}>
      <Box component="form" noValidate sx={{ width: "100%" }}>
        <Grow in>
          {/* Custom Alert Dialog */}
          <Grid item display={showAlert ? "block" : "none"} marginBottom={2}>
            <Alert severity="success">Bug is successfully reported!</Alert>
          </Grid>
        </Grow>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel
              style={{ fontFamily: "Poppins" }}
              id="demo-simple-select-label"
              required
            >
              Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              style={{ fontFamily: "Poppins" }}
              id="category"
              value={category}
              label="Category"
              name="category"
              required
              error={categoryError}
              onChange={(event) => {
                setCategoryError(false);
                setCategory(event.target.value);
              }}
            >
              {availableCategories.map((i) => (
                <MenuItem style={{ fontFamily: "Poppins" }} key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Grid item my={2}>
            <TextField
              value={bugDesc}
              name="bugdesc"
              fullWidth
              multiline
              required
              error={descError}
              helperText={descError ? "Required Field" : ""}
              inputProps={{ style: { fontFamily: "Poppins" } }}
              InputLabelProps={{ style: { fontFamily: "Poppins" } }}
              id="name"
              label="Discuss the bug"
              rows={5}
              onChange={(event) => {
                setDescError(false);
                setBugDesc(event.target.value);
              }}
            />
          </Grid>

          <Grid item style={{display: "flex", alignItems:"center"}}>
          <InputLabel
              style={{ fontFamily: "Poppins", color: "black"}}
            >
              Upload attachments:
            </InputLabel>
            <Tooltip title="Choose file to upload">
              <Button
                className={classes.outlinedButton}
                variant="outlined"
                component="label"
                style={{margin: "0 1rem 0 2rem", fontWeight: "bold"}}
                >
                Choose File
                <input type="file" multiple hidden onChange={handleFileUpload}/>
              </Button>
            </Tooltip>
              {filesSelected.length > 0 ? <small>{filesSelected.join(", ")}</small> : "No file chosen"}
          </Grid>

          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ marginTop: "2rem" }}
          >
            <Grid item>
              <Button
                className={classes.styledButton}
                variant="contained"
                onClick={handleOnClick}
                size="large"
                sx={{ height: "50px", borderRadius: "5px" }}
              >
                Report Bug
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Feedback;
