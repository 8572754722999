import { makeStyles } from '@mui/styles';

export const useStylesDetails = makeStyles({
    readonlyField: {
        pointerEvents: 'none',
        backgroundColor: '#eaeaea',
        "& input": {
            color: "#666"
        }
    }
});