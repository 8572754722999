import { Grid, TextField, Button, Chip, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Fab, Tooltip } from "@mui/material";
import { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import Employeecard from "./EmployeeCard";
import { useSelector, useDispatch } from "react-redux";
import * as businessActions from "../../../../redux/actions/Business";
import { useStyles } from "../../../Home/Business/styles";
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';

const Employees = () => {
  const classes = useStyles();
  const { employees } = useSelector((state) => state.businessReducer);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState({});
  const [showNameErr, setShowNameErr] = useState(false);
  const [showContactErr, setShowContactErr] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowNameErr(false);
    setShowContactErr(false);
    setEmployeeData({});
  };

  const handleOnchangeData = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      setShowNameErr(false);
    }
    else if (name === "contact") {
      value = value.replace(/[. ,:-]+/g, "").replace(/[{()}]/g, '');
      if (!(/^\d{0,15}$/).test(value[0] === '+' ? value.slice(1) : value))
        return;
      setShowContactErr(false);
    }
    setEmployeeData({ ...employeeData, [name]: value });
  };

  const OnClickAdd = () => {
    const validName = Boolean(employeeData?.name?.trim());
    const tempContact = employeeData?.contact?.trim();
    const validContact = Boolean(tempContact) && (tempContact.length >= (tempContact[0] === '+' ? 11 : 10));

    if (validName && validContact) {
      dispatch(businessActions.updateAddEmployeeState(employeeData));
      handleClose();
    }
    else {
      if (!validName)
        setShowNameErr(true);
      if (!validContact)
        setShowContactErr(true);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      {
        employees.length === 0 ?
          (
            <Chip label="No Employee Added" style={{ marginTop: "40px" }} />
          ) :
          (
            <Grid item container justifyContent="center" gap={3}>
              {employees.map(item => <Employeecard key={item.uid} data={item} />)}
            </Grid>
          )
      }
      {/*  Dialog box for getting details for new employee */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "18px", fontFamily: "Poppins", paddingBottom: 1 }}>
          Add new employee
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill employee details
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Employee Name"
                type="text"

                error={showNameErr}
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 30 }}
                value={employeeData?.name || ""}
                onChange={(e) => handleOnchangeData(e)}
              />
              <TextField
                required
                margin="dense"
                id="contact"
                name="contact"
                label="Employee Contact"
                type="text"

                error={showContactErr}
                fullWidth
                variant="standard"
                value={employeeData?.contact || ""}
                onChange={(e) => handleOnchangeData(e)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingX: 3, paddingBottom: 2 }}>
          <Button
            variant="outlined"
            className={classes.popupButton}
            fullWidth
            style={{ marginRight: "1rem" }}
            onClick={OnClickAdd}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            className={classes.popupButton}
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/*  Dialog box for editing employee */}

      <Fab onClick={handleClickOpen} aria-label="add"
        sx={{
          color: "#FFFFFF", background: PRIMARY_COLOR,
          position: "fixed", bottom: "4rem", right: "4rem",
          "&:hover": { backgroundColor: PRIMARY_COLOR }
        }}
      >
        <Tooltip title="Add Employee" placement="left">
          <AddIcon style={{ fontSize: "2rem" }} />
        </Tooltip>
      </Fab>
    </Grid>
  );
};

export default Employees;
