import React from "react";
import {
    Route, Redirect
} from "react-router-dom";
import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { LoadingBackdrop } from "../components/LoadingBackdrop";


export const PublicRoute = ({ path, component }) => {
    const { auth, userData, businessData } = useSelector((state) => state.authReducer);

    if (!auth) {
        return (<Route exact path={path} component={component} />);

    } else if (auth && businessData && businessData !== 'loading') {
        return (<Redirect to="/business/home" />);

    } else if (auth && userData && userData !== 'loading') {
        return (<Redirect to="/user/home" />);
    }
    else if ((auth && auth !== "loading") && !businessData && !userData) {
        if (localStorage.getItem('loginType') === 'BUSINESS') {
            return (<Redirect to="/business/signup" />)
        } else if (localStorage.getItem('loginType') === 'USER') {
            return (<Redirect to="/user/signup" />)
        } else {
            return (<LoadingBackdrop />);
        }
    } else {
        return (<LoadingBackdrop />);
    }
}