import { useEffect, useState } from "react";

const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
       
        window.resizedFinished = setTimeout(function () {
            setWidth(window.innerWidth);
        }, 550);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 372 ? 'mobile' : width <= 1024 ? 'tablet' : 'pc');
}

export default useCheckMobileScreen