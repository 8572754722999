import React, { useRef } from "react";
import { Grid, IconButton } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import CustomizedCard from "../../../../components/CustomizedCard/CustomizedCard";
import Scheduler from "../Scheduler";
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { MainQueueCardPlaceHolder } from "../PlaceHolders";

const HomePanel = ({ queueList, employeeQueueList, date, setDate, highlightedEmployee }) => {

  let scrollRef = useRef(null);

  const scroll = (scrollOff) => {
    const { scrollLeft } = scrollRef.current;
    scrollRef.current.scrollTo({ left: scrollOff + scrollLeft, behavior: 'smooth' });
  };

  return (
    <div>
      <Grid container spacing={2} direction="column">
        {
          queueList.length !== 0 &&  
          <div>
            <IconButton style={{ position: 'absolute', top: "150px", left: "8px", zIndex: 99, background: "#333333dd" }} onClick={() => scroll(-600)}>
              <ArrowBackIosIcon fontSize="small" style={{ color: '#fff', transform: "translate(2.5px)" }} />
            </IconButton>
            <IconButton style={{ position: 'absolute', top: "150px", right: "8px", zIndex: 99, background: "#333333dd" }} onClick={() => scroll(600)}>
              <ArrowForwardIosIcon fontSize="small" style={{ color: '#fff' }} />
            </IconButton>
          </div>
        }
        
        <Grid ref={scrollRef} style={{ overflowX: 'scroll', width: "95%", paddingLeft: 0 }} item container marginTop="1rem" marginX="auto">
          
          <Droppable droppableId="newdd1" direction="horizontal">
            {(provided, snapshot) => (
              <div {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ display: 'flex', width:"100%" }}
              >
                {queueList.length === 0 &&  <MainQueueCardPlaceHolder  />}
                {queueList.sort((a, b) => (a.created_timestamp - b.created_timestamp)).map((i, index) => (
                  <Draggable draggableId={i.id} index={index} key={i.id}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}

                      >
                        <Grid item>
                          <CustomizedCard item={i} wid={snapshot.isDragging ? "140px" : null} hei={snapshot.isDragging ? "140px" : null} />
                        </Grid>
                        {provided.placeholder}
                      </div>)}
                  </Draggable>))}
                {provided.placeholder}
              </div>)}
          </Droppable>
        </Grid>
        <Grid item style={{ padding: '5px', paddingRight: 0 }}>
          {employeeQueueList && <Scheduler highlightedEmployee={highlightedEmployee} date={date} setDate={setDate}
            employeeQueueList={employeeQueueList} />}
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePanel;
