import React, { useState, useEffect } from 'react';
import {
    Button, TextField, Grid, Box, CircularProgress, Grow, Typography,
    FormControl, Select, InputLabel, MenuItem, Snackbar
} from '@mui/material';
import MuiAlert from "@mui/material/Alert";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import moment from 'moment';

function getTime(timeStr) {
    if (!timeStr)
        return null;
    let valArr = timeStr.split(":");
    let hrs = +valArr[0];
    valArr = valArr[1].split(" ");
    let mins = +valArr[0];
    const isPM = (valArr[1] === "pm");

    if (isPM)
        hrs += 12;
    if (hrs % 12 === 0)
        hrs -= 12;

    return new Date().setHours(hrs, mins, 0);
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ScheduleInfoStep = ({ nextStep1, consolidateData, error, setError, loading, classes, setActiveStep, setConsolidateData }) => {
    const [category, setCategory] = useState(consolidateData?.category || "");
    const [openTime, setOpenTime] = useState(getTime(consolidateData?.openTime) || (new Date().setHours(9, 0, 0)));
    const [closeTime, setCloseTime] = useState(getTime(consolidateData?.closeTime) || (new Date().setHours(18, 0, 0)));
    const [availableCategories, setAvailableCategories] = useState([]);
    const [daysArr, setDaysArr] = useState(consolidateData?.openDays?.split(','));
    const [showErr, setShowErr] = useState(false);

    useEffect(() => {
        const remoteConfig = getRemoteConfig();
        remoteConfig.settings.minimumFetchIntervalMillis = 1000;
        remoteConfig.defaultConfig = {
            "categories": "Doc,Sal"
        };
        fetchAndActivate(remoteConfig)
            .then(() => {
                const val = getValue(remoteConfig, "categories");
                setAvailableCategories(val._value.split(","));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const getDayName = (index) => {
        switch (index) {
            case 0:
                return 'Monday'
            case 1:
                return 'Tuesday'
            case 2:
                return 'Wednesday'
            case 3:
                return 'Thursday'
            case 4:
                return 'Friday'
            case 5:
                return 'Saturday'
            case 6:
                return 'Sunday'
            default:
                return 'Sunday'
        }
    }


    const validateInputs = (event) => {
        const isValid = moment(openTime).isValid() && moment(closeTime).isValid();
        const areDiff = !moment(openTime).isSame(closeTime);
        if(isValid && areDiff)
            nextStep1(event);
        else {
            if(!areDiff)
                setShowErr(true);
            event.preventDefault();
        }
    }

    return (
        <Grow in>
            <Box component="form" noValidate sx={{ mt: 3, }} onSubmit={validateInputs} >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={error?.category?.status}>
                            <InputLabel style={{ fontFamily: 'Poppins' }} id="demo-simple-select-label">Category</InputLabel>
                            <Select
                                style={{ fontFamily: 'Poppins' }}
                                id="category"
                                value={category}
                                label="Category"
                                name="category"
                                onChange={(event) => {
                                    setCategory(event.target.value);
                                    setError((value) => ({ ...value, category: { status: false } }));
                                }}
                            >
                                {availableCategories.map((i) => (
                                    <MenuItem style={{ fontFamily: 'Poppins' }} key={i} value={i}>{i}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '16px', marginBottom: '8px' }}>
                            Operating hours:
                        </Typography>

                        <Grid container spacing={2} justifyContent="space-between" sx={{ position: "relative" }}>
                            <Grid item alignSelf="center" sx={{ position: "absolute", width: "100%" }}>
                                <hr />
                            </Grid>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        value={openTime}
                                        onChange={(newValue) => {
                                            setOpenTime(newValue);
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            style={{ maxWidth: "135px", background: "#fff" }}
                                            defaultValue={consolidateData?.openTime}
                                            name="openTime"
                                            error={error?.website?.openTime}
                                            helperText={error?.website?.openTime && error?.openTime?.message}
                                            onChange={() => {
                                                setError((value) => ({ ...value, openTime: { status: false } }));
                                            }}
                                            onKeyDown={e => e.preventDefault()}
                                        />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item alignSelf="center">
                                <div className={classes.divider}>
                                    to
                                </div>
                            </Grid>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        name="closeTime"
                                        value={closeTime}
                                        onChange={(newValue) => {
                                            setCloseTime(newValue);
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            style={{ maxWidth: "135px", background: "#fff" }}
                                            defaultValue={consolidateData?.closeTime}
                                            name="closeTime"
                                            error={error?.website?.closeTime}
                                            helperText={error?.website?.closeTime && error?.closeTime?.message}
                                            onChange={() => {
                                                setError((value) => ({ ...value, closeTime: { status: false } }));
                                            }}
                                            onKeyDown={e => e.preventDefault()}
                                        />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '15px', marginBottom: '8px' }}>
                            Operating days:
                        </Typography>

                        <Grid container columnSpacing={1} rowSpacing={1}>
                            {
                                daysArr.map((day, index) => {
                                    return (
                                        <Grid item xs={6} sm={4} key={index}>
                                            <Button
                                                id="sign-in-button"
                                                fullWidth
                                                name={day}
                                                className={day === '1' ? classes.styledButton : classes.disabledButton}
                                                style={{ height: '40px', borderRadius: '5px', textTransform: 'none' }}
                                                onClick={() => {
                                                    const tempDayArr = [...daysArr];
                                                    tempDayArr[index] = tempDayArr[index] === '1' ? '0' : '1';
                                                    
                                                    if(tempDayArr.some(el => el === '1')) {
                                                        setDaysArr(tempDayArr);
                                                        let dupData = { ...consolidateData, openDays: tempDayArr.toString() };
                                                        setConsolidateData(dupData);
                                                    }
                                                }}
                                            >
                                                {getDayName(index).toUpperCase()}
                                            </Button>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2} marginTop={4}>
                    <Grid item xs={6}>
                        <Button
                            id="sign-in-button"
                            fullWidth
                            variant="contained"
                            className={classes.styledButton}
                            sx={{ height: '50px', borderRadius: '5px' }}
                            disabled={loading}
                            onClick={() => setActiveStep(1)}
                        >
                            Previous
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            id="sign-in-button"
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.styledButton}
                            sx={{ height: '50px', borderRadius: '5px' }}
                            disabled={loading}
                        >
                            {loading ? <><CircularProgress style={{ color: 'white', float: 'left', width: '20px', height: '20px' }} /> <span style={{ marginLeft: '10px' }}>Working on it</span></> : 'Next'}
                        </Button>
                    </Grid>
                </Grid>
                <Snackbar
                    open={showErr}
                    onClose={() => { setShowErr(false) }}
                    autoHideDuration={3000}
                >
                    <Alert severity="error">Open and close time must be different</Alert>
                </Snackbar>
            </Box>
        </Grow>
    )
}