import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { Backdrop, CircularProgress } from "@mui/material";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MobileAuthCheck = () => {
    let query = useQuery();

    const { auth } = useSelector((state) => state.authReducer);
    const [businessData, setbusinessData] = useState(null);

    useEffect(() => {
        if (auth) {
            let data = {
                businessId: query.get('bid'),
                name: query.get('name'),
                services: query.get('services'),
                atleastOneServiceEnabled: query.get('atleastOneServiceEnabled'),
                category: query.get('category')
            }
            setbusinessData(data);
        }
    }, [auth, query]);

    if (!businessData) {
        return (<Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
        </Backdrop>)
    }
    if (auth && auth !== 'loading') {
        getAuth().signOut();
        return (<Redirect to={{
            pathname: '/mobile/user/quick-login',
            state: { businessData: businessData }
        }} />);
        /*return (<Redirect to={{
             pathname: '/mobile/user/view-token',
             state: { businessData: businessData }
         }} />);*/
    }
    if (!auth) {
        return (<Redirect to={{
            pathname: '/mobile/user/quick-login',
            state: { businessData: businessData }
        }} />);
    }
    return (<Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
    </Backdrop>)
}

export default MobileAuthCheck;