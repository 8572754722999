import React from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { PRIMARY_COLOR } from "../../config/appStyleConfig";
import { ShowSharePreview } from "./ShowSharePreview";
import "./stylesCoupon.css";
import { padding } from "@mui/system";
export const CouponItem = ({ item, setShowTC, setConfirmDelete }) => {
  const [sharePreview, setSharePreview] = React.useState(null);

  return (
    <div style={{ position: "relative", marginTop: "-5px" }}>
          {item.isExpired && (
            <div
              className="ribbon-coupon ribbon-coupon-top-left"
              style={{ marginLeft: "0px", zoom: "0.85" }}
            >
              <span style={{ backgroundColor: "red" }}>Expired</span>
            </div>
          )}
    <Card style={{ margin: "8px", position: "relative" ,padding:"10px"}}>
      <CardContent>
        <Typography style={{ fontFamily: "Poppins" }}>
            <strong>{item.name}</strong>
          </Typography>
          <Typography style={{ fontFamily: "Poppins" }}>{item.code}</Typography>
          <Typography
            style={{
              fontFamily: "Poppins",
              color: "grey",
              fontSize: "12px",
              wordWrap: "break-word",
            }}
          >
            {item.description}
          </Typography>
          <Typography
            style={{ fontFamily: "Poppins", color: "grey", fontSize: "12px" }}
          >
            <strong>{item.startDate}</strong> To <strong>{item.endDate}</strong>
          </Typography>
          <Typography
            style={{ fontFamily: "Poppins", color: "grey", fontSize: "12px" }}
          >
            <strong>{item.startTime}</strong> To <strong>{item.endTime}</strong>
          </Typography>
          <div
            style={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => setShowTC(item.id)}
              style={{
                marginTop: "10px",
                color: "black",
                border: `1px solid black`,
              }}
            >
              T & C
            </Button>
            <div>
              <Button
                disabled={item.isExpired}
                variant="outlined"
                size="small"
                onClick={() => setSharePreview(item)}
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  color: PRIMARY_COLOR,
                  border: `1px solid ${PRIMARY_COLOR}`,
                  opacity: item.isExpired ? 0.5 : 1,
                }}
              >
                Share
              </Button>

              <Button
                variant="outlined"
                size="small"
                onClick={() => setConfirmDelete(item)}
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  color: "red",
                  border: `1px solid red`,
                }}
              >
                Delete
              </Button>
            </div>
          </div>
      </CardContent>
      {sharePreview && (
        <ShowSharePreview item={sharePreview} setItem={setSharePreview} />
      )}
    </Card>
    </div>
  );
};
