import { put, select, takeEvery } from "redux-saga/effects";
import * as types from "../../actions/Auth/types";
import * as authActions from "../../actions/Auth";
import * as businessActions from "../../actions/Business";
import { store } from "../../store/configureStore";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getDatabase,
  ref,
  onValue,
  update,
  set,
  remove,
  runTransaction,
  get
} from "firebase/database";

function* getAuthStateAsync() {
  try {
    if (getAuth()) {
      onAuthStateChanged(getAuth(), (user) => {
        if (user) {
          store.dispatch(authActions.updateAuthState(user));
          return onValue(ref(getDatabase(), `iQueue/Public/Businesses/${user?.uid}/profile/`), (snapshot) => {
            if (snapshot.val() && localStorage.getItem('loginType') === "BUSINESS") {
              //Update Token counter here
              return onValue(ref(getDatabase(), `iQueue/Restricted/Businesses/${user?.uid}/analytics/token_counter`), (tokenSnapshot) => {
                if (tokenSnapshot.val()) {
                  localStorage.setItem('tokenCounter', parseInt(tokenSnapshot.val()));
                  store.dispatch(authActions.setBusinessLoginData(snapshot.val()));
                  store.dispatch(authActions.setUserLoginData(null));
                } else {
                  localStorage.setItem('tokenCounter', 0);
                  store.dispatch(authActions.setBusinessLoginData(snapshot.val()));
                  store.dispatch(authActions.setUserLoginData(null));
                }
              });
            } else {
              return onValue(ref(getDatabase(), `iQueue/Restricted/Consumers/${user?.uid}/profile/`), (snapshot) => {
                if (snapshot.val() && localStorage.getItem('loginType') === "USER") {
                  store.dispatch(authActions.setUserLoginData(snapshot.val()));
                  store.dispatch(authActions.setBusinessLoginData(null));
                } else {
                  store.dispatch(authActions.setUserLoginData(null));
                  store.dispatch(authActions.setBusinessLoginData(null));
                }
              }, {
                onlyOnce: true
              });
            }
          }, {
            onlyOnce: true
          });
        } else {
          //Handle logout (Clear all reducers etc)
          store.dispatch(businessActions.updateResetQueueState());
          store.dispatch(authActions.updateAuthState(null));
          store.dispatch(authActions.setUserLoginData('loading'));
          store.dispatch(authActions.setBusinessLoginData('loading'));
        }
      });
    }
    yield put(authActions.updateAuthState('loading'));
  } catch (error) {
    console.log(error);
  }
}

function* updateBusinessDetailsAsync({ updatedBusinessData }) {
  try {
    if (getAuth()) {
      const { auth, businessData } = yield select((state) => state.authReducer);
      const db = getDatabase();
      const profileRef = ref(
        db,
        `iQueue/Public/Businesses/${auth?.uid}/profile/`
      );
      if (updatedBusinessData?.category === businessData?.category) {
        yield update(profileRef, updatedBusinessData).then(() => {
          store.dispatch(authActions.setAlert());
        });
      } else {
        yield set(`${profileRef}/services`, updatedBusinessData?.services);
      }
      setTimeout(() => {
        store.dispatch(authActions.clearAlert());
      }, 3000);
    }
  } catch (error) {
    console.log(error);
  }
}
function clearStorage() {
  localStorage.clear();
  sessionStorage.clear();
}

function* deleteBusinessAsync() {
  try {
    if (getAuth()) {
      const { auth } = yield select((state) => state.authReducer);
      const db = getDatabase();
      const profileRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/profile`);
      const businessRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}`);
      const mapMarkerRef = ref(db, `iQueue/Public/MapMarkers/${auth?.uid}`);
      const floorRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/FloorSetup`);
      const couponRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/Coupons`);
      yield remove(profileRef);
      yield remove(businessRef);
      yield remove(floorRef);
      yield remove(couponRef);
      yield remove(mapMarkerRef).then(() => {
        store.dispatch(authActions.logOut());
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* logOutAsync() {
  try {
    if (getAuth()) {
      const { auth } = yield select((state) => state.authReducer);
      const db = getDatabase();
      const tokenRef = ref(db, `iQueue/Restricted/NotificationTokens/WB_${auth?.uid}`);
      yield remove(tokenRef);
      yield clearStorage();
      yield getAuth().signOut();
    }
  } catch (error) {
    console.log(error)
  }
}
// eslint-disable-next-line require-yield
function* updateGlobalAnalyticsAsync({ field, data }) {
  try {
    const db = getDatabase();
    const userRef = ref(db, "iQueue/Restricted/Analytics");
    get(userRef).then((snapshot) => {
      if (!snapshot.val()) {
        update(userRef, { Appointments: 0, Businesses: 0, Customers: 0, minutes_saved: 0 })
      }
      switch (field) {
        case "Businesses":
          runTransaction(userRef, (item => {
            if (item) {
              item.Businesses++;
            }
            return item;
          }))
          break;
        case "Appointments":
          runTransaction(userRef, (item => {
            if (item) {
              item.Appointments++;
            }
            return item;
          }))
          break;
        case "Customers":
          runTransaction(userRef, (item => {
            if (item) {
              item.Customers++;
            }
            return item;
          }))
          break;
        case "minutes_saved":
          runTransaction(userRef, (item => {
            if (item) {
              item.minutes_saved = item.minutes_saved + data;
            }
            return item;
          }))
          break;

        default:
          break;
      }
    });


  } catch (error) {
    console.log(error)
  }
}
export function* authSaga() {
  yield takeEvery(types.ENABLE_AUTH_STATE_LISTENER, getAuthStateAsync);
}

export function* businessDetailsSaga() {
  yield takeEvery(types.UPDATE_BUSINESS_DETAILS, updateBusinessDetailsAsync);
}

export function* DeleteBusinessSaga() {
  yield takeEvery(types.DELETE_BUSINESS, deleteBusinessAsync);
}

export function* logOutSaga() {
  yield takeEvery(types.LOG_OUT, logOutAsync)
}
export function* updateGlobalAnalyticsSaga() {
  yield takeEvery(types.UPDATE_GLOBAL_ANALYTICS, updateGlobalAnalyticsAsync)
}
