import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import {
  BrowserRouter
} from "react-router-dom";
//Actions
import * as authActions from './redux/actions/Auth';
//Public Router
import { Routes } from './routes';

//Firebase
import { initializeApp } from "firebase/app";
import { firebaseConfig } from './config/firebaseConfig';
import { getMessaging, onMessage, isSupported } from "firebase/messaging";
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

function App() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>)

  const [app] = useState(initializeApp(firebaseConfig));

  useEffect(() => {
    if (app) {
      dispatch(authActions.enableAuthStateListener());
      const initMessaging = async () => {
        try {
          if (await isSupported() === true) {
            const messaging = getMessaging(app);
            onMessage(messaging, (payload) => {
              console.log('Message received. ', payload);
              setOpen(true)
              setMessage(payload?.data?.body)
            });
          }
        } catch (e) {
          console.log(e)
        }
      }
      initMessaging();
    }
  }, [app, dispatch]);

  if (app) {
    return (
      <BrowserRouter>
        <Routes />
        <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          message={message}
          action={action}
        />
      </BrowserRouter>
    );
  } else {
    return (<div>Check firebase config</div>)
  }
}

export default App;
