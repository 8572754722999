import React, { useEffect, useState } from 'react'
import {
    Button, Dialog, DialogContent,
    DialogTitle, DialogActions, Divider, Typography,
    FormControlLabel, FormGroup, Checkbox, Snackbar
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import * as businessActions from "../../redux/actions/Business";
import { PRIMARY_COLOR } from '../../config/appStyleConfig';
import * as businessCouponActions from "../../redux/actions/BusinessCoupons";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function CheckLabel(props) {
    return (<Typography style={{
        fontSize: props?.size ? props?.size : '14px',
        fontFamily: 'Poppins',
        fontWeight: '600'
    }}>
        {props?.text}
        <br></br>
        {props?.contact ? <span style={{ fontSize: '11px' }}>  ({props?.contact})</span> : ''}
    </Typography>);
}

export const ShareWith = ({ item, setItem }) => {
    const dispatch = useDispatch();

    const { businessData } = useSelector((state) => state.authReducer);
    const customers = useSelector((state) => state.businessReducer.customers.filter((i) => i?.uid?.length > 15));

    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [totalLength, setTotalLength] = useState(0);
    const [snackbarData, setSnackBarData] = useState(null);

    useEffect(() => {
        dispatch(businessActions.getCustomers(businessData?.uid));
    }, [businessData, dispatch]);

    const handleCheckBoxChange = (event) => {
        if (event.target.checked) {
            //Add customer uid to the list
            setSelectedCustomers((customerList) => {
                let foundCustomer = customerList.find((customer) => customer === event.target.value);
                if (!foundCustomer) {
                    customerList.push(event.target.value);
                }
                //Update this to check select all option
                setTotalLength(customerList.length);
                return customerList;
            });
        } else {
            //Remove customer uid from the list
            setSelectedCustomers((customerList) => {
                let foundCustomer = customerList.find((customer) => customer === event.target.value);
                if (foundCustomer) {
                    customerList = customerList.filter((customer) => {
                        return customer !== foundCustomer;
                    });
                }
                //Update this to check select all option
                setTotalLength(customerList.length);
                return customerList;
            });
        }
    }

    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            setSelectedCustomers(customers.map((customer) => customer?.uid));
            setTotalLength(customers.length);
        } else {
            setSelectedCustomers([]);
            setTotalLength(0);
        }
    }

    const handleShare = () => {
        if (selectedCustomers.length === 0) {
            setSnackBarData({
                open: true,
                message: "Select atleast one customer",
                severity: 'error'
            });
            return;
        }
        dispatch(businessCouponActions.shareCouponWith(selectedCustomers, item));
        setSnackBarData({
            open: true,
            message: "Coupon sent successfully",
            severity: 'success'
        });
    }

    return (
        <Dialog
            hideBackdrop
            open={item !== null}
            aria-labelledby="Delete Dialog1"
        >
            <DialogTitle id="responsive-dialog-title">
                Share With
                <Typography style={{ fontSize: '11px', fontFamily: 'Poppins', fontWeight: '600' }}>
                    This will also send push notification to your customers
                </Typography>
            </DialogTitle>
            <DialogContent style={{ overFlow: 'auto' }}>
                <div style={{ width: '410px', padding: '6px', height: '200px' }}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={customers.length === totalLength && customers.length !== 0}
                            onChange={handleSelectAllChange}
                            color="success" sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} />}
                            label={<CheckLabel size="15px" text="Select All" />} />
                        <div style={{ margin: '0px 0px 15px 0px', }}>
                            <Typography style={{ fontSize: '11px', fontFamily: 'Poppins', fontWeight: '600' }}>
                                Only eligible customers will be shown
                            </Typography>
                            {customers.map((cust) => (
                                <div style={{ marginTop: '12px' }}>
                                    <FormControlLabel control={<Checkbox
                                        value={cust?.uid}
                                        checked={selectedCustomers?.filter((e) => e === cust?.uid).length !== 0}
                                        onChange={handleCheckBoxChange}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                    />} label={<CheckLabel size="14px" text={cust?.name}
                                        contact={cust?.contact} />} />
                                </div>
                            ))}
                        </div>
                    </FormGroup>
                    <Divider />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="small"
                    onClick={() => handleShare()}
                    style={{
                        marginTop: '10px', color: PRIMARY_COLOR,
                        border: `1px solid ${PRIMARY_COLOR}`
                    }}>
                    Share
                </Button>
                <Button variant="outlined" size="small"
                    onClick={() => setItem(null)}
                    style={{
                        marginTop: '10px', color: 'red',
                        border: `1px solid red`
                    }}>
                    Cancel
                </Button>
            </DialogActions>
            {snackbarData && snackbarData.open && (
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackbarData.open} autoHideDuration={3000} onClose={() => setSnackBarData(null)}>
                    <Alert severity={snackbarData.severity}>{snackbarData.message}</Alert>
                </Snackbar>
            )}
        </Dialog>
    )
}