import React from 'react';
import {
    Drawer, AppBar, Box, Toolbar, Typography, CssBaseline,
    List, ListItem, ListItemIcon, ListItemText, Divider,
    IconButton, Snackbar, Avatar, Button, Tooltip, Badge
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "./styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { NavLink } from "react-router-dom";

export const Subscription = () => {
    const { businessData } = useSelector((state) => state.authReducer);
    const classes = useStyles();

    return (<div>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                <NavLink style={{ textDecoration: 'none' }} to="/business/home">
                    <IconButton style={{ color: 'white' }} aria-label="open drawer" edge="start">
                        <ArrowBackIcon />
                    </IconButton>
                </NavLink>
                <Typography variant="h6" component="div" sx={{ fontSize: "18px", flexGrow: 1, fontFamily: "Poppins", marginLeft: "12px" }}>
                    {businessData.name}
                    <br></br>
                    <Typography style={{ fontFamily: "Poppins", fontSize: "12px" }}>iQueue Business (Manage Subscriptions)</Typography>
                </Typography>
            </Toolbar>
        </AppBar>
        <Toolbar />
        <div className={classes.content}>
            <Button>
                Entry Level
            </Button>
            <Button>Mid Level</Button>
        </div>
    </div>)
}