import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { DateRange } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateBusinessDetails } from "../../../../redux/actions/Auth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 2,
  bgcolor: "#FFFFFF",
  border: "2px solid grey",
  borderRadius: "10px",
};
const LeaveDaysCalender = ({ setShowLeaveDays }) => {
  const { businessData } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleUpdate = () => {
    dispatch(
      updateBusinessDetails({
        ...businessData,
        leave_days: `${selectionRange[0].startDate.valueOf()},${selectionRange[0].endDate.valueOf()}`,
      })
    );
    setShowLeaveDays(false)
  };


  return (
    <Box sx={style}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="subtitle2">Leave Days</Typography>
        </Grid>
        <Grid item>
          <DateRange
            minDate={new Date()}
            moveRangeOnFirstSelection={false}
            ranges={selectionRange}
            onChange={(item) => setSelectionRange([item.selection])}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color='error'
            sx={{ height: "50px", borderRadius: "5px", marginTop: "20px" ,marginRight: "40px" }}
            onClick={() => {setShowLeaveDays(false)}}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ height: "50px", borderRadius: "5px", marginTop: "20px" }}
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeaveDaysCalender;
