import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Typography, Grid } from '@mui/material';
import React, { useState } from 'react';

export const MenuItemPriceSelect = ({ item, setItem, setMenuItems }) => {
    const [price, setPrice] = useState(item?.split(';')[1] ? item?.split(';')[1] : '0');


    const handleUpdatePrice = () => {
        if (price) {
            setMenuItems((menuItem) => {
                return menuItem.map((i) => i === item ? `${item?.split(';')[0]};${price ? price : '30'}` : i)
            });
            setItem(null)
        }
    }

    return (<Dialog open={item}>
        <DialogTitle>
            <Typography variant="h6" style={{ fontFamily: 'Poppins' }}> {item?.split(';')[0]}</Typography>
            <Typography variant="subtitle2" style={{ fontFamily: 'Poppins' }}>Price (in $) : </Typography>
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item>
                    <TextField
                        value={price}
                        inputProps={{ maxLength: 3 }}
                        sx={{ width: "100px" }}
                        onChange={(e) => {
                            // const re = /^([0-1]?[0-9]|2[0-3])$/g;
                            if (e.target.value === '')
                                setPrice('');
                            // else if (re.test(e.target.value))
                            else
                                setPrice(e.target.value);
                        }}
                        size="small"
                    />
                </Grid>
            </Grid>
            <DialogActions style={{ marginTop: '15px' }}>
                <Button
                    size="small"
                    onClick={() => handleUpdatePrice()}>
                    Save
                </Button>
                <Button
                    size="small"
                    onClick={() => setItem(null)}>
                    Cancel
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>)
}