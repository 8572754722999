import React, { useEffect, useCallback } from 'react';
import * as businessRestaurantActions from "../../../redux/actions/BusinessRestaurant";
import { useSelector, useDispatch } from "react-redux";
import { DropTest } from './dropTest';
import useOrientation from '../../../components/Hooks/useOrientation';

const styles = {
    width: '100%',
    height: '70vh',
    marginTop: '10px',
    border: '1px solid black',
    position: 'relative',
}
export const ConTest = ({ snapToGrid, tables, date, setDate, suggestedTable, preferredTable, isMobile }) => {
    const dispatch = useDispatch();
    const containerRef = React.useRef();
    //const { businessData } = useSelector((state) => state.authReducer);

    const [mTables, setTables] = React.useState(null);
    const orientation = useOrientation();

    const loadFloorSizeFromDB = useCallback(() => {
        return new Promise((resolve, reject) => {
            dispatch(businessRestaurantActions.loadFloorSize(resolve, reject));
        });
    }, [dispatch]);

    const handleResize = useCallback(async () => {
        let floorSize = await loadFloorSizeFromDB();
        let leftScale = containerRef?.current?.clientWidth / floorSize.floorWidth;
        let topScale = containerRef?.current?.clientHeight / floorSize.floorHeight;
        // if (containerRef?.current?.clientWidth < containerRef?.current?.clientHeight) {
        //     let mHeight = containerRef?.current?.clientHeight - (containerRef?.current?.clientHeight / 1.6);
        //     topScale = (mHeight / floorSize.floorHeight);
        // }
        let newTables = {};
        Object.keys(tables).forEach((key) => {
            let tb = {
                ...tables[key],
                size: {
                    height: topScale * tables[key].size.height,
                    width: leftScale * tables[key].size.width
                },
                left: leftScale * tables[key].left,
                top: topScale * tables[key].top
            }
            newTables = { ...newTables, [key]: tb }
        });
        setTables(newTables);
    }, [tables, loadFloorSizeFromDB]);

    useEffect(() => {
        handleResize();
    }, [tables, handleResize]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    const handleUnassign = (tableData) => {
        dispatch(businessRestaurantActions.unAssignCustomerFromTable({ ...tableData, freeTable: false }));
    }

    const handleFreeTable = (tableData) => {
        dispatch(businessRestaurantActions.unAssignCustomerFromTable({ ...tableData, freeTable: true, served: true }));
    }

    return (
        <div>
            <div ref={containerRef} style={{ ...styles, height: orientation === "PORTRAIT" ? isMobile ? '50vh' :  '35vh' : '70vh' }}>
                {mTables && Object.keys(mTables).map((key) => (
                    <DropTest preferredTable={preferredTable} suggestedTable={suggestedTable} key={key} myKey={key} table={mTables[key]} handleUnassign={handleUnassign} handleFreeTable={handleFreeTable} />
                ))}
            </div>
            {/* <div style={{ marginTop: '20px' }}>
                <Scheduler date={date} setDate={setDate} />
            </div> */}
        </div>
    )
}
