import React, { useState } from 'react';
import { Link as RLink } from "react-router-dom";
import {
    Button, CssBaseline, FormControlLabel,
    Checkbox, Grid, Box, Typography, Container, createTheme, ThemeProvider,
    CircularProgress, Grow, Link
} from '@mui/material';
import { useStyles } from './styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

//Firebase
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { getDatabase } from "firebase/database";
//Components
import { SideIntro, Header } from '../../../components/SideIntro';
import OTPVerification from '../../../components/OTPVerification';

const theme = createTheme();

export default function UserLogin() {
    const classes = useStyles();

    const [countryCode] = useState('in');
    const [otpData, setOTPData] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const data = { contact: new FormData(event.currentTarget).get('contact').replace(/([. ,:-])+/g, "").replace(/[{()}]/g, '') };
        if (getDatabase()) {
            signInWithNumber(data);
        }
    };

    const signInWithNumber = (data, renderCaptcha = true, resendOtp = false) => {
        //Use resendOtp to show a snack maybe
        if (renderCaptcha) {
            window.recaptchaVerifier = new RecaptchaVerifier('cap-contain', {
                size: 'invisible'
            }, getAuth());
        }

        signInWithPhoneNumber(getAuth(), data.contact, window.recaptchaVerifier)
            .then((confirmationResult) => {
                let newData = {
                    userData: data,
                    userLogin: true,
                    confirmationResult: confirmationResult
                }
                setOTPData(newData);
                setLoading(false);
            }).catch((error) => {
                alert(error.message);
                setLoading(false);
                if (renderCaptcha) {
                    window.recaptchaVerifier.render().then(function (widgetId) {
                        // eslint-disable-next-line no-undef
                        grecaptcha.reset(widgetId);
                    });
                }
            });
    }

    return (
        <ThemeProvider theme={theme}>
            <Header />
            <Grid container sx={{ pb: '25px', minHeight: "calc(100vh - 175px)", display: "flex", alignItems: "center" }}>
                <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Container component="main" maxWidth='sm'>
                        <SideIntro sideTitle={'Welcome to iQueue'} sideSubTitle={"Only contact number is needed to login"} />
                    </Container>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography component="h1" variant="h5" style={{ fontFamily: 'Poppins' }}>
                                Sign into your account
                            </Typography>
                            {!otpData &&
                                <Grow in>
                                    <Box component="form" onKeyDown={(e) => {
                                        if (e.code === "Enter") { handleSubmit(e); }
                                    }} onSubmit={handleSubmit} noValidate sx={{ mt: 4, width: '100%' }}>
                                        <PhoneInput
                                            countryCodeEditable={false}
                                            country={countryCode}
                                            preferredCountries={['in', 'us', 'ca']}
                                            inputStyle={{ fontFamily: 'Poppins', width: '100%' }}
                                            inputProps={{
                                                id: "contact",
                                                label: "Mobile Number",
                                                name: "contact",
                                            }}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox sx={{ marginTop: 1 }} value="remember" color="primary" />}
                                            label={<Typography sx={{ fontFamily: 'Poppins', marginTop: 1 }}>Remember me</Typography>}
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            disabled={loading}
                                            variant="contained"
                                            className={classes.styledButton}
                                            style={{ height: '50px', borderRadius: '5px' }}
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            {loading ? <><CircularProgress style={{ color: 'white', float: 'left', width: '20px', height: '20px' }} /> <span style={{ marginLeft: '10px' }}>Signing you in</span></> : 'Sign In'}
                                        </Button>
                                        <Grid container>
                                            <Grid item xs />
                                            <Grid item>
                                                <Link component={RLink} to="/business/login" variant="body2" style={{ fontFamily: 'Poppins' }}>
                                                    Switch to iQueue Business
                                                </Link>
                                                {/* <RLink to="/business/login" style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
                                                    {"Switch to iQueue Business"}
                                                </RLink> */}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grow>}
                            {otpData &&
                                <OTPVerification
                                    data={otpData}
                                    loading={loading}
                                    setLoading={setLoading}
                                    signInWithNumber={signInWithNumber}
                                />
                            }
                        </Box>
                        <div id="cap-contain" />
                    </Container>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}