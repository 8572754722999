import { useState, useEffect } from 'react';
import { Button, TextField, Grid, Box, CircularProgress, Typography, Autocomplete } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import axios from 'axios';
import { getAuth } from "firebase/auth";
import AboutDialog from '../../../components/AboutDialog';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';

export const BusinessInfoStep = ({ nextStep, consolidateData, error, setError, loading, classes, setConsolidateData }) => {

    const [addressDrop, setAddressDrop] = useState([]);
    const [addressHint, setAddressHint] = useState('');
    const [open, setOpen] = useState(false);
    const [businessContact, setBusinessContact] = useState(consolidateData?.contact ? consolidateData?.contact : localStorage.getItem('contact') || '');

    useEffect(() => {
        const timer = setTimeout(() => {
            axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${addressHint}&key=0e2acf0b337c45e6b5650d89763957d5`)
                .then(res => {
                    //setAddressDrop(res.data.data);
                    setAddressDrop(res.data.results.map((item) => {
                        return {
                            label: item.formatted,
                            latitude: item.geometry.lat,
                            longitude: item.geometry.lng
                        }
                    }));
                })
                .catch(err => {
                    console.log(err);
                });
        }, 1000);

        return () => clearTimeout(timer);
    }, [addressHint]);

    const toggleDialog = () => {
        setOpen(open => !open);
    }

    const getList = () => {
        if (addressHint.trim())
            return [
                {
                    label: addressHint,
                    latitude: 23.785345,
                    longitude: -28.580709
                },
                ...addressDrop
            ];
        return addressDrop;
    }

    return (
        <Box component="form" noValidate onSubmit={nextStep} sx={{ mt: 3, }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        defaultValue={consolidateData?.name}
                        error={error?.name?.status}
                        name="name"
                        required
                        fullWidth
                        inputProps={{ style: { fontFamily: 'Poppins' }, maxLength: 30 }}
                        InputLabelProps={{ style: { fontFamily: 'Poppins' } }}
                        helperText={error?.name?.status && error?.name?.message}
                        id="name"
                        label="Business Name"
                        autoFocus
                        onChange={() => setError((value) => ({ ...value, name: { status: false } }))}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        options={getList()}
                        isOptionEqualToValue={(option, val) => option.label === val.label}
                        inputValue={consolidateData?.address || ""}
                        onChange={(event, newValue) => {
                            setConsolidateData({
                                ...consolidateData,
                                lat: isNaN(parseFloat(newValue?.latitude)) ? 23.785345 : newValue.latitude,
                                lng: isNaN(parseFloat(newValue?.longitude)) ? -28.580709 : newValue.longitude,
                                address: newValue?.label ? newValue?.label : ""
                            });
                        }}
                        value={consolidateData?.address}
                        freeSolo
                        renderInput={(params) => <TextField
                            {...params}
                            value={consolidateData?.address}
                            id="address"
                            label="Address"
                            name="address"
                            error={error?.address?.status}
                            helperText={error?.address?.status && error?.address?.message}
                            required
                            fullWidth
                            onChange={(e) => {
                                setAddressHint(e.target.value);
                                setConsolidateData({
                                    ...consolidateData,
                                    address: e.target.value,
                                    lat: 23.785345,
                                    lng: -28.580709,
                                });
                                setError((value) => ({ ...value, address: { status: false } }));
                            }}
                        />}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: "none" }}>
                    <PhoneInput
                        countryCodeEditable={false}
                        country={'in'}
                        value={localStorage.getItem('contact') ? localStorage.getItem('contact') : consolidateData?.contact}
                        preferredCountries={['in', 'us', 'ca']}
                        inputStyle={{ fontFamily: 'Poppins', width: '100%' }}
                        disableDropdown="true"
                        inputProps={{
                            id: "contact",
                            label: "Mobile Number",
                            name: "contact",
                            readOnly: true
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        value={businessContact}
                        inputProps={{ style: { fontFamily: 'Poppins' } }}
                        InputLabelProps={{ style: { fontFamily: 'Poppins' } }}
                        id="business_contact"
                        label="Business Contact"
                        name="business_contact"
                        error={error?.business_contact?.status}
                        helperText={error?.business_contact?.status && error?.business_contact?.message}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d{0,15}$/.test(value[0] === "+" ? value.slice(1) : value)) {
                                setError((value) => ({ ...value, business_contact: { status: false } }));
                                setBusinessContact(value);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        defaultValue={consolidateData?.email}
                        inputProps={{ style: { fontFamily: 'Poppins' } }}
                        InputLabelProps={{ style: { fontFamily: 'Poppins' } }}
                        id="email"
                        label="Email"
                        name="email"
                        error={error?.email?.status}
                        helperText={error?.email?.status && error?.email?.message}
                        onChange={() => {
                            setError((value) => ({ ...value, email: { status: false } }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        defaultValue={consolidateData?.website}
                        inputProps={{ style: { fontFamily: 'Poppins' } }}
                        InputLabelProps={{ style: { fontFamily: 'Poppins' } }}
                        id="website"
                        label="Website"
                        name="website"
                        error={error?.website?.status}
                        helperText={error?.website?.status && error?.website?.message}
                        onChange={() => {
                            setError((value) => ({ ...value, website: { status: false } }));
                        }}
                    />
                </Grid>
                {/* For uploading images
                <Grid item xs={12} sm={6}>
                    <input
                        accept="image/*"
                        className={classes.input}
                        id="business-images"
                        multiple
                        onClick={(e) => { e.target.value = null }}
                        type="file"
                        hidden
                        onChange={(event) => {
                            if (event.target.files.length > 3 || consolidateData.businessImages.length === 3) {
                                return alert("Select only 3 images")
                            }
                            let files = [];
                            for (var i = 0; i < event.target.files.length; i++) {
                                files.push(event.target.files[i]);
                            }
                            setConsolidateData({
                                businessImages: [...consolidateData.businessImages, ...files]
                            });
                        }}
                    />
                    <label htmlFor="business-images">
                        <Button variant="outlined"
                            component="span"
                        >Upload Business images</Button>
                    </label>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Stack direction="row" style={{ float: 'right' }}>
                        {consolidateData?.businessImages?.map((image, index) => (
                            <React.Fragment key={index}>
                                <Avatar src={image && URL.createObjectURL(image)} />
                                <CancelIcon style={{ zIndex: 10 }} onClick={() => {
                                    setConsolidateData({
                                        businessImages: consolidateData?.businessImages?.filter((oldImage) => oldImage !== image)
                                    });
                                }} />
                            </React.Fragment>
                        ))}
                    </Stack>
                    <Stack direction="row" style={{ float: 'right' }}>
                        {consolidateData?.businessImages?.length === 0 &&
                            [0, 1, 2].map((item) => (
                                <Avatar key={item} src={''} >
                                    <BusinessIcon />
                                </Avatar>
                            ))
                        }
                    </Stack>
                    </Grid>*/}
            </Grid>
            <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '13px' }}>
                    By clicking "NEXT" you agree with our <br />
                    <span onClick={toggleDialog} style={{ color: PRIMARY_COLOR, cursor: 'pointer' }}>terms & conditions</span>
                    &nbsp;&nbsp;and&nbsp;&nbsp;
                    <a href="https://iqueue365.com/policies/privacy.html" target="_blank" rel="noreferrer" style={{ color: PRIMARY_COLOR, textDecoration: 'none' }}>privacy policy</a>
                </Typography>
                <AboutDialog open={open} toggleDialog={toggleDialog} typeOfDialogBox={1} />
            </div>

            <div style={{ textAlign: 'center' }}>
                <Button
                    id="sign-in-button"
                    type="submit"
                    variant="contained"
                    className={classes.styledButton}
                    style={{ height: '50px', width: '100%', borderRadius: '5px' }}
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}
                >
                    {loading ? <><CircularProgress style={{ color: 'white', float: 'left', width: '20px', height: '20px' }} /> <span style={{ marginLeft: '10px' }}>Working on it</span></> : 'Next'}
                </Button>
                <div style={{ textAlign: 'center' }}>
                    <Typography style={{ fontFamily: 'Poppins', fontSize: '13px' }}>
                        Not you ? <span onClick={() => getAuth().signOut()} style={{ color: PRIMARY_COLOR, cursor: 'pointer' }}>Sign In using a different number</span>
                    </Typography>
                </div>
            </div>
            <div id="cap-contain" />
        </Box >)
}