import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useStyles } from './styles';
import { Box, Paper, Typography, IconButton, InputBase, Grow } from '@mui/material';
import { Send } from '@mui/icons-material';
import axios from 'axios';

const ChatBox = () => {
    const classes = useStyles();
    const { businessData } = useSelector((state) => state.authReducer);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([
        {
            isIncoming: true,
            text: 'Hello, how can I help you today?'
        },

    ]);
    const [inputValue, setInputValue] = useState('');
    const chatBoxBodyRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        chatBoxBodyRef.current.scrollTop = chatBoxBodyRef.current.scrollHeight;
    }, [messages]);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    }

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    }, [loading]);

    const handleSendMessage = async () => {
        if (!inputValue) {
            return;
        }
        setMessages((mes) => [...mes, { isIncoming: false, text: inputValue }]);
        setInputValue('');
        setLoading(true);
        try {
            let res = await axios.get(`https://us-central1-iqueue365-bca29.cloudfunctions.net/getChatBot?id=${businessData.uid}&question=${messages[messages.length - 1]}\n${inputValue}`);
            setMessages((mes) => [...mes, { isIncoming: true, text: res.data }]);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    }

    return (
        <Grow in>
            <Box className={classes.chatBoxContainer}>
                <Paper className={classes.chatBox}>
                    <Box className={classes.chatBoxHeader}>
                        <Typography variant="h6" style={{ fontFamily: 'Poppins', fontSize: '18px', color: 'white' }}>AI Assist</Typography>
                    </Box>
                    <Box ref={chatBoxBodyRef} className={classes.chatBoxBody}>
                        {messages.map((message, index) => (
                            <Box
                                key={index}
                                className={`${classes.messageContainer} ${message.isIncoming ? classes.incomingMessage : classes.outgoingMessage
                                    } `}
                            >
                                <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }}>{message.text}</Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box className={classes.inputContainer}>
                        <InputBase
                            autoFocus
                            ref={inputRef}
                            className={classes.input}
                            disabled={loading}
                            placeholder={loading ? 'Thinking...' : "Type a message..."}
                            value={inputValue}
                            onKeyPress={handleKeyPress}
                            onChange={handleInputChange}
                        />
                        <IconButton
                            disabled={loading}
                            className={classes.sendButton}
                            onClick={handleSendMessage}
                        >
                            <Send />
                        </IconButton>
                    </Box>
                </Paper>
            </Box>
        </Grow>
    );
};

export default ChatBox;
