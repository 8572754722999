import React, { useEffect, useState } from "react";
import {
    Grid, Box, Button, Checkbox, Typography, Grow,
    TextField, IconButton, Tooltip
} from "@mui/material";
import { useStyles } from "../Timing/styles";
import { useDispatch, useSelector } from "react-redux";
import { updateBusinessDetails } from "../../../../redux/actions/Auth";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useStylesDetails } from "./styles";
import { MenuItemPriceSelect } from "./changeMenuItemPrice";
import { v4 as uuidv4 } from 'uuid';

export const Menu = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { businessData } = useSelector((state) => state.authReducer);
    const [menuItems, setMenuItems] = useState([]);
    const [menuItemsV2, setMenuItemsV2] = useState({});
    const [newMenuItem, setNewMenuItem] = useState("");
    const [newMenuItemDesc, setNewMenuItemDesc] = useState("");
    const [newMenuItemPrice, setNewMenuItemPrice] = useState("");
    const [newMenuItemTime, setNewMenuItemTime] = useState("");
    const [changePrice, setChangePrice] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const classesDetails = useStylesDetails();

    useEffect(() => {
        if (businessData?.services?.split(",") && businessData?.services?.split(",")[0]) {
            setMenuItems(businessData?.services?.split(","));
        }
        if (businessData?.servicesv2) {
            setMenuItemsV2(businessData?.servicesv2);
        }
    }, []);


    const addNewService = (event) => {
        if (!newMenuItem || !newMenuItemDesc || !newMenuItemPrice || !newMenuItemTime) {
            return alert("All fields are required");
        }
        const menuItemName = newMenuItem.trim();
        if (menuItemName && !menuItems.some(s => s.split(';')[0] === menuItemName)) {
            setMenuItems([...menuItems, `${menuItemName};${newMenuItemPrice}`]);
        }
        let uid = uuidv4();
        let v2Data = {};
        v2Data[uid] = {
            name: newMenuItem,
            description: newMenuItemDesc,
            price: newMenuItemPrice,
            time: newMenuItemTime,
            uid: uid
        }
        setMenuItemsV2((v) => ({ ...v, ...v2Data }));
        setNewMenuItem("");
        setNewMenuItemPrice("");
        setNewMenuItemDesc("");
        setNewMenuItemTime("");
    };

    const handleUpdate = () => {
        dispatch(
            updateBusinessDetails({
                ...businessData,
                services: menuItems.toString(),
                servicesv2: menuItemsV2
            })
        );
        setIsEdit(!isEdit);
        setNewMenuItem("");
        setNewMenuItemPrice("");
        setNewMenuItemDesc("");
        setNewMenuItemTime("");
    };

    const ServiceItem = ({ item, isCheck, onClickCheckbox, onClickDelete }) => {
        return (
            <>
                <Grid container sx={{ width: "400px", margin: "auto" }}>
                    <Grid xs={8} sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox onClick={onClickCheckbox} checked={isCheck} />
                        <Typography>{item?.split(";")[0]}</Typography>
                    </Grid>
                    <Grid xs={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title="Change Item Price" placement="left">
                            <Button onClick={() => {
                                if (isEdit)
                                    setChangePrice(item);
                            }}>
                                <div>

                                {item?.split(";")[1]} $</div>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Delete Menu Item" placement="right">
                            <IconButton>
                                <DeleteOutlineIcon onClick={onClickDelete} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </>
        );
    };
    return (
        <Grow in>
            <Box component="form" noValidate sx={{ width: "100%" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <div style={{ margin: "auto" }}>
                            {businessData.category === "Restaurant" && <Typography variant="h6" component="h6" style={{ fontFamily: 'Poppins', fontWeight: '600', textAlign: 'center', fontSize: '15px' }}>Please add menu items the clients should see when taking eat-now/reservation.</Typography>}
                            {businessData.category === "Food Truck" && <Typography variant="h6" component="h6" style={{ fontFamily: 'Poppins', fontWeight: '600', textAlign: 'center', fontSize: '15px' }}>Please add menu items the clients should see when taking take-way/catering.</Typography>}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            <TextField
                                InputProps={{ style: { fontFamily: "Poppins" }, maxLength: 100, readOnly: !isEdit }}
                                InputLabelProps={{ style: { fontFamily: "Poppins" } }}
                                label="Enter Menu Item name"
                                required
                                className={isEdit ? "" : classesDetails.readonlyField}
                                value={newMenuItem}
                                onChange={(e) => {
                                    if (!e.target.value.includes(';'))
                                        setNewMenuItem(e.target.value);
                                }}
                                style={{ margin: '10px' }}
                                size="small"
                            />
                            <TextField
                                InputProps={{ style: { fontFamily: "Poppins" }, maxLength: 100, readOnly: !isEdit }}
                                InputLabelProps={{ style: { fontFamily: "Poppins" } }}
                                label="Enter item description"
                                required
                                className={isEdit ? "" : classesDetails.readonlyField}
                                value={newMenuItemDesc}
                                onChange={(e) => {
                                    if (!e.target.value.includes(';'))
                                        setNewMenuItemDesc(e.target.value);
                                }}
                                style={{ margin: '10px' }}
                                size="small"
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <TextField
                                InputProps={{ style: { fontFamily: "Poppins" }, maxLength: 100, readOnly: !isEdit }}
                                InputLabelProps={{ style: { fontFamily: "Poppins" } }}
                                label="Enter item price (in $)"
                                required
                                className={isEdit ? "" : classesDetails.readonlyField}
                                value={newMenuItemPrice}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^([0-9]{0,8}((.)[0-9]{0,2}))?$/.test(value)) {
                                        if (!e.target.value.includes(';'))
                                            setNewMenuItemPrice(e.target.value);
                                    }
                                }}
                                style={{ margin: '10px' }}
                                size="small"
                            />
                            <TextField
                                InputProps={{ style: { fontFamily: "Poppins" }, maxLength: 100, readOnly: !isEdit }}
                                InputLabelProps={{ style: { fontFamily: "Poppins" } }}
                                label="Enter prepration time (minutes)"
                                required
                                className={isEdit ? "" : classesDetails.readonlyField}
                                value={newMenuItemTime}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d{0,15}$/.test(value)) {
                                        if (!e.target.value.includes(';'))
                                            setNewMenuItemTime(e.target.value);
                                    }
                                }}
                                style={{ margin: '10px' }}
                                size="small"
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                disabled={!isEdit}
                                variant="contained"
                                sx={{ margin: '10px' }}
                                className={isEdit ? classes.styledButton : classes.disabledButton}
                                onClick={addNewService}>
                                Add Menu item
                            </Button>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {isEdit ?
                                <Button id="sign-in-button" size="small" fullWidth variant="outlined" sx={{
                                    "&:hover": { borderColor: 'red' },
                                    color: 'red', border: '1px solid red', mt: 3, height: "50px", borderRadius: "5px", width: '30%'
                                }} onClick={handleUpdate}>
                                    Save Menu
                                </Button> :
                                <Button id="sign-in-button" size="small" fullWidth variant="contained" className={classes.styledButton} sx={{ mt: 3, height: "50px", borderRadius: "5px", width: '30%' }} onClick={() => setIsEdit(!isEdit)}>
                                    Edit
                                </Button>}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ overflow: 'scroll', maxHeight: '75vh' }}>
                        {menuItems?.map((item, index) => (
                            <ServiceItem
                                key={index}
                                item={item}
                                isCheck={menuItems.some((service) => service.split(';')[0] === item.split(';')[0])}
                                onClickDelete={() => {
                                    if (!isEdit) return;
                                    setMenuItems(menuItems.filter((service) => service !== item));
                                    let i = Object.keys(menuItemsV2).find(el => {
                                        return menuItemsV2[el].name === item.split(';')[0]
                                    });
                                    let m = menuItemsV2;
                                    delete m[i];
                                    setMenuItemsV2(m);

                                }}
                            />
                        ))}
                    </Grid>
                </Grid>
                {changePrice && <MenuItemPriceSelect setItem={setChangePrice} item={changePrice} setMenuItems={setMenuItems} />}
            </Box>
        </Grow>
    );
};
