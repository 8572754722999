import { Button, Grid, Snackbar, Chip, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomerCard from "./CustomerCard";
import * as businessActions from "../../redux/actions/Business";

const CRM = () => {
  const { businessData } = useSelector((state) => state.authReducer);
  const { customers } = useSelector((state) => state.businessReducer);
  const dispatch = useDispatch();

  const [fetchedData, setFetchedData] = useState([]);
  const [customersArr, setCustomersArr] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [customerToBeRemoved, setCustomerToBeRemoved] = useState({});
  const [query, setQuery] = useState('');

  //fetching customers
  useEffect(() => {
    dispatch(businessActions.getCustomers(businessData?.uid));
  }, [businessData, dispatch]);

  useEffect(() => {
    setCustomersArr(customers);
    setFetchedData(customers);
  }, [customers]);

  //delete customer from customerArr
  const removeWalkinHandler = (index) => {
    let tempCustomersArr = [...customersArr];
    tempCustomersArr.splice(index, 1);
    setSnackbarOpen(true);
    setCustomerToBeRemoved({ ...customersArr[index] });
    setCustomersArr(tempCustomersArr);
  };
  //delete customer form DB
  const deleteFromDB = async () => {
    dispatch(businessActions.deleteCustomer(customerToBeRemoved, businessData?.uid));
    dispatch(businessActions.updateCustomers(customersArr));
    setSnackbarOpen(false);
    setFetchedData(customersArr);
  };

  return (
    <div>
      <TextField size="small" sx={{ margin: "2rem 0rem 0rem 2rem", width: '300px' }} label="Search Customers" onChange={(e) => setQuery(e.target.value)} />
      <Grid container sx={{ padding: "2rem 2rem" }} spacing={3}>
        {customersArr.length === 0 ? (
          <Chip label="No Customers !" style={{ margin: "auto", marginTop: "40px" }} />
        ) : (
          customersArr.filter((customer) => customer?.name?.toLowerCase().includes(query.toLocaleLowerCase())).map((customer, ind) => {
            return (
              <CustomerCard
                key={ind}
                customer={customer}
                removeHandler={() => {
                  removeWalkinHandler(ind);
                }}
              />
            );
          })
        )}
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={deleteFromDB}
        message="Item removed"
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              setCustomersArr(fetchedData);
              setSnackbarOpen(false);
            }}
          >
            Undo
          </Button>
        }
      />
    </div>
  );
};

export default CRM;
