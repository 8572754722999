import React, { useEffect, useState } from "react";
import { Grid, Box, Button, Checkbox, Typography, Grow, FormControl, Select, InputLabel, MenuItem, TextField, IconButton, FormGroup, FormControlLabel, Switch, Tooltip } from "@mui/material";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { useStyles } from "../Timing/styles";
import { useDispatch, useSelector } from "react-redux";
import { updateBusinessDetails } from "../../../../redux/actions/Auth";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ServiceTimeSelect } from '../../../Signup/Business/serviceTimeSelect';
export const Services = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { businessData } = useSelector((state) => state.authReducer);
  const [category, setCategory] = useState(businessData.category);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableServices, setAvailableServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState(businessData?.services?.split(",") || []);
  const [newService, setNewService] = useState("");
  const [changeServiceTime, setChangeServiceTime] = useState(null);
  const [atleastOneServiceEnabled, setAtleastOneServiceEnabled] = useState(businessData?.atleastOneServiceEnabled);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const remoteConfig = getRemoteConfig();
    remoteConfig.settings.minimumFetchIntervalMillis = 1000;
    remoteConfig.defaultConfig = {
      categories: "Doc,Sal",
    };
    fetchAndActivate(remoteConfig)
      .then(() => {
        const val = getValue(remoteConfig, "categories");
        setAvailableCategories(val._value.split(","));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const addNewService = (event) => {
    const serviceName = newService.trim();
    if (serviceName && !availableServices.some(s => s.split(';')[0] === serviceName)) {
      setAvailableServices([...availableServices, `${serviceName};30`]);
      setSelectedServices([...selectedServices, `${serviceName};30`]);
    }
    setNewService("");
  };

  useEffect(() => {
    const remoteConfig = getRemoteConfig();
    remoteConfig.settings.minimumFetchIntervalMillis = 1000;
    let defObj = category;
    remoteConfig.defaultConfig = {
      defObj: "Doc,Sal",
    };
    fetchAndActivate(remoteConfig)
      .then(() => {
        const val = getValue(remoteConfig, defObj);
        if (category === businessData?.category) {
    
          let temp2 = businessData?.services?.split(",").map((i) => i.trim());

          setAvailableServices([...temp2].filter((item) => item !== ""));
          setSelectedServices([...temp2].filter((item) => item !== ""));
        } else {
          setAvailableServices([...val?._value?.split(",")].filter((item) => item !== "").map((item) => item.trim() + ";30"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    if (selectedServices.length !== 0 && availableServices.length !== 0) {
      setSelectedServices(availableServices.filter((item) => selectedServices.some((item2) => item.split(";")[0] === item2.split(";")[0])));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableServices]);

  const handleUpdate = () => {
    dispatch(
      updateBusinessDetails({
        ...businessData,
        category: category,
        services: selectedServices.toString(),
        atleastOneServiceEnabled: atleastOneServiceEnabled
      })
    );
    setIsEdit(!isEdit);
  };
  const ServiceItem = ({ item, isCheck, onClickCheckbox, onClickDelete }) => {
    return (
      <>
        <Grid container sx={{ width: "400px", margin: "auto" }}>
          <Grid xs={8} sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox onClick={onClickCheckbox} checked={isCheck} />
            <Typography>{item?.split(";")[0]}</Typography>
          </Grid>
          <Grid xs={2} sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Change Service Time" placement="left">
              <Button onClick={() => { 
                if(isEdit)
                setChangeServiceTime(item);
              }}>
                {item?.split(";")[1]} Min
              </Button>
            </Tooltip>
            <Tooltip title="Delete Service" placement="right">
              <IconButton>
                <DeleteOutlineIcon onClick={onClickDelete} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <Grow in>
      <Box component="form" noValidate sx={{ width: "100%" }}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ fontFamily: "Poppins" }} id="demo-simple-select-label">
                Category
              </InputLabel>
              <Select
                readOnly={!isEdit}
                style={{ fontFamily: "Poppins" }}
                id="category"
                value={category}
                label="Category"
                name="category"
                onChange={(event) => {
                  setCategory(event.target.value);
                }}
              >
                {availableCategories.map((i) => (
                  <MenuItem style={{ fontFamily: "Poppins" }} key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12}>
            <div style={{ margin: "auto" }}>
              <p style={{ textAlign: "center" }}>Please select the services clients should see when taking appointment/walk-in. You can add new services as well.</p>
              {availableServices?.map((item, index) => (
                <ServiceItem
                  key={index}
                  item={item}
                  isCheck={selectedServices.some((service) => service.split(';')[0] === item.split(';')[0])}
                  onClickCheckbox={(e) => {
                    if(!isEdit) return;
                    if(e.target.checked) {
                      setSelectedServices([...selectedServices, item]);
                    }
                    else {
                      const itemName = item.split(';')[0];
                      setSelectedServices(selectedServices.filter((service) => service.split(';')[0] !== itemName));
                    }
                  }}
                  onClickDelete={() => {
                    if(!isEdit) return;
                    setAvailableServices(availableServices.filter((service) => service !== item));
                    setSelectedServices(selectedServices.filter((service) => service !== item));
                  }}
                />
              ))}
            </div>
          </Grid>
          <Grid item container direction={"row"} justifyContent={"center"} alignItems="center">
            <TextField
              InputProps={{ style: { fontFamily: "Poppins" }, maxLength: 100,readOnly:!isEdit }}
              InputLabelProps={{ style: { fontFamily: "Poppins" } }}
              label="Enter New Service"
              value={newService}
              onChange={(e) => {
                if(!e.target.value.includes(';'))
                    setNewService(e.target.value);
              }}
              size="small"
            />
            <Button variant="contained" sx={{ marginLeft: "1rem" }} className={classes.styledButton} onClick={addNewService}>
              Add New Service
            </Button>
          </Grid>
          <Grid
            item
            container
            direction={"row"}
            justifyContent={"center"}
            alignItems="center"
          >
            <FormGroup>
              <FormControlLabel
                control={<Switch
                  checked={atleastOneServiceEnabled}
                  onChange={() => {
                    if(isEdit) setAtleastOneServiceEnabled(!atleastOneServiceEnabled);
                  }}
                />}
                label={<p style={{ textAlign: "center" }}>Customer must select at-least one service to take appointment/walk-in</p>}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} alignItems={"center"} sx={{ marginTop: "1rem" }}>
          <Grid item>
            {isEdit ?
              <Button id="sign-in-button" size="large" fullWidth variant="contained" className={classes.styledButton} sx={{ mt: 3, height: "50px", borderRadius: "5px" }} onClick={handleUpdate}>
                Update
              </Button> :
              <Button id="sign-in-button" size="large" fullWidth variant="contained" className={classes.styledButton} sx={{ mt: 3, height: "50px", borderRadius: "5px" }} onClick={() => setIsEdit(!isEdit)}>
                Edit
            </Button>}
          </Grid>
        </Grid>
        {changeServiceTime && <ServiceTimeSelect
                classes={classes}
                item={changeServiceTime}
                setItem={setChangeServiceTime}
                availableServices={availableServices}
                setAvailableServices={setAvailableServices} />}
      </Box>
    </Grow>
  );
};
