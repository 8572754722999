import { memo, useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { Box } from './Box.js'
import { ItemTypes } from './ItemTypes.js'
import { getImageForCapacity } from '../../../utils/getImageForTable';

function getStyles(left, top, isDragging) {
  const transform = `translate3d(${left}px, ${top}px, 0)`
  return {
    position: 'absolute',
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
  }
}
export const DraggableBox = memo(function DraggableBox(props) {
  const { id, title, left, top, image, isOnScreen, setBoxes, size, capacity, type, rotate, handleSaveFlow } = props
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { id, left, top, title, image, size, capacity, type, rotate },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top, title, rotate],
  )
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])
  return (
    <div
      ref={drag}
      style={getStyles(left, top, isDragging)}
      role="DraggableBox"
    >
      <Box handleSaveFlow={handleSaveFlow} capacity={capacity} rotate={rotate} id={id} title={title} image={getImageForCapacity(type, capacity)} isOnScreen={isOnScreen} setBoxes={setBoxes} top={top} size={size} />
    </div>
  )
})
