import React, { useState, useEffect } from 'react';
import {
    Button, CssBaseline, Grid, Box,
    Typography, Container, createTheme, ThemeProvider,
    CircularProgress, Grow, TextField, Select, FormControl, InputLabel,
    MenuItem, OutlinedInput, RadioGroup, FormControlLabel, Radio,
    Snackbar
} from '@mui/material';
import { useStyles, MenuProps, getStyles } from './styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import axios from 'axios';
import AboutDialog from '../../../components/AboutDialog';

//Firebase
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { getDatabase } from "firebase/database";
//Components
import { SideIntro, Header } from '../../../components/SideIntro';
import OTPVerification from '../../../components/OTPVerification';
import MuiAlert from "@mui/material/Alert";
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';

const theme = createTheme();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MobileLogin({ location }) {
    const classes = useStyles();
    const { businessData } = location.state;

    const [countryCode, setCountryCode] = useState('us');
    const [otpData, setOTPData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedServices, setSelectedServices] = useState([]);
    const [visitType, setVisitType] = useState('walkin');
    const [open, setOpen] = useState(false);
    const [snackbarData, setSnackBarData] = useState(null);

    useEffect(() => {
        axios.get('https://1.1.1.1/cdn-cgi/trace').then((response) => {
            setCountryCode(response?.data?.split("\n")?.filter(el => el?.startsWith("loc"))?.pop()?.split('=')[1]?.toLowerCase())
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const data = {
            name: new FormData(event.currentTarget).get('name'),
            contact: new FormData(event.currentTarget).get('contact').replace(/([. ,:-])+/g, "").replace(/[{()}]/g, ''),
            note: new FormData(event.currentTarget).get('note'),
            capacity: new FormData(event.currentTarget).get('capacity') ? new FormData(event.currentTarget).get('capacity') : null,
        };
        if ((!data.capacity || data.capacity < 1) && businessData?.category === "Restaurant") {
            setLoading(false);
            setSnackBarData({
                open: true,
                message: "Please enter valid capacity",
                severity: 'error'
            });
            return;
        }
        if (!data.name) {
            setLoading(false);
            setSnackBarData({
                open: true,
                message: "Please enter your name",
                severity: 'error'
            });
            return;
        } else if (selectedServices.length === 0 && businessData.atleastOneServiceEnabled === 'true') {
            setLoading(false);
            setSnackBarData({
                open: true,
                message: "Please select atleast one service",
                severity: 'error'
            });
            return;
        }
        if (getDatabase()) {
            signInWithNumber(data);
        }
    };

    useEffect(() => {
        window.recaptchaVerifier = null
    }, [])

    const signInWithNumber = (data, renderCaptcha = true, resendOtp = false) => {
        //Use resendOtp to show a snack maybe
        if (renderCaptcha && !window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier('cap-contain', {
                size: 'invisible'
            }, getAuth());
        }
        signInWithPhoneNumber(getAuth(), data.contact, window.recaptchaVerifier)
            .then((confirmationResult) => {
                let newData = {
                    userData: data,
                    userQuickLogin: true,
                    businessData: businessData,
                    selectedServices: selectedServices,
                    visitType: visitType,
                    confirmationResult: confirmationResult
                }
                setOTPData(newData);
                setLoading(false);
            }).catch((error) => {
                setSnackBarData({
                    open: true,
                    message: "Please enter valid phone number",
                    severity: 'error'
                });
                setLoading(false);
                if (renderCaptcha) {
                    window.recaptchaVerifier.render().then(function (widgetId) {
                        // eslint-disable-next-line no-undef
                        grecaptcha.reset(widgetId);
                    });
                }
            });
    }
    const handleServicesChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedServices(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const toggleDialog = () => {
        setOpen(open => !open);
    }

    return (
        <ThemeProvider theme={theme}>
            <Header />
            <Grid container sx={{ pb: '25px', minHeight: "calc(100vh - 175px)", display: "flex", alignItems: "center" }}>
                <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Container component="main" maxWidth='sm'>
                        <SideIntro sideTitle={'Welcome to iQueue'} sideSubTitle={"Please fill the required details"} />
                    </Container>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography component="h1" variant="h5" style={{ fontFamily: 'Poppins', textAlign: 'center' }}>
                                {businessData.name}<br></br>
                                <span style={{ fontSize: '16px' }}>iQueue quick walk-In</span>
                            </Typography>
                            {!otpData &&
                                <Grow in>
                                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, width: '100%' }}>
                                        <TextField
                                            name="name"
                                            required
                                            fullWidth
                                            inputProps={{ style: { fontFamily: 'Poppins' } }}
                                            InputLabelProps={{ style: { fontFamily: 'Poppins' } }}
                                            id="name"
                                            label="Name"
                                            autoFocus
                                            sx={{ marginBottom: '15px' }}
                                        />
                                        {businessData?.category === "Restaurant" && <TextField
                                            name="capacity"
                                            type="number"
                                            required
                                            InputProps={{
                                                inputProps: { min: 1 }
                                            }}
                                            fullWidth
                                            inputProps={{ style: { fontFamily: 'Poppins' } }}
                                            InputLabelProps={{ style: { fontFamily: 'Poppins' } }}
                                            id="capacity"
                                            label="Capacity"
                                            autoFocus
                                            sx={{ marginBottom: '15px' }}
                                        />}
                                        <PhoneInput
                                            countryCodeEditable={false}
                                            country={countryCode}
                                            preferredCountries={['in', 'us', 'ca']}
                                            inputStyle={{ fontFamily: 'Poppins', width: '100%' }}
                                            inputProps={{
                                                id: "contact",
                                                label: "Mobile Number",
                                                name: "contact",
                                            }}
                                        />
                                        {businessData?.category !== "Restaurant" && <FormControl sx={{ width: '100%', marginTop: '15px' }}>
                                            <InputLabel id="servicesLabel">{`Service ${businessData.atleastOneServiceEnabled === "true" ? '*' : ''}`}</InputLabel>
                                            <Select
                                                labelId="services"
                                                id="services"
                                                multiple
                                                value={selectedServices}
                                                onChange={handleServicesChange}
                                                input={<OutlinedInput label={`Service ${businessData.atleastOneServiceEnabled === "true" ? '*' : ''}`} />}
                                                MenuProps={MenuProps}
                                                required={businessData.atleastOneServiceEnabled === "true" ? true : false}
                                            >
                                                {businessData.services.split(',').map((name) => (
                                                    <MenuItem
                                                        key={name}
                                                        value={name}
                                                        style={getStyles(name, selectedServices, theme)}
                                                    >
                                                        {name.split(';')[0]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>}
                                        <TextField
                                            name="note"
                                            fullWidth
                                            inputProps={{ style: { fontFamily: 'Poppins' } }}
                                            InputLabelProps={{ style: { fontFamily: 'Poppins' } }}
                                            id="note"
                                            label="Note"
                                            autoFocus
                                            sx={{ marginTop: '15px' }}
                                        />
                                        {businessData?.category !== "Restaurant" && <FormControl style={{ marginTop: '10px' }}>
                                            <RadioGroup
                                                row
                                                name="visit-type"
                                                value={visitType}
                                                onChange={(event) => setVisitType(event.target.value)}
                                            >
                                                <FormControlLabel value="walkin" control={<Radio />} label="Walk In" />
                                                <FormControlLabel value="video" control={<Radio />} label="Video Visit" />
                                            </RadioGroup>
                                        </FormControl>}
                                        <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
                                            <Typography style={{ fontFamily: 'Poppins', fontSize: '13px' }}>
                                                By clicking "NEXT" you agree with our <br />
                                                <span onClick={toggleDialog} style={{ color: PRIMARY_COLOR, cursor: 'pointer' }}>terms & conditions</span>
                                                &nbsp;&nbsp;and&nbsp;&nbsp;
                                                <a href="https://iqueue365.com/policies/privacy.html" target="_blank" rel="noreferrer" style={{ color: PRIMARY_COLOR, textDecoration: 'none' }}>privacy policy</a>
                                            </Typography>
                                            <AboutDialog open={open} toggleDialog={toggleDialog} typeOfDialogBox={1} />
                                        </div>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            disabled={loading}
                                            variant="contained"
                                            className={classes.styledButton}
                                            style={{ height: '50px', borderRadius: '5px' }}
                                            sx={{
                                                mt: 2, mb: 2, background: PRIMARY_COLOR, "&:hover": {
                                                    backgroundColor: PRIMARY_COLOR
                                                }, "&:disabled": {
                                                    color: 'white',
                                                    backgroundColor: PRIMARY_COLOR
                                                }
                                            }}
                                        >
                                            {loading ? <><CircularProgress style={{ color: 'white', float: 'left', width: '20px', height: '20px' }} /> <span style={{ marginLeft: '10px' }}>Confirming</span></> : 'Check In'}
                                        </Button>
                                        <Typography variant="subtitle2" gutterBottom component="div" sx={{ color: '#666' }}>
                                            <b>Note :</b> Standard message or data rates may apply, and this text message may
                                            count towards any messaging limit set in your mobile plan. Check with your mobile
                                            provider for further information.
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs />
                                            <Grid item>
                                                {//Maybe Android Icons
                                                }
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grow>}
                            {otpData && <OTPVerification
                                data={otpData}
                                loading={loading}
                                setLoading={setLoading}
                                signInWithNumber={signInWithNumber} />}
                        </Box>
                        <div id="cap-contain" />
                    </Container>
                </Grid>
                {snackbarData && snackbarData.open && (
                    <Snackbar open={snackbarData.open} autoHideDuration={3000} onClose={() => setSnackBarData(null)}>
                        <Alert severity={snackbarData.severity}>{snackbarData.message}</Alert>
                    </Snackbar>
                )}
            </Grid>
        </ThemeProvider>
    );
}