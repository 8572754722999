import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
//Routes
import { PublicRoute } from "./publicRoute";
import { AuthRouteBusiness, AuthRouteUser, AuthRouteSetup } from "./authRoute";
//Pages
import {
  UserSignup, UserLogin, UserHome, BusinessSignup,
  BusinessLogin, BusinessHome, NotFound, ExternalVideoCallUser,
  ExternalVideoCallBusiness, MobileAuthCheck, MobileLogin, ViewToken
} from "../pages";
import { Subscription } from "../pages/Subscription";
import ExternalRestaurnt from "../pages/MobileOnly/Restaurant";
// import UserHomePage from "../pages/Home/User/HomePage/HomePage";

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/business/login" />
      </Route>
      {/*Public Routes (Only when user is not logged in)*/}
      <PublicRoute exact path="/user/login" component={UserLogin} />
      <PublicRoute exact path="/business/login" component={BusinessLogin} />
      {/*Protected routes (Only when user is logged in)*/}
      {/*Account Setup Routes (When you have auth, but no db data*/}
      <AuthRouteSetup exact path="/user/signup" component={UserSignup} />
      <AuthRouteSetup exact path="/business/signup" component={BusinessSignup} />
      {/*User Routes*/}
      <AuthRouteUser exact path="/user/home" component={UserHome} />
      {/* <AuthRouteUser exact path="/user/homepage" component={UserHomePage} /> */}
      {/*Business Routes*/}
      <AuthRouteBusiness exact path="/business/home" component={BusinessHome} />
      <AuthRouteBusiness exact path="/business/subscription" component={Subscription} />
      {/*VideoCall (No auth because it will get redirected)*/}
      <Route exact path="/user/external/videocall" component={ExternalVideoCallUser} />
      <Route exact path="/business/external/videocall" component={ExternalVideoCallBusiness} />
      {/*Restaurant on Mobile Route*/}
      <Route exact path="/business/external/restaurant" component={ExternalRestaurnt} />
      {/*Only mobile route*/}
      <Route exact path="/mobile/user/quick-check-in" component={MobileAuthCheck} />
      <Route exact path="/mobile/user/quick-login" component={MobileLogin} />
      <AuthRouteUser exact path="/mobile/user/view-token" component={ViewToken} />
      {/*--------------------*/}
      <Route path="*" component={NotFound} />
    </Switch>
  );
};
