import * as React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { DefaultTabs, RestaurantTabs, FoodTruckTabs } from "./generateTabs";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function BasicTabs({ value, setValue }) {
  const { success } = useSelector((state) => state.authReducer);
  const { businessData } = useSelector((state) => state.authReducer);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTabs = () => {
    switch (businessData.category) {
      case "Restaurant":
        return <RestaurantTabs value={value} handleChange={handleChange} businessData={businessData} />;
      case "Food Truck":
        return <FoodTruckTabs value={value} handleChange={handleChange} businessData={businessData} />;
      default:
        return <DefaultTabs value={value} handleChange={handleChange} businessData={businessData} />;
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      {success && (
        <Snackbar open={true}
          autoHideDuration={3000}>
          <Alert severity='success'>Details updated successfully</Alert>
        </Snackbar>
      )}
      {getTabs()}
    </Box>
  );
}
