import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useDrop } from 'react-dnd'

// import { MainRestaurantCardPlaceHolder } from "../PlaceHolders";
import { ConTest } from "../ConTest";
import { DragTest } from "./DragTest";
import ServerEntriesDrawer from "./ServedEntry";
import animationData from '../../../../assets/lottie/empty_notification.json';
import mealReady from '../../../../assets/lottie/meals_ready.json';
import Lottie from 'react-lottie';
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';
import { PendingDragTest } from "./PendingDragTest";
import { DragTest2 } from "./DragTest2";

const HomePanel = ({ queueList, move }) => {

  const [open, setOpen] = React.useState(false);


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const mealReadyOptions = {
    loop: true,
    autoplay: true,
    animationData: mealReady,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'BOX',
    drop: (item, monitor) => {
      // console.log(item)
      return { name: 'mainQueue' };
    },
  }));

  const [{ canDrop2, isOver2 }, drop2] = useDrop(() => ({
    accept: 'BOX',
    drop: (item, monitor) => {
      // console.log(item)
      return { name: 'readyQueue' };
    },
  }));

  return (
    <Grid container spacing={2} >
      <Grid item xs={2.2}>
        {/* {queueList.length === 0 && <MainRestaurantCardPlaceHolder />} */}
        <Typography noWrap style={{ textAlign: 'center', fontFamily: 'Poppins', fontSize: '12px', marginTop: '24px', fontWeight: '600' }}>
          New entries
        </Typography>
        <div ref={drop} style={{ marginTop: '10px', height: '80vh', maxHeight: '70vh', overflow: 'scroll', border: '1px solid black' }}>
          {queueList.filter((i) => i.status !== 4).length === 0 &&
            <div>
              <Lottie
                options={defaultOptions}
                height={200}
                width={'13vw'}
              />
              <Typography style={{ textAlign: 'center', fontFamily: 'Poppins', fontSize: '12px' }}>
                No entries yet
              </Typography>
            </div>
          }
          {queueList.sort((a, b) => (a.created_timestamp - b.created_timestamp)).map((i, index) => (
            i.status === 2 ?
              <PendingDragTest item={i} key={i.uid} />
              : i.status === 1 ?
                <DragTest move={move} item={i} key={i.uid} /> : null
          ))}
        </div>
      </Grid>
      <Grid item xs={7.5}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: '25px', textAlign: 'left' }}>
              <Typography style={{ textAlign: 'center', fontFamily: 'Poppins', fontSize: '12px', fontWeight: '600' }}>
                In Progress
              </Typography>
            </div>
            <div style={{ marginTop: '10px', textAlign: 'right' }}>
              <Button onClick={() => setOpen(true)}
                variant="outlined" size="small" style={{ color: PRIMARY_COLOR, background: "#fff", border: `1px solid ${PRIMARY_COLOR}` }}>View Served Entries</Button>
            </div>
          </div>
          <ConTest move={move} />
        </div>
      </Grid>
      <Grid item xs={2.2}>
        {/* {queueList.length === 0 && <MainRestaurantCardPlaceHolder />} */}
        <Typography style={{ textAlign: 'center', fontFamily: 'Poppins', fontSize: '12px', marginTop: '24px', fontWeight: '600' }}>
          Ready to go
        </Typography>
        <div ref={drop2} style={{ marginTop: '10px', height: '80vh', maxHeight: '70vh', overflow: 'scroll', border: '1px solid black' }}>
          {queueList.filter((i) => i.status === 4).length === 0 &&
            <div>
              <Lottie
                options={mealReadyOptions}
                height={200}
                width={'13vw'}
              />
              <Typography style={{ textAlign: 'center', fontFamily: 'Poppins', fontSize: '12px' }}>
                No entries yet
              </Typography>
            </div>
          }
          {queueList.sort((a, b) => (a.created_timestamp - b.created_timestamp)).map((i, index) => (
            i.status === 4 ? <DragTest2 move={move} item={i} key={i.uid} /> :
              null
          ))}
        </div>
      </Grid>
      <ServerEntriesDrawer open={open} setOpen={setOpen} queueList={queueList} />
    </Grid >
  );
};

export default HomePanel;
