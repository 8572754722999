import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';

export const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: "black"
    },
    sizeButton: {
        backgroundColor: PRIMARY_COLOR,
        fontSize: '15px',
        color: 'white',
        borderRadius: '80px',
        position: 'absolute',
        textTransform: "none",
        justifyContent: 'center',

    },
    sizeBusyButton: {
        backgroundColor: 'red',
        fontSize: '15px',
        color: 'white',
        borderRadius: '80px',
        position: 'absolute',
        textTransform: "none",
        justifyContent: 'center',

    }
}));