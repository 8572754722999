import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { sendNotificationToken } from "../redux/actions/Business";
import { store } from "../redux/store/configureStore";
import { firebaseConfig } from "../config/firebaseConfig";

export async function getNotificationToken() {
  try {
    if (await isSupported() === true) {
      const messaging = getMessaging();
      const fbConfig = encodeURIComponent(JSON.stringify(firebaseConfig));
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register(`../firebase-messaging-sw.js?firebaseConfig=${fbConfig}`)
          .then((registration) => {
            // console.log(registration)
            getToken(messaging, {
              serviceWorkerRegistration: registration,
              vapidKey:
                process.env.REACT_APP_PUSH_NOTIFICATION_KEY,
            })
              .then((currentToken) => {
                if (currentToken) {
                  // console.log(currentToken);
                  store.dispatch(sendNotificationToken(currentToken));
                  // Send the token to your server and update the UI if necessary
                  // ...
                } else {
                  // Show permission request UI
                  console.log(
                    "No registration token available. Request permission to generate one."
                  );
                  // ...
                }
              })
              .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
                // ...
              });
          })
          .catch((error) =>
            console.log("Service Worker regsitration Failed error: ", error)
          );
      }
    }
  } catch (e) {
    console.log(e);
  }
}
