export const validateEmail = (email) => {
    return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email));
};

export const isValidURL = (myURL) => {
    return (/^(http(s?):\/\/)?(www\.)?[-a-zA-Z0-9]{2,256}\.[a-z]{2,6}$/g).test(myURL);
}

export const validateData = (data, setError) => {
    let isTrue = false;
    if (!data.get('name')?.trim()) {
        setError((value) => ({ ...value, name: { status: true, message: 'Business Name is mandatory' } }));
        isTrue = true;
    }

    if (!data.get('address')?.trim()) {
        setError((value) => ({ ...value, address: { status: true, message: 'Address is mandatory' } }));
        isTrue = true;
    }

    const business_contact_len = data.get("business_contact")?.trim()?.length;
    if (business_contact_len && business_contact_len < 10) {
        setError((value) => ({ ...value, business_contact: { status: true, message: 'Invalid contact number' } }));
        isTrue = true;
    }

    const email = data.get("email")?.trim();
    if(email && !validateEmail(email)) {
        setError((value) => ({ ...value, email: { status: true, message: 'Invalid email address' } }));
        isTrue = true;
    }

    const website = data.get("website")?.trim();
    if(website && !isValidURL(website)) {
        setError((value) => ({ ...value, website: { status: true, message: 'Invalid website URL' } }));
        isTrue = true;
    }

    return isTrue;
}
export const validateDataStep2 = (data, setError) => {
    let isTrue = false;
    if (!data.get('category') || data.get('category') === "Select Category") {
        setError((value) => ({ ...value, category: { status: true, message: 'Category is required' } }));
        isTrue = true;
    }
    if (!data.get('openTime')) {
        setError((value) => ({ ...value, openTime: { status: true, message: 'Open Time is required' } }));
        isTrue = true;
    }
    if (!data.get('closeTime')) {
        setError((value) => ({ ...value, closeTime: { status: true, message: 'Close time is required' } }));
        isTrue = true;
    }
    return isTrue;
}