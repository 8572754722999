import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  TextField,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useStyles } from "../../styles";
//Date picker
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import moment from "moment";
//Actions
import * as consumerActions from "../../../../../redux/actions/Consumer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const serviceItem = (item) => {
  const [name, duration] = item.split(';');
  return (
    <Typography sx={{ width: '100%' }}>
      <Grid container justifyContent="space-between">
        <Grid item alignSelf={"center"}>
          <Typography style={{ fontFamily: "Poppins", fontWeight: 600 }}>{name}</Typography>
        </Grid>
        <Grid item>
          <Button disabled style={{ color: '#1976d2' }}>{duration} Min</Button>
        </Grid>
      </Grid>
    </Typography>
  )
}


export const ServicesConfirmDialog = ({popupData, serviceConfirmDialog, setServiceConfirmDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { businessData } = useSelector((state) => state.authReducer);

  const [date, setDate] = useState(new Date());
  const [selectedServices, setSelectedServices] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [customerContact, setCustomerContact] = useState("");
  const [snackbarData, setSnackBarData] = useState(null);
  
  useEffect(() => {
    date?.setHours(date?.getHours()+1);
    date?.setMinutes(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const handleContactChange = (value) => {
    if (/^\d{0,15}$/.test(value[0] === "+" ? value.slice(1) : value))
      setCustomerContact(value);
  };

  const handleConfirmWalkIn = () => {
    if (!customerName.trim() || !customerContact.trim()) {
      setSnackBarData({
        open: true,
        message: "Please fill in all the required fields.",
        severity: "error",
      });
      return;
    }
    let appointmentDate = moment(date);
    if (
      popupData.entryType === 1 &&
      (!appointmentDate.isValid() || appointmentDate < moment(new Date()))
    ) {
      setSnackBarData({
        open: true,
        message: "Please select a valid date and time",
        severity: "error",
      });
      return;
    }

    if (popupData.entryType === 0) {
      appointmentDate = moment(new Date());
    }

    const leaveDays = businessData?.leave_days?.split(",");
    let onLeaveDays = false;
    if (
      leaveDays && leaveDays?.length > 0 &&
      appointmentDate >= moment(Number(leaveDays[0])) &&
      appointmentDate <= moment(Number(leaveDays[1]) + 86400000)
    ) {
      onLeaveDays = true;
    }
    if (onLeaveDays) {
      setSnackBarData({
        open: true,
        message: popupData.entryType === 1 ? "Can not book appointment on leave days." : "Can not book walk-in on leave days.",
        severity: "error",
      });
      return;
    }

    let dataToAdd = {
      ...businessData,
      type: "MANUAL",
      title: businessData.name,
      entryType: popupData.entryType,
      services: selectedServices?.toString(),
      customerData: {
        name: customerName,
        contact: customerContact,
      },
    };
    dispatch(
      consumerActions.joinQueue({
        businessData: {
          ...dataToAdd,
          created_timestamp: popupData.entryType === 1 ? new Date(date).getTime() : new Date().getTime(),
        },
      })
    );
    setServiceConfirmDialog(false);
  };
  const handleServicesChange = (event) => {
    if (event.target.checked) {
      setSelectedServices((val) => [...val, event.target.name]);
    } else {
      setSelectedServices((val) =>
        val?.services?.filter((i) => i !== event?.target?.name)
          ? val?.services?.filter((i) => i !== event?.target?.name)
          : []
      );
    }
  };
  return (
    <Dialog fullWidth open={serviceConfirmDialog}>
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          sx={{ fontSize: "18px", flexGrow: 1, fontFamily: "Poppins" }}
        >
          {popupData?.entryType ? "Add appointment" : "Add walk-in"}
          <br></br>
          <Typography style={{ fontFamily: "Poppins", fontSize: "12px" }}>
            Select services and confirm
          </Typography>
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {popupData?.entryType ? (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Select Date and Time"
                  value={date}
                  minDate={moment(new Date())}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                />
              </LocalizationProvider>
            ) : null}
            {popupData?.entryType === 1 && <br></br>}
            <TextField
              style={{ width: "48%" }}
              margin="normal"
              required
              inputProps={{ maxLength: 30 }}
              id="customerName"
              label={`Customer Name`}
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              name="customerName"
              autoFocus
            />
            <TextField
              style={{ width: "48%", marginLeft: "15px" }}
              margin="normal"
              required
              type="text"
              id="customerContact"
              name="customerContact"
              label={`Customer Contact`}
              value={customerContact}
              onChange={(e) => handleContactChange(e.target.value)}
            />
            {((businessData?.services.length>0)?
            <FormGroup>
              {businessData?.services?.split(",").map((item, index) => (
                <FormControlLabel
                  key={item}
                  sx={{ width: '100%' }}
                  control={
                    <Checkbox
                      checked={selectedServices?.find((i) => i.name === item)}
                      name={item}
                      onChange={handleServicesChange}
                    />
                  }
                  label={serviceItem(item)}
                />
              ))}
            </FormGroup>
            :"")}
          </Grid>
        </Grid>
        <Divider />
        <Button
          variant="outlined"
          className={classes.popupButton}
          style={{ marginTop: "15px" }}
          onClick={handleConfirmWalkIn}
        >
          Confirm {popupData?.entryType ? "Appointment" : "Walk In"}
        </Button>
        <Button
          variant="outlined"
          className={classes.popupButton}
          style={{ marginTop: "15px", marginLeft: "10px" }}
          onClick={() => setServiceConfirmDialog(false)}
        >
          Cancel
        </Button>
      </DialogContent>
      {snackbarData && snackbarData.open && (
        <Snackbar
          open={snackbarData.open}
          autoHideDuration={3000}
          onClose={() => setSnackBarData(null)}
        >
          <Alert severity={snackbarData.severity}>{snackbarData.message}</Alert>
        </Snackbar>
      )}
    </Dialog>
  );
};
