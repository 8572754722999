import * as React from 'react';
import { Typography, Paper, Fade, Grow, Divider } from '@mui/material';
import Popper from '@mui/material/Popper';
import { useSelector } from "react-redux";

export default function NotificationPopOver({ anchorEl }) {
    const { notifications } = useSelector((state) => state.businessReducer);
    return (
        <Popper open={anchorEl != null} anchorEl={anchorEl} placement='auto' style={{ zIndex: 2}}>
            {({ TransitionProps }) => (
                <Paper style={{ marginTop: 20, marginRight: 80, width: "90%", maxHeight: '50vh', overflow: 'scroll', maxWidth: '30vw' }}>
                    {notifications.sort(function (x, y) {
                        return y.timestamp - x.timestamp;
                    }).map((noti, index) => {
                        if (noti.title) {
                            return (<div key={noti?.uid + index} style={{ padding: 10 }}>
                                <div style={{ display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
                                    <div>
                                        <Typography style={{ maxWidth: '15vw', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '600' }}>{noti?.title}</Typography>
                                        <Typography style={{ maxWidth: '15vw', fontFamily: 'Poppins', fontSize: '13px', marginBottom: '10px' }} >{noti?.text}</Typography>
                                    </div>
                                    <div>
                                        <Typography style={{ fontFamily: 'Poppins', fontSize: '11px' }}>{new Date(noti?.timestamp).toLocaleTimeString('en-us', {
                                            year: 'numeric', month: 'short', day: 'numeric',
                                            hour: '2-digit', minute: '2-digit'
                                        })}</Typography>
                                    </div>
                                </div>
                                <Divider />
                            </div>)
                        } else {
                            return null;
                        }
                    })}
                </Paper>
            )
            }
        </Popper >
    );
}