import React, { useState } from 'react';

import {
    Button, TextField, Grid, Box, CircularProgress, Typography
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import AboutDialog from '../../../components/AboutDialog';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';

export const GeneralInfoStep = ({ nextStep, consolidateData, error, setError, loading, classes }) => {
    const [open, setOpen] = useState(false);

    const toggleDialog = () => {
        setOpen(open => !open);
    }

    return (
        <Box component="form" noValidate onSubmit={nextStep} sx={{ mt: 4, }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        defaultValue={consolidateData?.name}
                        error={error?.name?.status}
                        name="name"
                        required
                        fullWidth
                        helperText={error?.name?.status && error?.name?.message}
                        inputProps={{ style: { fontFamily: 'Poppins' } }}
                        InputLabelProps={{ style: { fontFamily: 'Poppins' } }}
                        id="name"
                        label="Full Name"
                        autoFocus
                        onChange={() => setError((value) => ({ ...value, name: { status: false } }))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PhoneInput
                        countryCodeEditable={false}
                        country={'in'}
                        value={localStorage.getItem('contact') ? localStorage.getItem('contact') : consolidateData?.contact}
                        preferredCountries={['in', 'us', 'ca']}
                        style={{ marginTop: '16px' }}
                        inputStyle={{ fontFamily: 'Poppins', width: '400px' }}
                        inputProps={{
                            id: "contact",
                            label: "Mobile Number",
                            name: "contact",
                            readOnly: true
                        }}
                    />
                </Grid>
            </Grid>
            <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '13px' }}>
                    By clicking "NEXT" you agree with our <br />
                    <span onClick={toggleDialog} style={{ color: PRIMARY_COLOR, cursor: 'pointer' }}>terms & conditions</span>
                    &nbsp;&nbsp;and&nbsp;&nbsp;
                    <a href="https://iqueue365.com/policies/privacy.html" target="_blank" rel="noreferrer" style={{ color: PRIMARY_COLOR, textDecoration: 'none' }}>privacy policy</a>
                </Typography>
                <AboutDialog open={open} toggleDialog={toggleDialog} typeOfDialogBox={1} />
            </div>
            <div style={{ textAlign: 'center' }}>
                <Button
                    id="sign-in-button"
                    type="submit"
                    variant="contained"
                    className={classes.styledButton}
                    style={{ height: '50px', width: '100%', borderRadius: '5px' }}
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}
                >
                    {loading ? <><CircularProgress style={{ color: 'white', float: 'left', width: '20px', height: '20px' }} /> <span style={{ marginLeft: '10px' }}>Working on it</span></> : 'Next'}
                </Button>
            </div>
            <div id="cap-contain" />
        </Box>)
}