//suraj config file
// export const firebaseConfig = {
//   apiKey: "AIzaSyD7HFR5Uos2W5DSx5ppKIuWA1dCdmZbHn8",
//   authDomain: "iqueue-333fb.firebaseapp.com",
//   databaseURL: "https://iqueue-333fb-default-rtdb.firebaseio.com",
//   projectId: "iqueue-333fb",
//   storageBucket: "iqueue-333fb.appspot.com",
//   messagingSenderId: "351810926622",
//   appId: "1:351810926622:web:116f1ea162925e214a560f",
//   measurementId: "G-75L3C9EVR5",
// };

// Pranav's config file
// export const firebaseConfig = {
//   apiKey: "AIzaSyCksrCiEBSjqs-udZtnqAJFS4j9ty4xL0g",
//   authDomain: "iqueue-7c004.firebaseapp.com",
//   databaseURL: "https://iqueue-7c004-default-rtdb.firebaseio.com",
//   projectId: "iqueue-7c004",
//   storageBucket: "iqueue-7c004.appspot.com",
//   messagingSenderId: "841426499918",
//   appId: "1:841426499918:web:2572e93f9336bae259061f",
//   measurementId: "G-PPN6H6M23S"
// };
// export const mapApiKey = {
//   apiKey: "AIzaSyCksrCiEBSjqs-udZtnqAJFS4j9ty4xL0g",
// }

// Mohit Config
// export const firebaseConfig = {
//   apiKey: "AIzaSyCH4kMYEcniuzcWCOejblksFFpt0p1Qzqo",
//   authDomain: "iqueue-d38a6.firebaseapp.com",
//   databaseURL: "https://iqueue-d38a6-default-rtdb.firebaseio.com",
//   projectId: "iqueue-d38a6",
//   storageBucket: "iqueue-d38a6.appspot.com",
//   messagingSenderId: "557476048145",
//   appId: "1:557476048145:web:315592547c66b9d843f0b0",
//   measurementId: "G-87N9S9MFVD"
// };

// Deepak's config file
// export const firebaseConfig = {
//   apiKey: "AIzaSyAiDcfqnO48pjUKwib5u61Xn-Jw0sJmCcM",
//   authDomain: "iqueue-bb56d.firebaseapp.com",
//   databaseURL: "https://iqueue-bb56d-default-rtdb.firebaseio.com",
//   projectId: "iqueue-bb56d",
//   storageBucket: "iqueue-bb56d.appspot.com",
//   messagingSenderId: "704127042086",
//   appId: "1:704127042086:web:bf1d08ed596d965d3c5ce7",
//   measurementId: "G-W24EQ3TRHX",
// };

//Ashwani's config file
// export const firebaseConfig = {
//   apiKey: "AIzaSyB7GYXJXVZkgnh0alKuPtTHdurATjG3Dws",
//   authDomain: "iqueue-bac71.firebaseapp.com",
//   databaseURL: "https://iqueue-bac71-default-rtdb.firebaseio.com",
//   projectId: "iqueue-bac71",
//   storageBucket: "iqueue-bac71.appspot.com",
//   messagingSenderId: "442043311177",
//   appId: "1:442043311177:web:0ed8903b01884ad5134b98",
//   measurementId: "G-2QS6VYM09R"
// };



//Development
// export const firebaseConfig = {
//     apiKey: "AIzaSyD3Kwfue8aoqp0sC_CguSieXa9KrgxTMgI",
//     authDomain: "playground-54d3b.firebaseapp.com",
//     projectId: "playground-54d3b",
//     databaseURL: "https://playground-54d3b-default-rtdb.asia-southeast1.firebasedatabase.app",
//     storageBucket: "playground-54d3b.appspot.com",
//     messagingSenderId: "386324325777",
//     appId: "1:386324325777:web:823b1374c864fd560ea630",
//     measurementId: "G-12D99CDK9K"
// };

// Production
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABSE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// For loading Maps
export const mapApiKey = {
    apiKey: process.env.REACT_APP_FIREBASE_MAP_API_KEY
}