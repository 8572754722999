import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import * as businessActions from "../../../redux/actions/Business";
import HomePanel from "./HomePanel";
import { getNotificationToken } from '../../../utils/pushNotifications';

const DashboardBusiness = () => {
    const dispatch = useDispatch();

    const { businessData } = useSelector((state) => state.authReducer);
    const { employees, iQueues } = useSelector((state) => state.businessReducer);

    const [queueList, setQueueList] = useState(iQueues);
    const [employeeQueueList, setEmployeeQueueList] = useState(null);
    const [date, setDate] = useState(new Date());
    const [highlightedEmployee, setHighlightedEmployee] = useState(null);

    // removes contact from local-storage once user lands on home page
    useEffect(() => {
        localStorage.removeItem('contact');
    }, []);

    useEffect(() => {
        if (navigator?.serviceWorker) {
            getNotificationToken();
        }
    }, [])
    useEffect(() => {
        let availableQueues = iQueues.filter((queue) => queue.status === 1);
        setQueueList(availableQueues);
    }, [iQueues]);

    useEffect(() => {
        if (employees) {
            const tempList = [];
            employees.forEach((employee) => {
                let pushObj = {
                    ...employee,
                    queue: [],
                };
                let availableQueues = iQueues.filter((queue) => queue.status === 0 && queue.service_employee === employee.uid);
                pushObj.queue = availableQueues;
                tempList.push(pushObj);
            });
            setEmployeeQueueList(tempList);
        }
    }, [employees, iQueues]);
    useEffect(() => {
        let date = new Date();
        dispatch(businessActions.enableEmployeeListener());
        dispatch(businessActions.updateResetQueueState());
        dispatch(businessActions.enableQueueListener({ year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }));
    }, [dispatch]);

    const move = (droppableSource, dropId, sourceId) => {
        let checkSourceClone = employeeQueueList.find((item) => item.id === sourceId);
        let destSourceClone = employeeQueueList.find((item) => item.id === dropId);
        //Move back to main queue
        if (checkSourceClone && dropId === "newdd1") {
            const [removed] = checkSourceClone.queue.splice(droppableSource.index, 1);
            dispatch(businessActions.updateQueueElement({ businessData, removed, destClone: checkSourceClone, moveBackToMainQueue: true, status: 1 }, { year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }));
            //TODO : Something efficient (For now just update the whole list by reloading)
            //dispatch(businessActions.disableQueueListener({ year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }));
            //dispatch(businessActions.enableQueueListener({ year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }));
            return;
        }
        //Dropped somewhere outside
        if (checkSourceClone && !destSourceClone) {
            return;
        }
        //Move between employees
        if (checkSourceClone && destSourceClone) {
            const [removed] = checkSourceClone.queue.splice(droppableSource.index, 1);
            dispatch(businessActions.updateQueueElement({ businessData, removed, destClone: destSourceClone, moveBetweenEmployees: true, status: 0 }, { year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }));
            return;
        }
        //Move from main to employee queueue
        const [removed] = queueList.splice(droppableSource.index, 1);
        dispatch(businessActions.updateQueueElement({ businessData, removed, destClone: destSourceClone, status: 0 }, { year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }));
        //TODO : Something efficient (For now just update the whole list by reloading)
        //dispatch(businessActions.disableQueueListener({ year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }));
        //dispatch(businessActions.enableQueueListener({ year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }));
    };

    const onDragEnd = (result) => {
        setHighlightedEmployee('');
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            return;
        } else {
            move(source, destination.droppableId, source.droppableId);
        }
    };


    const onDragStart = (result) => {
        let suggested_emp = queueList.find((item) => item.uid === result.draggableId)?.suggested_emp;
        if (suggested_emp) {
            setHighlightedEmployee(queueList.find((item) => item.uid === result.draggableId).suggested_emp);
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <HomePanel highlightedEmployee={highlightedEmployee}
                date={date}
                setDate={setDate}
                queueList={queueList}
                employeeQueueList={employeeQueueList} />
        </DragDropContext>
    );
};

export default DashboardBusiness;