import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CssBaseline,
  Grid,
  Box,
  Typography,
  Container,
  ThemeProvider,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { theme, useStyles } from "./styles";
//Custom Stepper and Side Intro
import { QontoConnector, QontoStepIcon } from "./customStepper";
//Data Validators
import { validateData, validateDataStep2 } from "./dataValidators";
//Firebase
import { getDatabase, ref, set } from "firebase/database";
import {
  getStorage,
  uploadBytes,
  getDownloadURL,
  ref as storageRef,
} from "firebase/storage";
//Components
import { SideIntro, Header } from "../../../components/SideIntro";
//Pages
import { BusinessInfoStep } from "./businessInfoStep";
import { ConfirmLocationStep } from "./confirmLocationStep";
import { ServiceInfoStep } from "./serviceInfoStep";
import { ScheduleInfoStep } from "./scheduleInfoStep";
import { OtherSetupSettings } from "./otherSetupSettings";
import * as authActions from "../../../redux/actions/Auth";
import * as businessActions from "../../../redux/actions/Business";
import * as geofire from "geofire-common";
import { OtherSetupSettingsFoodTruck } from "./otherSetupSettingsFoodTruck";

export default function BusinessSignup() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { auth } = useSelector((state) => state.authReducer);
  const [consolidateData, setConsolidateData] = useState({
    name: "",
    contact: "" /*'+91'*/,
    address: null,
    lat: null,
    lng: null,
    city: null,
    state: null,
    postalCode: null,
    email: null,
    website: "",
    openTime: null,
    closeTime: null,
    openDays: "1,1,1,1,1,1,1",
    category: null,
    services: [],
    businessImages: [],
    accept_appointments: true,
    accept_video_visits: false,
    auto_accept: false,
  });

  const [error, setError] = useState({
    name: {
      status: false,
      message: "",
    },
    contact: {
      status: false,
      message: "",
    },
    address: {
      status: false,
      message: "",
    },
    business_contact: {
      status: false,
      message: "",
    },
    email: {
      status: false,
      message: "",
    },
    website: {
      status: false,
      message: "",
    },
    city: {
      status: false,
      message: "",
    },
    state: {
      status: false,
      message: "",
    },
    postalCode: {
      status: false,
      message: "",
    },
    openTime: {
      status: false,
      message: "",
    },
    closeTime: {
      status: false,
      message: "",
    },
    category: {
      status: false,
      message: "",
    },
  });

  const dynamicTitles = [
    "Setup your account",
    "Confirm your location on map",
    "Business Information",
    "Business Information",
    "Business Information",
  ];

  useEffect(() => {
    return (window.recaptchaVerifier = null);
  }, []);

  const nextStep = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let error = validateData(data, setError);
    if (error) {
      return;
    }

    setConsolidateData({
      ...consolidateData,
      name: data.get("name"),
      phone_number: data
        .get("contact")
        .replace(/[. ,:-]+/g, "")
        .replace(/[{()}]/g, ""),
      contact: data
        .get("business_contact")
        .replace(/[. ,:-]+/g, "")
        .replace(/[{()}]/g, ""),
      email: data.get("email"),
      address: data.get("address"),
      website: data.get("website"),
      // category: 'First',
      lat: consolidateData.lat,
      lng: consolidateData.lng,
      businessImages: consolidateData?.businessImages
        ? consolidateData?.businessImages
        : [],
    });
    setLoading(true);
    if (getDatabase()) {
      setActiveStep(1);
      setLoading(false);
    }
  };
  const nextStep0 = async (event) => {
    event.preventDefault();
    setActiveStep(2);
  };
  const nextStep1 = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let error = validateDataStep2(data, setError);
    if (error) {
      return;
    }
    setConsolidateData({
      ...consolidateData,
      openTime: data.get("openTime"),
      closeTime: data.get("closeTime"),
      category: data.get("category"),
      geoHash: geofire.geohashForLocation([
        consolidateData.lat,
        consolidateData.lng,
      ]),
    });
    if (data.get("category") === "Restaurant") {
      skipAStep({
        ...consolidateData,
        openTime: data.get("openTime"),
        closeTime: data.get("closeTime"),
        category: data.get("category"),
        atleastOneServiceEnabled: false,
        geoHash: geofire.geohashForLocation([
          consolidateData.lat,
          consolidateData.lng,
        ]),
      });
    } else {
      setActiveStep(3);
    }
  };

  const nextStep2 = async (event, settings) => {
    event.preventDefault();
    setConsolidateData({ ...consolidateData, ...settings });
    if (consolidateData.category === "Food Truck") {
      skipAStep({
        ...consolidateData,
        ...settings
      }
      );
    }
    else {
      setActiveStep(4);
    }

  }

  const otpStep = (event) => {
    event.preventDefault();
    let newData = {
      ...consolidateData,
    };
    signInWithNumber(newData);
  };

  const skipAStep = (myData) => {
    let newData = {
      ...myData,
    };
    signInWithNumber(newData);
  };

  const signInWithNumber = (data) => {
    setLoading(true);
    let newData = {
      userData: data,
    };
    //setOTPData(newData);
    saveDataToDB(newData);
    //setActiveStep(3);
  };

  const saveDataToDB = (data) => {
    if (
      data?.userData?.businessImages &&
      data?.userData?.businessImages?.length !== 0
    ) {
      const storage = getStorage();
      const promises = data?.userData?.businessImages?.map((file, index) => {
        const imagesRef = storageRef(
          storage,
          `businessImages/${new Date().getTime()}`
        );
        return uploadBytes(imagesRef, file).then((snapshot) => {
          return getDownloadURL(snapshot.ref).then((downloadURL) => {
            return downloadURL;
          });
        });
      });
      Promise.all(promises)
        .then((uploadedMediaList) => {
          setLoading(false);
          const dbData = {
            ...data?.userData,
            uid: auth?.uid,
            businessImages: uploadedMediaList,
          };
          if (getDatabase()) {
            set(
              ref(
                getDatabase(),
                `iQueue/Public/Businesses/${dbData?.uid}/profile/`
              ),
              dbData
            )
              .then(() => {
                dispatch(authActions.setBusinessLoginData(dbData));
                if (dbData.category === "Restaurant" || dbData.category === "Food Truck") {
                  dispatch(businessActions.updateAddEmployeeState({ name: "TempRestaurantEmployee", contact: "+1111" }));
                }
              })
              .catch((error) => {
                alert(error);
                history.push({
                  pathname: "/business/home",
                });
              });
          }
        })
        .catch((err) => {
          alert(err);
          setLoading(false);
          console.log(err);
        });
    } else {
      setLoading(false);
      const dbData = {
        ...data?.userData,
        uid: auth?.uid,
        create_time: new Date().getTime(),
        services: data?.userData?.services?.toString(),
      };
      if (getDatabase()) {
        set(
          ref(
            getDatabase(),
            `iQueue/Public/Businesses/${dbData?.uid}/profile/`
          ),
          dbData
        )
          .then(() => {
            dispatch(authActions.setBusinessLoginData(dbData));
            //update global analytics
            dispatch(authActions.updateGlobalAnalytics("Businesses"));
            if (dbData.category === "Restaurant" || dbData.category === "Food Truck") {
              dispatch(businessActions.updateAddEmployeeState({ name: "TempRestaurantEmployee", contact: "+1111" }));
            }
          })
          .catch((error) => {
            alert(error);
            history.push({
              pathname: "/business/home",
            });
          });
        const markerData = {
          name: dbData.name,
          geoHash: dbData.geoHash,
          lat: dbData.lat,
          lng: dbData.lng,
        };
        set(
          ref(getDatabase(), `iQueue/Public/MapMarkers/${dbData?.uid}`),
          markerData
        )
          .then(() => { })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Grid
        container
        sx={{
          pb: "25px",
          minHeight: "calc(100vh - 175px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
          <Container component="main" maxWidth="sm">
            <SideIntro
              sideTitle={"Welcome to iQueue Business"}
              sideSubTitle={"Enter your business name, address and website"}
            />
          </Container>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: { md: "-20px" } }}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                component="h3"
                variant="h5"
                style={{ fontFamily: "Poppins", zIndex: 9 }}
              >
                {dynamicTitles[activeStep]}
              </Typography>
              <Box
                sx={{
                  marginTop: 3,
                  width: "620px",
                  display: { xs: "none", md: "none", lg: "none" },
                }}
              >
                <Stepper activeStep={activeStep} connector={<QontoConnector />}>
                  {[
                    "Business Information",
                    "Schedule Information",
                    "Verify OTP",
                  ].map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel
                          {...labelProps}
                          StepIconComponent={QontoStepIcon}
                        >
                          <Typography style={{ fontFamily: "Poppins" }}>
                            {label}
                          </Typography>
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
              {activeStep === 0 && (
                <BusinessInfoStep
                  nextStep={nextStep}
                  consolidateData={consolidateData}
                  error={error}
                  setError={setError}
                  loading={loading}
                  classes={classes}
                  setConsolidateData={setConsolidateData}
                />
              )}
              {activeStep === 1 && (
                <ConfirmLocationStep
                  nextStep0={nextStep0}
                  consolidateData={consolidateData}
                  error={error}
                  setError={setError}
                  loading={loading}
                  classes={classes}
                  setActiveStep={setActiveStep}
                  setConsolidateData={setConsolidateData}
                />
              )}
              {activeStep === 2 && (
                <ScheduleInfoStep
                  nextStep1={nextStep1}
                  consolidateData={consolidateData}
                  error={error}
                  setError={setError}
                  loading={loading}
                  classes={classes}
                  setActiveStep={setActiveStep}
                  setConsolidateData={setConsolidateData}
                />
              )}
              {activeStep === 3 && consolidateData.category !== "Food Truck" && (
                <OtherSetupSettings
                  nextStep2={nextStep2}
                  classes={classes}
                  setActiveStep={setActiveStep}
                  consolidateData={consolidateData}
                />
              )}
              {activeStep === 3 && consolidateData.category === "Food Truck" && (
                <OtherSetupSettingsFoodTruck
                  nextStep2={nextStep2}
                  classes={classes}
                  setActiveStep={setActiveStep}
                  consolidateData={consolidateData}
                />
              )}
              {activeStep === 4 && (
                <ServiceInfoStep
                  otpStep={otpStep}
                  consolidateData={consolidateData}
                  error={error}
                  setError={setError}
                  loading={loading}
                  classes={classes}
                  setActiveStep={setActiveStep}
                  setConsolidateData={setConsolidateData}
                />
              )}
            </Box>
          </Container>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
