import React from "react";
import { Typography, Card, Rating, IconButton, Tooltip } from "@mui/material";
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CancelIcon from '@mui/icons-material/Cancel';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useDispatch } from "react-redux";
import * as businessActions from '../../../../redux/actions/Business';

const QueueCard = ({ item }) => {
    const dispatch = useDispatch()
    const deleteIqueue = () => {
        dispatch(businessActions.deleteIqueue(item));
    }
    if (item.name === "null")
        return (<Card style={{ boxShadow: 'none', margin: '10px', width: '110px', height: '140px', backgroundColor: '#ffffff', borderRadius: '5px' }}></Card>)
    return (
        <Card variant="outlined" style={{
            position: "relative", padding: '7.5px', display: 'flex', margin: '5px',
            width: '110px', height: '140px', backgroundColor: '#ffffff', borderRadius: '5px',
            flexDirection: "column", boxShadow: "2.5px 2.5px 10px rgba(0, 0, 0, 0.15)"
        }}>
            <div style={{ position: "absolute", right: '3px', top: '3px' }}>
                <Tooltip title="Delete Appointment">
                    <IconButton onClick={() => deleteIqueue()} size="small">
                        <CancelIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
            <div style={{ textAlign: "center", marginTop: '3px', width: "100%" }}>
                {item.entry_type === 0 && <DirectionsWalkIcon fontSize="small" />}
                {item.entry_type === 1 && <DateRangeIcon fontSize="small" />}
            </div>
            <div style={{ display: "flex", margin: "1px 0 0 3px" }}>
                <PersonOutlineIcon sx={{ fontSize: 12, marginTop: '2px' }} />
                <Typography noWrap style={{ color: "#555", fontFamily: 'Poppins', fontSize: '10px', marginLeft: '5px' }} align="left" variant="subtitle2" color="text.secondary">
                    {item?.customer_name}
                </Typography>
            </div>
            <div style={{ display: "flex", margin: "1px 0 0 3px" }}>
                <ConfirmationNumberIcon sx={{ fontSize: 12, marginTop: '2px' }} />
                <Typography style={{ color: "#555", fontFamily: 'Poppins', fontSize: '10px', marginLeft: '5px' }} align="left" variant="subtitle2" color="text.secondary">
                    {item?.token}
                </Typography>
            </div>
            <div style={{ display: "flex", margin: "1px 0 0 3px" }}>
                <PlaylistAddCheckIcon sx={{ fontSize: 12, marginTop: '2px' }} />
                <Typography noWrap style={{ color: "#555", fontFamily: 'Poppins', fontSize: '10px', marginLeft: '5px' }} align="left" variant="subtitle2" color="text.secondary">
                    {item?.services ? `${item?.services?.split(',').map((i) => i.split(';')[0]).join(", ")}` : 'No Services'}
                </Typography>
            </div>
            <div style={{ display: "flex", margin: "1px 0 0 3px" }}>
                <AccessTimeIcon sx={{ fontSize: 12, marginTop: '2px' }} />
                <Typography noWrap style={{ color: "#555", fontFamily: 'Poppins', fontSize: '10px', marginLeft: '5px' }} align="left" variant="subtitle2" color="text.secondary">
                    {new Date(parseInt(item?.created_timestamp)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                </Typography>
            </div>

            <div style={{ margin: "6px 0 0 3px" }}>
                <Rating
                    readOnly
                    size="small"
                    name="simple-controlled"
                    value={item?.rating ? item?.rating: 0}
                />
            </div>
        </Card>)
}

export default QueueCard;