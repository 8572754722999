import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
// import { DragDropContext } from "react-beautiful-dnd";
import * as businessActions from "../../../redux/actions/Business";
import HomePanel from "./HomePanel";
import { getNotificationToken } from '../../../utils/pushNotifications';
import * as businessRestaurantActions from "../../../redux/actions/BusinessRestaurant";
import { DndProvider } from 'react-dnd'
// import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { usePreview } from 'react-dnd-preview';
import { Card, Grid, Snackbar, Typography } from '@mui/material';
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const MyPreview = () => {
    const { display, itemType, item, style } = usePreview();
    if (!display) {
        return null;
    }
    return (
        <div className="item-list__item" style={style}>
            <Card
                item={item}
                elevation={0}
                style={{
                    width: '100%',
                    margin: '15px 5px 5px 8px', fontFamily: 'Poppins', fontSize: '12px',
                    padding: '0px 10px 10px 10px',
                    border: '1px solid #A9A9A9',
                    opacity: '0.8',
                }}
            >
                <Grid container spacing={2} >
                    <Grid style={{ textAlign: 'center', marginLeft: '10px' }} item xs={5}>
                        <Typography style={{ fontSize: '13px', color: 'blue', fontFamily: 'Poppins', fontWeight: '600', marginTop: '25px' }}>
                            {item?.token ? item.token : 'NA'}
                        </Typography>
                        <Typography style={{ fontSize: '11px', color: 'black', fontFamily: 'Poppins', fontWeight: '600', marginBottom: '17px' }}>
                            {new Date(item?.created_timestamp).toLocaleTimeString('en-us', {
                                hour: '2-digit', minute: '2-digit'
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontFamily: 'Poppins', fontSize: '10px', marginTop: '10px', fontWeight: '600' }}>
                            {item?.entry_type === 1 ? 'CATERING BY' : 'TAKE-OUT BY'}
                        </Typography>
                        <Typography noWrap style={{ fontFamily: 'Poppins', fontSize: '13px', fontWeight: '600' }}>
                            {item.customer_name}
                        </Typography>
                        <Typography style={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: '600' }}>
                            {item.customer_contact}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container >
                    <Typography style={{
                        // overflow: "hidden",
                        // textOverflow: "ellipsis", display: "-webkit-box",
                        // WebkitLineClamp: "2",
                        // WebkitBoxOrient: "vertical",
                        fontSize: '11px', color: 'black', fontFamily: 'Poppins', fontWeight: '600', marginBottom: '15px'
                    }}>
                        {item?.services ? `${item?.services?.split(',').map((i) => i.split(';')[0]).join(", ")}` : 'No menu items selected'}
                    </Typography>
                </Grid>
            </Card>
        </div>
    );
};

const DashboardFoodTruckBusiness = () => {
    const dispatch = useDispatch();

    const { iQueues } = useSelector((state) => state.businessReducer);

    const [queueList, setQueueList] = useState(iQueues);
    const [showErrorSnack, setShowErrorSnack] = useState(null);

    useEffect(() => {
        dispatch(businessRestaurantActions.loadFloorSetup());
    }, [dispatch, iQueues]);

    // removes contact from local-storage once user lands on home page
    useEffect(() => {
        localStorage.removeItem('contact');
    }, []);

    useEffect(() => {
        if (navigator?.serviceWorker) {
            getNotificationToken();
        }
    }, [])

    useEffect(() => {
        let availableQueues = iQueues.filter((queue) => queue.status === 1 || queue.status === 2 || queue.status === 4);
        setQueueList(availableQueues);
    }, [iQueues]);

    useEffect(() => {
        let date = new Date();
        dispatch(businessActions.updateResetQueueState());
        dispatch(businessActions.enableQueueListener({ year: date.getFullYear(), month: date.getMonth(), date: date.getDate() }));
    }, [dispatch]);

    const move = async (removed, status) => {
        dispatch(businessActions.updateQueueStatus({ ...removed, status: status }));
    };

    return (
        <div>
            <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
                <HomePanel
                    queueList={queueList}
                    move={move} />
                <MyPreview />
            </DndProvider>
            {showErrorSnack && <Snackbar
                open={(showErrorSnack !== null)}
                autoHideDuration={3000}
                onClose={() => setShowErrorSnack(null)}
            >
                <Alert severity="error">{showErrorSnack}</Alert>
            </Snackbar>}
        </div>
    );
};

export default DashboardFoodTruckBusiness;