import React, { useState } from 'react';
import {
    SpeedDial
} from "@mui/material";
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';
import { Cancel, ChatBubble, Close } from '@mui/icons-material';
import ChatBox from './ChatBox';


const SpeedDialMenuChatBot = () => {
    const [enableChat, setEnableChat] = useState(false);

    return (
        <div>
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: 'absolute', bottom: 36, right: 116 }}
                onClick={() => { setEnableChat(!enableChat) }}
                FabProps={{
                    sx: {
                        bgcolor: PRIMARY_COLOR,
                        '&:hover': {
                            bgcolor: PRIMARY_COLOR,
                        }
                    }
                }}
                icon={enableChat ? <Close /> :<ChatBubble />}
            >
            </SpeedDial>
            {enableChat ? <ChatBox /> : null}
        </div>
    );
}

export default SpeedDialMenuChatBot;