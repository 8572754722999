import React, { useState, useCallback } from 'react'
import {
    Button, Dialog, DialogContent,
    DialogTitle, DialogActions, Divider, Typography,
    IconButton,
    TextField
} from "@mui/material";
import { Facebook, Share, Twitter, WhatsApp } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import * as businessCouponActions from "../../redux/actions/BusinessCoupons";
import { useEffect } from 'react';

export const ShareVia = ({ item, setItem }) => {
    const dispatch = useDispatch();
    const { businessData } = useSelector((state) => state.authReducer);

    const [url, setUrl] = useState(item?.downloadURL ? item?.downloadURL : 'Generating');
    const [copied, setCopied] = useState(false);

    const getURL = useCallback(async () => {
        return new Promise((resolve, reject) => {
            dispatch(businessCouponActions.uploadAndGenerateUrl(item, resolve, reject));
        });
    }, [dispatch, item]);

    useEffect(() => {
        const handleShareVia = async () => {
            let res = await getURL();
            setUrl(res);
        }
        if (!item?.downloadURL) {
            handleShareVia();
        }
    }, [getURL, item]);

    const testShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: "Awesome Deals",
                    text: `Hello, check out this great deal at ${businessData?.name} `,
                    url: url,
                })
                .then(() => {
                    console.log("Successfully shared");
                })
                .catch((error) => {
                    console.error("Something went wrong", error);
                });
        }
    }
    return (
        <Dialog
            hideBackdrop
            open={item !== null}
            aria-labelledby="Delete Dialog1"
        >
            <DialogTitle id="responsive-dialog-title">
                Share Via
            </DialogTitle>
            <DialogContent style={{ overFlow: 'auto' }}>
                <div style={{ width: '410px', padding: '6px', height: '200px' }}>
                    <TextField
                        style={{ width: "100%" }}
                        margin="normal"
                        onClick={(e) => {
                            e.target.select();
                            navigator.clipboard.writeText(url);
                            setCopied(true);
                        }}
                        inputProps={{
                            min: 0,
                            max: 100,
                            step: 2,
                            onKeyDown: (event) => {
                                event.preventDefault();
                            },
                            readOnly: true
                        }}
                        id="url"
                        value={url}
                        label={`URL`}
                        name="url"
                    />
                    {copied ? <Typography style={{ marginLeft: '10px', fontFamily: 'Poppins', fontSize: '12px' }}>Copied to clipboard!</Typography>
                        : <Typography style={{ marginLeft: '10px', fontFamily: 'Poppins', fontSize: '12px' }}>Click above to copy!</Typography>}
                    <div style={{ margin: '10px 0px 15px 0px', display: 'flex', justifyContent: 'center' }}>
                        {url ? <IconButton onClick={() => { testShare() }} style={{ color: 'black', background: "#fff" }} size="large">
                            <Share style={{ width: '40px', height: '40px', color: 'black' }} />
                        </IconButton> : null}
                        {/* <IconButton onClick={() => { }} style={{ color: '#4267B2', background: "#fff" }} size="large">
                            <Facebook style={{ width: '40px', height: '40px', color: '#4267B2' }} />
                        </IconButton>
                        <IconButton onClick={() => { }} style={{ color: '#1DA1F2', background: "#fff" }} size="large">
                            <Twitter style={{ width: '40px', height: '40px', color: '#1DA1F2' }} />
                        </IconButton> */}
                    </div>
                    <Divider />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="small"
                    onClick={() => setItem(null)}
                    style={{
                        marginTop: '10px', color: 'red',
                        border: `1px solid red`
                    }}>
                    Cancel
                </Button>
            </DialogActions >
        </Dialog >
    )
}