import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Grow,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as AuthActions from "../../../../redux/actions/Auth";
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';

const DeleteBusiness = () => {
  const [dialog1, setDialog1] = useState(false);
  const [dialog2, setDialog2] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <Grow in>
      <Box sx={{ mt: 3 }}>
        <Grid
          container
          spacing={2}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={6}>
            <DeleteOutlineIcon fontSize="large" sx={{ color: PRIMARY_COLOR }} />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle">
              Delete your Business from iQueue
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              size="large"
              sx={{ height: "50px", borderRadius: "5px", marginTop: "20px" }}
              onClick={() => {
                setDialog1(true);
              }}
              color="error"
            >
              Delete Account
            </Button>
          </Grid>
          {/* Dialog1 */}
          <Dialog
            open={dialog1}
            onClose={() => {
              setDialog1(false);
            }}
            aria-labelledby="Delete Dialog1"
          >
            <DialogTitle id="responsive-dialog-title">
              Delete Account ?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Deleting your account will delete all its data from the system
                and you will not be able to sign-in again with same account
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  setDialog1(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setDialog1(false);
                  setDialog2(true);
                }}
                autoFocus
                color="error"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          {/* Dialog2 */}
          <Dialog
            open={dialog2}
            onClose={() => {
              setDialog2(false);
            }}
            aria-labelledby="Delete Dialog2"
          >
            <DialogTitle id="responsive-dialog-title">
              Are you sure ?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                You are about to delete your account. This action cannot be
                undone. Are you sure you want to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  setDialog2(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setLoading(true);
                  dispatch(AuthActions.deleteBusiness());
                }}
                autoFocus
                color="error"
              >
                {loading ? (
                  <>
                    <CircularProgress
                      style={{
                        color: "red",
                        float: "left",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <span style={{ marginLeft: "10px" }}>Deleting Account</span>
                  </>
                ) : (
                  "Confirm"
                )}

              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    </Grow>
  );
};

export default DeleteBusiness;
