import { useEffect, useState } from 'react';

function useOrientation() {
    const [orientation, setOrientation] = useState("PORTRAIT");

    const handleWindowSizeChange = () => {
        if (window.innerHeight > window.innerWidth) {
            setOrientation("PORTRAIT")
        }

        if (window.innerHeight < window.innerWidth) {
            setOrientation("LANDSCAPE")
        }
    }

    useEffect(() => {
        handleWindowSizeChange();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

    }, []);

    return orientation;
}

export default useOrientation