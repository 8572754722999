import tableForOne from '../assets/resImages/1.png';
import tableForTwo from '../assets/resImages/2.png';
import tableForFour from '../assets/resImages/4.png';
import tableForSix from '../assets/resImages/6.png';
import tableForEight from '../assets/resImages/8.png';
import tableForTen from '../assets/resImages/10.png';
import tableForTwelve from '../assets/resImages/12.png';
import tableForFourteen from '../assets/resImages/14.png';
import tableForSixteen from '../assets/resImages/16.png';
//Round Tables
import tableForTwoRound from '../assets/resImages/2_round.png';
import tableForFourRound from '../assets/resImages/4_round.png';
import tableForFiveRound from '../assets/resImages/5_round.png';
import tableForSixRound from '../assets/resImages/6_round.png';
import tableForEightRound from '../assets/resImages/8_round.png';

export function getImageForCapacity(type, capacity) {
    if (type === "Generic") {
        switch (capacity) {
            case 1:
                return tableForOne;
            case 2:
                return tableForTwo;
            case 4:
                return tableForFour;
            case 6:
                return tableForSix;
            case 8:
                return tableForEight;
            case 10:
                return tableForTen;
            case 12:
                return tableForTwelve;
            case 14:
                return tableForFourteen;
            case 16:
                return tableForSixteen;
            default:
                return tableForOne;
        }
    }
    if (type === "Round") {
        switch (capacity) {
            case 2:
                return tableForTwoRound;
            case 4:
                return tableForFourRound;
            case 5:
                return tableForFiveRound;
            case 6:
                return tableForSixRound;
            case 8:
                return tableForEightRound;
            default:
                return tableForTwoRound;
        }
    }
    return tableForTwo;
}