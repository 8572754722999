import React from "react";
import { Typography, Divider, IconButton } from "@mui/material";
import EmployeeRow from "./employeeRow";
// import { SchedulerRowPlaceHolder } from "../PlaceHolders";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import * as businessActions from '../../../../redux/actions/Business';
import { useDispatch } from 'react-redux';

const Scheduler = ({ employeeQueueList, date, setDate, highlightedEmployee }) => {
    const dispatch = useDispatch();

    const scrollerRef = React.useRef(null);

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const handleDateChange = (myDate) => {
        setDate(myDate);
        dispatch(businessActions.updateResetQueueState());
        dispatch(businessActions.enableQueueListener({ year: myDate.getFullYear(), month: myDate.getMonth(), date: myDate.getDate() }));
    }

    return (
        <div style={{ marginTop: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', zoom: "0.85" }}>
                <IconButton onClick={() => handleDateChange(addDays(date, -1))} style={{ marginTop: '-8px', color: 'black' }} component="span">
                    <ArrowBackIosIcon sx={{ transform: "translate(5px)" }} />
                </IconButton>
                <Typography textAlign="center" style={{ fontFamily: 'Poppins', fontWeight: 700, width: '150px' }}>
                    {date.toDateString()}
                </Typography>
                <IconButton onClick={() => handleDateChange(addDays(date, 1))} style={{ marginTop: '-8px', color: 'black' }} component="span">
                    <ArrowForwardIosIcon />
                </IconButton>
            </div>
            <Divider style={{ marginTop: '10px' }} />
            <div ref={scrollerRef} style={{ height: '55vh', overflowX: 'scroll', overflowY: 'scroll' }}>
                {employeeQueueList && employeeQueueList.map((i, index) => (
                    <EmployeeRow highlightedEmployee={highlightedEmployee} key={i.id} dropableId={i.id} employee={{ name: i.name, uid: i.uid }} employeeQueueList={i.queue} scrollerRef={scrollerRef} />
                ))}
            </div>
        </div>
    );
}


export default Scheduler;