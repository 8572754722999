import createReducer from "../../../utils/createReducer";
import * as authTypes from "../../actions/Business/types";

const initialState = {
  iQueues: [],
  invQueue: [],
  employees: [],
  customers: [],
  notifications: [],
  error: false,
  success: false,
  successMessage: "",
  errorMessage: "",
};

export const businessReducer = createReducer(initialState, {
  [authTypes.UPDATE_BUSINESS_NOTIFICATIONS](state, action) {
    return {
      ...state,
      notifications: Object.values(action?.notifications)
    }
  },
  [authTypes.UPDATE_EMPLOYEE_NEXT_FREE](state, action) {
    return {
      ...state,
      employees: state.employees.map((emp) => {
        if (emp.uid === action?.employeeId) {
          return {
            ...emp,
            nextFree: action?.nextFree,
          }
        }
        return emp;
      })
    }
  },
  [authTypes.ASSIGN_EMPLOYEE_WORK](state, action) {
    return {
      ...state,
      employees: state.employees.map((emp) => {
        if (emp.uid === action?.empId?.empId) {
          return {
            ...emp,
            busyTill: new Date().getTime() + (action?.empId?.duration * 1000 * 60),
            currentServing: action?.empId?.uid
          }
        }
        return emp;
      })
    }
  },
  [authTypes.UPDATE_ADD_EMPLOYEE_STATE](state, action) {
    return {
      ...state,
      employees: state.employees.filter((i) => {
        if (i.uid === action?.employee.uid) {
          i.name = action?.employee.name;
          i.contact = action?.employee.contact;
        }
        return i;
      })
    }
  },
  [authTypes.UPDATE_DELETE_EMPLOYEE_STATE](state, action) {
    return {
      ...state,
      employees: state.employees.filter((i) => i.uid !== action?.employee?.uid),
    };
  },
  [authTypes.UPDATE_EMPLOYEE_STATE](state, action) {
    let exist = state.employees.find((i) => i.uid === action?.employee?.uid);
    return {
      ...state,
      employees: exist ? state.employees : [...state.employees, action?.employee],
      error: false,
    };
  },
  [authTypes.UPDATE_ADD_QUEUE_STATE](state, action) {
    let exist = state.iQueues.find((i) => i.id === action?.iQueue.id);
    if (action?.iQueue.isQueueInBack) {
      let newExist = state.invQueue.find((i) => i.id === action?.iQueue.id);
      if (!newExist) {
        return {
          ...state,
          error: false,
          success: true,
          successMessage: "You have a new walk in, move to current date to view!",
        };
      }
      return state;
    }
    if (!exist) {
      return {
        ...state,
        invQueue: [...state.invQueue, action?.iQueue],
        iQueues: [...state.iQueues, action?.iQueue],
        error: false,
      };
    }
    return state;
  },
  [authTypes.UPDATE_DELETE_QUEUE_STATE](state, action) {
    return {
      ...state,
      iQueues: state.iQueues.filter((queue) => queue.id !== action?.iQueue?.id),
      error: false,
      success: false,
    };
  },
  [authTypes.UPDATE_CHANGE_QUEUE_STATE](state, action) {
    return {
      ...state,
      iQueues: state.iQueues.map((queue) => {
        if (queue.id === action?.iQueue?.id) {
          return action?.iQueue;
        }
        return queue;
      }),
      error: false,
    };
  },
  [authTypes.UPDATE_QUEUE_STATUS](state, action) {
    return {
      ...state,
      iQueues: state.iQueues.map((queue) => {
        if (queue.id === action?.iQueue?.id) {
          return { ...queue, status: action?.iQueue?.status };
        }
        return queue;
      }),
      error: false,
    };
  },
  [authTypes.UPDATE_RESET_QUEUE_STATE](state, action) {
    return {
      ...state,
      iQueues: [],
      error: false,
      success: false,
    };
  },
  [authTypes.DISABLE_QUEUE_SUCCESS_MESSAGE_STATE](state, action) {
    return {
      ...state,
      success: false,
      successMessage: "",
    };
  },
  [authTypes.GET_CUSTOMERS](state, action) {
    return {
      ...state,
      success: false,
      successMessage: "",
    };
  },
  [authTypes.UPDATE_CUSTOMERS](state, action) {
    return {
      ...state,
      customers: action?.customers,
      success: false,
      successMessage: "",
    };
  },
  [authTypes.DELETE_CUSTOMER](state, action) {
    return {
      ...state,
      success: false,
      successMessage: "",
    };
  },
  [authTypes.UPDATE_CUSTOMER_RATING](state, action) {
    return {
      ...state,
      customers: state.customers.filter((item) => {
        if (item.uid === action?.customer?.uid) {
          item.rating = action?.customer?.rating;
        }
        return item;
      }),
      success: false,
      successMessage: "",
    };
  },
  [authTypes.UPDATE_CUSTOMER_BLOCK_STATUS](state, action) {
    return {
      ...state,
      customers: state.customers.filter((item) => {
        if (item.uid === action?.customer?.uid) {
          item.is_blocked = action?.customer?.is_blocked;
        }
        return item;
      }),
      success: false,
      successMessage: "",
    };
  },
  [authTypes.SEND_BUG_REPORT](state, action) {
    return {
      ...state,
      bug: action?.bug,
      success: false,
      successMessage: "",
    };
  },
});
