import React, { memo, useEffect, createRef } from 'react';

export const LocalVideo = memo((props) => {
    const { localStream } = props;
    const localVideoTag = createRef();

    useEffect(() => {
        localVideoTag.current.srcObject = localStream;
    }, [localVideoTag, localStream]);

    return (
        <video playsInline style={{ height: '100%', width: '100%' }} ref={localVideoTag} autoPlay muted />
    );
});

export const RemoteVideo = memo((props) => {
    const { remoteStream } = props;
    const remoteVideoTag = createRef();

    useEffect(() => {
        remoteVideoTag.current.srcObject = remoteStream;
    }, [remoteVideoTag, remoteStream]);

    return (
        <video playsInline style={{ height: '100%', width: '100%' }} ref={remoteVideoTag} autoPlay/>
    );
});