import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';

export const useStyles = makeStyles({
    styledButton: {
        background: PRIMARY_COLOR,
        fontFamily: 'Poppins',
        "&:hover": {
            backgroundColor: PRIMARY_COLOR
        },
        "&:disabled": {
            color: 'white',
            backgroundColor: PRIMARY_COLOR
        }
    },
});