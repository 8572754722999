import { memo, useEffect, useState } from 'react'
import { Box } from './Box.js'
import { getImageForCapacity } from '../../../utils/getImageForTable';

const styles = {
  display: 'inline-block',
  border: '1px solid blue',
  // WebkitTransform: 'rotate(-7deg)',
}
export const BoxDragPreview = memo(function BoxDragPreview({ id, title, image, size, capacity, type, rotate }) {
  const [tickTock, setTickTock] = useState(false)
  useEffect(
    function subscribeToIntervalTick() {
      const interval = setInterval(() => setTickTock(!tickTock), 500)
      return () => clearInterval(interval)
    },
    [tickTock],
  )
  return (
    <div style={{
      ...styles,
      transform: `rotate(${rotate}deg)`,
    }}>
      <Box rotate={0} image={getImageForCapacity(type, capacity)} title={title} yellow={tickTock} preview id={id} size={size} />
    </div>
  )
})
