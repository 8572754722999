import React from "react";
import { Button, Grid, Typography } from "@mui/material";

// import { MainRestaurantCardPlaceHolder } from "../PlaceHolders";
import { ConTest } from "../ConTest";
import { DragTest } from "./DragTest";
import ServerEntriesDrawer from "./ServedEntry";
import animationData from '../../../../assets/lottie/empty_notification.json';
import Lottie from 'react-lottie';
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';
import { PendingDragTest } from "./PendingDragTest";

const HomePanel = ({ queueList, tables, move, date, setDate, isMobile }) => {

  const [suggestedTable, setSuggestedTable] = React.useState(null);
  const [preferredTable, setPreferredTable] = React.useState(null);
  const [open, setOpen] = React.useState(false);


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Grid container spacing={2} >
      {isMobile ? null : <Grid item xs={2}>
        {/* {queueList.length === 0 && <MainRestaurantCardPlaceHolder />} */}
        <Typography noWrap style={{ textAlign: 'center', fontFamily: 'Poppins', fontSize: '12px', marginTop: '24px', fontWeight: '600' }}>
          New entries
        </Typography>
        <div style={{ marginTop: '10px', height: '80vh', maxHeight: '70vh', overflow: 'scroll', border: '1px solid black' }}>
          {queueList.length === 0 &&
            <div>
              <Lottie
                options={defaultOptions}
                height={200}
                width={'13vw'}
              />
              <Typography style={{ textAlign: 'center', fontFamily: 'Poppins', fontSize: '12px' }}>
                No entries yet
              </Typography>
            </div>
          }
          {queueList.sort((a, b) => (a.created_timestamp - b.created_timestamp)).map((i, index) => (
            i.status === 2 ?
              <PendingDragTest tables={tables} move={move} item={i} key={i.uid} setSuggestedTable={setSuggestedTable} setPreferredTable={setPreferredTable} />
              :
              <DragTest tables={tables} move={move} item={i} key={i.uid} setSuggestedTable={setSuggestedTable} setPreferredTable={setPreferredTable} />
          ))}
        </div>
      </Grid>}
      <Grid item xs={isMobile ? 12 : 9.5}>
        <div>
          <div style={{ marginTop: '10px', textAlign: 'right' }}>
            <Button onClick={() => setOpen(true)}
              variant="outlined" size="small" style={{ color: PRIMARY_COLOR, background: "#fff", border: `1px solid ${PRIMARY_COLOR}` }}>View Served Entries</Button>
          </div>
          {Object.keys(tables).length !== 0 && <ConTest isMobile={isMobile} tables={tables} date={date} setDate={setDate} suggestedTable={suggestedTable} preferredTable={preferredTable} />}
        </div>
      </Grid>
      <ServerEntriesDrawer open={open} setOpen={setOpen} queueList={queueList} />
      {isMobile ? <Grid item xs={2} direction="row">
        <div style={{ display:'flex',width: '98vw', marginTop: '0px', height: '15vh', maxHeight: '70vh', overflow: 'scroll', border: '1px solid black' }}>
          {queueList.length === 0 &&
            <div>
              <Lottie
                options={defaultOptions}
                height={80}
                width={'12vw'}
              />
              <Typography style={{ textAlign: 'center', fontFamily: 'Poppins', fontSize: '12px' }}>
                No entries yet
              </Typography>
            </div>
          }
          {queueList.sort((a, b) => (a.created_timestamp - b.created_timestamp)).map((i, index) => (
            i.status === 2 ?
              <PendingDragTest tables={tables} move={move} item={i} key={i.uid} setSuggestedTable={setSuggestedTable} setPreferredTable={setPreferredTable} />
              :
              <div style={{minWidth:'130px'}}>
              <DragTest tables={tables} move={move} item={i} key={i.uid} setSuggestedTable={setSuggestedTable} setPreferredTable={setPreferredTable} />
              </div>
          ))}
        </div>
      </Grid> : null}
    </Grid >
  );
};

export default HomePanel;
