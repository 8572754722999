
import { Card, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from "react-redux";
import * as businessActions from '../../../../redux/actions/Business';
import { Cancel, Check } from '@mui/icons-material';
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';
import './stylesCSS.css';

export const PendingDragTest = ({ item }) => {
    const dispatch = useDispatch();

    const deleteIqueue = () => {
        dispatch(businessActions.deleteIqueue(item))
    }

    const handleAppointmentStatus = (status) => {
        dispatch(businessActions.updateQueueStatus({ ...item, status: status }));
    }

    return (
        <div className="box" style={{ position: 'relative' }}>
            {item.status === 2 ?
                <div className="ribbon-pending ribbon-pending-top-left" style={{ marginLeft: "0px", zoom: "0.85" }}>
                    <span>Pending</span>
                </div> : <div className="ribbon ribbon-top-left" style={{ marginLeft: "0px", zoom: "0.85" }}>
                    <span>Confirmed</span>
                </div>}
            <Card
                item={item}
                elevation={0}
                style={{
                    width: '90%', height: '122px',
                    margin: '15px 5px 5px 8px', fontFamily: 'Poppins', fontSize: '12px',
                    padding: '0px 10px 10px 10px',
                    border: '1px solid #A9A9A9',
                }}
            >
                <Grid container spacing={2} >
                    <Grid style={{ textAlign: 'center' }} item xs={5}>
                        <Typography style={{ fontSize: '25px', color: 'blue', fontFamily: 'Poppins', fontWeight: '600', marginTop: '18px' }}>
                            {item?.capacity ? item.capacity : 'NA'}
                        </Typography>
                        <Typography style={{ fontSize: '11px', color: 'black', fontFamily: 'Poppins', fontWeight: '600', marginBottom: '17px' }}>
                            {new Date(item?.created_timestamp).toLocaleTimeString('en-us', {
                                hour: '2-digit', minute: '2-digit'
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography style={{ fontFamily: 'Poppins', fontSize: '12px', marginTop: '10px' }}>
                            {item?.entry_type === 1 ? 'RESERVE BY' : 'EAT-NOW BY'}
                        </Typography>
                        <Typography noWrap style={{ fontFamily: 'Poppins', fontSize: '13px', fontWeight: '600' }}>
                            {item.customer_name}
                        </Typography>
                        <Typography style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: '600' }}>
                            {item.customer_contact}
                        </Typography>
                    </Grid>
                </Grid>
                <div style={{ marginTop: '-10px' }}>
                    <IconButton onClick={() => { handleAppointmentStatus(1) }} style={{ color: 'green', background: "#fff", border: `1px solid ${'green'}`, marginLeft: '15px', marginTop: '5px' }} size="small">
                        <Check style={{ width: '15px', height: '15px', color: 'green' }} />
                    </IconButton>
                    <IconButton onClick={() => { handleAppointmentStatus(-2) }} style={{ color: PRIMARY_COLOR, background: "#fff", border: `1px solid ${PRIMARY_COLOR}`, marginLeft: '15px', marginTop: '5px' }} size="small">
                        <Cancel style={{ width: '15px', height: '15px', color: PRIMARY_COLOR }} />
                    </IconButton>
                    <IconButton onClick={() => deleteIqueue()} style={{ color: PRIMARY_COLOR, background: "#fff", border: "1px solid #d32f2f", marginLeft: '15px', marginTop: '5px' }} size="small">
                        <DeleteIcon style={{ width: '15px', height: '15px' }} color="error" />
                    </IconButton>
                </div>
            </Card>
        </div>
    )
}