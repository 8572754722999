import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import { useDispatch } from "react-redux";
import * as businessActions from '../../redux/actions/Business';

export default function ConfirmationDialog({ headingText = "Sample Heading", descriptionText = "Sample Description", cancelText = "Cancel", acceptText = "Remove", item, setItem }) {
    const dispatch = useDispatch();

    const deleteIqueue = () => {
        dispatch(businessActions.deleteIqueue(item))
    }

    return (
        item ? (
            <Dialog
                open={item !== null}
                onClose={() => {
                    setItem(null);
                }}
                aria-labelledby="Delete Dialog"
            >
                <DialogTitle id="responsive-dialog-title">
                    {headingText}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {descriptionText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => {
                            setItem(null);
                        }}
                    >
                        {cancelText}
                    </Button>
                    <Button
                        onClick={() => {
                            deleteIqueue();
                            setItem(null);
                        }}
                        autoFocus
                        color="error"
                    >
                        {acceptText}
                    </Button>
                </DialogActions>
            </Dialog>
        ) : null
    );
}