import createReducer from '../../../utils/createReducer';
import * as authTypes from '../../actions/Auth/types';

const initialState = {
    auth: 'loading',
    userData: 'loading',
    businessData: 'loading',
    error: false,
    success: false,
};

export const authReducer = createReducer(initialState, {
    [authTypes.UPDATE_AUTH_STATE](state, action) {
        return {
            ...state,
            auth: action?.auth,
            error: false,
            success: false,
        };
    },
    [authTypes.SET_USER_LOGIN_DATA](state, action) {
        return {
            ...state,
            userData: action?.userLoginData,
            error: false,
            success: false,
        };
    },
    [authTypes.SET_BUSINESS_LOGIN_DATA](state, action) {
        return {
            ...state,
            businessData: action?.businessLoginData,
            error: false,
            success: false,
        };
    },
    [authTypes.UPDATE_BUSINESS_DETAILS](state,action) {
        return{
            ...state,
            businessData: action?.updatedBusinessData,
            error: false,
            success: false, 
        }
    },
    [authTypes.SET_ALERT](state,action){
        return{
            ...state,
            error: false,
            success: true
        }
    },
    [authTypes.CLEAR_ALERT](state,action){
        return{
            ...state,
            error: false,
            success: false
        }
    }
});