import React from 'react';
import { useDispatch } from "react-redux";
import * as authActions from '../../../../redux/actions/Auth';
import {
    Typography, Menu, MenuItem
} from "@mui/material";


const UserMenu = ({ setSelectedPageIndex,setAnchorElUser, anchorElUser, setActiveTab }) => {
    const dispatch = useDispatch();
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Menu
            sx={{ mt: "45px", width: "200px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
        >
            <MenuItem onClick={() => {setSelectedPageIndex(5); setActiveTab(0); handleCloseUserMenu(); }}>
                <Typography textAlign="center">Profile</Typography>
            </MenuItem>
            <MenuItem onClick={() => { dispatch(authActions.logOut()) }}>
                <Typography textAlign="center">LogOut</Typography>
            </MenuItem>
        </Menu>);
}

export default UserMenu;