import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Typography, Button } from '@mui/material';
import VideoCallScreen from "./index";
import {
    getDatabase, ref, onValue, set, remove, get, update, child
} from "firebase/database";
import { useStyles } from './styles';
import animationData from '../../assets/lottie/lobby_waiting';
import Lottie from 'react-lottie';

const WaitLobby = (props) => {
    const { videoCallLobby, setVideoCallLobby,
        request, consumer, consumerId, businessId, consumerQueueId, consumerTimeStamp } = props;
    const classes = useStyles();

    const [videoCallDialog, setVideoCallDialog] = useState(false);
    const [myRequest, setMyRequest] = useState(request);
    const [goAhead, setGoHead] = useState(false);
    const [myStream, setMyStream] = useState(null);


    useEffect(() => {
        navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
        }).then((myStream) => {
            setMyStream(myStream);
            setGoHead(true);
        });
    }, []);

    useEffect(() => {
        if (consumer && goAhead) {
            let newDate = new Date(consumerTimeStamp);
            set(ref(getDatabase(), `iQueue/Restricted/Businesses/${request.recId}/iQueues/${newDate.getFullYear()}/${newDate.getMonth()}/${newDate.getDate()}/${consumerQueueId}/is_in_lobby`), true)
            onValue(ref(getDatabase(), `iQueue/Restricted/Consumers/${consumerId}/iQueues/${consumerQueueId}/is_in_lobby`), (snapshot) => {
                if (snapshot?.val() === true) {
                    setVideoCallDialog(true);
                }
            });
        }
    }, [consumer, consumerId, consumerQueueId, request, consumerTimeStamp, goAhead]);

    useEffect(() => {
        if (!consumer && !myRequest && goAhead) {
            //Consumer/bleh/bleh write that i am in lobby
            set(ref(getDatabase(), `iQueue/Restricted/Consumers/${consumerId}/iQueues/${consumerQueueId}/is_in_lobby`), true)
            onValue(ref(getDatabase(), `iQueue/Restricted/Businesses/${businessId}/videocall`), (snapshot) => {
                if (!myRequest) {
                    if (snapshot?.val()?.state === "ringing") {
                        let callData = snapshot.val();
                        setMyRequest({
                            callId: callData.from,
                            callName: callData.from_name,
                            recId: callData.to,
                            recName: callData.to_name,
                            offer: { type: "offer", sdp: callData.offer },
                            initiator: false,
                        });
                        setVideoCallDialog(true);
                    }
                }
            });
        }
    }, [myRequest, consumer, businessId, consumerId, consumerQueueId, goAhead]);

    const assignConsumerToSuggestedEmployee = async () => {
        let newDate = new Date(consumerTimeStamp);
        const consumerRef = ref(getDatabase(), `iQueue/Restricted/Consumers/${consumerId}/iQueues/${consumerQueueId}`);
        const businessRef = ref(getDatabase(), `iQueue/Restricted/Businesses/${businessId}/iQueues/${newDate.getFullYear()}/${newDate.getMonth()}/${newDate.getDate()}/${consumerQueueId}`);
        const snapshot = await get(child(businessRef, "suggested_emp"));
        const suggestedEmployee = snapshot.val();

        if (suggestedEmployee) {
            if (consumer) {
                update(consumerRef, { status: 0, service_employee: suggestedEmployee });
            } else {
                update(consumerRef, { status: 0, service_employee: suggestedEmployee });
                update(businessRef, { status: 0, service_employee: suggestedEmployee });
            }
        }
    }

    const handleQuitLobby = () => {
        if (consumer) {
            remove(ref(getDatabase(), `iQueue/Restricted/Businesses/${request.recId}/iQueues/${new Date().getFullYear()}/${new Date().getMonth()}/${new Date().getDate()}/${consumerQueueId}/is_in_lobby`), false)
        } else {
            remove(ref(getDatabase(), `iQueue/Restricted/Consumers/${consumerId}/iQueues/${consumerQueueId}/is_in_lobby`), false);
        }
        assignConsumerToSuggestedEmployee()
        setVideoCallLobby(false);
        if (window.iQueueApp) {
            window.iQueueApp.onCanceled();
            window.iQueueApp.closeWindow();
        }
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('closeWindow').then(function (result) {
                // get result from Flutter side. It will be the number 64.
                console.log(result);
            });
        }
        myStream.getTracks().forEach(function (track) {
            track.stop();
        });
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Dialog fullScreen open={true}>
            <DialogContent style={{ textAlign: 'center', marginTop: '18%' }}>
                <Lottie
                    options={defaultOptions}
                    height={220}
                    width={220}
                />
                <Typography variant="subtitle2" fontSize={30}>
                    You are in the lobby.
                </Typography>
                <Typography>
                    Waiting for everyone to join the call
                </Typography>
                <br></br>
                <Button size="large" className={classes.styledButton} style={{ marginLeft: '15px' }} onClick={() => handleQuitLobby()}>
                    Cancel
                </Button>
            </DialogContent>
            {videoCallDialog && myRequest && <VideoCallScreen
                videoCallDialog={videoCallDialog}
                setVideoCallDialog={setVideoCallDialog}
                handleQuitLobby={handleQuitLobby}
                consumer={consumer}
                request={myRequest} />}
        </Dialog>);
}

export default WaitLobby;