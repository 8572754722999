import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import WaitLobby from "../../../components/VideoCall/waitLobby";
import { firebaseConfig } from '../../../config/firebaseConfig';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ExternalVideoCallBusiness = () => {
    let query = useQuery();

    const [videoCallLobby, setVideoCallLobby] = useState(false);
    const [callData, setCallData] = useState(null);
    const [auth, setAuth] = useState(null);

    useEffect(() => {
        if (auth) {
            setCallData((JSON.parse(query.get("callObj"))));
            setVideoCallLobby(true);
        }
    }, [auth, query]);

    useEffect(() => {
        if (!("indexedDB" in window)) {
            alert("This browser doesn't support IndexedDB")
        } else {
            let user = JSON.parse(query.get("userAuthObj"));
            user = { ...user, apiKey: firebaseConfig.apiKey }
            let indexdb = window.indexedDB.open('firebaseLocalStorageDb', 1);
            indexdb.onupgradeneeded = function (event) {
                var db = event.target.result;
                db.createObjectStore("firebaseLocalStorage", { keyPath: "fbase_key" });

            }
            indexdb.onsuccess = function (event) {
                let db = event.target.result;
                let transaction = db.transaction(['firebaseLocalStorage'], 'readwrite')
                let storage = transaction.objectStore('firebaseLocalStorage')
                storage.put({
                    fbase_key: `firebase:authUser:${user.apiKey}:[DEFAULT]`,
                    value: user
                });
                setAuth(true);
            }
        }
    }, [query]);
    return (<div>
        <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
        </Backdrop>
        {
            videoCallLobby && callData && (
                <WaitLobby
                    videoCallLobby={videoCallLobby}
                    setVideoCallLobby={setVideoCallLobby}
                    consumer={false}
                    consumerId={callData?.customer_uid}
                    consumerQueueId={callData?.customer_queue_id}
                    consumerTimeStamp={callData?.customer_queue_timestamp}
                    businessId={callData?.business_uid}
                    request={null}
                />
            )
        }
    </div>)
}

export default ExternalVideoCallBusiness;