import {
  Box,
  Grid,
  TextField,
  Button,
  Grow,
  Autocomplete
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import * as authActions from "../../../../redux/actions/Auth";
import axios from 'axios';
import { useStyles } from "../Timing/styles";
import { useStylesDetails } from "./styles";
import MapComponent from "./mapComponent";
import * as geofire from 'geofire-common'
import { validateEmail, isValidURL } from './../../../Signup/Business/dataValidators'

const Details = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const classesDetails = useStylesDetails();
  const { businessData } = useSelector((state) => state.authReducer);

  const [data, setData] = useState(businessData);
  const [errors, setErrors] = useState({
    name: false,
    address: false,
    contact: false,
    email: false,
    website: false
  });

  const [addressDrop, setAddressDrop] = useState([]);

  const [latPos, setLatPos] = useState(businessData?.lat);
  const [lngPos, setLngPos] = useState(businessData?.lng);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${data?.address}&key=0e2acf0b337c45e6b5650d89763957d5`)
          .then(res => {
              setAddressDrop(res.data.results.map((item) => {
                  return {
                      label: item.formatted,
                      latitude: item.geometry.lat,
                      longitude: item.geometry.lng
                  }
              }));
          })
          .catch(err => {
              console.log(err);
          });
      }, 1000);

      return () => clearTimeout(timer);
  }, [data?.address]);
  
  
  const getList = () => {
      if(data?.address?.trim()) {
          return [
              {
                  label: data.address,
                  latitude: 23.785345,
                  longitude: -28.580709
              },
              ...addressDrop
          ];
      }

      return addressDrop;
  }

  const validator = () => {
    let errorExists = false;

    if(!data?.name?.trim()) {
      setErrors((val) => ({ ...val, name: true }));
      errorExists = true;
    }

    if(!data?.address?.trim()) {
      setErrors((val) => ({ ...val, address: true }));
      errorExists = true;
    }

    const business_contact_len = data?.contact?.trim()?.length;
    if (business_contact_len && business_contact_len < 10) {
      setErrors((val) => ({ ...val, contact: true }));
      errorExists = true;
    }

    const email = data?.email?.trim();
    if(email && !validateEmail(email)) {
      setErrors((val) => ({ ...val, email: true }));
      errorExists = true;
    }

    const website = data?.website?.trim();
    if(website && !isValidURL(website)) {
      setErrors((val) => ({ ...val, website: true }));
      errorExists = true;
    }

    return errorExists;
  }

  const handleUpdate = () => {
    if(!validator()) {
      dispatch(authActions.updateBusinessDetails(data));
      setIsEdit(!isEdit);
    }
  };
  useEffect(() => {
    setData({ ...data, lat: latPos, lng: lngPos, geoHash: geofire.geohashForLocation([latPos, lngPos]), })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latPos, lngPos])


  return (
    <Grow in>
      <Box sx={{ mt: 5 }}>

        <Grid container justifyContent="space-evenly" spacing={10}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Grid
              container
              direction={"column"}
              spacing={2}
            >
              <Grid item>
                <TextField
                  InputProps={{
                    readOnly: !isEdit,
                  }}
                  className={isEdit ? "" : classesDetails.readonlyField}
                  value={data?.name}
                  name="name"
                  required
                  fullWidth
                  inputProps={{ style: { fontFamily: 'Poppins' }, maxLength: 30 }}
                  InputLabelProps={{ style: { fontFamily: "Poppins" } }}
                  id="name"
                  label="Business Name"
                  error={errors.name}
                  helperText={errors.name ? "Required field" : ""}
                  onChange={(event) => {
                    setErrors({...errors, name: false});
                    setData({ ...data, name: event.target.value });
                  }}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  options={getList()}
                  isOptionEqualToValue={(option, val) => option.label === val.label}
                  className={isEdit ? "" : classesDetails.readonlyField}
                  inputValue={data?.address || ""}
                  onChange={(event, newValue) => {
                    setData({ ...data, address: newValue?.label ? newValue?.label : "" });
                    setLatPos(isNaN(parseFloat(newValue?.latitude)) ? 23.785345 : newValue.latitude);
                    setLngPos(isNaN(parseFloat(newValue?.longitude)) ? -28.580709 : newValue.longitude);
                  }}
                  value={data?.address}
                  freeSolo
                  renderInput={(params) => <TextField
                    {...params}
                    InputProps={{
                      readOnly: !isEdit,
                    }}
                    value={data?.address}
                    id="address"
                    label="Address"
                    name="address"
                    error={errors.address}
                    helperText={errors.address ? "Required field" : ""}
                    required
                    fullWidth
                    onChange={(e) => {
                      setData({ ...data, address: e.target.value });
                      setLatPos(23.785345);
                      setLngPos(-28.580709);
                      setErrors({...errors, address: false});
                    }}
                  />}
                />
              </Grid>
              <Grid item>
                <TextField
                  InputProps={{
                    readOnly: !isEdit,
                    style: { fontFamily: "Poppins" }
                  }}
                  value={data?.contact}
                  name="contact"
                  fullWidth
                  className={isEdit ? "" : classesDetails.readonlyField}
                  InputLabelProps={{ style: { fontFamily: "Poppins" } }}
                  id="contact"
                  label="Contact No."
                  error={errors.contact}
                  helperText={errors.contact ? "Invalid contact number" : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,15}$/.test(value[0] === "+" ? value.slice(1) : value)) {
                      setErrors({...errors, contact: false});
                      setData({ ...data, contact: e.target.value.trim() });
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  InputProps={{
                    readOnly: !isEdit,
                    style: { fontFamily: "Poppins" }
                  }}
                  value={data?.email}
                  name="email"
                  className={isEdit ? "" : classesDetails.readonlyField}
                  fullWidth
                  InputLabelProps={{ style: { fontFamily: "Poppins" } }}
                  id="email"
                  label="Email"
                  error={errors.email}
                  helperText={errors.email ? "Invalid email address" : ""}
                  onChange={(event) => {
                    setErrors({...errors, email: false});
                    setData({ ...data, email: event.target.value.trim() });
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  InputProps={{
                    readOnly: !isEdit,
                    style: { fontFamily: "Poppins" }
                  }}
                  value={data?.website}
                  name="website"
                  fullWidth
                  className={isEdit ? "" : classesDetails.readonlyField}
                  InputLabelProps={{ style: { fontFamily: "Poppins" } }}
                  id="website"
                  label="Website"
                  error={errors.website}
                  helperText={errors.website ? 'Invalid website URL' : ""}
                  onChange={(event) => {
                    setErrors({...errors, website: false});
                    setData({ ...data, website: event.target.value.trim() });
                  }}
                />
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <MapComponent
              isEdit={isEdit}
              data={data}
              setData={setData}
              setLatPos={setLatPos}
              setLngPos={setLngPos}
            />
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ marginTop: "1rem" }}
        >
          <Grid item>
            {isEdit === true ?
              (<Button
                className={classes.styledButton}
                variant="contained"
                onClick={handleUpdate}
                size="large"
                sx={{ height: "50px", borderRadius: "5px" }}
              >
                Update
              </Button>) :
              (<Button
                className={classes.styledButton}
                variant="contained"
                onClick={()=>setIsEdit(!isEdit)}
                size="large"
                sx={{ height: "50px", borderRadius: "5px" }}
              >
                Edit
              </Button>)
            }
          </Grid>
        </Grid>
      </Box>
    </Grow>
  );
};

export default Details;
