export const ENABLE_AUTH_STATE_LISTENER = 'ENABLE_AUTH_STATE_LISTENER';
export const DISABLE_AUTH_STATE_LISTENER = 'DISABLE_AUTH_STATE_LISTENER';
export const UPDATE_AUTH_STATE = 'UPDATE_AUTH_STATE';

export const SET_USER_LOGIN_DATA = 'SET_USER_LOGIN_DATA';
export const SET_BUSINESS_LOGIN_DATA = 'SET_BUSINESS_LOGIN_DATA';
export const DELETE_BUSINESS = 'DELETE_BUSINESS'
export const LOG_OUT = 'LOG_OUT'

export const UPDATE_BUSINESS_DETAILS = 'UPDATE_BUSINESS_DETAILS'
export const SET_ALERT = 'SET_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'
export const UPDATE_GLOBAL_ANALYTICS = 'UPDATE_GLOBAL_ANALYTICS'