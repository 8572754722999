import {
    Button, Grid, Typography, Dialog, DialogContent,
    DialogTitle, DialogContentText, DialogActions, Snackbar
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from 'react-redux';
import * as businessCouponActions from "../../redux/actions/BusinessCoupons";
import animationData from '../../assets/lottie/voucher';
import { CouponItem } from "./CouponItem";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CouponsList = () => {
    const dispatch = useDispatch();
    const { coupons } = useSelector((state) => state.businessCouponsReducer);
    const [confirmDelete, setConfirmDelete] = useState(null);
    const [showTC, setShowTC] = useState(null);
    const [snackbarData, setSnackBarData] = useState(null);

    useEffect(() => {
        dispatch(businessCouponActions.loadCoupons());
    }, [dispatch]);

    const handleDeleteCoupon = () => {
        dispatch(businessCouponActions.deleteCoupon(confirmDelete));
        setConfirmDelete(null);
        setSnackBarData({
            open: true,
            message: "Coupon deleted successfully",
            severity: 'success'
        });
        return;
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (<div style={{ margin: '10px 20px 0px 20px', overflow: 'scroll', maxHeight: '80vh' }}>
        <Grid container spacing={2}>
            {(!coupons || (coupons && Object.keys(coupons).length === 0)) &&
                <Grid item xs={12}>
                    <Lottie
                        options={defaultOptions}
                        height={270}
                        width={270}
                    />
                    <Typography style={{
                        textAlign: 'center',
                        fontFamily: 'Poppins',
                        fontSize: '18px',
                        fontWeight: '600'
                    }}>Create coupons for your customers</Typography>
                </Grid>}
            {coupons && Object.keys(coupons).length > 0 && Object.keys(coupons).reverse().map((coup) => (
                <Grid item xs={12} md={6}>
                    <CouponItem item={coupons[coup]} setShowTC={setShowTC} setConfirmDelete={setConfirmDelete} />
                </Grid>
            ))}
        </Grid>
        {confirmDelete && <Dialog
            open={confirmDelete != null}
            onClose={() => {
                setConfirmDelete(null);
            }}
            aria-labelledby="Delete Dialog1"
        >
            <DialogTitle id="responsive-dialog-title">
                Delete Coupon {confirmDelete?.name} ({confirmDelete?.code})?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this coupon?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={() => {
                        setConfirmDelete(null);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        handleDeleteCoupon();
                    }}
                    autoFocus
                    color="error"
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>}
        {showTC && <Dialog
            open={showTC != null}
            onClose={() => {
                setShowTC(null);
            }}
            aria-labelledby="Delete Dialog1"
        >
            <DialogTitle id="responsive-dialog-title">
                Terms and conditions for Coupon {coupons[showTC]?.name} ({coupons[showTC]?.code})
            </DialogTitle>
            <DialogContent style={{ overFlow: 'auto' }}>
                <DialogContentText>
                    <Typography style={{ wordWrap: 'break-word' }}>
                        {coupons[showTC]?.termsCondition}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={() => {
                        setShowTC(null);
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>}
        {snackbarData && snackbarData.open && (
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackbarData.open} autoHideDuration={3000} onClose={() => setSnackBarData(null)}>
                <Alert severity={snackbarData.severity}>{snackbarData.message}</Alert>
            </Snackbar>
        )}
    </div>)
}