import { Grid, Typography } from "@mui/material";
import Lottie from 'react-lottie';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import animationData from '../../../../assets/lottie/anime1.json';
import { PRIMARY_COLOR } from '../../../../config/appStyleConfig';

export const MainQueueCardPlaceHolder = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"}>
            <Lottie options={defaultOptions} style={{ height: "138px", width: "auto" }} />
            <Typography sx={{ color: PRIMARY_COLOR, fontWeight: "bolder", fontFamily: "Poppins", margin: "1rem", fontSize: "15px" }}>Take a break while you wait for your customers.</Typography>
        </Grid>
    )
}

export const SchedulerRowPlaceHolder = ({ setSelectedPageIndex, setActiveTab }) => {
    return (
        <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} rowGap={"1.5rem"} sx={{ position: "fixed", top: "500px", zoom: "0.85" }}>
            <Typography sx={{ color: PRIMARY_COLOR, fontSize: "17px", fontWeight: "bolder", fontFamily: "Poppins" }}>Add an employee to get started.</Typography>
            <Fab onClick={() => { setSelectedPageIndex(5); setActiveTab(1); }}
                sx={{
                    color: "#FFFFFF", background: PRIMARY_COLOR,
                    "&:hover": {
                        backgroundColor: PRIMARY_COLOR
                    }
                }}
                aria-label="add">
                <AddIcon style={{ fontSize: "2rem" }} />
            </Fab>
            <Typography textAlign={"center"} sx={{ fontWeight: "bold", fontFamily: "Poppins", fontSize: "16px" }}>If you run business single-handedly then add <br />yourself as an employee.</Typography>

        </Grid>
    )
}