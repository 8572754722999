import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { PRIMARY_COLOR } from '../../config/appStyleConfig';

function CircularProgressWithLabel(props) {
  return (
    <Box style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", color: PRIMARY_COLOR, height: "70px" }}>
      <CircularProgress style={{ height: "3rem", width: "66px" }} color={"inherit"} thickness="7" variant="determinate" {...props} />
      <Box
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "15px",
        }}
      >
        <Typography style={{ fontFamily: "Poppins", fontStyle: "normal", fontWeight: 500, fontSize: "25px", lineHeight: "18px", textAlign: "center", color: PRIMARY_COLOR }} variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic({ value }) {
  return <CircularProgressWithLabel value={value} />;
}
