import * as types from './types';

export function enableAuthStateListener() {
    return {
        type: types.ENABLE_AUTH_STATE_LISTENER
    };
};

export function updateAuthState(auth) {
    return {
        type: types.UPDATE_AUTH_STATE,
        auth
    };
};

export function setUserLoginData(userLoginData) {
    return {
        type: types.SET_USER_LOGIN_DATA,
        userLoginData
    };
};

export function setBusinessLoginData(businessLoginData) {
    return {
        type: types.SET_BUSINESS_LOGIN_DATA,
        businessLoginData
    };
};

export function updateBusinessDetails(updatedBusinessData) {
    return {
        type: types.UPDATE_BUSINESS_DETAILS,
        updatedBusinessData
    }
}

export function deleteBusiness(){
    return{
        type: types.DELETE_BUSINESS
    }
}

export function logOut() {
    return{
        type: types.LOG_OUT
    }
}
export function setAlert(){
    return{
        type: types.SET_ALERT
    }
}

export function clearAlert(){
    return{
        type: types.CLEAR_ALERT
    }
}
export function updateGlobalAnalytics(field,data){
    return{
        type: types.UPDATE_GLOBAL_ANALYTICS,
        field,
        data
    }
}