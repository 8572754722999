import { Backdrop, Typography } from '@mui/material';
import mainLogo from '../../assets/images/logo.png';
import './styles.css';

export const LoadingBackdrop = () => {
    return (<Backdrop open={true} sx={{ color: '#fff', backgroundColor: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: "column", textAlignL: 'center' }}>
            <img style={{ alignSelf: 'center' }} width={50} height={50} src={mainLogo} alt="MainLogo" />
            <div style={{ alignSelf: 'center', marginTop: '10px' }} class="snippet" data-title=".dot-carousel">
                <div class="stage">
                    <div class="dot-carousel"></div>
                </div>
            </div>
            <Typography style={{ color: 'black', fontFamily: 'Poppins', fontSize: '15px', fontWeight: '800', marginTop: '5px' }}>iQueue 365</Typography>
        </div>
    </Backdrop>)
}