import { Grid, Box, } from "@mui/material";
import React from "react";
import TabPanel from '../User/TabPanel/TabPanel';
import { PRIMARY_COLOR } from '../../../config/appStyleConfig';

const RecentHistory = () => {
  return (
    <Grid
      style={{ height: "100%", zIndex: "10" }}
      container
      direction={"column-reverse"}
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Box
        style={{
          position: "absolute",
          backgroundColor: PRIMARY_COLOR,
          top: 0,
          left: 0,
          right: 0,
          bottom: "60%",
          zIndex: "0",
        }}
      ></Box>
      <TabPanel />
    </Grid>
  );
};
export default RecentHistory;
