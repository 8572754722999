import { Box, Button, CircularProgress, FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import { useState } from "react";

export const OtherSetupSettings = ({ classes, nextStep2, setActiveStep, loading, consolidateData }) => {
    const [settings, setSettings] = useState({
        accept_appointments: consolidateData.accept_appointments || false,
        accept_video_visits: consolidateData.accept_video_visits || false,
        auto_accept: consolidateData.auto_accept || false
    });

    const handleChange = (key) => {
        setSettings((settings) => ({ ...settings, [key]: !settings[key] }));
    }

    return (
        <Box component="form" noValidate sx={{ mt: 3, width: '100%' }} onSubmit={(e) => nextStep2(e, settings) }>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormGroup >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={settings.accept_appointments}
                                    onChange={() => handleChange('accept_appointments')}
                                />
                            }
                            label="Accept Appointments"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={settings.accept_video_visits}
                                    onChange={() => handleChange('accept_video_visits')}
                                />
                            }
                            label="Accept Video Visits"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={settings.auto_accept}
                                    onChange={() => handleChange('auto_accept')}
                                />
                            }
                            label="Auto-Accept"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container columnSpacing={2} marginTop={4}>
                <Grid item xs={6}>
                    <Button
                        id="sign-in-button"
                        fullWidth
                        variant="contained"
                        className={classes.styledButton}
                        sx={{ height: '50px', borderRadius: '5px' }}
                        disabled={loading}
                        onClick={() => setActiveStep(2)}
                    >
                        Previous
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        id="sign-in-button"
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.styledButton}
                        sx={{ height: '50px', borderRadius: '5px' }}
                        disabled={loading}
                    >
                        {loading ? <><CircularProgress style={{ color: 'white', float: 'left', width: '20px', height: '20px' }} /> <span style={{ marginLeft: '10px' }}>Working on it</span></> : 'Next'}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}