import { Grid } from "@mui/material"
import TabPanel from './TabPanel/index'

const Settings = ({activeTab, setActiveTab}) => {
    return(
        <Grid container>
            <TabPanel value={activeTab} setValue={setActiveTab} />
        </Grid>
    )
}

export default Settings