import { select, takeEvery } from "redux-saga/effects";
import * as types from "../../actions/BusinessRestaurant/types";
import * as businessRestaurantActions from "../../actions/BusinessRestaurant";
import * as authActions from "../../actions/Auth";
import { getAuth } from "firebase/auth";
import { get, getDatabase, off, onValue, push, ref, remove, set, update } from "firebase/database";
import { store } from "../../store/configureStore";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";

function* saveDefaultTableAsync(action) {
    try {
        if (getAuth()) {
            const { auth } = yield select((state) => state.authReducer);
            const db = getDatabase();
            const tokenRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/RestaurantDetails/DefaultTables`);
            const newRef = push(tokenRef);
            yield set(newRef, {
                ...action?.tableData,
                id: newRef.key
            }).then(() => {
                action.resolve(newRef.key);
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* saveFloorSizeAsync(action) {
    try {
        if (getAuth()) {
            const { businessData, auth } = yield select((state) => state.authReducer);
            const db = getDatabase();
            const tokenRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/profile`);
            yield update(tokenRef, {
                ...action?.tableSize,
            }).then((data) => {
                store.dispatch(authActions.setBusinessLoginData({
                    ...businessData,
                    floorHeight: action?.tableSize.floorHeight,
                    floorWidth: action?.tableSize.floorWidth
                }));
            }).catch((error) => {
                console.log(error);
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* loadDefaultTablesAsync(action) {
    try {
        if (getAuth()) {
            // const { auth } = yield select((state) => state.authReducer);
            // const db = getDatabase();
            // const tokenRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/RestaurantDetails/DefaultTables`);
            // onValue(tokenRef, (snapshot) => {
            //     console.log(snapshot.val());
            //     action.resolve(snapshot.val());
            // },
            //     {
            //         onlyOnce: true
            //     }
            // );=
            const remoteConfig = getRemoteConfig();
            remoteConfig.settings.minimumFetchIntervalMillis = 1000;
            yield fetchAndActivate(remoteConfig);
            const val = getValue(remoteConfig, "table_list");
            action.resolve(JSON.parse(val._value));
        }
    } catch (error) {
        console.log(error);
    }
}

function* deleteDefaultTableAsync(action) {
    try {
        if (getAuth()) {
            const { auth } = yield select((state) => state.authReducer);
            const db = getDatabase();
            const tokenRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/RestaurantDetails/DefaultTables/${action?.tableId}`);
            remove(tokenRef);
        }
    } catch (error) {
        console.log(error);
    }
}

function* saveFloorDetailsAsync(action) {
    try {
        if (getAuth()) {
            const { auth } = yield select((state) => state.authReducer);
            const db = getDatabase();
            const tokenRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/FloorSetup`);
            set(tokenRef, action.tableData);
        }
    } catch (error) {
        console.log(error);
    }
}


function* loadFloorDetailsAsync(action) {
    try {
        if (getAuth()) {
            const { auth } = yield select((state) => state.authReducer);
            const db = getDatabase();
            const tokenRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/FloorSetup`);
            off(tokenRef);
            onValue(tokenRef, (snapshot) => {
                //action.resolve(snapshot.val());
                store.dispatch(
                    businessRestaurantActions.updateFloorClientSide(snapshot.val())
                );
            },
            );
        }
    } catch (error) {
        console.log(error);
    }
}

function* assignCustomerToTableAsync(action) {
    try {
        if (getAuth()) {
            const { auth } = yield select((state) => state.authReducer);
            const { tables } = yield select((state) => state.businessRestaurantReducer);

            // if (tables[action?.tableId]?.capacity < action?.queueItem?.capacity) {
            //     action.resolve({ error: true, msg: 'Insufficient capacity' });
            // } else
            if (!tables[action?.tableId]?.busy) {
                const db = getDatabase();
                const time = new Date(action?.queueItem?.created_timestamp);
                const tokenRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/iQueues/${time.getFullYear()}/${time.getMonth()}/${time.getDate()}/${action?.queueItem?.uid}`);
                yield update(tokenRef, {
                    'serving_table': action?.tableId,
                    'status': 5
                });
                const customerRef = ref(db, `iQueue/Restricted/Consumers/${action?.queueItem?.customer_uid}/iQueues/${action?.queueItem?.uid}`);
                yield update(customerRef, {
                    'serving_table': action?.tableId,
                    'status': 5
                });
                const tableRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/FloorSetup/${action?.tableId}`);
                yield update(tableRef, {
                    'busy': true,
                    'queue_uid': action?.queueItem?.uid,
                    'customer_uid': action?.queueItem?.customer_uid,
                    'customer_name': action?.queueItem?.customer_name,
                    'queue_timestamp': action?.queueItem?.created_timestamp,
                });
                action.resolve({ error: false, msg: 'Table is Free' });
            } else {
                action.resolve({ error: true, msg: 'Table is Busy' });
            }

        }
    } catch (error) {
        console.log(error);
    }
}

function* unAssignCustomerFromTableAsync(action) {
    try {
        if (getAuth()) {
            const { auth } = yield select((state) => state.authReducer);
            const db = getDatabase();
            if (action?.tableData?.freeTable === false) {
                const time = new Date(action?.tableData?.queue_timestamp);
                const tokenRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/iQueues/${time.getFullYear()}/${time.getMonth()}/${time.getDate()}/${action?.tableData?.queue_uid}`);
                yield update(tokenRef, {
                    'serving_table': null,
                    'status': 1
                });
                const customerRef = ref(db, `iQueue/Restricted/Consumers/${action?.tableData?.customer_uid}/iQueues/${action?.tableData?.queue_uid}`);
                yield update(customerRef, {
                    'serving_table': null,
                    'status': 1
                });
            }
            if (action?.tableData?.served === true) {
                const time = new Date(action?.tableData?.queue_timestamp);
                const tokenRef = ref(db, `iQueue/Restricted/Businesses/${auth?.uid}/iQueues/${time.getFullYear()}/${time.getMonth()}/${time.getDate()}/${action?.tableData?.queue_uid}`);
                yield update(tokenRef, {
                    'serving_table': null,
                    'status': 0
                });
                const customerRef = ref(db, `iQueue/Restricted/Consumers/${action?.tableData?.customer_uid}/iQueues/${action?.tableData?.queue_uid}`);
                yield update(customerRef, {
                    'serving_table': null,
                    'status': 0
                });
            }
            const tableRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/FloorSetup/${action?.tableData?.id}`);
            yield update(tableRef, {
                'busy': false,
                'queue_uid': null,
                'customer_name': null,
                'customer_uid': null,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* loadFloorSizeAsync(action) {
    try {
        if (getAuth()) {
            const { auth } = yield select((state) => state.authReducer);
            const db = getDatabase();
            const tokenRef = ref(db, `iQueue/Public/Businesses/${auth?.uid}/profile`);
            yield get(tokenRef).then((data) => {
                let floorSize = {
                    floorHeight: data.val().floorHeight,
                    floorWidth: data.val().floorWidth
                }
                action.resolve(floorSize);
            }).catch((error) => {
                console.log(error);
            });
        }
    } catch (error) {
        console.log(error);
    }
}

export function* saveDefaultTableSaga() {
    yield takeEvery(types.SAVE_DEFAULT_TABLE, saveDefaultTableAsync);
}

export function* loadDefaultTableSaga() {
    yield takeEvery(types.LOAD_DEFAULT_TABLES, loadDefaultTablesAsync);
}

export function* deleteDefaultTableSaga() {
    yield takeEvery(types.DELETE_DEFAULT_TABLE, deleteDefaultTableAsync);
}

export function* saveFloorSetupSaga() {
    yield takeEvery(types.SAVE_FLOOR_SETUP, saveFloorDetailsAsync);
}

export function* loadFloorSetupSaga() {
    yield takeEvery(types.LOAD_FLOOR_SETUP, loadFloorDetailsAsync);
}

export function* assignCustomerToTableSaga() {
    yield takeEvery(types.ASSIGN_CUSTOMER_TO_TABLE, assignCustomerToTableAsync);
}

export function* unAssignCustomerFromTableSaga() {
    yield takeEvery(types.UNASSIGN_CUSTOMER_FROM_TABLE, unAssignCustomerFromTableAsync);
}

export function* saveFloorSizeSaga() {
    yield takeEvery(types.SAVE_FLOOR_SIZE, saveFloorSizeAsync);
}
export function* loadFloorSizeSaga() {
    yield takeEvery(types.LOAD_FLOOR_SIZE, loadFloorSizeAsync);
}