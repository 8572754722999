import React from 'react';
import { useDispatch } from 'react-redux';
import {
    Dialog, DialogContent, DialogTitle, Divider,
    Grid, FormGroup, FormControlLabel, Checkbox,
    Typography, Button, TextField
} from '@mui/material';
import { useStyles } from '../styles';
//Date picker
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
//Actions
import * as consumerActions from '../../../../redux/actions/Consumer';

export const ServicesConfirmDialog = ({ popupData, serviceConfirmDialog, setServiceConfirmDialog }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [date, setDate] = React.useState(new Date());

    const handleConfirmWalkIn = () => {
        dispatch(
            consumerActions.joinQueue({
                businessData: { ...popupData, created_timestamp: new Date(date).getTime() }
            })
        );
        setServiceConfirmDialog(false);
    }
    return (
        <Dialog fullWidth open={serviceConfirmDialog}>
            <DialogTitle>
                <Typography variant="h6" component="div" sx={{ fontSize: '18px', flexGrow: 1, fontFamily: 'Poppins' }}>
                    {popupData?.title}<br></br>
                    <Typography style={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 600 }}>{popupData?.address}</Typography>
                    <Typography style={{ fontFamily: 'Poppins', fontSize: '12px' }}>Select services and confirm</Typography>
                </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {popupData?.entryType ? (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label="Select Date and Time"
                                    value={date}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        ) : null}
                        <FormGroup>
                            {['Service 1', 'Service 2', 'Service 3', 'Service 4', 'Service 5'].map((item, index) => (
                                <FormControlLabel control={
                                    <Checkbox />}
                                    label={<Typography style={{ fontFamily: "Poppins", fontWeight: 600 }}>{item}</Typography>} />
                            ))}
                        </FormGroup>
                    </Grid>
                </Grid>
                <Divider />
                <Button variant="outlined" className={classes.popupButton} style={{ marginTop: '15px' }}
                    onClick={handleConfirmWalkIn}>
                    Confirm {popupData?.entryType ? "Appointment" : "Walk In"}
                </Button>
                <Button variant="outlined" className={classes.popupButton} style={{ marginTop: '15px', marginLeft: '10px' }}
                    onClick={() => setServiceConfirmDialog(false)}>
                    Cancel
                </Button>
            </DialogContent>
        </Dialog>
    );
}