import { useState, useCallback, useEffect, useRef } from 'react'
import { Container } from './Container.js'
import { CustomDragLayer } from './CustomDragLayer.js'
import { Grid } from '@mui/material'
import * as businessRestaurantActions from "../../../redux/actions/BusinessRestaurant";
import { useDispatch } from 'react-redux'
import { StaticSideBox } from './StaticSideBox.js'

export const TableSetup = () => {
    const dispatch = useDispatch();

    const [snapToGridAfterDrop, setSnapToGridAfterDrop] = useState(false);
    const [snapToGridWhileDragging, setSnapToGridWhileDragging] = useState(false);
    const [boxes, setBoxes] = useState({});
    const containerRef = useRef();

    const loadDefaultTablesFromDB = useCallback(() => {
        return new Promise((resolve, reject) => {
            dispatch(businessRestaurantActions.loadDefaultTables(resolve, reject));
        });
    }, [dispatch]);

    useEffect(() => {
        let defFunc = async () => {
            let tables = await loadDefaultTablesFromDB();
            setBoxes((b) => {
                return {
                    ...b,
                    ...tables
                }
            });
        }
        defFunc();
    }, [loadDefaultTablesFromDB]);

    // const saveDefaultTableToDB = useCallback(async (newTable) => {
    //     return new Promise((resolve, reject) => {
    //         dispatch(businessRestaurantActions.saveDefaultTable(newTable, resolve, reject));
    //     });
    // }, [dispatch]);

    // const handleAddTable = async () => {
    //     // let id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    //     let newTable =
    //     {
    //         // x: 0,
    //         // y: 0,
    //         top: 0,
    //         left: 0,
    //         capacity: 2,
    //         size: {
    //             height: 300,
    //             width: 300
    //         },
    //         type: "Generic",
    //         accessible: false,
    //         busy: false,
    //     }
    //     let uid = await saveDefaultTableToDB(newTable);
    //     setBoxes({
    //         ...boxes,
    //         [uid]: { ...newTable, id: uid }
    //     });
    // }

    return (
        <Grid container spacing={2}>
            <Grid style={{ height: '75vh', maxHeight: '75vh', overflowY: 'scroll', overflowX:'hidden', border: '1px solid black' }} item xs={2}>
                {/* <Button onClick={handleAddTable}>Add Table</Button> */}
                <div style={{ position: 'relative', display: 'flex',justifyContent:'center' }}>
                    {Object.keys(boxes).map((key, index) => (
                        <StaticSideBox containerRef={containerRef} key={key} id={key} {...boxes[key]} isOnScreen={false} setBoxes={setBoxes} top={index} />
                    ))}
                </div>
            </Grid>
            <Grid item xs={9.8}>
                <div>
                    <Container ref={containerRef} snapToGrid={snapToGridAfterDrop} />
                    <CustomDragLayer snapToGrid={snapToGridWhileDragging} />
                </div>
            </Grid>
        </Grid >
    )
}
