import createReducer from "../../../utils/createReducer";
import * as authTypes from "../../actions/BusinessRestaurant/types";

const initialState = {
    tables: {},
};

export const businessRestaurantReducer = createReducer(initialState, {
    [authTypes.UPDATE_FLOOR_CLIENT_SIDE](state, action) {
        return {
            ...state,
            tables: {
                ...action.tableData
            }
        }
    },
});