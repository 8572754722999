import { Grid } from '@mui/material';
import React from 'react';
import { AddCoupons } from './AddCoupons';
import { CouponsList } from './CouponsList';

export const Coupons = () => {
    return (
        <div style={{ margin: '10px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <AddCoupons />
                </Grid>
                <Grid item xs={12} md={8}>
                    <CouponsList />
                </Grid>
            </Grid>
        </div>
    )
}